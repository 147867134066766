import React, { Fragment, useState, useEffect, useRef, createRef } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section, Dropdown, Icon, Button } from "rbx"
import Components, { methods } from "./methods-and-components/methods-and-components"
import LoadingOverlay from "../loading-overlay"
import NotificationOverlay from "../notification-overlay"
import WorkspaceTitle from "./title"
import CalendarTitle from "./calendar-title"
import UsersService from "../../../services/users"
import EventsService from "../../../services/events"
import AttendancePackageTemplatesService from "../../../services/attendancePackageTemplates"
import "../../../styles/workspace.scss"
import "../../../styles/loading.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBox, faBoxesStacked, faFilter, faGift, faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faCalendarCheck } from '@fortawesome/free-regular-svg-icons'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import ptbrLocale from '@fullcalendar/core/locales/pt-br'
import interactionPlugin from '@fullcalendar/interaction'
import { DateTime } from "luxon"
import calendar from "../../../config/calendar"
import { faCog, faCheck } from '@fortawesome/free-solid-svg-icons';

const Calendar = (props) => {

  const [newEventDay, setNewEventDay] = useState(new Date(Date.now()))
  const [newEventStart, setNewEventStart] = useState(new Date(new Date(Date.now()).setHours(8, 0, 0)))
  const [newEventEnd, setNewEventEnd] = useState(new Date(new Date(Date.now()).setHours(9, 0, 0)))

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }

  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const [openConfig, setOpenConfig] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)

  const [eventsColors, setEventsColors] = useState(localStorage.getItem("events-colors") || "by-professional")
  const [hideSaturday, setHideSaturday] = useState(localStorage.getItem("hide-saturday") || "true")
  const [hideSunday, setHideSunday] = useState(localStorage.getItem("hide-sunday") || "true")
  const [slotMinTime, setSlotMinTime] = useState(localStorage.getItem("slot-min-time") || "08:00")
  const [slotMaxTime, setSlotMaxTime] = useState(localStorage.getItem("slot-max-time") || "19:00")

  const [viewTitle, setViewTitle] = useState(null)

  const [initialView, setInitialView] = useState(localStorage.getItem("initial-view") || "timeGridWeek")

  const [openDateClickMenu, setOpenDateClickMenu] = useState(false)
  const [openNewEvent, setOpenNewEvent] = useState(false)
  const [openNewPatient, setOpenNewPatient] = useState(false)
  const [newPatientRegistered, setNewPatientRegistered] = useState(null)
  const [openPayments, setOpenPayments] = useState(null)

  const [events, setEvents] = useState([])
  const [professionals, setProfessionals] = useState([])
  const [filters, setFilters] = useState({
    statusPending: true,
    statusDone: true,
    statusPatientNotPresent: true,
    noCost: true,
    paid: true,
    notPaid: true,
  })

  const [eventPreviewInfo, setEventPreviewInfo] = useState(null)
  const [eventPreviewHeight, setEventPreviewHeight] = useState(null)
  const calendarScrollTop = useRef(0)
  const calendarScrollerRef = useRef(null)

  const [eventClickInfo, setEventClickInfo] = useState(null)

  const [radioOptionChecked, setRadioOptionChecked] = useState("this-event")

  const [packageTemplates, setPackageTemplates] = useState([])
  const [chosenPackageTemplate, setChosenPackageTemplate] = useState(null)
  const [openNewAttendancePackage, setOpenNewAttendancePackage] = useState(false)
  const [openPackageTemplatesToChoose, setOpenPackageTemplatesToChoose] = useState(false)

  const [attendancePackageName, setAttendancePackageName] = useState("")
  const [patient, setPatient] = useState("")

  const calendarEl = useRef(undefined)
  const isDragging = useRef(false)
  const dontHandleDateClick = useRef(false)
  const hasMovedX = useRef(false)
  const hasMovedY = useRef(false)
  const startPosX = useRef(0)
  const startPosY = useRef(0)
  const currentTranslate = useRef(0)
  const prevTranslate = useRef(0)
  const animationID = useRef(undefined)

  const isMounted = useRef(false)

  const calendarRef = useRef()
  const eventPreviewRef = createRef()
  const dropdownRef = useRef()

  const getAndSetEvents = async () => {
    setLoading(true)
    await EventsService.getEvents()
    .then( (response) => {
      let events = response.data
      let eventsToRender = []
      let professionalsToSet = []
      events.map( (event) => {
        if (eventsColors == "by-status") {
          if (event.status == "Aguard. atendimento") {
            event.color = calendar.attendanceStatusColors.pending
          } else if (event.status == "Atendido") {
            event.color = calendar.attendanceStatusColors.done
          } else if (event.status == "Paciente não compareceu") {
            event.color = calendar.attendanceStatusColors.patientNotPresent
          }
        } else {
          event.color = event.professionalInfo[0].eventsColor
        }
        event.professional = event.professionalInfo[0].name
        event.patient = event.patientInfo[0].name
        event.attendancePackage = event.attendancePackage[0] ? event.attendancePackage[0].name : null
        event.title = `${event.patient.split(" ")[0]} [${event.professional.split(" ")[0].toUpperCase()}]`
        if (event.patientInfo[0].phoneNumber) {
          event.patientPhoneNumber = `${event.patientInfo[0].areaCode.lenght > 0 ? `(${event.patientInfo[0].areaCode}) ` : ""}${methods.maskAndSetPhoneNumber(event.patientInfo[0].phoneNumber)}`
        }
        eventsToRender.push(event)
        if (!professionalsToSet.some( (professional) => {
          return professional.id == event.professionalId
        })) {
          professionalsToSet.push({
            name: event.professionalInfo[0].name,
            id: event.professionalId,
            eventsColor: event.professionalInfo[0].eventsColor,
            show: true,
          })
        }
        setProfessionals(professionalsToSet)
      })
      setEvents(eventsToRender)
      setLoading(false)
    })
    .catch(function (error) {
      console.log(error)
      try {
        if (error.response.status == 401) {
          setLoading(false)
          setNotification({
            success: false,
            text: "Erro de autenticação. Você será redirecionado para a tela de login",
            callback: ( () => {
              setNotification(false)
              UsersService.logout()
              setRedirectToLogin(true)
            })
          })
        } else {
          setLoading(false)
          setNotification({
            success: false,
            text: error.response.data,
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      } catch {
        setLoading(false)
        setNotification({
          success: false,
          text: "Não foi possível atualizar as informações. Tente novamente",
          callback: ( () => {
            setNotification(false)
          })
        })
      }
    })
  }

  const getPackageTemplates = async () => {
    setPackageTemplates([])
    try {
      setLoading(true)
      await AttendancePackageTemplatesService.getAttendancePackageTemplates()
      .then( (response) => {
        setPackageTemplates(response.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      try {
        if (error.response.status == 401) {
          setLoading(false)
          setNotification({
            success: false,
            text: "Erro de autenticação. Você será redirecionado para a tela de login",
            callback: ( () => {
              setNotification(false)
              UsersService.logout()
              setRedirectToLogin(true)
            })
          })
        } else {
          setLoading(false)
          setNotification({
            success: false,
            text: error.response.data,
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      } catch {          
        setLoading(false)
        setNotification({
          success: false,
          text: "Não foi possível carregar as informações. Tente novamente",
          callback: ( () => {
            setNotification(false)
          })
        })
      }
    }
  }

  useEffect( () => {
    getPackageTemplates()
  }, [])

  useEffect( () => {
    dropdownRef.current.focus()
  }, [openDateClickMenu])

  useEffect( () => {
    setOpenPackageTemplatesToChoose(false)
  }, [openNewAttendancePackage])

  const filterEvents = () => {
    let eventsToRender = []
    events.map( (event) => {
      let paid = 0
      if (event.payments) {
        event.payments.map( (payment) => {
          if (payment) {
            paid += payment.value
          }
        })
      }
      if (
        (
          filters.statusPending && event.status == "Aguard. atendimento"
          ||
          filters.statusDone && event.status == "Atendido"
          ||
          filters.statusPatientNotPresent && event.status == "Paciente não compareceu"
        )
        &&
        (
          (filters.noCost && (event.price == 0 || event.price == "" || !event.price))
          ||
          (filters.paid && event.price > 0 && event.price <= paid)
          ||
          (filters.notPaid && (event.price > 0 && event.price > paid))
        )
        &&
        (
          professionals.some( (professional) => {
            return (professional.show && professional.id == event.professionalId)
          })
        )
      ) {
        event.display = "auto"
      } else {
        event.display = "none"
      }
      eventsToRender.push(event)
    })
    setEvents(eventsToRender)
  }

  const renderEventContent = (eventInfo) => {
    let paid = 0
    if (eventInfo.event._def.extendedProps.payments) {
      eventInfo.event._def.extendedProps.payments.map( (payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    if (eventInfo.event._def.extendedProps.attendancePackageId) {
      paid = "Pacote de atendimento"
    } else if (eventInfo.event._def.extendedProps.price == 0 || !eventInfo.event._def.extendedProps.price) {
      paid = "Sem cobrança"
    } else if (eventInfo.event._def.extendedProps.price > paid) {
      paid = "Pendente"
    } else if (eventInfo.event._def.extendedProps.price <= paid) {
      paid = "Pago"
    } 
    const paidElement = <span className={paid == "Pacote de atendimento" ? "has-background-primary has-text-white ml-2 event-icon" : paid == "Sem cobrança" ? "has-background-grey has-text-white ml-2 event-icon" : paid == "Pago" ? "has-background-success has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon"} >{paid == "Pacote de atendimento" ? "▣" : "$"}</span>
    const eventStatus = eventInfo.event._def.extendedProps.status
    const statusElement = <span className={eventStatus == "Atendido" ? "has-background-success has-text-white ml-2 event-icon" : (eventStatus == "Aguard. atendimento" ? "has-background-info has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon") } >{eventStatus == "Atendido" ? "✓" : (eventStatus == "Aguard. atendimento" ? "⧗" : "✕")}</span>
    if (eventInfo.view.type == "dayGridMonth") {
      return (
        <Fragment >
          <div className="fc-daygrid-event-dot" style={{ borderColor: eventInfo.event.borderColor }}></div>
          <div className="fc-event-time">{eventInfo.timeText}</div>
          <div className="fc-event-title">{eventInfo.event._def.title}
            {statusElement}
            {paidElement}
          </div>
        </Fragment>
      )
    } else if (eventInfo.view.type == "timeGridWeek" || eventInfo.view.type == "timeGridDay") {
      return (
        <div className="fc-event-main-frame">
          <div className="fc-event-time">{eventInfo.timeText}
            {statusElement}
            {paidElement}
          </div>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">{eventInfo.event._def.title}</div>
          </div>
        </div>
      )
    } else if (eventInfo.view.type == "listDay") {
      return (
        <span>
          <a>{eventInfo.event._def.title}</a>
          {statusElement}
          {paidElement}
        </span>
      )
    }
  }

  const handleDateClick = (dateClickInfo) => {
    if (!dontHandleDateClick.current) {
      calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
      calendarScrollTop.current = calendarScrollerRef.current.scrollTop
      if (!hasMovedX.current && !hasMovedY.current) {
        let element = dateClickInfo.jsEvent.target
        let left = window.innerWidth - dateClickInfo.jsEvent.clientX > 260 ? dateClickInfo.jsEvent.clientX : dateClickInfo.jsEvent.clientX - 241
        let top = window.innerHeight - dateClickInfo.jsEvent.clientY > 110 ? dateClickInfo.jsEvent.clientY : dateClickInfo.jsEvent.clientY - 107
        if (openDateClickMenu) {
          setOpenDateClickMenu(false)
        } else {
          setOpenDateClickMenu({
            date: dateClickInfo.date,
            allDay: dateClickInfo.allDay,
            top: top,
            left: left,
          })
        }
      }
      calendarScrollerRef.current.scrollTop = calendarScrollTop.current
    }
  }

  const handleEventDropOrResize = (eventDropInfo) => {
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollTop.current = calendarScrollerRef.current.scrollTop
    setEventPreviewInfo(null)
    const weekdayNumber = new Date(eventDropInfo.oldEvent.start).getDay()
    const thisWeekday = weekdayNumber == 0 ? "domingo" : weekdayNumber == 1 ? "segunda-feira" : weekdayNumber == 2 ? "terça-feira" : weekdayNumber == 3 ? "quarta-feira" : weekdayNumber == 4 ? "quinta-feira" : weekdayNumber == 5 ? "sexta-feira" : "sábado"
    const dateDiff = new Date(new Date(new Date(eventDropInfo.event.start).setHours(0, 0, 0)).getTime() - new Date(new Date(eventDropInfo.oldEvent.start).setHours(0, 0, 0)).getTime()).getTime() / 1000 / 60 / 60 / 24
    const oldStart = DateTime.fromJSDate(eventDropInfo.oldEvent.start).toLocaleString(DateTime.TIME_24_SIMPLE)
    const oldEnd = DateTime.fromJSDate(eventDropInfo.oldEvent.end).toLocaleString(DateTime.TIME_24_SIMPLE)
    const newStart = DateTime.fromJSDate(eventDropInfo.event.start).toLocaleString(DateTime.TIME_24_SIMPLE)
    const newEnd = DateTime.fromJSDate(eventDropInfo.event.end).toLocaleString(DateTime.TIME_24_SIMPLE)
    setNotification({
      withCancel: true,
      success: true,
      title: `Alteração de agendamento${eventDropInfo.event._def.extendedProps.recurrenceId ? " recorrente" : ""}`,
      text:
        <Fragment>
          {dateDiff != 0 &&
            <p className="mb-3">{`Dias a ${dateDiff >= 0 ? "avançar" : "retroceder"}:`} <span className="has-text-weight-normal">{`${dateDiff < 0 ? dateDiff * (-1) : dateDiff} dia${dateDiff < (-1) || dateDiff > 1  ? "s" : ""}`}</span></p>
          }
          {(oldStart != newStart || oldEnd != newEnd) &&
            <Fragment>
              <p>Horário anterior:</p>
              <p className="mb-3 has-text-weight-normal">{`Das ${oldStart} às ${oldEnd}`}</p>
              <p>Novo horário:</p>
              <p className="mb-3 has-text-weight-normal">{`Das ${newStart} às ${newEnd}`}</p>
            </Fragment>
          }
        </Fragment>,
      radioOptionChecked: radioOptionChecked,
      setRadioOptionChecked: setRadioOptionChecked,
      radioOptions: eventDropInfo.event._def.extendedProps.recurrenceId ? [
        {
          value: "this-event",
          text: "Este agendamento"
        },
        {
          value: "this-weekday",
          text: `Agendamentos de ${thisWeekday} do paciente, do mesmo grupo de agendamentos`
        },
        {
          value: "this-and-next",
          text: "Este e os agendamentos seguintes do paciente, do mesmo grupo de agendamentos"
        },
        {
          value: "all",
          text: "Todos os agendamentos do paciente, do mesmo grupo de agendamentos"
        },
      ] : undefined,
      cancelCallback: ( () => {
        calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
        calendarScrollTop.current = calendarScrollerRef.current.scrollTop
        eventDropInfo.revert()
        setNotification(false)
      }),
      confirmCallback: ( async (localRadioOptionChecked) => {
        setLoading(true)
        setNotification(false)
        let eventToEdit = {
          eventId: eventDropInfo.event._def.extendedProps._id,
          recurrenceId: eventDropInfo.event._def.extendedProps.recurrenceId,
          editOption: localRadioOptionChecked,
          weekdayNumber: weekdayNumber,
          start: eventDropInfo.event.start,
          end: eventDropInfo.event.end,
        }
        await EventsService.editEvent(eventToEdit)
        .then( () => {
          getAndSetEvents()
          .then( () => {
            setFormChanged(false)
          })
          setNotification({
            success: true,
            text: "Agendamento alterado.",
            callback: () => {
              setNotification(false)
              setOpenNewEvent(false)
            }
          })
        })
        .catch(function (error) {
          console.log(error)
          try {
            if (error.response.status == 401) {
              setLoading(false)
              setNotification({
                success: false,
                text: "Erro de autenticação. Você será redirecionado para a tela de login",
                callback: ( () => {
                  setNotification(false)
                  UsersService.logout()
                  setRedirectToLogin(true)
                })
              })
            } else {
              setLoading(false)
              setNotification({
                success: false,
                text: error.response.data,
                callback: ( () => {
                  eventDropInfo.revert()
                  setNotification(false)
                  setOpenNewEvent(false)
                })
              })
            }
          } catch {          
            setLoading(false)
            setNotification({
              success: false,
              text: "Não foi possível realizar o agendamento. Tente novamente",
              callback: ( () => {
                setNotification(false)
                setOpenNewEvent(false)
              })
            })
          }
        })
      })
    })
    calendarScrollerRef.current.scrollTop = calendarScrollTop.current
  }

  const handleEventMouseEnter = (mouseEnterInfo) => {
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollTop.current = calendarScrollerRef.current.scrollTop
    if (!eventClickInfo) {
      let element = mouseEnterInfo.jsEvent.target
      if (/fc-event-title\sfc-sticky|event-icon/.test(element.classList.value)) {
        element = element.parentElement.parentElement.parentElement.parentElement
      } else if (/fc-event-title-container|fc-event-time/.test(element.classList.value)) {
        element = element.parentElement.parentElement.parentElement
      } else if (/fc-event-main-frame|fc-event-resizer/.test(element.classList.value)) {
        element = element.parentElement.parentElement
      } else if (/fc-event-main/.test(element.classList.value)) {
        element = element.parentElement
      }
      let rect = element.getBoundingClientRect()
      let left = window.innerWidth - rect.left > 260 ? rect.left : null
      let right = left == null ? window.innerWidth - rect.right : null
      let start = null
      let end = null
      if (mouseEnterInfo.view.type == "dayGridMonth") {
        start = mouseEnterInfo.event.start
        end = mouseEnterInfo.event.end
      }
      let price = mouseEnterInfo.event._def.extendedProps.price ? mouseEnterInfo.event._def.extendedProps.price : 0
      price = methods.numberToPrice(price)
      let paid = 0
      if (mouseEnterInfo.event._def.extendedProps.payments) {
        mouseEnterInfo.event._def.extendedProps.payments.map( (payment) => {
          if (payment) {
            paid += payment.value
          }
        })
      }
      let debt = price != 0 ? mouseEnterInfo.event._def.extendedProps.price - paid : 0
      debt = methods.numberToPrice(debt.toFixed(2))
      paid = methods.numberToPrice(paid.toFixed(2))
      setEventPreviewInfo({
        attendancePackage: mouseEnterInfo.event._def.extendedProps.attendancePackage,
        attendancePackageId: mouseEnterInfo.event._def.extendedProps.attendancePackageId,
        top: rect.top,
        left: left,
        right: right,
        start: start,
        end: end,
        professional: mouseEnterInfo.event._def.extendedProps.professional,
        patient: mouseEnterInfo.event._def.extendedProps.patient,
        description: mouseEnterInfo.event._def.extendedProps.description,
        status: mouseEnterInfo.event._def.extendedProps.status,
        price: price,
        paid: paid,
        debt: debt
      })
    }
    calendarScrollerRef.current.scrollTop = calendarScrollTop.current
  }
  
  const handleEventMouseLeave = (mouseLeaveInfo) => {
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollTop.current = calendarScrollerRef.current.scrollTop
    setEventPreviewInfo(null)
    calendarScrollerRef.current.scrollTop = calendarScrollTop.current
  }

  const handleEventClick = (eventClickInfo) => {
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollTop.current = calendarScrollerRef.current.scrollTop
    setEventPreviewInfo(null)
    let price = eventClickInfo.event._def.extendedProps.price ? eventClickInfo.event._def.extendedProps.price : 0
    price = methods.numberToPrice(price)
    let paid = 0
    if (eventClickInfo.event._def.extendedProps.payments) {
      eventClickInfo.event._def.extendedProps.payments.map( (payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    let debt = price != 0 ? eventClickInfo.event._def.extendedProps.price - paid : 0
    debt = methods.numberToPrice(debt.toFixed(2))
    paid = methods.numberToPrice(paid.toFixed(2))
    setEventClickInfo({
      attendancePackage: eventClickInfo.event._def.extendedProps.attendancePackage,
      attendancePackageId: eventClickInfo.event._def.extendedProps.attendancePackageId,
      id: eventClickInfo.event._def.extendedProps._id,
      recurrenceId: eventClickInfo.event._def.extendedProps.recurrenceId,
      recurrenceWeekdays: eventClickInfo.event._def.extendedProps.recurrenceWeekdays,
      date: eventClickInfo.event.start,
      start: eventClickInfo.event.start,
      end: eventClickInfo.event.end,
      patient: eventClickInfo.event._def.extendedProps.patient,
      patientId: eventClickInfo.event._def.extendedProps.patientId,
      patientPhone: eventClickInfo.event._def.extendedProps.patientPhone,
      professional: eventClickInfo.event._def.extendedProps.professional,
      professionalId: eventClickInfo.event._def.extendedProps.professionalId,
      description: eventClickInfo.event._def.extendedProps.description,
      status: eventClickInfo.event._def.extendedProps.status,
      payments: eventClickInfo.event._def.extendedProps.payments,
      price: price,
      paid: paid,
      debt: debt,
      hasRegisteredAttendance: eventClickInfo.event._def.extendedProps.attendanceDateTime ? true : false,
    })
    calendarScrollerRef.current.scrollTop = calendarScrollTop.current
  }

  function touchStart (event) {
    startPosX.current = event.touches[0].clientX
    startPosY.current = event.touches[0].clientY
    isDragging.current = true
    animationID.current = requestAnimationFrame(animation)
    calendarEl.current.classList.add('grabbing')
  }

  function touchMove (event) {
    if (isDragging.current) {
      const currentPositionX = event.touches[0].clientX
      const currentPositionY = event.touches[0].clientY
      if (!hasMovedX.current && !hasMovedY.current && (currentPositionY - startPosY.current > 30 || currentPositionY - startPosY.current < -30)) {
        hasMovedY.current = true
      }
      if (!hasMovedY.current && (hasMovedX.current || currentPositionX - startPosX.current > 30 || currentPositionX - startPosX.current < -30)) {
        dontHandleDateClick.current = true
        hasMovedX.current = true
        currentTranslate.current = currentPositionX - startPosX.current
      }
    }
  }

  function touchEnd() {
    hasMovedX.current = false
    hasMovedY.current = false
    isDragging.current = false
    setTimeout(() => {
      dontHandleDateClick.current = false
    }, 10)
    if (currentTranslate.current < -100) {
      calendarNav("next")
    } else if (currentTranslate.current > 100) {
      calendarNav("prev")
    } else {
      calendarEl.current.style.transform = `translateX(0px)`
      currentTranslate.current = 0
    }
    calendarEl.current.classList.remove('grabbing')
    cancelAnimationFrame(animationID)
  }

  function calendarNav (direction) {
    let calendarApi = calendarRef.current.getApi()
    if (direction == "next") {
      calendarEl.current.style.opacity = 0
      setTimeout(() => {
        calendarEl.current.style.transform = `translateX(-${window.innerWidth}px)`        
        currentTranslate.current = 0
      }, 10)
      setTimeout(() => {
        calendarEl.current.style.transform = `translateX(${window.innerWidth/2}px)`
        calendarApi.next()
      }, 110)
      setTimeout(() => {
        calendarEl.current.style.transform = `translateX(0px)`
        calendarEl.current.style.opacity = 1
        setViewTitle(calendarApi.view.title)
      }, 210)
    } else {
      calendarEl.current.style.opacity = 0
      setTimeout(() => {
        calendarEl.current.style.transform = `translateX(${window.innerWidth}px)`
        currentTranslate.current = 0
      }, 10)
      setTimeout(() => {
        calendarEl.current.style.transform = `translateX(-${window.innerWidth/2}px)`
        calendarApi.prev()
      }, 110)
      setTimeout(() => {
        calendarEl.current.style.transform = `translateX(0px)`
        calendarEl.current.style.opacity = 1
        setViewTitle(calendarApi.view.title)
      }, 210)
    }
  }

  function animation () {
    setSliderPosition()
    if (isDragging.current) {
      requestAnimationFrame(animation)
    }
  }

  function setSliderPosition() {
    calendarEl.current.style.transform = `translateX(${currentTranslate.current}px)`
  }

  useEffect( () => {
    getAndSetEvents()
    window.oncontextmenu = (event) => {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }, [])

  useEffect( () => {
    const eventListener = (event) => {
      event.preventDefault()
      if (formChangedRef.current) {
        let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }
    window.addEventListener('beforeunload', eventListener)
    return () => {
      window.removeEventListener('beforeunload', eventListener)
    }
  }, [])

  useEffect(() => {
    const calendarElement = document.querySelector('.fc-view-harness')
    calendarElement.addEventListener("touchstart", touchStart)
    calendarElement.addEventListener("touchmove", touchMove)
    calendarElement.addEventListener("touchend", touchEnd)
    calendarElement.addEventListener("wheel", handleEventMouseLeave)
    calendarEl.current = calendarElement
    return () => {
      calendarElement.removeEventListener("touchstart", touchStart)
      calendarElement.removeEventListener("touchmove", touchMove)
      calendarElement.removeEventListener("touchend", touchEnd)
    }
  }, [])

  useEffect( () => {
    if (isMounted.current) {
      getAndSetEvents()
    } else  {
      isMounted.current = true
    }
  }, [eventsColors])

  useEffect( () => {
    filterEvents()
  }, [filters])

  useEffect( () => {
    let calendarApi = calendarRef.current.getApi()
    setViewTitle(calendarApi.view.title)
  }, [])

  useEffect( () => {
    if (eventPreviewRef.current) {
      setEventPreviewHeight(eventPreviewRef.current.clientHeight)
    }
  }, [eventPreviewInfo])

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Fragment>

    <Section className="pb-0">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}
      {eventPreviewInfo &&
        <Components.EventPreview
          eventPreviewRef={eventPreviewRef}
          eventPreviewInfo={eventPreviewInfo}
          eventPreviewHeight={eventPreviewHeight}
          />
      }

      <WorkspaceTitle>
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faCalendar} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Agenda</span>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Dropdown>
              <Dropdown.Trigger>
                <Components.ToogleButton
                  color="success"
                  icon={faPlus}
                  setOpen={null}
                  hasArrowDown={true}
                >
                  Novo
                </Components.ToogleButton>
              </Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Content>
                  <Dropdown.Item onClick={() => setOpenNewEvent(true)}>
                    <Icon color="info">
                      <FontAwesomeIcon icon={faBox} />
                    </Icon>
                    <span className="has-text-weight-normal">Agendamento</span>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => setOpenPackageTemplatesToChoose(true)}>
                    <Icon color="info">
                      <FontAwesomeIcon icon={faBoxesStacked} />
                    </Icon>
                    <span className="has-text-weight-normal">Pacote de Atendimentos</span>
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown.Menu>
            </Dropdown>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Components.ToogleButton
              color="primary"
              icon={faFilter}
              setOpen={setOpenFilters}
            >
              Filtros
            </Components.ToogleButton>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Components.ToogleButton
              color="warning"
              icon={faCog}
              setOpen={setOpenConfig}
            >
              Configurar
            </Components.ToogleButton>
          </Column>
        </Column.Group>
      </WorkspaceTitle>

      <CalendarTitle>
        {viewTitle}
      </CalendarTitle>

      <Dropdown ref={dropdownRef} as="button" managed active={true} className="button date-click-menu m-0" style={{ textAlign: "start", border: "0", boxShadow: "unset", padding: "0px", width: "0px", height: "0px", display: openDateClickMenu ? "inherit" : "none", top: openDateClickMenu.top, left: openDateClickMenu.left, }} onBlur={(event) => {if (!event.currentTarget.contains(event.relatedTarget)) null}} >
        <Dropdown.Menu >
          <Dropdown.Content>
            <Dropdown.Item
              onClick={e => {
                let dt = DateTime.fromJSDate(openDateClickMenu.date)
                setNewEventDay(openDateClickMenu.date)
                if (openDateClickMenu.allDay) {
                  setNewEventStart(new Date(dt.plus({ hours: 8 }).toISO()))
                  setNewEventEnd(new Date(dt.plus({ hours: 9 }).toISO()))
                } else {
                  setNewEventStart(openDateClickMenu.date)
                  setNewEventEnd(new Date(dt.plus({ hours: 1 }).toISO()))
                }
                setOpenNewEvent(true)
                setOpenDateClickMenu(false)
              }}
            >
              <Icon color="info">
                <FontAwesomeIcon icon={faBox} />
              </Icon>
              <span className="has-text-weight-normal">Agendamento</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => {setOpenPackageTemplatesToChoose(true); setOpenDateClickMenu(false)}}>
              <Icon color="info">
                <FontAwesomeIcon icon={faBoxesStacked} />
              </Icon>
              <span className="has-text-weight-normal">Pacote de Atendimentos</span>
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Menu>
      </Dropdown>

      {openFilters &&
        <Components.CalendarFiltersOverlay
          filters={filters}
          setFilters={setFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          professionals={professionals}
          setProfessionals={setProfessionals}
        />
      }

      {openConfig &&
        <Components.CalendarConfigOverlay
          setOpenConfig={setOpenConfig}
          eventsColors={eventsColors}
          setEventsColors={setEventsColors}
          hideSaturday={hideSaturday}
          setHideSaturday={setHideSaturday}
          hideSunday={hideSunday}
          setHideSunday={setHideSunday}          
          slotMinTime={slotMinTime}
          setSlotMinTime={setSlotMinTime}
          slotMaxTime={slotMaxTime}
          setSlotMaxTime={setSlotMaxTime}
          professionals={professionals}
          setProfessionals={setProfessionals}
          getAndSetEvents={getAndSetEvents}
          setFormChanged={setFormChanged}          
          setNotification={setNotification}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}

        />
      }

      {openNewEvent &&
        <Components.NewEventOverlay
          setOpenNewEvent={setOpenNewEvent}
          newEventDay={newEventDay}
          setNewEventDay={setNewEventDay}
          newEventStart={newEventStart}
          setNewEventStart={setNewEventStart}
          newEventEnd={newEventEnd}
          setNewEventEnd={setNewEventEnd}
          setOpenNewPatient={setOpenNewPatient}
          newPatientRegistered={newPatientRegistered}
          setNewPatientRegistered={setNewPatientRegistered}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          getAndSetEvents={getAndSetEvents}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

      {openPackageTemplatesToChoose &&
        <Components.PackageTemplatesToChoose
          packageTemplates={packageTemplates}
          setChosenPackageTemplate={setChosenPackageTemplate}
          setOpenPackageTemplatesToChoose={setOpenPackageTemplatesToChoose}
          setOpenNewAttendancePackage={setOpenNewAttendancePackage}
          setFormChanged={setFormChanged}
          setNotification={setNotification}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

      {openNewAttendancePackage &&
        <Components.NewAttendancePackage
          chosenPackageTemplate={chosenPackageTemplate}
          setOpenNewAttendancePackage={setOpenNewAttendancePackage}
          getAndSetEvents={getAndSetEvents}
          setFormChanged={setFormChanged}
          setNotification={setNotification}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

      {openNewPatient &&
        <Components.NewPatientOverlay
          openNewPatient={openNewPatient}
          setOpenNewPatient={setOpenNewPatient}
          setNewPatientRegistered={setNewPatientRegistered}
          setOpenNewEvent={setOpenNewEvent}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }


      {eventClickInfo &&
        <Components.EventClickOverlay        
          eventClickInfo={eventClickInfo}
          setEventClickInfo={setEventClickInfo}
          setOpenPayments={setOpenPayments}
          setFocusedItem={props.setFocusedItem}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          getAndSetEvents={getAndSetEvents}
          setRedirectToLogin={setRedirectToLogin}
          setObjectToEdit={props.setObjectToEdit}
        />
      }

      {openPayments &&
        <Components.PaymentsOverlay
          eventClickInfo={eventClickInfo}
          setOpenPayments={setOpenPayments}
          setEventClickInfo={setEventClickInfo}
          getAndSetEvents={getAndSetEvents}
          setFormChanged={setFormChanged}
          setNotification={setNotification}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

    </Section>

    <Section className="calendar-section"
      onClick={e => {
        let calendarApi = calendarRef.current.getApi()
        localStorage.setItem("initial-view", calendarApi.view.type)
        setViewTitle(calendarApi.view.title)
      }}
    >

      <FullCalendar
        ref={calendarRef}
        plugins={[ listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin ]}
        locale={ptbrLocale}
        height="calc(100vh - 190px)"
        expandRows={true}
        headerToolbar={
          {
            start: "today prev,next",
            center: "title",
            end: "listDay,timeGridDay,timeGridWeek,dayGridMonth"
          }
        }
        slotLabelFormat={
          {
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short'
          }
        }
        stickyHeaderDates={true}
        initialView={initialView}
        slotMinTime={slotMinTime}
        slotMaxTime={slotMaxTime}
        slotDuration="00:30"
        allDaySlot={false}
        hiddenDays={
          hideSaturday == "true" && hideSunday == "true" ? [0,6] :
          hideSaturday == "true" && hideSunday == "false" ? [6] :
          hideSaturday == "false" && hideSunday == "true" ? [0] :
          []
        }
        editable={true}
        nowIndicator={true}
        navLinks={true}
        navLinkDayClick={
          (date, jsEvent) => {
            let calendarApi = calendarRef.current.getApi()
            calendarApi.changeView('timeGridDay', date)
          }
        }

        events={events}

        eventContent={renderEventContent}
        
        dateClick={handleDateClick}
        eventDragStart={() => {
          calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
          calendarScrollTop.current = calendarScrollerRef.current.scrollTop
          setEventPreviewInfo(null)
          cancelAnimationFrame(animationID)
          calendarEl.current.style.transform = `translateX(0px)`
          currentTranslate.current = 0
          isDragging.current = false
          if (calendarScrollerRef.current) {
            calendarScrollerRef.current.scrollTop = calendarScrollTop.current
          }
        }}
        eventDrop={handleEventDropOrResize}
        eventResize={handleEventDropOrResize}
        eventMouseEnter={handleEventMouseEnter}
        eventMouseLeave={handleEventMouseLeave}
        eventClick={handleEventClick}

      />

    </Section>

    </Fragment>
  )
}

export default Calendar;