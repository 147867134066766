import React, { useState, useEffect, Fragment } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section } from "rbx"
import UsersService from "../../services/users"
import CompaniesService from "../../services/companies"
import DiskSpaceService from "../../services/diskSpace"
import Header from "../components/header"
import SidebarMenu from "../components/sidebar-menu"
import Workspace from "../components/workspace"
import LoadingAnimation from "../components/loading-animation"
import LoadingOverlay from "../components/loading-overlay"
import "../../styles/workspace.scss"
import "../../styles/loading.scss"
import { systemLogo, noLogo } from "../../config/sidebarMenu"
import NotificationOverlay from '../components/notification-overlay'

const AppView = () => {
  const [objectToEdit, setObjectToEdit] = useState(null)
  const [headerUserName, setHeaderUserName] = useState("")
  const [logoImage, setLogoImage] = useState(null)
  const [tradingName, setTradingName] = useState(null)
  const [focusedItem, setFocusedItem] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [notification, setNotification] = useState(null)
  const [loading, setLoading] = useState(true)
  const [redirectToLogin, setRedirectToLogin] = useState(false)  

  const authConfirm = async () => {
    setHeaderUserName(<LoadingAnimation loaderClass="element-loader" />)
    UsersService.refreshIndexHtml()
    .then( (response) => {
      const lastModified = response.headers["last-modified"]
      if (localStorage.getItem("last-modified") != lastModified) {
        localStorage.setItem("last-modified", lastModified)
        window.location.reload()
      }      
    })
    UsersService.autoLogin()
    .then(() => {
      setHeaderUserName(JSON.parse(localStorage.getItem('user')).name)
      setLoading(false)
    })
    .catch( (e) => {
      setRedirectToLogin(true)
      UsersService.logout()
    })
  }

  const changeHeaderUserName = (changedUserName) => {
    setHeaderUserName(changedUserName)
  }

  useEffect( () => {
    if (localStorage.getItem('user') && localStorage.getItem('auth-token')) {
      authConfirm()
    }
    const test = async () => {
      let response = await DiskSpaceService.diskSpace()
      console.log(response.data)
    }
    if (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).isSystemAdm) {
      test()
    }
  }, [])

  const getCompanyLogo = async () => {
    let user = JSON.parse(localStorage.getItem("user"))
    const response = await CompaniesService.getLogo({ id: user.companyId })
    return response
  }

  useEffect( () => {
    getCompanyLogo()
    .then( (response) => {
      if (response.data.tradingName) {
        setTradingName(response.data.tradingName)
        if (response.data.image) {
          setLogoImage(response.data.image)
        } else {
          setLogoImage(noLogo)
        }
      } else {
        setLogoImage(systemLogo)
        setTradingName("Administrador do sistema")
      }
    })
    .catch( (error) => {
      console.log(error)
      setLogoImage(systemLogo)
      setTradingName("Administrador do sistema")
    })
  }, [])

  if (!localStorage.getItem('user') || !localStorage.getItem('auth-token')) {
    return <Redirect push to={{ pathname: "/login" }} />
  }

  if (redirectToLogin) {
    return <Redirect push to={{ pathname: "/login" }} />
  }

  if (loading) {
    return <LoadingOverlay loaderClass="app-loader"/>
  }

  return (
    <div className="app-screen">

      {notification && <NotificationOverlay notification={notification} />}

      <Header focusedItem={focusedItem} setFocusedItem={setFocusedItem} userName={headerUserName} setObjectToEdit={setObjectToEdit} logoImage={logoImage} setLogoImage={setLogoImage} tradingName={tradingName} setTradingName={setTradingName} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} notification={notification} setNotification={setNotification} />
      
      <Section className="main-section p-0">

        <Column.Group className="workspace m-0">
          <Column id="menu-column" size={2} className="menu-column p-0 is-hidden-touch">
            <SidebarMenu focusedItem={focusedItem} setFocusedItem={setFocusedItem} setObjectToEdit={setObjectToEdit} logoImage={logoImage} setLogoImage={setLogoImage} tradingName={tradingName} setTradingName={setTradingName} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} notification={notification} setNotification={setNotification} />
          </Column>

          <Column className="workspace-column p-0">
            <Workspace
              objectToEdit={objectToEdit}
              setObjectToEdit={setObjectToEdit}
              changeHeaderUserName={changeHeaderUserName}
              setLogoImage={setLogoImage}
              setTradingName={setTradingName}
              logoImage={logoImage}
              focusedItem={focusedItem}
              setFocusedItem={setFocusedItem}
              hasUnsavedChanges={hasUnsavedChanges}
              setHasUnsavedChanges={setHasUnsavedChanges}
              notification={notification}
              setNotification={setNotification}              
            />
          </Column>
        </Column.Group>

      </Section>
    </div>
  )
};

export default AppView;