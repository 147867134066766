import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import CompaniesService from "../../../../services/companies"
import UsersService from "../../../../services/users"
import "../../../../styles/workspace.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons'

const Companies = (props) => {

  const [name, setName] = useState("")

  const [tradingName, setTradingName] = useState("")

  const [cnpj, setCnpj] = useState("")
  const [cnpjError, setCnpjError] = useState(null)

  const [initialDate, setInitialDate] = useState("")
  const [initialDateError, setInitialDateError] = useState(null)
  const [finalDate, setFinalDate] = useState("")
  const [finalDateError, setFinalDateError] = useState(null)

  const [areaPhysio, setAreaPhysio] = useState(false)
  const [areaOccupational, setAreaOccupational] = useState(false)
  const [areaPilates, setAreaPilates] = useState(false)
  const [areaAesthetics, setAreaAesthetics] = useState(false)
  const [areaOthers, setAreaOthers] = useState(false)

  const [city, setCity] = useState("")
  const [uf, setUf] = useState("")

  const [openNewCompany, setOpenNewCompany] = useState(false)

  const [companies, setCompanies] = useState(null)
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)
  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = React.useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }

  const mandatoryFields = []

  const errorList = [
    cnpjError,
    initialDateError,
    finalDateError,
  ]

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
      setNotification({
        success: false,
        text: "Favor preencher todos os campos obrigatórios",
        callback: () => {
          setNotification(false)
        }
      })
    } else if (methods.hasErrors(errorList)) {
      setNotification({
        success: false,
        text: methods.errorsToShow(errorList),
        callback: () => {
          setNotification(false)
        }
      })
    } else {
      setLoading(true)
      let searchFields = {
        name: name,
        tradingName: tradingName,
        cnpj: cnpj.replace(/\D/g,""),
        initialDate: ( () => {
          if (initialDate == "") {
            return new Date(1900, 0, 1)
          } else {
            return methods.stringToDate(initialDate)
          }
        })(),
        finalDate: ( () => {
          if (finalDate == "") {
            return new Date(2100, 0, 1)
          } else {
            return methods.stringToDate(finalDate)
          }
        })(),
      }
      await CompaniesService.search(searchFields)
      .then( (response) => {
        setCompanies(response.data)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        try {
          console.log(error.response.data)
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setNotification({
              success: false,
              text: "Não foi possível pesquisar as empresas. Tente novamente",
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {
          setNotification({
            success: false,
            text: "Não foi possível pesquisar as empresas. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      })
    }
  }

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle >
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faBuilding} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Empresas</span>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Components.ToogleButton
              color="success"
              icon={faPlus}
              setOpen={setOpenNewCompany}
            >
              Nova
            </Components.ToogleButton>
          </Column>
        </Column.Group>
      </WorkspaceTitle>

      <form onSubmit={handleSubmit}  >

        <Components.SearchFiltersCard>

          <Column.Group multiline>

            <Components.GenericInput
              required={false}
              elementName="company-name"
              value={name}
              setValue={setName}
            >
              Nome empresarial:
            </Components.GenericInput>

            <Components.GenericInput
              required={false}
              elementName="trading-name"
              value={tradingName}
              setValue={setTradingName}
            >
              Nome fantasia:
            </Components.GenericInput>

            <Components.Cnpj
              required={false}
              cnpj={cnpj}
              cnpjError={cnpjError}
              setCnpj={setCnpj}
              setCnpjError={setCnpjError}
            />

            <Components.DatePeriod
              required={false}
              label1="Período de cadastro:"
              label2="Até:"
              elementName="date"
              initialDate={initialDate}
              initialDateError={initialDateError}
              finalDate={finalDate}
              finalDateError={finalDateError}
              setInitialDate={setInitialDate}
              setInitialDateError={setInitialDateError}
              setFinalDate={setFinalDate}
              setFinalDateError={setFinalDateError}
            />

          </Column.Group>

        </Components.SearchFiltersCard>

        {companies && 
          <Components.SearchCompaniesResults
            companies={companies}
            setObjectToEdit={props.setObjectToEdit}
          />
        }

        {openNewCompany &&
          <Components.NewCompanyOverlay
            setOpenNewCompany={setOpenNewCompany}
            setObjectToEdit={props.setObjectToEdit}
            setNotification={setNotification}
            setFormChanged={setFormChanged}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </form>

    </Section>
  )
}

export default Companies;