const calendar = {
  attendanceStatusColors: {
    pending: "#2070e0",
    done: "#10c060",
    patientNotPresent: "#e04040",
  },
  eventsColors: [
    "#33b679", "#039be5", "#e67c73", "#8e24aa", "#ef6c00", "#7986cb",
    "#e4c441", "#0b8043", "#ad1457", "#795548", "#f4511e", "#3f51b5",
    "#c0ca33", "#009688", "#d81b60", "#616161", "#f09300", "#b39ddb",
    "#7cb342", "#4285f4", "#d50000", "#a79b8e", "#f6bf26", "#9e69af",
  ]
}

export default calendar