import React, { useState, useRef, Fragment } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section, Dropdown, Icon } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import PatientsService from "../../../../services/patients"
import UsersService from "../../../../services/users"
import "../../../../styles/workspace.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlus, faDollarSign, faMinus } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-regular-svg-icons'
import { DateTime } from "luxon"

const Finances = (props) => {

  const [openNewRevenueOrExpense, setOpenNewRevenueOrExpense] = useState(false)
  const [revenueOrExpense, setRevenueOrExpense] = useState("revenue")

  const [newEventDay, setNewEventDay] = useState(new Date(Date.now()))
  const [newEventStart, setNewEventStart] = useState(new Date(new Date(Date.now()).setHours(8, 0, 0)))
  const [newEventEnd, setNewEventEnd] = useState(new Date(new Date(Date.now()).setHours(9, 0, 0)))

  const [activeTab, setActiveTab] = useState("1")

  const [refreshRevenuesAndExpenses, setRefreshRevenuesAndExpenses] = useState(0)

  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle>
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faDollarSign} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Financeiro</span>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0"
            onClick={() => setRevenueOrExpense("revenue")}
          >
            <Components.ToogleButton
              color="success"
              icon={faPlus}
              setOpen={setOpenNewRevenueOrExpense}

            >
              Cadastrar receita
            </Components.ToogleButton>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0"
            onClick={() => setRevenueOrExpense("expense")}
          >
            <Components.ToogleButton
              color="danger"
              icon={faMinus}
              setOpen={setOpenNewRevenueOrExpense}
              
            >
              Cadastrar despesa
            </Components.ToogleButton>
          </Column>
          
        </Column.Group>
      </WorkspaceTitle>

      {openNewRevenueOrExpense &&
        <Components.NewRevenueOrExpenseOverlay
          revenueOrExpense={revenueOrExpense}
          openNewRevenueOrExpense={openNewRevenueOrExpense}
          setOpenNewRevenueOrExpense={setOpenNewRevenueOrExpense}
          newEventDay={newEventDay}
          setNewEventDay={setNewEventDay}
          newEventStart={newEventStart}
          setNewEventStart={setNewEventStart}
          newEventEnd={newEventEnd}
          setNewEventEnd={setNewEventEnd}
          setRefreshRevenuesAndExpenses={setRefreshRevenuesAndExpenses}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

      <Components.FinanceTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      
      <Section className="tabs-content">

      {activeTab == "1" &&
          <Fragment>
            <Components.CashFlow
              setObjectToEdit={props.setObjectToEdit}
              setFocusedItem={props.setFocusedItem}
              refreshRevenuesAndExpenses={refreshRevenuesAndExpenses}
              setRefreshRevenuesAndExpenses={setRefreshRevenuesAndExpenses}
              setNotification={setNotification}
              setLoading={setLoading}
              setRedirectToLogin={setRedirectToLogin}
            />
            <span className="mb-3"></span>
          </Fragment>
        }

        {activeTab == "2" &&
          <Fragment>
            <Components.RevenuesAndExpenses
              setObjectToEdit={props.setObjectToEdit}
              setFocusedItem={props.setFocusedItem}
              refreshRevenuesAndExpenses={refreshRevenuesAndExpenses}
              setRefreshRevenuesAndExpenses={setRefreshRevenuesAndExpenses}
              setNotification={setNotification}
              setLoading={setLoading}
              setRedirectToLogin={setRedirectToLogin}
            />
            <span className="mb-3"></span>
          </Fragment>
        }

      </Section>

    </Section>
  )
}

export default Finances;