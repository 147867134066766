import React, { Fragment, useState } from 'react';
import { useHistory  } from "react-router-dom";
import { Column, Section, Field, Label, Control, Input, Button, Icon } from "rbx"
import { methods } from "./methods-and-components/methods-and-components"
import LoadingOverlay from "../loading-overlay"
import NotificationOverlay from "../notification-overlay"
import WorkspaceTitle from "./title"
import UsersService from "../../../services/users"
import "../../../styles/workspace.scss"
import "../../../styles/loading.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faExclamationTriangle, faUserLock } from '@fortawesome/free-solid-svg-icons'

const ChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(false);

  let history = useHistory();
 
  const verifyAndSetPassword = (password) => {
    setPassword(password)
    if (password.length < 6) {
      setPasswordError("Senha deve conter no mínimo 6 caracteres")
    } else if (password.length > 255) {
      setPasswordError("Senha deve conter no máximo 255 caracteres")
    }
    else {
      setPasswordError(null)
    }
  }

  const verifyAndSetNewPassword = (newPassword) => {
    setNewPassword(newPassword)
    if (newPassword.length < 6) {
      setNewPasswordError("Nova senha deve conter no mínimo 6 caracteres")
    } else if (newPassword.length > 255) {
      setNewPasswordError("Nova senha deve conter no máximo 255 caracteres")
    }
    else {
      setNewPasswordError(null)
    }
    if (newPasswordConfirm != newPassword) {
      setNewPasswordConfirmError("As senhas não conferem")
    } else {
      setNewPasswordConfirmError(null)
    }
  }

  const verifyAndSetNewPasswordConfirm = (newPasswordConfirm) => {
    setNewPasswordConfirm(newPasswordConfirm)
    if (newPasswordConfirm != newPassword) {
      setNewPasswordConfirmError("As senhas não conferem")
    } else if (newPasswordConfirm.length < 6) {
      setNewPasswordConfirmError("Nova senha deve conter no mínimo 6 caracteres")
    } else if (newPasswordConfirm.length > 255) {
      setNewPasswordConfirmError("Nova senha deve conter no máximo 255 caracteres")
    }
    else {
      setNewPasswordConfirmError(null)
    }
  }

  const mandatoryFields = [
    password,
    newPassword,
    newPasswordConfirm
  ]

  const errorList = [
    passwordError,
    newPasswordError,
    newPasswordConfirmError
  ]

  const handleSubmit = async (event) => {
    event.preventDefault()
    const mandatoryFieldsNotFilled = (mandatoryFields) => {
      let notFilled = false
      if (mandatoryFields.some( (field) => field == "")) {
        notFilled = true
      }
      return (notFilled)
    }  
    const hasErrors = (errorList) => {
      let hasError = false
      if (errorList.some( (error) => (error != null && error != false))) {
        hasError = true
      }
      return (hasError)
    }  
    const errorsToShow = (errorList) => {
      return (
        <Fragment>
          {errorList.map( (error) => {
            if (error) {
              return (<Fragment><p>{error}</p><hr className="error-divider"></hr></Fragment>)
            }
          })}
        </Fragment>
      )
    }

    if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
      setNotification({
        success: false,
        text: "Favor preencher todos os campos obrigatórios",
        callback: () => {
          setNotification(false)
        }
      })
    } else if (methods.hasErrors(errorList)) {
      setNotification({
        success: false,
        text: methods.errorsToShow(errorList),
        callback: () => {
          setNotification(false)
        }
      })
    } else {
      setLoading(true)
      event.preventDefault()
      let infoToSubmit = {
        password: password,
        newPassword: newPassword
      }
      await UsersService.changePassword(infoToSubmit)
      .then( () => {
        setLoading(false)
        setNotification({
          success: true,
          text: "Senha atualizada",
          callback: () => {
            setNotification(false)
            history.push("/app/home")
          }
        })
      })
      .catch(function (error) {
        try {
          if (error.response.data == "Senha incorreta") {
            setLoading(false)
            setPasswordError("Senha incorreta")
            setNotification({
              success: false,
              text: "Senha incorreta",
              callback: ( () => {
                setNotification(false)
              })
            })          
          } else {
            setLoading(false)
            setNotification({
              success: false,
              text: "Não foi possível atualizar a senha. Tente novamente",
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {
          setLoading(false)
          setNotification({
            success: false,
            text: "Não foi possível atualizar a senha. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      })
    }
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle icon={faUserLock}>
        Alterar senha
      </WorkspaceTitle>
      
      <Section className="workspace-content">

        <form onSubmit={handleSubmit} autoComplete="off">

          <Column.Group multiline>

            <Column size={6} className="field-column" >
              <Field>
                <Label >Senha atual:<span className="has-text-danger"> *</span></Label>
                <Control tooltip={passwordError} className="has-tooltip-danger" iconRight>
                  <Input
                    style={{ WebkitTextSecurity: "disc" }}
                    color={passwordError && "danger"}
                    type="password"
                    value={password}
                    onChange={e => verifyAndSetPassword(e.target.value)}
                    name="old-password"
                  />
                  {passwordError &&
                    <Icon size="small" align="right">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </Icon>
                  } 
                </Control>
              </Field>
            </Column>

          </Column.Group>
            
          <Column.Group multiline>

            <Column size={6} className="field-column" >
              <Field>
                <Label>Nova senha:<span className="has-text-danger"> *</span></Label>
                <Control tooltip={newPasswordError} className="has-tooltip-danger" iconRight>
                  <Input 
                    style={{ WebkitTextSecurity: "disc" }}
                    color={newPasswordError && "danger"}
                    type="password"
                    value={newPassword}
                    onChange={e => verifyAndSetNewPassword(e.target.value)}
                    name="new-password"
                  />
                  {newPasswordError &&
                    <Icon size="small" align="right">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </Icon>
                  }
                </Control>
              </Field>
            </Column>

          </Column.Group>

          <Column.Group multiline>

          <Column size={6} className="field-column" >
              <Field>
                <Label>Confirmar nova senha:<span className="has-text-danger"> *</span></Label>
                <Control tooltip={newPasswordConfirmError} className="has-tooltip-danger" iconRight>
                  <Input 
                    style={{ WebkitTextSecurity: "disc" }}
                    color={newPasswordConfirmError && "danger"}
                    type="password"
                    value={newPasswordConfirm}
                    onChange={e => verifyAndSetNewPasswordConfirm(e.target.value)}
                    name="new-password-confirm"
                  />
                  {newPasswordConfirmError &&
                    <Icon size="small" align="right">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </Icon>
                  }
                </Control>
              </Field>
            </Column>

          </Column.Group>

          <Column.Group centered>
            <Column size={6} >
              <Field>
                <Button.Group align="centered">
                  <Button color="info" size="medium" className="has-text-weight-semibold">
                    <Icon size="small" align="right">
                      <FontAwesomeIcon icon={faEdit} />
                    </Icon>
                    <span>Alterar</span>
                  </Button>
                </Button.Group>
              </Field>
            </Column>
          </Column.Group>

        </form>

      </Section>

    </Section>
  )
}

export default ChangePassword;