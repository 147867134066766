import React, { useState, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import PatientsService from "../../../../services/patients"
import UsersService from "../../../../services/users"
import "../../../../styles/workspace.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-regular-svg-icons'

const Patients = (props) => {

  const [name, setName] = useState("")

  const [cpf, setCpf] = useState("")
  const [cpfError, setCpfError] = useState(null)

  const [active, setActive] = useState(true)
  const [notActive, setNotActive] = useState(true)

  const [patients, setPatients] = useState(null)
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const [openNewPatient, setOpenNewPatient] = useState(false)

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }

  const mandatoryFields = []

  const errorList = [
    cpfError,
  ]

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
      setNotification({
        success: false,
        text: "Favor preencher todos os campos obrigatórios",
        callback: () => {
          setNotification(false)
        }
      })
    } else if (methods.hasErrors(errorList)) {
      setNotification({
        success: false,
        text: methods.errorsToShow(errorList),
        callback: () => {
          setNotification(false)
        }
      })
    } else {
      setLoading(true)
      let searchFields = {
        name: name,
        cpf: cpf.replace(/\D/g,""),
        active: active,
        notActive: notActive,
      }
      await PatientsService.searchPatients(searchFields)
      .then( (response) => {
        setPatients(response.data)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        try {
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setNotification({
              success: false,
              text: "Não foi possível pesquisar os pacientes. Tente novamente",
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {
          setNotification({
            success: false,
            text: "Não foi possível pesquisar os pacientes. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      })
    }
  }

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle >
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faAddressCard} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Pacientes</span>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Components.ToogleButton
              color="success"
              icon={faPlus}
              setOpen={setOpenNewPatient}
            >
              Novo
            </Components.ToogleButton>
          </Column>
        </Column.Group>
      </WorkspaceTitle>

      <form onSubmit={handleSubmit} >

        <Components.SearchFiltersCard>

          <Column.Group multiline>

            <Components.GenericInput
              required={false}
              elementName="name"
              value={name}
              setValue={setName}
            >
              Nome:
            </Components.GenericInput>

            <Components.Cpf
            required={false}
            cpf={cpf}
            cpfError={cpfError}
            setCpf={setCpf}
            setCpfError={setCpfError}
          />

          <Components.CheckboxContainer
            columnSize="9"
            required={false}
            label="Situação:"
          >

            <Components.Checkbox key={0} checked={active} onChange={setActive} >
              Mostrar Ativos
            </Components.Checkbox>

            <Components.Checkbox key={1} checked={notActive} onChange={setNotActive} >
              Mostrar Inativos
            </Components.Checkbox>

          </Components.CheckboxContainer>

          </Column.Group>

        </Components.SearchFiltersCard>

        {patients && 
          <Components.SearchPatientsResults
            patients={patients}
            setObjectToEdit={props.setObjectToEdit}
          />
        }

      </form>

      {openNewPatient &&
        <Components.NewPatientOverlay
          showFields
          openNewPatient={openNewPatient}
          setOpenNewPatient={setOpenNewPatient}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

    </Section>
  )
}

export default Patients;