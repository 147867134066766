import Api from "./api"

const DocumentTemplatesService = {

  newDocumentTemplate: async (params) => {
    const response = await Api.post("/documentTemplates/newDocumentTemplate", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },
  
  editDocumentTemplate: async (params) => {
    let response = await Api.put("/documentTemplates/editDocumentTemplate", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getDocumentTemplateById: async (params) => {
    let response = await Api.post("/attendances/getDocumentTemplateById", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  searchDocumentTemplates: async (params) => {
    let response = await Api.post("/documentTemplates/searchDocumentTemplates", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteDocumentTemplate: async (params) => {
    let response = await Api.post("/documentTemplates/deleteDocumentTemplate", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default DocumentTemplatesService