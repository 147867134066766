import React, { Fragment, useState, useEffect } from 'react';
import { Column, Section, Title, Container, Card, Menu } from "rbx"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const WorkspaceTitle = (props) => {

  return (
    <Fragment>
      <Column.Group centered breakpoint="mobile" textAlign="centered" >
        <Column size={12} className="pb-0">
          <Title className="workspace-title">
            {props.icon && <span className={props.color && `pr-3 has-text-${props.color}` || "pr-3"}><FontAwesomeIcon icon={props.icon} /></span>}
            {props.children}
            <hr className="title-divider" />
          </Title>
        </Column>
      </Column.Group>
    </Fragment>
  )
}

export default WorkspaceTitle;