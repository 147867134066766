import React, { Fragment, useRef, useEffect, useState } from 'react';
import { Column, Section, Title, Message, Delete, Button, Icon, Label, Radio } from "rbx"
import "../../styles/loading.scss"
import "../../styles/notification-overlay.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const NotificationOverlay = (props) => {
  const [localRadioOptionChecked, setLocalRadioOptionChecked] = useState("this-event")
  const buttonRef = useRef(null)

  const { radioOptions, setRadioOptionChecked } = props.notification

  let color = props.notification.success ? "success" : "danger"
  if (props.notification.withCancel && props.notification.success) {
    color = "info"
  }
  let icon = faCheckSquare
  let title = "Sucesso"
  if (!props.notification.success) {
    title = "Atenção"
  }
  if (color == "info") {
    title = "Confirmação"
    icon = faExclamationTriangle
  }

  if(props.notification.title) {
    title = props.notification.title
  }

  if (!props.notification.success) {
    icon = faExclamationTriangle
  }

  useEffect(()=>{
    buttonRef.current.focus()
  }, [])

  return (
    <Fragment>
      <div className="overlay-index-200"/>
      <Section className="notification-section" >
        <Column.Group vcentered centered className="is-flex">
          <Column size={4} className="notification-container is-11-mobile is-8-tablet is-5-desktop is-4-widescreen is-3-fullhd">
            <Message color={color} className="notification-card" >
              <Message.Header className="has-text-centered">
                <Column.Group vcentered className="is-mobile">
                  <Column narrow>
                    <Icon size="medium">
                      <FontAwesomeIcon icon={icon} size="2x" />
                    </Icon>
                  </Column>
                  <Column>
                    <Title size={5} className="has-text-white">{title}</Title>
                  </Column>
                </Column.Group>
              </Message.Header>
              <Message.Body>
                <Column.Group vcentered centered className="is-mobile">
                  <Column >
                    <Title size={6}>
                      {props.notification.text}
                    </Title>
                    {radioOptions &&
                      (radioOptions.map((radioOption, index) => (
                        <Label key={index} className="ml-1 has-text-dark" style={{ display: "block" }}>
                          <Radio
                            value={radioOption.value}
                            checked={localRadioOptionChecked == radioOption.value}
                            onChange={(event) => {setRadioOptionChecked(radioOption.value);setLocalRadioOptionChecked(radioOption.value)}}
                          />
                          <span className="ml-2 has-text-weight-normal is-size-6">{radioOption.text}</span>
                        </Label>
                      )))
                    }
                  </Column>
                </Column.Group>
                <Button.Group align="centered">
                  {props.notification.withCancel &&
                    <Fragment>
                      <Button ref={buttonRef} onClick={ () => props.notification.confirmCallback(localRadioOptionChecked) } color="success" className="has-text-weight-semibold">
                        <span>Confirmar</span>
                      </Button>
                      <Button ref={buttonRef} onClick={ props.notification.cancelCallback } color="danger" className="has-text-weight-semibold ml-5">
                        <span>Cancelar</span>
                      </Button>
                    </Fragment>
                  ||
                    <Button ref={buttonRef} onClick={ props.notification.callback } color="info" size="medium" className="has-text-weight-semibold">
                      <span>OK</span>
                    </Button>
                  }
                </Button.Group>
              </Message.Body>
            </Message>
          </Column>
        </Column.Group>
      </Section>
    </Fragment>
  )

}

export default NotificationOverlay;