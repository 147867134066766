import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { Column, Section, Field, Label, Control, Input, Button, Radio, Select, Icon } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import CompaniesService from "../../../../services/companies"
import UsersService from "../../../../services/users"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { ufList } from "../../../../constants"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faBuilding } from '@fortawesome/free-solid-svg-icons'

const EditMyCompany = (props) => {
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(null)

  const [tradingName, setTradingName] = useState("")
  const [tradingNameError, setTradingNameError] = useState(null)

  const [cnpj, setCnpj] = useState("")
  const [cnpjError, setCnpjError] = useState(null)

  const [areaPhysio, setAreaPhysio] = useState(false)
  const [areaOccupational, setAreaOccupational] = useState(false)
  const [areaPilates, setAreaPilates] = useState(false)
  const [areaAesthetics, setAreaAesthetics] = useState(false)
  const [areaOthers, setAreaOthers] = useState(false)

  const [areaCode, setAreaCode] = useState("")
  const [areaCodeError, setAreaCodeError] = useState(null)  
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberError, setPhoneNumberError] = useState(null)

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(null)

  const [cep, setCep] = useState("")
  const [cepError, setCepError] = useState(null)
  const [street, setStreet] = useState("")
  const [adressNumber, setAdressNumber] = useState("")
  const [complement, setComplement] = useState("")
  const [neighborhood, setNeighborhood] = useState("")
  const [city, setCity] = useState("")
  const [uf, setUf] = useState("")

  const [logoImage, setLogoImage] = useState(null)
  const [logoImageError, setLogoImageError] = useState(null)
  const [logoImageName, setLogoImageName] = useState(null)
  const [slogan, setSlogan] = useState("")

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = React.useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }

  const [loading, setLoading] = useState(true)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  let history = useHistory();

  const mandatoryFields = [
    tradingName
  ]

  const errorList = [
    nameError,
    tradingNameError,
    cnpjError,
    areaCodeError,
    phoneNumberError,
    emailError,
    cepError,
    logoImageError,
  ]

  const getCompanyLogo = async () => {
    let user = JSON.parse(localStorage.getItem("user"))
    const response = await CompaniesService.getLogo({ id: user.companyId })
    return response
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
      setNotification({
        success: false,
        text: "Favor preencher todos os campos obrigatórios",
        callback: () => {
          setNotification(false)
        }
      })
    } else if (methods.hasErrors(errorList)) {
      setNotification({
        success: false,
        text: methods.errorsToShow(errorList),
        callback: () => {
          setNotification(false)
        }
      })
    } else {      
      setLoading(true)
      const formData = new FormData()
      let user = JSON.parse(localStorage.getItem("user"))
      formData.append("_id", user.companyId);
      formData.append("name", name);
      formData.append("tradingName", tradingName);
      formData.append("cnpj", cnpj.replace(/\D/g,""));
      formData.append("areaPhysio", areaPhysio);
      formData.append("areaOccupational", areaOccupational);
      formData.append("areaPilates", areaPilates);
      formData.append("areaAesthetics", areaAesthetics);
      formData.append("areaOthers", areaOthers);
      formData.append("areaCode", areaCode.replace(/\D/g,""));
      formData.append("phoneNumber", phoneNumber.replace(/\D/g,""));
      formData.append("email", email);
      formData.append("cep", cep.replace(/\D/g,""));
      formData.append("street", street);
      formData.append("adressNumber", adressNumber);
      formData.append("complement", complement);
      formData.append("neighborhood", neighborhood);
      formData.append("city", city);
      formData.append("uf", uf);
      formData.append("logoImage", logoImage);
      formData.append("slogan", slogan);

      await CompaniesService.editCompany(formData)
      .then( (response) => {
        localStorage.setItem("company", JSON.stringify(response.data))
        props.setTradingName(response.data.tradingName)
        getCompanyLogo()
        .then( (response) => {
          if (logoImage) {
            props.setLogoImage(response.data.image)
          }
        })
        setLoading(false)
        setNotification({
          success: true,
          text: "Informações da empresa atualizadas",
          callback: () => {
            setNotification(false)
            setFormChanged(false)
          }
        })
      })
      .catch(function (error) {
        setLoading(false)
        try {
          console.log(error.response.data)
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else if (error.response.data) {
            setNotification({
              success: false,
              text: error.response.data,
              callback: ( () => {
                setNotification(false)
              })
            })          
          } else {
            setNotification({
              success: false,
              text: "Não foi possível atualizar a empresa. Tente novamente",
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {
          setNotification({
            success: false,
            text: "Não foi possível atualizar a empresa. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      })
    }
  }

  const getMyCompany = async () => {
    let user = JSON.parse(localStorage.getItem("user"))
    const response = await CompaniesService.searchMyCompany({ id: user.companyId })
    return response
  }

  useEffect( () => {
    const eventListener = (event) => {
      event.preventDefault()
      if (formChangedRef.current) {
        let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }
    window.addEventListener('beforeunload', eventListener)
    try {
      getMyCompany()
      .then( (response) => {
        let company = response.data
        setName(company.name)
        setTradingName(company.tradingName)
        methods.maskAndSetCnpj(company.cnpj, setCnpj, setCnpjError)
        setAreaPhysio(company.areaPhysio)
        setAreaOccupational(company.areaOccupational)
        setAreaPilates(company.areaPilates)
        setAreaAesthetics(company.areaAesthetics)
        setAreaOthers(company.areaOthers)
        methods.maskAndSetAreaCode(company.areaCode, setAreaCode, setAreaCodeError)
        methods.maskAndSetPhoneNumber(company.phoneNumber, setPhoneNumber, setPhoneNumberError)
        setEmail(company.email)
        methods.maskAndSetCep(company.cep, setCep, setCepError)
        setStreet(company.street)
        setAdressNumber(company.adressNumber)
        setComplement(company.complement)
        setNeighborhood(company.neighborhood)
        setCity(company.city)
        setUf(company.uf)
        setLogoImageName("Clique para incluir/alterar")
        setSlogan(company.slogan)
        setFormChanged(false)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        try {
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setNotification({
              success: false,
              text: "Não foi possível acessar os dados. Tente novamente",
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {
          setNotification({
            success: false,
            text: "Não foi possível acessar os dados. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, [])

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle icon={faBuilding}>
        Editar Informações da Empresa
      </WorkspaceTitle>

      <Section className="workspace-content">

        <form onSubmit={handleSubmit} >

          <Column.Group multiline>

          <Components.CompanyName
              required={false}
              name={name}
              nameError={nameError}
              setName={setName}
              setNameError={setNameError}
              setFormChanged={setFormChanged}
            />

            <Components.TradingName
              required={true}
              tradingName={tradingName}
              tradingNameError={tradingNameError}
              setTradingName={setTradingName}
              setTradingNameError={setTradingNameError}
              setFormChanged={setFormChanged}
            />

            <Components.GenericInput
              required={false}
              elementName="slogan"
              value={slogan}
              setValue={setSlogan}
              setFormChanged={setFormChanged}
            >
              Slogan:
            </Components.GenericInput>
            
            <Components.ImageUpload
              file={logoImage}
              setFile={setLogoImage}
              fileError={logoImageError}
              setFileError={setLogoImageError}
              fileMaxSize={5*1024*1024}
              fileName={logoImageName}
              setFileName={setLogoImageName}
              setFormChanged={setFormChanged}
            >
              Logomarca:
            </Components.ImageUpload>

            <Components.Cnpj
              required={false}
              cnpj={cnpj}
              cnpjError={cnpjError}
              setCnpj={setCnpj}
              setCnpjError={setCnpjError}
              setFormChanged={setFormChanged}
            />

            <Components.CheckboxContainer
              required={false}
              label="Áreas:"
            >

              <Components.Checkbox checked={areaPhysio} onChange={setAreaPhysio} setFormChanged={setFormChanged} >
                Fisioterapia
              </Components.Checkbox>

              <Components.Checkbox checked={areaOccupational} onChange={setAreaOccupational} setFormChanged={setFormChanged} >
                Terapia Ocupacional
              </Components.Checkbox>

              <Components.Checkbox checked={areaPilates} onChange={setAreaPilates} setFormChanged={setFormChanged} >
                Pilates
              </Components.Checkbox>
              
              <Components.Checkbox checked={areaAesthetics} onChange={setAreaAesthetics} setFormChanged={setFormChanged} >
                Estética
              </Components.Checkbox>

              <Components.Checkbox checked={areaOthers} onChange={setAreaOthers} setFormChanged={setFormChanged} >
                Outros
              </Components.Checkbox>

            </Components.CheckboxContainer>

            <Components.Phone
              required={false}
              areaCode={areaCode}
              areaCodeError={areaCodeError}
              setAreaCode={setAreaCode}
              setAreaCodeError={setAreaCodeError}
              phoneNumber={phoneNumber}
              phoneNumberError={phoneNumberError}
              setPhoneNumber={setPhoneNumber}
              setPhoneNumberError={setPhoneNumberError}
              setFormChanged={setFormChanged}
            />

            <Components.Email
              required={false}
              email={email}
              emailError={emailError}
              setEmail={setEmail}
              setEmailError={setEmailError}
              setFormChanged={setFormChanged}
            />

            <Components.Cep
              required={false}
              cep={cep}
              setCep={setCep}
              cepError={cepError}
              setCepError={setCepError}
              setStreet={setStreet}
              setAdressNumber={setAdressNumber}
              setComplement={setComplement}
              setNeighborhood={setNeighborhood}
              setCity={setCity}
              setUf={setUf}
              setLoading={setLoading}
              setFormChanged={setFormChanged}
            />

            <Components.Street
              required={false}
              street={street}
              setStreet={setStreet}
              setFormChanged={setFormChanged}
            />

            <Components.AdressNumber
              required={false}
              adressNumber={adressNumber}
              setAdressNumber={setAdressNumber}
              setFormChanged={setFormChanged}
            />

            <Components.Complement
              required={false}
              complement={complement}
              setComplement={setComplement}
              setFormChanged={setFormChanged}
            />

            <Components.Neighborhood
              required={false}
              neighborhood={neighborhood}
              setNeighborhood={setNeighborhood}
              setFormChanged={setFormChanged}
            />

            <Components.CityAndUf
              required={false}
              city={city}
              setCity={setCity}
              uf={uf}
              setUf={setUf}
              setFormChanged={setFormChanged}
            />

          </Column.Group>

          <Components.SubmitButton icon={faEdit}>
            Atualizar
          </Components.SubmitButton>

        </form>

      </Section>

    </Section>
  )
}

export default EditMyCompany;