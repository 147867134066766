import Api from "./api"

const AttendancePackagesService = {

  newAttendancePackage: async (params) => {
    const response = await Api.post("/attendancePackages/newAttendancePackage", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },
  
  editAttendancePackage: async (params) => {
    let response = await Api.put("/attendancePackages/editAttendancePackage", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },
  
  deleteAttendanceFromAttendancePackage: async (params) => {
    let response = await Api.post("/attendancePackages/deleteAttendanceFromAttendancePackage", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getAttendancePackages: async (params) => {
    let response = await Api.post("/attendancePackages/getAttendancePackages", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getAttendancePackagesByPatientId: async (params) => {
    let response = await Api.post("/attendancePackages/getAttendancePackagesByPatientId", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getAttendancesOfOnePackage: async (params) => {
    let response = await Api.post("/attendancePackages/getAttendancesOfOnePackage", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  searchAttendancePackages: async (params) => {
    let response = await Api.post("/attendancePackages/searchAttendancePackages", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteAttendancePackage: async (params) => {
    let response = await Api.post("/attendancePackages/deleteAttendancePackage", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  registerAttendancePackagePayment: async (params) => {
    let response = await Api.post("/attendancePackages/registerAttendancePackagePayment", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteAttendancePackagePayment: async (params) => {
    let response = await Api.post("/attendancePackages/deleteAttendancePackagePayment", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default AttendancePackagesService