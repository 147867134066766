import React, { useState, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import AttendancesService from "../../../../services/attendances"
import "../../../../styles/workspace.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStethoscope, faPlus } from '@fortawesome/free-solid-svg-icons'

const Attendances = (props) => {

  const [attendances, setAttendances] = useState(null)

  const [patient, setPatient] = useState("")
  const [professional, setProfessional] = useState("")

  const [initialDate, setInitialDate] = useState("")
  const [initialDateError, setInitialDateError] = useState(null)
  const [finalDate, setFinalDate] = useState("")
  const [finalDateError, setFinalDateError] = useState(null)

  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const [openNewAttendance, setOpenNewAttendance] = useState(false)

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }

  const user = JSON.parse(localStorage.getItem('user'))

  const mandatoryFields = []

  const errorList = [
    initialDateError,
    finalDateError,
  ]

  const getAttendances = async (event) => {
    let submitInfo = {
      event: event,
      setNotification: setNotification,
      setLoading: setLoading,
      setOpenComponent: null,
      mandatoryFields: mandatoryFields,
      errorList: errorList,
      ServiceMethod: AttendancesService.searchAttendances,
      params: {
        patient: patient,
        professional: professional,
        initialDate: initialDate,
        finalDate: finalDate,
      },
      noSuccessNotification: true,
      onSuccessText: null,
      onErrorText: "Não foi possível pesquisar os atendimentos. Tente novamente",
      onSuccessCallback: (response) => {
        setAttendances(response.data)
      },
      onErrorCallback: null,
      setRedirectToLogin: setRedirectToLogin,
    }
    methods.handleSubmit(submitInfo)
  }

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle >
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faStethoscope} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Atendimentos</span>
          </Column>
          {user.isProfessional &&
            <Column narrow className="py-0 pl-1 pr-0" >
              <Components.ToogleButton
                color="success"
                icon={faPlus}
                setOpen={setOpenNewAttendance}
              >
                Novo
              </Components.ToogleButton>
            </Column>
          }
        </Column.Group>
      </WorkspaceTitle>

      <form onSubmit={getAttendances}  >

        <Components.SearchFiltersCard>

          <Column.Group multiline>

            <Components.GenericInput
              required={false}
              elementName="patient-name"
              value={patient}
              setValue={setPatient}
            >
              Paciente:
            </Components.GenericInput>

            <Components.GenericInput
              required={false}
              elementName="professional"
              value={professional}
              setValue={setProfessional}
            >
              Profissional:
            </Components.GenericInput>

            <Components.DatePeriod
              required={false}
              elementName="date"
              initialDate={initialDate}
              initialDateError={initialDateError}
              finalDate={finalDate}
              finalDateError={finalDateError}
              setInitialDate={setInitialDate}
              setInitialDateError={setInitialDateError}
              setFinalDate={setFinalDate}
              setFinalDateError={setFinalDateError}
            />

          </Column.Group>

        </Components.SearchFiltersCard>

        {attendances && 
          <Components.SearchAttendancesResults
            attendances={attendances}
            getAttendances={getAttendances}
            setObjectToEdit={props.setObjectToEdit}
            setNotification={setNotification}
            setFormChanged={setFormChanged}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </form>

      {openNewAttendance &&
        <Components.NewAttendanceOverlay
          setOpenNewAttendance={setOpenNewAttendance}
          setObjectToEdit={props.setObjectToEdit}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

    </Section>
  )
}

export default Attendances;