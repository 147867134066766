import React, { useState } from 'react';
import { Navbar, Column, Dropdown } from 'rbx';
import logoImage from '../../assets/images/logo-192-rounded.png';
import "../../styles/header.scss";
import UsersService from '../../services/users';
import { Redirect, useHistory  } from "react-router-dom";
import SidebarMenu from "../components/sidebar-menu"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock, faPowerOff, faUserEdit } from '@fortawesome/free-solid-svg-icons'

function Header(props) {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const focusedItem = props.focusedItem
  const setFocusedItem = props.setFocusedItem
  const hasUnsavedChanges = props.hasUnsavedChanges
  const setHasUnsavedChanges = props.setHasUnsavedChanges
  const setNotification = props.setNotification

  let history = useHistory();

  const logOut = async () => {
    await UsersService.logout();
    setRedirectToLogin(true);
  }

  if (redirectToLogin === true)
    return <Redirect to={{ pathname: "/login" }} />

  const name = props.userName

  return (
    <Navbar color="custom-green-dark" className="navbar-logged is-fixed-top">

      <Navbar.Brand
        className="is-hidden-touch"
        onClick={e => {
          if (hasUnsavedChanges) {
            setNotification({
              withCancel: true,
              success: false,
              title: "Alterações não salvas",
              text: "Pode haver alterações não salvas. Ir para outra página?",
              cancelCallback: ( () => {
                setNotification(false)
              }),
              confirmCallback: ( () => {
                setNotification(false)
                setHasUnsavedChanges(false)
                history.push("/app")
              })
            })
          } else {
            history.push("/app") 
          }          
        }}
      >
        <Navbar.Item >
          <img src={logoImage} alt="logo" />
          <span className="navbar-title px-3 py-1">
            Cliniweb
          </span>
        </Navbar.Item>
        <Navbar.Burger/>
      </Navbar.Brand>

      <Navbar.Brand className="is-hidden-desktop" >

          <Dropdown>
            <Dropdown.Trigger>
            <Navbar.Item as="div">
              <img src={logoImage} alt="logo" />
              <span className="navbar-title menu-toogle px-3 py-1">
                MENU
              </span>
              <span className="sidebar-menu-dropdown-arrow"/>
              </Navbar.Item>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <Column.Group className="workspace m-0">
                <Column className="menu-column p-0">
                  <SidebarMenu isMobileSidbarMenu focusedItem={focusedItem} setFocusedItem={setFocusedItem} setObjectToEdit={props.setObjectToEdit} logoImage={props.logoImage} setLogoImage={props.setLogoImage} tradingName={props.tradingName} setTradingName={props.setTradingName} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
                </Column>
              </Column.Group>
            </Dropdown.Menu>
          </Dropdown>

        <Navbar.Burger/>
      </Navbar.Brand>

      <Navbar.Menu>
        <Navbar.Segment as="div" className="navbar-item navbar-start" align="start">
        </Navbar.Segment>
        <Navbar.Segment as="div" className="navbar-item navbar-end" align="right">
          <Navbar.Item dropdown>
            <Navbar.Link color="white">
              {name}
            </Navbar.Link>

            <Navbar.Dropdown align="right">

              <Navbar.Item onClick={e => {
                if (hasUnsavedChanges) {
                  setNotification({
                    withCancel: true,
                    success: false,
                    title: "Alterações não salvas",
                    text: "Pode haver alterações não salvas. Tem certeza que deseja sair?",
                    cancelCallback: ( () => {
                      setNotification(false)
                    }),
                    confirmCallback: ( () => {
                      setNotification(false)
                      setHasUnsavedChanges(false)
                      history.push("/app/my-account")
                    })
                  })
                } else {
                  history.push("/app/my-account")
                }
                }}>
                <Column.Group className="is-mobile">
                  <Column size="3" className="has-text-link">
                    <FontAwesomeIcon icon={faUserEdit} />
                  </Column>
                  <Column size="9" className="px-0">
                    <span>Editar minhas informações</span>
                  </Column>
                </Column.Group>
              </Navbar.Item>

              <Navbar.Divider/>

              <Navbar.Item onClick={e => {
                if (hasUnsavedChanges) {
                  setNotification({
                    withCancel: true,
                    success: false,
                    title: "Alterações não salvas",
                    text: "Pode haver alterações não salvas. Tem certeza que deseja sair?",
                    cancelCallback: ( () => {
                      setNotification(false)
                    }),
                    confirmCallback: ( () => {
                      setNotification(false)
                      setHasUnsavedChanges(false)
                      history.push("/app/change-password")
                    })
                  })
                } else {
                  history.push("/app/change-password")
                }
                }}>
                <Column.Group className="is-mobile">
                  <Column size="3" className="has-text-link">
                    <FontAwesomeIcon icon={faUserLock} />
                  </Column>
                  <Column size="9" className="px-0">
                    <span>Alterar senha</span>
                  </Column>
                </Column.Group>
              </Navbar.Item>

              <Navbar.Divider/>

              <Navbar.Item onClick={e => {
                if (hasUnsavedChanges) {
                  setNotification({
                    withCancel: true,
                    success: false,
                    title: "Alterações não salvas",
                    text: "Pode haver alterações não salvas. Tem certeza que deseja sair?",
                    cancelCallback: ( () => {
                      setNotification(false)
                    }),
                    confirmCallback: ( () => {
                      setNotification(false)
                      setHasUnsavedChanges(false)
                      logOut()
                    })
                  })
                } else {
                  logOut()
                }
              }}>
                <Column.Group className="is-mobile">
                  <Column size="3" className="has-text-link">
                    <FontAwesomeIcon icon={faPowerOff} />
                  </Column>
                  <Column size="9" className="px-0">
                    <span>Sair</span>
                  </Column>
                </Column.Group>
              </Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
        </Navbar.Segment>
      </Navbar.Menu>
      
    </Navbar>
  )
}

export default Header;