import React, { Fragment, useState, useEffect, useRef, PureComponent } from 'react';
import { useHistory } from "react-router-dom";
import { Column, Section, Field, Label, Control, Input, Button, Radio, Select, Icon, Checkbox, File as RbxFile, Card, Title, Table, Dropdown, Tab, Delete, Level, Box, Generic, Textarea } from "rbx"
import Switch from "react-switch"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker"
import ptBR from 'date-fns/locale/pt-BR'
import { DateTime } from "luxon"
import UsersService from "../../../../services/users"
import CompaniesService from "../../../../services/companies"
import PatientsService from "../../../../services/patients"
import EventsService from "../../../../services/events"
import AttendancesService from "../../../../services/attendances"
import ClinicalExamsService from "../../../../services/clinicalExams"
import AttendancePackageTemplatesService from "../../../../services/attendancePackageTemplates"
import AttendancePackagesService from "../../../../services/attendancePackages"
import DocumentTemplatesService from "../../../../services/documentTemplates"
import RevenuesAndExpensesService from "../../../../services/revenuesAndExpenses"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { ufList, slotTimesList, eventDurationList, quantityTypes } from "../../../../constants"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGift, faEdit, faExclamationTriangle, faFilter, faList, faSearch, faStethoscope, faUpload, faChartLine, faCog, faDollarSign, faTrash, faHourglassHalf, faCheck, faTimes, faMoneyCheckAlt, faPhoneAlt, faFileImport, faPencilAlt, faRepeat, faPlus, faAngleDown, faAlignLeft, faDownload, faClipboardCheck, faPaintBrush, faCircleInfo, faFileSignature, faMinus, faBoxOpen, faCloudUpload, faMagnifyingGlassDollar, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons'
import { faEye, faEyeSlash, faClipboard, faCalendar, faCalendarCheck, faClock, faFilePdf, faCircleDot, faSquareCheck, faCircleQuestion, faCircleCheck, faFileLines, faSave } from '@fortawesome/free-regular-svg-icons'
import { Chart } from "react-google-charts"
import { ResponsiveContainer, LineChart, BarChart, XAxis, YAxis, CartesianGrid, ReferenceLine, LabelList, Tooltip, Legend, Bar, Line } from 'recharts';
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createStaticToolbarPlugin from '@draft-js-plugins/static-toolbar'
import createTextAlignmentPlugin from '@draft-js-plugins/text-alignment'
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons'
import '@draft-js-plugins/static-toolbar/lib/plugin.css'
import '@draft-js-plugins/text-alignment/lib/plugin.css'
import 'draft-js/dist/Draft.css'
import pdfMake, { createPdf } from "pdfmake/build/pdfmake.min"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs
import StateToPdfMake from "draft-js-export-pdfmake"
import { CirclePicker } from 'react-color'
import calendar from "../../../../config/calendar"

registerLocale('pt-BR', ptBR) // Para o DatePicker

const methods = {

  mandatoryFieldsNotFilled: (mandatoryFields) => {
    let notFilled = false
    if (mandatoryFields.some((field) => field == "" || !field)) {
      notFilled = true
    }
    return (notFilled)
  },

  hasErrors: (errorList) => {
    let hasError = false
    if (errorList.some((error) => (error != null && error != false))) {
      hasError = true
    }
    return (hasError)
  },

  errorsToShow: (errorList) => {
    return (
      <Fragment>
        {errorList.map((error, key) => {
          if (error) {
            return (<Fragment key={key}><p>{error}</p><hr className="error-divider"></hr></Fragment>)
          }
        })}
      </Fragment>
    )
  },

  handleSubmit: async (info) => {
    const { event, setNotification, setLoading, setOpenComponent, mandatoryFields, errorList, ServiceMethod, params, noSuccessNotification, onSuccessText, onErrorText, onSuccessCallback, onErrorCallback, setRedirectToLogin, setHasUnsavedChanges } = info
    if (event) {
      event.preventDefault()
    }
    if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
      setNotification({
        success: false,
        text: "Favor preencher todos os campos obrigatórios",
        callback: () => {
          setNotification(false)
        }
      })
    } else if (methods.hasErrors(errorList)) {
      setNotification({
        success: false,
        text: methods.errorsToShow(errorList),
        callback: () => {
          setNotification(false)
        }
      })
    } else {
      setLoading(true)
      await ServiceMethod(params)
        .then((response) => {
          setLoading(false)
          if (setHasUnsavedChanges) {
            setHasUnsavedChanges(false)
          }
          if (noSuccessNotification) {
            if (setOpenComponent) {
              setOpenComponent(false)
            }
            if (onSuccessCallback) {
              onSuccessCallback(response)
            }
          } else {
            setNotification({
              success: true,
              text: onSuccessText,
              callback: () => {
                setNotification(false)
                if (setOpenComponent) {
                  setOpenComponent(false)
                }
                if (onSuccessCallback) {
                  onSuccessCallback(response)
                }
              }
            })
          }
        })
        .catch(function (error) {
          setNotification(false)
          console.log(error)
          try {
            if (error.response.status == 401) {
              setLoading(false)
              setNotification({
                success: false,
                text: "Erro de autenticação. Efetue o login novamente.",
                callback: (() => {
                  setNotification(false)
                  UsersService.logout()
                  setRedirectToLogin(true)
                })
              })
            } else {
              setNotification({
                success: false,
                text: error.response.data,
                callback: (() => {
                  setLoading(false)
                  setNotification(false)
                  if (setOpenComponent) {
                    setOpenComponent(false)
                  }
                  if (onErrorCallback) {
                    onErrorCallback()
                  }
                })
              })
            }
          } catch {
            setLoading(false)
            setNotification({
              success: false,
              text: onErrorText,
              callback: (() => {
                setLoading(false)
                setNotification(false)
                if (setOpenComponent) {
                  setOpenComponent(false)
                }
              })
            })
          }
        })
    }
  },

  verifyNotEmptyAndSetName: (name, setName, setNameError) => {
    setName(name)
    if (name == "") {
      setNameError("Campo nome é obrigatório")
    } else if (name.length > 100) {
      setNameError("Nome deve ter no máximo 100 caracteres")
    } else {
      setNameError(null)
    }
  },

  verifyAndSetName: (name, setName, setNameError) => {
    setName(name)
    if (name.length > 100) {
      setNameError("Nome empresarial deve ter no máximo 100 caracteres")
    } else {
      setNameError(null)
    }
  },

  searchAndSetCompaniesDropdown: (value, setInputValue, setCompanies, setCompaniesError, timer, setTimer, setCompanyId, setLoading, setDropdownIsActive) => {
    setCompanyId(null)
    setInputValue(value)
    clearTimeout(timer)

    const getCompaniesByName = async (value) => {
      const response = await CompaniesService.searchByName({ name: value })
      return response
    }

    if (value != "") {
      setTimer(setTimeout(() => {
        if (setLoading) {
          setLoading(true)
        }
        getCompaniesByName(value)
          .then((response) => {
            if (response.data == false) {
              setCompanies(null)
            } else {
              setCompanies(response.data.slice(0, 10))
            }
            if (setLoading) {
              setLoading(false)
            }
            setDropdownIsActive(true)
          })
          .catch((error) => {
            console.log(error)
            setCompanies(null)
            setCompaniesError("Não foi possível pesquisar as empresas. Tente novamente mais tarde")
            if (setLoading) {
              setLoading(false)
            }
          })
      }, 300))
    } else {
      setCompanies(null)
    }
  },

  searchAndSetUsersDropdown: (value, setInputValue, setUsers, setUsersError, timer, setTimer, setLoading, setDropdownIsActive) => {
    setInputValue(value)
    clearTimeout(timer)

    const getUsersByName = async (value) => {
      const response = await UsersService.searchByName({ name: value })
      return response
    }

    if (value != "") {
      setTimer(setTimeout(() => {
        if (setLoading) {
          setLoading(true)
        }
        getUsersByName(value)
          .then((response) => {
            if (response.data == false) {
              setUsers(null)
            } else {
              setUsers(response.data.slice(0, 10))
            }
            if (setLoading) {
              setLoading(false)
            }
            setDropdownIsActive(true)
          })
          .catch((error) => {
            console.log(error)
            setUsers(null)
            setUsersError("Não foi possível pesquisar usuários. Tente novamente mais tarde")
            if (setLoading) {
              setLoading(false)
            }
          })
      }, 300))
    } else {
      setUsers(null)
    }
  },

  searchAndSetPatientsDropdown: (value, setInputValue, setPatients, setPatientsError, timer, setTimer, setPatientId, setLoading, setDropdownIsActive) => {
    setPatientId(null)
    setInputValue(value)
    clearTimeout(timer)

    const getPatientsByName = async (value) => {
      const response = await PatientsService.searchByName({ name: value })
      return response
    }

    if (value != "") {
      setTimer(setTimeout(() => {
        if (setLoading) {
          setLoading(true)
        }
        getPatientsByName(value)
          .then((response) => {
            if (response.data == false) {
              setPatients(null)
            } else {
              setPatients(response.data.slice(0, 10))
            }
            if (setLoading) {
              setLoading(false)
            }
            setDropdownIsActive(true)
          })
          .catch((error) => {
            console.log(error)
            setPatients(null)
            setPatientsError("Não foi possível pesquisar os pacientes. Tente novamente mais tarde")
            if (setLoading) {
              setLoading(false)
            }
          })
      }, 300))
    } else {
      setPatients(null)
    }
  },

  searchAndSetProfessionalsDropdown: (value, setInputValue, setProfessionals, setProfessionalsError, timer, setTimer, setProfessionalId, setLoading, setDropdownIsActive) => {
    setProfessionalId(null)
    setInputValue(value)
    clearTimeout(timer)

    const getProfessionalsByName = async (value) => {
      const response = await UsersService.searchByName({ name: value })
      return response
    }

    if (value != "") {
      setTimer(setTimeout(() => {
        if (setLoading) {
          setLoading(true)
        }
        getProfessionalsByName(value)
          .then((response) => {
            if (response.data == false) {
              setProfessionals(null)
            } else {
              setProfessionals(response.data.slice(0, 10))
            }
            if (setLoading) {
              setLoading(false)
            }
            setDropdownIsActive(true)
          })
          .catch((error) => {
            console.log(error)
            setProfessionals(null)
            setProfessionalsError("Não foi possível pesquisar os profissionais. Tente novamente mais tarde")
            if (setLoading) {
              setLoading(false)
            }
          })
      }, 300))
    } else {
      setProfessionals(null)
    }
  },

  maskAndSetEmail: (email, setEmail, setEmailError) => {
    setEmail(email)
    let validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,255}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,255}[a-zA-Z0-9])?)*$/.test(email)
    if (!validEmail) {
      setEmailError("E-mail inválido")
    } else {
      setEmailError(null)
    }
  },

  maskAndSetDate: (date, setDate, setDateError) => {
    if (!date && setDateError) {
      setDateError("Data inválida")
    } else {
      date = date.replace(/\D/g, "")
      date = date.replace(/(\d{2})(\d)/, "$1/$2")
      date = date.replace(/(\d{2})(\d)/, "$1/$2")
      date = date.replace(/(\/\d{4})(\d*)/, "$1")
      setDate && setDate(date)
      let dateRegEx = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
      if (date.length == 10) {
        let isCorrect = dateRegEx.test(date)
        if (isCorrect) {
          if (setDateError) {
            setDateError(null)
          }
        } else {
          if (setDateError) {
            setDateError("Data inválida")
          }
        }
      } else if (date.length > 0) {
        if (setDateError) {
          setDateError("Data inválida")
        }
      } else {
        if (setDateError) {
          setDateError(null)
        }
      }
    }    
    return date
  },

  maskDate: (date) => {
    date = date.replace(/\D/g, "")
    date = date.replace(/(\d{2})(\d)/, "$1/$2")
    date = date.replace(/(\d{2})(\d)/, "$1/$2")
    date = date.replace(/(\/\d{4})(\d*)/, "$1")
    return date
  },

  maskAndSetTime: (time, setTime, setTimeError) => {
    time = time.replace(/\D/g, "")
    time = time.replace(/(\d{2})(\d{1,2})/, "$1:$2")
    time = time.replace(/(\:\d{2})(\d*)/, "$1")
    setTime(time)
    let timeRegEx = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
    if (time.length == 5) {
      let isCorrect = timeRegEx.test(time)
      if (isCorrect) {
        if (setTimeError) {
          setTimeError(null)
        }
      } else {
        if (setTimeError) {
          setTimeError("Hora inválida")
        }
      }
    } else if (time.length > 0) {
      if (setTimeError) {
        setTimeError("Hora inválida")
      }
    } else {
      if (setTimeError) {
        setTimeError(null)
      }
    }
  },

  verifyPeriod: (initialDate, finalDate, setInitialDateError, setFinalDateError, stringToDate) => {
    if (initialDate.length == 10 && finalDate.length == 10) {
      initialDate = stringToDate(initialDate)
      finalDate = stringToDate(finalDate)
      if (finalDate < initialDate) {
        setFinalDateError("A data final deve ser maior ou igual a data inicial")
      } else {
        setInitialDateError(null)
        setFinalDateError(null)
      }
    }
  },

  maskAndSetAreaCode: (areaCode, setAreaCode, setAreaCodeError) => {
    areaCode = areaCode.replace(/^\((\d{1})(\d{1})$/, "$1")
    areaCode = areaCode.replace(/\D/g, "")
    areaCode = areaCode.replace(/(\d{2})(\d*)/, "($1)")
    setAreaCode(areaCode)
    if (areaCode.length == 1) {
      setAreaCodeError("Código de área deve ter 2 dígitos")
    } else {
      setAreaCodeError(null)
    }
  },

  maskAndSetPhoneNumber: (phoneNumber, setPhoneNumber, setPhoneNumberError) => {
    phoneNumber = phoneNumber.replace(/\D/g, "")
    phoneNumber = phoneNumber.replace(/(\d{4})(\d)/, "$1-$2")
    phoneNumber = phoneNumber.replace(/(\d{1})(\d{3})-(\d{1})(\d{4})/, "$1 $2$3-$4")
    phoneNumber = phoneNumber.replace(/(-\d{4})(\d*)/, "$1")
    if (setPhoneNumber) {
      setPhoneNumber(phoneNumber)
      if (phoneNumber.length > 0 && phoneNumber.length < 9) {
        setPhoneNumberError("Número inválido")
      } else {
        setPhoneNumberError(null)
      }
    }
    return phoneNumber
  },

  verifyAndSetRgIdentifier: (rgIdentifier, setRgIdentifier, setRgIdentifierError) => {
    setRgIdentifier(rgIdentifier)
    if (rgIdentifier != "") {
      if (rgIdentifier.length < 5) {
        setRgIdentifierError("O RG deve possuir no mínimo 5 dígitos")
      } else if (rgIdentifier.length > 15) {
        setRgIdentifierError("O RG deve possuir no máximo 15 dígitos")
      } else {
        setRgIdentifierError(null)
      }
    } else {
      setRgIdentifierError(null)
    }
  },

  maskAndSetRgDigit: (rgDigit, setRgDigit) => {
    rgDigit = rgDigit.replace(/\D/g, "")
    rgDigit = rgDigit.replace(/(\d{1})(\d)/, "$1")
    setRgDigit(rgDigit)
  },

  maskAndSetCpf: (cpf, setCpf, setCpfError) => {
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})/, "$1-$2")
    cpf = cpf.replace(/(-\d{2})(\d*)/, "$1")
    if (setCpf) {
      setCpf(cpf)
    }
    if (cpf.length > 0 && cpf.length < 14) {
      setCpfError && setCpfError("CPF inválido")
    } else {
      setCpfError && setCpfError(null)
    }
    return cpf
  },

  maskAndSetPrice: (price, setPrice) => {
    price = price.replace(/\D/g, "") // Mantém somente dígitos
    price = price.replace(/^(\d{1})$/, "00$1") //Insere dois zeros à esquerda quando houver somente um dígito
    price = price.replace(/^(\d{2})$/, "0$1") //Insere dois zeros à esquerda quando houver somente dois dígitos
    price = price.replace(/^(\d+)(\d{2})$/, "$1,$2") // Insere uma vírgula quando houver três ou mais dígitos
    price = price.replace(/^(0+)(\d+)/, "$2") // Retira zeros à esquerda
    price = price.replace(/^(\d+)(\d{3})/, "$1.$2") // Insere ponto entre centena e milhar
    price = price.replace(/^(\d+)(\d{3})/, "$1.$2") // Insere ponto entre milhar e milhão
    price = price.replace(/^(\d+)(\d{3})/, "$1.$2") // Insere ponto entre milhão e bilhão
    price = price.replace(/(.*)/, "R$ $1") // Insere os caracteres 'R$' no início
    if (setPrice) {
      setPrice(price)
    } else {
      return price
    }
  },

  priceToNumber: (price) => {
    price = price.replace(/(R\$\s)(.*)/, "$2") // Retira os caracteres 'R$ '
    price = price.replace(/(\.)/, "") // Retira os pontos
    price = price.replace(/(\.)/, "") // Retira os pontos
    price = price.replace(/(\.)/, "") // Retira os pontos
    price = price.replace(/(\d*)(,)(\d*)/, "$1.$3") // Troca a vírgula por ponto
    if (price == "") {
      price = "0"
    }
    price = parseFloat(price)
    price = price.toFixed(2)
    price = parseFloat(price)
    return (price)
  },

  numberToPrice: (price) => {
    if (!isNaN(price)) {
      price = parseFloat(price)
      price = price.toFixed(2)
    }
    price = price.toString()
    price = price.replace(/(\.)(\d{2}$)/, ",$2") // Troca o ponto por vírgula caso tenha dois dígitos decimais
    price = price.replace(/(\.)(\d{1}$)/, ",$20") // Troca o ponto por vírgula e insere um zero caso tenha um dígito decimal
    price = price.replace(/^(\d*)$/, "$1,00") // Insere vírgula e dois zeros caso não tenha casas decimais
    price = price.replace(/^(-?)(\d+)(\d{3})/, "$1$2.$3") // Insere ponto entre centena e milhar
    price = price.replace(/^(-?)(\d+)(\d{3})/, "$1$2.$3") // Insere ponto entre milhar e milhão
    price = price.replace(/^(-?)(\d+)(\d{3})/, "$1$2.$3") // Insere ponto entre milhão e bilhão
    price = price.replace(/(.*)/, "R$ $1") // Insere os caracteres 'R$' no início
    return (price)
  },

  dateToString: (date, setDate) => {
    if (date) {
      let newDate = new Date(date)
      let day = newDate.getDate().toString()
      let month = (newDate.getMonth() + 1).toString()
      let year = newDate.getFullYear().toString()
      if (day.length == 1) {
        day = `0${day}`
      }
      if (month.length == 1) {
        month = `0${month}`
      }
      if (setDate) {
        setDate(`${day}/${month}/${year}`)
      } else {
        return `${day}/${month}/${year}`
      }
    } else {
      if (setDate) {
        setDate("Sem registro")
      } else {
        return "Sem registro"
      }
    }
  },

  stringToDate: (date) => {
    if (date == "") {
      return null
    }
    date = date.replace(/\D/g, "")
    let dateDay = date.replace(/(\d{2})(\d{2})(\d{4})/, "$1")
    let dateMonth = date.replace(/(\d{2})(\d{2})(\d{4})/, "$2")
    dateMonth -= 1
    let dateYear = date.replace(/(\d{2})(\d{2})(\d{4})/, "$3")
    return new Date(dateYear, dateMonth, dateDay)
  },

  maskAndSetCep: (cep, setCep, setCepError) => {
    cep = cep.replace(/\D/g, "")
    cep = cep.replace(/(\d{5})(\d)/, "$1-$2")
    cep = cep.replace(/(-\d{3})(\d*)/, "$1")
    if (setCep) {
      setCep(cep)
      if (setCepError) {
        if (cep.length > 0 && cep.length < 9) {
          setCepError("CEP inválido")
        } else {
          setCepError(null)
        }
      }
    }
    return cep
  },

  maskAndSetCepAndGetAdress: (cep, setCep, setCepError, setStreet, setAdressNumber, setComplement, setNeighborhood, setCity, setUf, setLoading) => {
    const getAdressByCep = async (cep) => {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
      const adress = await response.json()
      return adress
    }

    cep = cep.replace(/\D/g, "")
    cep = cep.replace(/(\d{5})(\d)/, "$1-$2")
    cep = cep.replace(/(-\d{3})(\d*)/, "$1")
    setCep(cep)
    setCepError(null)
    if (cep.length > 0 && cep.length < 9) {
      setCepError("CEP inválido")
    } else {
      setCepError(null)
    }
    if (cep.length == 9) {
      setLoading(true)
      getAdressByCep(cep)
        .then(adress => {
          if (adress.erro) {
            setCepError("CEP inexistente")
          } else {
            setStreet(adress.logradouro)
            setAdressNumber("")
            setComplement("")
            setNeighborhood(adress.bairro)
            setCity(adress.localidade)
            setUf(adress.uf)
          }
          setLoading(false)
        })
        .catch(error => {
          console.log(error)
          setCepError("Não foi possível buscar o endereço pelo CEP")
          setLoading(false)
        })
    }
  },

  maskAndSetAdressNumber: (number, setAdressNumber) => {
    number = number.replace(/\D/g, "")
    number = number.replace(/(\d{6})(\d*)/, "$1")
    setAdressNumber(number)
  },

  maskAndSetAttendancesQuantity: (number, setAttendancesQuantity) => {
    number = number.replace(/\D/g, "")
    number = number.replace(/(\d{2})(\d*)/, "$1")
    setAttendancesQuantity(number)
  },

  verifyNotEmptyAndSetTradingName: (tradingName, setTradingName, setTradingNameError) => {
    setTradingName(tradingName)
    if (tradingName == "") {
      setTradingNameError("Campo nome empresarial é obrigatório")
    } else if (tradingName.length > 100) {
      setTradingNameError("Nome empresarial deve ter no máximo 100 caracteres")
    } else {
      setTradingNameError(null)
    }
  },

  maskAndSetCnpj: (cnpj, setCnpj, setCnpjError) => {
    cnpj = cnpj.replace(/\D/g, "")
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2")
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2")
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2")
    cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")
    cnpj = cnpj.replace(/(-\d{2})(\d*)/, "$1")
    if (setCnpj) {
      setCnpj(cnpj)
      if (cnpj.length > 0 && cnpj.length < 18) {
        setCnpjError("CNPJ inválido")
      } else {
        setCnpjError(null)
      }
    } else {
      return cnpj
    }
  },

  stringCut: (string, maxLength) => {
    let cutString = string
    if (string.length > maxLength) {
      cutString = string.slice(0, maxLength) + "..."
    }
    return cutString
  },

  imageHandler: (file, setFile, setFileName, fileMaxSize, setFileError) => {
    try {
      if (/image/.test(file.type)) {
        if (file.size > fileMaxSize) {
          if (fileMaxSize < 1048576) {
            setFileError(`O arquivo deve ter no máximo ${(fileMaxSize / 1024).toFixed(2)} KB`)
          } else {
            setFileError(`O arquivo deve ter no máximo ${(fileMaxSize / 1048576).toFixed(2)} MB`)
          }
        } else {
          setFileError(null)
        }
        setFile(file)
        setFileName(file.name)
      } else {
        setFileError("O arquivo deve ser do tipo imagem")
        setFile(file)
        setFileName(file.name)
      }
    } catch (error) {
      console.log(error)
    }
  },

  createPdfFromState: ({ editorState, attendance, logoImage, title, date, setSrc }) => {
    const rawContent = convertToRaw(editorState.getCurrentContent())
    const stateToPdfMake = new StateToPdfMake(rawContent)
    const docPdf = pdfMake.createPdf(stateToPdfMake.generate())

    const company = JSON.parse(localStorage.getItem('company'))

    const companyTradingName = company.tradingName ? { text: company.tradingName, fontSize: 26, } : null
    const companySlogan = company.slogan ? company.slogan : null
    const companyCityAndUf = company.city && company.uf ? `${company.city} - ${company.uf}` : null
    const companyInfo = [
      "___________________________________________________________________________________________",
      company.name && company.name,
      company.cnpj && `CNPJ ${methods.maskAndSetCnpj(company.cnpj)}`,
      company.street && `${company.street}${company.adressNumber && `, ${company.adressNumber}`}${company.neighborhood && ` - ${company.neighborhood}`}${company.complement && ` - ${company.complement}`}`,
      (company.city && company.uf) && `${company.city} - ${company.uf}${company.cep && ` - ${methods.maskAndSetCep(company.cep)}`}`,
      `${company.phoneNumber && `Fone: ${company.areaCode && `(${company.areaCode}) `}${methods.maskAndSetPhoneNumber(company.phoneNumber)}`}${(company.email && company.email != "") && ` | ${company.email}`}`,
    ]

    const user = JSON.parse(localStorage.getItem('user'))

    const professionalTitle = attendance.professionalTitle ? { text: attendance.professionalTitle, alignment: 'center' } : null
    const council = attendance.councilName && attendance.councilRegister ? { text: `${attendance.councilName} ${attendance.councilRegister}`, alignment: 'center' } : null

    const formatedDate = DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL)
    
    const docDefinition = {
      pageMargins: [80, 180, 80, 90], // [Left, top, right, bottom] ou [horizontal, vertical]
      header: [
        {
          image: logoImage,
          style: {
            alignment: 'center',
          },
          margin: [0, 25, 0, 5],
          fit: [90, 90]
        },
        {
          stack: [
            companyTradingName,
            companySlogan,
            "__________________________________________________",
          ],
          style: 'header',
          color: '#228888',
          margin: [80, 0, 80, 30]
        }
      ],  
      content: [
        {
          text: title,
          style: 'header',
          fontSize: 20,
          margin: [0, 20]
        },
        {
          stack: [...docPdf.docDefinition.content],
          style: 'body',
        },
        {
          text: companyCityAndUf ? `${companyCityAndUf}, ${formatedDate}.` : `${formatedDate}.`,
          style: 'body',
          alignment: 'right',
          margin: [0, 20, 0, 0],
        },
        {
          text: attendance.professional,
          margin: [0, 60, 0, 0],
          bold: true,
          alignment: 'center',
        },
        professionalTitle,
        council,
      ],
      footer:[
        {
          stack: companyInfo,
          style: 'footer',
        },
      ],
      defaultStyle: {
        alignment: "justify"
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'center',
        },
        body: {
          fontSize: 12,
          alignment: 'justify',
          margin: [0, 10],
        },
        footer: {
          fontSize: 10,
          alignment: 'center',
        }
      },
      info: {
        title: title,
        author: attendance.professional,
      },
    }

    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const pdfBlob = new Blob([blob], { type: "application/pdf" })
      const pdfFile = new File([pdfBlob], `${title}.pdf`, { type: "application/pdf", name: `${title}`, fileName: `${title}.pdf` })
      const url = window.URL.createObjectURL(pdfBlob, { oneTimeOnly: true })
      setSrc(url)
    })
  },

  createPatientRecordPdf: ({ recordsToPdf, title, logoImage, setSrc }) => {
    const company = JSON.parse(localStorage.getItem('company'))

    const companyTradingName = company.tradingName ? { text: company.tradingName, fontSize: 26, } : null
    const companySlogan = company.slogan ? company.slogan : null
    const companyCityAndUf = company.city && company.uf ? `${company.city} - ${company.uf}` : null
    const companyInfo = [
      "___________________________________________________________________________________________",
      company.name && company.name,
      company.cnpj && `CNPJ ${methods.maskAndSetCnpj(company.cnpj)}`,
      company.street && `${company.street}${company.adressNumber && `, ${company.adressNumber}`}${company.neighborhood && ` - ${company.neighborhood}`}${company.complement && ` - ${company.complement}`}`,
      (company.city && company.uf) && `${company.city} - ${company.uf}${company.cep && ` - ${methods.maskAndSetCep(company.cep)}`}`,
      `${company.phoneNumber && `Fone: ${company.areaCode && `(${company.areaCode}) `}${methods.maskAndSetPhoneNumber(company.phoneNumber)}`}${(company.email && company.email != "") && ` | ${company.email}`}`,
    ]

    const user = JSON.parse(localStorage.getItem('user'))

    const formatedDate = DateTime.fromJSDate(new Date(Date.now())).toLocaleString(DateTime.DATE_FULL)
    
    const docDefinition = {
      pageMargins: [80, 180, 80, 90], // [Left, top, right, bottom] ou [horizontal, vertical]
      header: [
        {
          image: logoImage,
          style: {
            alignment: 'center',
          },
          margin: [0, 25, 0, 5],
          fit: [90, 90]
        },
        {
          stack: [
            companyTradingName,
            companySlogan,
            "__________________________________________________",
          ],
          style: 'header',
          color: '#228888',
          margin: [80, 0, 80, 30]
        }
      ],  
      content: [
        {
          text: title,
          style: 'header',
          fontSize: 16,
          decoration: "underline",
          margin: [0, 20]
        },        
        {
          stack: recordsToPdf.map((record) => (record.docDefinition.content)),
          style: 'body',
          margin: [0, 10]
        },
        {
          text: companyCityAndUf ? `Prontuário emitido em ${companyCityAndUf}, ${formatedDate}.` : `Prontuário emitido em ${formatedDate}.`,
          style: 'body',
          alignment: 'center',
          margin: [0, 20, 0, 0],
        },
      ],
      footer:[
        {
          stack: companyInfo,
          style: 'footer',
        },
      ],
      defaultStyle: {
        alignment: "justify"
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'center',
        },
        body: {
          fontSize: 12,
          alignment: 'justify',
          margin: [0, 10],
        },
        footer: {
          fontSize: 10,
          alignment: 'center',
        }
      },
      info: {
        title: title,
        author: user.name,
      },
    }

    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const pdfBlob = new Blob([blob], { type: "application/pdf" })
      const pdfFile = new File([pdfBlob], `${title}.pdf`, { type: "application/pdf", name: `${title}`, fileName: `${title}.pdf` })
      const url = window.URL.createObjectURL(pdfBlob, { oneTimeOnly: true })
      setSrc(url)
    })
  },

}

const Components = {

  UserName: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const name = props.name
    const nameError = props.nameError
    const setName = props.setName
    const setNameError = props.setNameError
    const setFormChanged = props.setFormChanged

    return (
      <Column size={columnSize || 6} className="field-column" >
        <Field>
          <Label >Nome:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control tooltip={nameError} className="has-tooltip-danger" iconRight>
            <Input
              size={inputSize}
              color={nameError && "danger"}
              type="text"
              value={name}
              onChange={e => { methods.verifyNotEmptyAndSetName(e.target.value, setName, setNameError); if (setFormChanged) { setFormChanged(true) } }}
              name="name"
            />
            {nameError &&
              <Icon size="small" align="right">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Icon>
            }
          </Control>
        </Field>
      </Column>
    )
  },

  EmailStatic: (props) => {

    const value = props.value
    const inputSize = props.inputSize

    return (
      <Column size={6} className="field-column" >
        <Field>
          <Label>E-mail:</Label>
          <Control>
            <Input
              size={inputSize}
              disabled
              type="email"
              value={value}
              name="email"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  Email: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const email = props.email
    const emailError = props.emailError
    const setEmail = props.setEmail
    const setEmailError = props.setEmailError
    const setFormChanged = props.setFormChanged

    return (
      <Column size={columnSize || 6} className="field-column" >
        <Field>
          <Label>E-mail:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control tooltip={emailError} className="has-tooltip-danger" iconRight>
            <Input
              size={inputSize}
              color={emailError && "danger"}
              value={email}
              onChange={e => { methods.maskAndSetEmail(e.target.value, setEmail, setEmailError); if (setFormChanged) { setFormChanged(true) } }}
              name="email"
            />
            {emailError &&
              <Icon size="small" align="right">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Icon>
            }
          </Control>
        </Field>
      </Column>
    )
  },

  Phone: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const areaCode = props.areaCode
    const areaCodeError = props.areaCodeError
    const setAreaCode = props.setAreaCode
    const setAreaCodeError = props.setAreaCodeError
    const phoneNumber = props.phoneNumber
    const phoneNumberError = props.phoneNumberError
    const setPhoneNumber = props.setPhoneNumber
    const setPhoneNumberError = props.setPhoneNumberError
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 6} className="field-column" >
        <Column.Group centered className="is-mobile">
          <Column className="two-digit-input">
            <Field>
              <Label>Telefone: {required && <span className="has-text-danger">  *</span>}</Label>
              <Control tooltip={areaCodeError} className="has-tooltip-danger" >
                <Input
                  size={inputSize}
                  color={areaCodeError && "danger"}
                  type="text"
                  value={areaCode}
                  onChange={e => { methods.maskAndSetAreaCode(e.target.value, setAreaCode, setAreaCodeError); if (setFormChanged) { setFormChanged(true) } }}
                  name="phone-area-code"
                />
              </Control>
            </Field>
          </Column>
          <Column className="pl-0">
            <Field>
              <Label className="has-text-white">-</Label>
              <Control tooltip={phoneNumberError} className="has-tooltip-danger" iconRight>
                <Input
                  color={phoneNumberError && "danger"}
                  type="text"
                  value={phoneNumber}
                  onChange={e => { methods.maskAndSetPhoneNumber(e.target.value, setPhoneNumber, setPhoneNumberError); if (setFormChanged) { setFormChanged(true) } }}
                  name="phone-number"
                />
                {phoneNumberError &&
                  <Icon size="small" align="right">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </Icon>
                }
              </Control>
            </Field>
          </Column>
        </Column.Group>
      </Column>
    )
  },

  Gender: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const gender = props.gender
    const setGender = props.setGender

    const onGenderChange = (event) => {
      setGender(event.target.value)
    }

    return (
      <Column size={props.columnSize || 6} className="field-column static-field" >
        <Field>
          <Label>Sexo:{required && <span className="has-text-danger"> *</span>}</Label>
          <Label className="pt-2">
            <Radio
              value="m"
              name="gender"
              checked={gender == "m"}
              onChange={onGenderChange}
            />
            <span className="pl-2">Masculino</span>
          </Label>
          <Label className="pt-2 pl-4 pr-4">
            <Radio
              value="f"
              name="gender"
              checked={gender == "f"}
              onChange={onGenderChange}
            />
            <span className="pl-2">Feminino</span>
          </Label>
          <Label className="pt-2 pl-0 ml-0">
            <Radio
              value="o"
              name="gender"
              checked={gender == "o"}
              onChange={onGenderChange}
            />
            <span className="pl-2">Não informado</span>
          </Label>
        </Field>
      </Column>
    )
  },

  Rg: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const rgIdentifier = props.rgIdentifier
    const rgIdentifierError = props.rgIdentifierError
    const setRgIdentifier = props.setRgIdentifier
    const setRgIdentifierError = props.setRgIdentifierError
    const rgDigit = props.rgDigit
    const setRgDigit = props.setRgDigit
    const rgUf = props.rgUf
    const setRgUf = props.setRgUf
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 6} className="field-column" >
        <Column.Group centered className="is-mobile">
          <Column >
            <Field>
              <Label>RG:{required && <span className="has-text-danger"> *</span>}</Label>
              <Control tooltip={rgIdentifierError} className="has-tooltip-danger" iconRight>
                <Input
                  size={inputSize}
                  color={rgIdentifierError && "danger"}
                  type="text"
                  value={rgIdentifier}
                  onChange={e => { methods.verifyAndSetRgIdentifier(e.target.value, setRgIdentifier, setRgIdentifierError); if (setFormChanged) { setFormChanged(true) } }}
                  name="rg-identifier"
                />
                {rgIdentifierError &&
                  <Icon size="small" align="right">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </Icon>
                }
              </Control>
            </Field>
          </Column>
          <Column className="pl-0 one-digit-input">
            <Field>
              <Label>DV:</Label>
              <Control>
                <Input
                  size={inputSize}
                  type="text"
                  value={rgDigit}
                  onChange={e => { methods.maskAndSetRgDigit(e.target.value, setRgDigit); if (setFormChanged) { setFormChanged(true) } }}
                  name="rg-digit"
                />
              </Control>
            </Field>
          </Column>
          <Column className="pl-0 uf-input">
            <Field>
              <Label>UF:</Label>
              <Control>
                <Select.Container>
                  <Select
                    type="text"
                    value={rgUf}
                    onChange={e => { setRgUf(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                    name="rg-uf"
                  >
                    {ufList.map((uf, key) => (
                      <Select.Option key={key}>{uf}</Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
            </Field>
          </Column>
        </Column.Group>
      </Column>
    )
  },

  Cpf: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const cpf = props.cpf
    const cpfError = props.cpfError
    const setCpf = props.setCpf
    const setCpfError = props.setCpfError
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 3} className="field-column" >
        <Field>
          <Label >CPF:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control tooltip={cpfError} className="has-tooltip-danger date-input">
            <Input
              size={inputSize}
              placeholder="____.____.____-___"
              color={cpfError && "danger"}
              type="text"
              value={cpf}
              onChange={e => { methods.maskAndSetCpf(e.target.value, setCpf, setCpfError); if (setFormChanged) { setFormChanged(true) } }}
              name="cpf"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  Date: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const label = props.label
    const elementName = props.elementName
    const date = props.date
    const dateError = props.dateError
    const setDate = props.setDate
    const setDateError = props.setDateError
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 3} className="field-column" >
        <Field>
          <Label>{label}{required && <span className="has-text-danger"> *</span>}</Label>
          <Control tooltip={dateError} className="has-tooltip-danger" iconRight>
            <Input
              size={inputSize}
              color={dateError && "danger"}
              placeholder="___/___/_____"
              type="text"
              value={date}
              onChange={e => { methods.maskAndSetDate(e.target.value, setDate, setDateError); if (setFormChanged) { setFormChanged(true) } }}
              name={elementName}
              onClick={() => this.select()}
            />
            {dateError &&
              <Icon size="small" align="right">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Icon>
            }
          </Control>
        </Field>
      </Column>
    )
  },

  Cep: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const cep = props.cep
    const cepError = props.cepError
    const setCep = props.setCep
    const setCepError = props.setCepError
    const setStreet = props.setStreet
    const setAdressNumber = props.setAdressNumber
    const setComplement = props.setComplement
    const setNeighborhood = props.setNeighborhood
    const setCity = props.setCity
    const setUf = props.setUf
    const setLoading = props.setLoading
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 3} className="field-column" >
        <Field>
          <Label>CEP:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control tooltip={cepError} className="has-tooltip-danger" iconRight>
            <Input
              size={inputSize}
              color={cepError && "danger"}
              placeholder="________-_____"
              type="text"
              value={cep}
              onChange={e => { methods.maskAndSetCepAndGetAdress(e.target.value, setCep, setCepError, setStreet, setAdressNumber, setComplement, setNeighborhood, setCity, setUf, setLoading); if (setFormChanged) { setFormChanged(true) } }}
              name="cep"
            />
            {cepError &&
              <Icon size="small" align="right">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Icon>
            }
          </Control>
        </Field>
      </Column>
    )
  },

  Street: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const street = props.street
    const setStreet = props.setStreet
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 7} className="field-column" >
        <Field>
          <Label>Logradouro:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control>
            <Input
              size={inputSize}
              placeholder="Rua João da Silva"
              type="text"
              value={street}
              onChange={e => { setStreet(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
              name="street"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  AdressNumber: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const adressNumber = props.adressNumber
    const setAdressNumber = props.setAdressNumber
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 2} className="field-column" >
        <Field>
          <Label>Número:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control>
            <Input
              size={inputSize}
              placeholder=""
              type="text"
              value={adressNumber}
              onChange={e => { methods.maskAndSetAdressNumber(e.target.value, setAdressNumber); if (setFormChanged) { setFormChanged(true) } }}
              name="adressNumber"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  Complement: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const complement = props.complement
    const setComplement = props.setComplement
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 3} className="field-column" >
        <Field>
          <Label>Complemento:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control>
            <Input
              size={inputSize}
              type="text"
              value={complement}
              onChange={e => { setComplement(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
              name="complement"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  Neighborhood: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const neighborhood = props.neighborhood
    const setNeighborhood = props.setNeighborhood
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 3} className="field-column" >
        <Field>
          <Label>Bairro:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control>
            <Input
              size={inputSize}
              placeholder=""
              type="text"
              value={neighborhood}
              onChange={e => { setNeighborhood(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
              name="neighborhood"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  CityAndUf: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const city = props.city
    const setCity = props.setCity
    const uf = props.uf
    const setUf = props.setUf
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 6} className="field-column" >
        <Column.Group className="is-mobile">
          <Column >
            <Field>
              <Label>Cidade:{required && <span className="has-text-danger"> *</span>}</Label>
              <Control>
                <Input
                  size={inputSize}
                  placeholder=""
                  type="text"
                  value={city}
                  onChange={e => { setCity(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                  name="city"
                />
              </Control>
            </Field>
          </Column>
          <Column className="pl-0 uf-input">
            <Field>
              <Label>UF:{required && <span className="has-text-danger"> *</span>}</Label>
              <Control>
                <Select.Container>
                  <Select
                    size={inputSize}
                    type="text"
                    value={uf}
                    onChange={e => { setUf(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                    name="uf"
                  >
                    {ufList.map((uf, key) => (
                      <Select.Option key={key}>{uf}</Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
            </Field>
          </Column>
        </Column.Group>
      </Column>
    )
  },

  ProfessionalTitle: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const professionalTitle = props.professionalTitle
    const setProfessionalTitle = props.setProfessionalTitle
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 5} className="field-column" >
        <Field>
          <Label>Título profissional:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control>
            <Input
              size={inputSize}
              placeholder="Exemplo: Fisioterapeuta"
              type="text"
              value={professionalTitle}
              onChange={e => { setProfessionalTitle(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
              name="professional-title"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  CouncilName: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const councilName = props.councilName
    const setCouncilName = props.setCouncilName
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 3} className="field-column" >
        <Field>
          <Label>Conselho prof.:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control>
            <Input
              size={inputSize}
              placeholder="Exemplo: CREFITO"
              type="text"
              value={councilName}
              onChange={e => { setCouncilName(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
              name="council-name"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  CouncilRegister: (props) => {

    const required = props.required
    const columnSize = props.columnSize
    const inputSize = props.inputSize
    const councilRegister = props.councilRegister
    const setCouncilRegister = props.setCouncilRegister
    const setFormChanged = props.setFormChanged

    return (
      <Column size={props.columnSize || 4} className="field-column" >
        <Field>
          <Label>Registro:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control>
            <Input
              size={inputSize}
              placeholder="Exemplo: 1 12345-PR"
              type="text"
              value={councilRegister}
              onChange={e => { setCouncilRegister(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
              name="council-register"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  FieldWithSearch: (props) => {

    const [value, setValue] = useState(props.value || "")
    const [list, setList] = useState(null)
    const [listError, setListError] = useState(null)
    const [dropdownIsActive, setDropdownIsActive] = useState(null)
    const [timer, setTimer] = useState(null)
    const [loading, setLoading] = useState(false)

    const resultId = props.resultId
    const setResultId = props.setResultId
    const placeholder = props.placeholder
    const setFormChanged = props.setFormChanged
    const searchAndSetDropdownItensCallback = props.searchAndSetDropdownItensCallback
    const disableSelect = props.disableSelect
    const setHasUnsavedChanges = props.setHasUnsavedChanges

    useEffect(() => {
      if (props.getValue) {
        props.getValue(value)
      }
    }, [value])

    return (
      <Field>
        <Control tooltip={listError} className="has-tooltip-danger" iconLeft>
          <Dropdown managed active={dropdownIsActive} className="search-dropdown" >
            <Dropdown.Trigger className="search-dropdown" >
              <Input
                readOnly={disableSelect ? true : false}
                static={disableSelect ? true : false}
                placeholder={placeholder || "Digite parte do nome"}
                size={12}
                color={listError && "danger"}
                type="text"
                value={value}
                onChange={e => { searchAndSetDropdownItensCallback(e.target.value, setValue, setList, setListError, timer, setTimer, setResultId, setLoading, setDropdownIsActive); setDropdownIsActive(false); if (setFormChanged) { setFormChanged(true) }; if (setHasUnsavedChanges) { setHasUnsavedChanges(true) } }}
                autoComplete="off"
              />
              <Icon size="small" align="left">
                {loading &&
                  <span className="loader-icon"></span>
                  ||
                  <FontAwesomeIcon className={(resultId != "" && resultId) && "success-icon" || ""} icon={(resultId != "" && resultId) && faCheck || faSearch} />
                }
              </Icon>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <Dropdown.Content>
                {list ?
                  list.map((item, key) => {
                    let itemInfo
                    if (item.tradingName) {
                      itemInfo = `${item.name} - ${item.tradingName}`
                    } else {
                      itemInfo = item.name
                    }
                    return (
                      <Dropdown.Item key={key} onClick={e => { setValue(itemInfo); setDropdownIsActive(false); setResultId(item._id) }} >
                        {itemInfo}
                      </Dropdown.Item>
                    )
                  })
                :
                  <Dropdown.Item onClick={e => { setValue(""); setDropdownIsActive(false)}} >
                    <span className="has-text-danger">Não foram encontrados resultados.</span>
                  </Dropdown.Item>
                }
              </Dropdown.Content>
            </Dropdown.Menu>
          </Dropdown>
        </Control>
      </Field>
    )
  },

  InputWithSearchCompany: (props) => {
    const [inputValue, setInputValue] = useState("")
    const [itens, setItens] = useState(null)
    const [itensError, setItensError] = useState(null)
    const [dropdownIsActive, setDropdownIsActive] = useState(null)
    const [timer, setTimer] = useState(null)

    const required = props.required
    const objectError = props.objectError
    const companyId = props.companyId
    const setCompanyId = props.setCompanyId
    const setFormChanged = props.setFormChanged

    return (
      <Column size={12} className="field-column" >
        <Label >Empresa (digite e selecione):{required && <span className="has-text-danger"> *</span>}</Label>
        <Components.FieldWithSearch
          resultId={companyId}
          setResultId={setCompanyId}
          searchAndSetDropdownItensCallback={methods.searchAndSetCompaniesDropdown}
        />
      </Column>
    )
  },

  MandatoryFieldsInfo: (props) => {
    return (
      <Column.Group centered className="m-0">
        <Column textAlign="left" className="p-0">
          <Label ><span className="has-text-danger">* Campos obrigatórios</span></Label>
        </Column>
      </Column.Group>
    )
  },

  SubmitButton: (props) => {

    const icon = props.icon
    const label = props.children
    const setOpen = props.setOpen

    return (
      <Field>
        <Button.Group align="centered">
          <Button color="info" size="medium" className="has-text-weight-semibold" onClick={e => { if (setOpen) { setOpen(!open) } }}>
            <Icon size="small" align="right">
              <FontAwesomeIcon icon={icon} />
            </Icon>
            <span>{label}</span>
          </Button>
        </Button.Group>
      </Field>
    )
  },

  CompanyName: (props) => {

    const required = props.required
    const inputSize = props.inputSize
    const name = props.name
    const nameError = props.nameError
    const setName = props.setName
    const setNameError = props.setNameError
    const setFormChanged = props.setFormChanged

    return (
      <Column size={6} className="field-column" >
        <Field>
          <Label >Nome Empresarial:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control tooltip={nameError} className="has-tooltip-danger" iconRight>
            <Input
              size={inputSize}
              color={nameError && "danger"}
              type="text"
              value={name}
              onChange={e => { methods.verifyAndSetName(e.target.value, setName, setNameError); if (setFormChanged) { setFormChanged(true) } }}
              name="name"
            />
            {nameError &&
              <Icon size="small" align="right">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Icon>
            }
          </Control>
        </Field>
      </Column>
    )
  },

  TradingName: (props) => {

    const required = props.required
    const inputSize = props.inputSize
    const tradingName = props.tradingName
    const tradingNameError = props.tradingNameError
    const setTradingName = props.setTradingName
    const setTradingNameError = props.setTradingNameError
    const setFormChanged = props.setFormChanged

    return (
      <Column size={6} className="field-column" >
        <Field>
          <Label >Nome fantasia:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control tooltip={tradingNameError} className="has-tooltip-danger" iconRight>
            <Input
              size={inputSize}
              color={tradingNameError && "danger"}
              type="text"
              value={tradingName}
              onChange={e => { methods.verifyNotEmptyAndSetTradingName(e.target.value, setTradingName, setTradingNameError); if (setFormChanged) { setFormChanged(true) } }}
              name="trading-name"
            />
            {tradingNameError &&
              <Icon size="small" align="right">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Icon>
            }
          </Control>
        </Field>
      </Column>
    )
  },

  Cnpj: (props) => {

    const required = props.required
    const inputSize = props.inputSize
    const cnpj = props.cnpj
    const cnpjError = props.cnpjError
    const setCnpj = props.setCnpj
    const setCnpjError = props.setCnpjError
    const setFormChanged = props.setFormChanged

    return (
      <Column size={2} className="field-column cnpj" >
        <Field>
          <Label >CNPJ:{required && <span className="has-text-danger"> *</span>}</Label>
          <Control tooltip={cnpjError} className="has-tooltip-danger">
            <Input
              size={inputSize}
              placeholder="___.____.____/______-___"
              color={cnpjError && "danger"}
              type="text"
              value={cnpj}
              onChange={e => { methods.maskAndSetCnpj(e.target.value, setCnpj, setCnpjError); if (setFormChanged) { setFormChanged(true) } }}
              name="cnpj"
            />
          </Control>
        </Field>
      </Column>
    )
  },

  CheckboxContainer: (props) => {

    const { required, columnSize, notNarrow, label, children } = props

    return (
      <Column size={columnSize ? columnSize : ""} narrow={notNarrow ? false : true} className="field-column" >

        <Field className="mb-0">
          {label &&
            <Label>
              {label}{required && <span className="has-text-danger"> *</span>}
            </Label>
          }
          <Control>
            <Column.Group multiline gapless className="checkboxes-container">

              {children}

            </Column.Group>
          </Control>
        </Field>
      </Column>
    )
  },

  Checkbox: (props) => {

    const size = props.size
    const label = props.children
    const checked = props.checked
    const onChange = props.onChange
    const color = props.color
    const noPadding = props.noPadding
    const setFormChanged = props.setFormChanged
    const notNarrow = props.notNarrow
    const icon = props.icon

    return (
      <Column narrow={notNarrow ? false : true} className={noPadding ? "p-0" : "pr-0 pl-2 py-2 is-12-touch"}>
        <Label className="checkbox-container">
          <Checkbox
            type="checkbox"
            checked={checked}
            onChange={e => { onChange(e.target.checked); if (setFormChanged) { setFormChanged(true) } }}
          />
          <Button as="div" text className="checkbox-button is-size-6 has-text-weight-normal is-fullwidth has-background-white ">
            <span className="checkmark" />
            {color &&
              <Button as="div" size="small" color={checked && color || ""} className={!checked && `has-background-light has-text-grey`} >{label}</Button>
              || <span>{label}</span>
            }
          </Button>
        </Label>
      </Column>
    )
  },

  GenericInput: (props) => {
    const [InputText, setInputText] = useState(Input)

    const inputRef = useRef(null)

    const required = props.required
    const type = props.type || "text"
    const inputSize = props.inputSize
    const fontWeight = props.fontWeight
    const size = props.size
    const label = props.children || props.label
    const leftLabel = props.leftLabel
    const icon = props.icon
    const placeholder = props.placeholder
    const elementName = props.elementName
    const value = props.value || ""
    const setValue = props.setValue
    const valueError = props.valueError
    const maskAndSetValue = props.maskAndSetValue
    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges

    const isTextarea = props.isTextarea
    const rows = props.rows

    const staticInput = props.staticInput
    const readOnly = props.readOnly
    const disableSelect = props.disableSelect
    const disabled = props.disabled

    const noPadding = props.noPadding

    useEffect(() => {
      if (isTextarea) {
        setInputText(Textarea)
      }
    }, [])

    useEffect(() => {
      inputRef.current.style.height = ""
      inputRef.current.style.height = inputRef.current.scrollHeight + 5 + "px"
    }, [InputText])

    return (
      <Column size={size == 0 ? null : size || 6} className={`field-column ${noPadding && "p-0"}`} >
        <Field>

          {label &&
            <Label className={`${disabled ? "has-text-grey-light" : ""}`} >{label}{required && <span className="has-text-danger"> *</span>}</Label>
          }
          <Control tooltip={valueError} className="has-tooltip-danger is-block" iconLeft={icon ? true : false}>
            <InputText
              ref={inputRef}
              style={disableSelect ? { border: "0px", backgroundColor: "inherit", boxShadow: "none", resize: "none", fontWeight: fontWeight || null } : null}
              color={valueError && "danger"}
              type={type}
              rows={isTextarea ? rows : null}
              onInput={isTextarea ? (() => { inputRef.current.style.height = ""; inputRef.current.style.height = inputRef.current.scrollHeight + 5 + "px" }) : null}
              static={((staticInput || disableSelect) && !isTextarea) ? true : undefined}
              readOnly={readOnly || disableSelect ? true : false}
              disabled={disabled ? true : false}
              size={inputSize && inputSize}
              placeholder={!disableSelect ? placeholder : null}
              value={value}
              onChange={e => { if (maskAndSetValue) { maskAndSetValue(e.target.value, setValue) } else { setValue(e.target.value) }; if (setFormChanged) { setFormChanged(true) }; if (setHasUnsavedChanges) { setHasUnsavedChanges(true) } }}
              name={elementName}
            />
            {(icon && !isTextArea) &&
              <Icon align="left">
                <FontAwesomeIcon icon={icon} />
              </Icon>
            }
          </Control>
        </Field>
      </Column>
    )
  },

  GenericStaticField: (props) => {

    const size = props.size
    const label = props.label
    const value = props.value

    return (
      <Column size={size || 6} className="field-column static-field" >
        <Title size={6}>{label}</Title>
        <Title
          subtitle
          size={6}
          type="text"
        >
          {value}
        </Title>
      </Column>
    )
  },

  Switch: (props) => {

    const label = props.label
    const leftLabel = props.leftLabel
    const checked = props.checked
    const onChange = props.onChange
    const noText = props.noText
    const labelSize = props.labelSize

    return (
      <Fragment>
        {!leftLabel &&
          <Column narrow className="field-column" >
            <Field className="mb-0">

              <Control>
                <Label>
                  {label}
                </Label>
                <Column narrow className="checkboxes-container">
                  <Label className="checkbox-container">
                    <Button as="div" text className="switch-button pl-1">
                      <Switch
                        onColor="#00CC66"
                        checked={checked}
                        onChange={onChange}
                        height={22}
                        width={48}
                      />
                      {checked && !noText && <Title textColor="success" subtitle size={5} className="px-2 m-0 has-text-weight-bold">Ativo</Title>}
                      {!checked && !noText && <Title textColor="danger" subtitle size={5} className="px-2 m-0 has-text-weight-bold">Inativo</Title>}
                    </Button>
                  </Label>
                </Column>
              </Control>

            </Field>
          </Column>
        }

        {leftLabel &&
          <Fragment>
            <Label>
              <Column.Group vcentered multiline breakpoint="mobile" className="my-1">
                <Column size={labelSize || 6} className="py-1">
                  <span>{label}</span>
                </Column>
                <Column size={labelSize ? 12 - labelSize : 6} className="py-1" >
                  <Button as="div" text className="switch-button pl-1">
                    <Switch
                      onColor="#00CC66"
                      checked={checked}
                      onChange={onChange}
                      height={22}
                      width={48}
                    />
                    {checked && !noText && <Title textColor="success" subtitle size={5} className="px-2 m-0 has-text-weight-bold">Ativo</Title>}
                    {!checked && !noText && <Title textColor="danger" subtitle size={5} className="px-2 m-0 has-text-weight-bold">Inativo</Title>}
                  </Button>
                </Column>
              </Column.Group>
            </Label>
          </Fragment>
        }
      </Fragment>


    )
  },

  ImageUpload: (props) => {

    const required = props.required
    const inputSize = props.inputSize
    const size = props.size
    const label = props.children
    const file = props.file
    const setFile = props.setFile
    const fileMaxSize = props.fileMaxSize
    const fileError = props.fileError
    const setFileError = props.setFileError
    const fileName = props.fileName
    const setFileName = props.setFileName
    const setFormChanged = props.setFormChanged

    return (
      <Column size={size || 6} className="field-column" >
        <Field>
          <Label >{label}{required && <span className="has-text-danger"> *</span>}</Label>
          <Control tooltip={fileError} className="has-tooltip-danger" iconRight>
            <RbxFile hasName fullwidth color="primary" textColor={fileError && "danger"}>
              <RbxFile.Label>
                <RbxFile.Input
                  size={inputSize}
                  name="file-input"
                  onChange={e => { methods.imageHandler(e.target.files[0], setFile, setFileName, fileMaxSize, setFileError); if (setFormChanged) { setFormChanged(true) } }}
                />
                <RbxFile.CTA>
                  <RbxFile.Icon>
                    <FontAwesomeIcon icon={faUpload} />
                  </RbxFile.Icon>
                  <RbxFile.Label as="span" textColor={fileError && "danger"}>Escolha uma imagem</RbxFile.Label>
                </RbxFile.CTA>
                <RbxFile.Name>{fileName}</RbxFile.Name>
              </RbxFile.Label>
            </RbxFile>
            {fileError &&
              <Icon size="small" align="right">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Icon>
            }
          </Control>
        </Field>
      </Column>
    )
  },

  SearchFiltersCard: (props) => {
    const [showFilters, setShowFilters] = useState(true)
    const [showFiltersIcon, setShowFiltersIcon] = useState(faEyeSlash)
    const [showFiltersIconColor, setShowFiltersIconColor] = useState("black")
    const [showFiltersMessage, setShowFiltersMessage] = useState("Ocultar")

    const children = props.children

    return (
      <Card className="search-card">
        <Section className="card-section">
          <Components.CardTitle icon={faSearch} >
            <span className="pr-4">Pesquisar</span>
          </Components.CardTitle>
          {showFilters &&
            <Fragment>
              <hr className="card-divider" />
              {children}
              <Components.SubmitButton icon={faSearch} setOpen={null} >
                Pesquisar
              </Components.SubmitButton>
            </Fragment>
          }
        </Section>
      </Card>
    )
  },

  CardTitle: (props) => {

    const label = props.children
    const icon = props.icon
    let onClick = null
    if (props.onClick) {
      onClick = props.onClick
    } else {
      onClick = () => { }
    }


    return (
      <Title className="card-title mb-3" onClick={props.onClick}>
        <Column.Group className="mb-0" breakpoint="mobile">
          {icon &&
            <Column narrow className="pt-3 pb-0">
              <Icon>
                <FontAwesomeIcon icon={icon} className="vertical-align-middle" />
              </Icon>
            </Column>
          }
          <Column className="pt-2 pb-0">
            <span>{label}</span>
          </Column>
        </Column.Group>
      </Title>
    )
  },

  DatePeriod: (props) => {

    const required = props.required
    const inputSize = props.inputSize
    const elementName = props.elementName
    const initialDate = props.initialDate
    const initialDateError = props.initialDateError
    const finalDate = props.finalDate
    const finalDateError = props.finalDateError
    const setInitialDate = props.setInitialDate
    const setInitialDateError = props.setInitialDateError
    const setFinalDate = props.setFinalDate
    const setFinalDateError = props.setFinalDateError
    const setFormChanged = props.setFormChanged

    return (
      <Column className="field-column" >
        <Column.Group>

          <Column narrow className="field-column date-input">
            <Field>
              <Label>Data inicial:{required && <span className="has-text-danger"> *</span>}</Label>
                <DatePicker
                    selected={initialDate}
                    onChange={(date) => { setInitialDate(date); setInitialDateError(null) }}
                    customInput={
                      <Components.DatePickerInput
                        icon={faCalendar}
                        dateError={initialDateError}
                        setDateError={setInitialDateError}
                      />}
                    dateFormat="dd/MM/yyyy"
                    locale="pt-BR"
                    strictParsing
                  />
            </Field>
          </Column>

          <Column narrow className="field-column date-input">
            <Field>
              <Label>Data final:{required && <span className="has-text-danger"> *</span>}</Label>
              <DatePicker
                selected={finalDate}
                onChange={(date) => { setFinalDate(date); setFinalDateError(null) }}
                customInput={
                  <Components.DatePickerInput
                    icon={faCalendar}
                    dateError={finalDateError}
                    setDateError={setFinalDateError}
                  />}
                dateFormat="dd/MM/yyyy"
                locale="pt-BR"
                strictParsing
              />
            </Field>
          </Column>

        </Column.Group>
      </Column>
    )
  },

  SearchCompaniesResults: (props) => {

    let history = useHistory()

    const companies = props.companies
    const changeView = props.changeView
    const setObjectToEdit = props.setObjectToEdit

    if (!companies[0]) {
      return (
        <Title size={5} className="has-text-centered has-text-danger" >
          Não foram encontrados resultados com os parâmetros informados.
        </Title>
      )
    }

    return (
      <Column.Group>
        <Column className="table-container is-size-6">

          <Table fullwidth hoverable narrow >

            <Table.Head>
              <Table.Row>
                <Table.Heading textAlign="centered" className="table-column-small" >
                  Situação
                </Table.Heading>
                <Table.Heading>
                  Nome Empresarial
                </Table.Heading>
                <Table.Heading>
                  Nome Fantasia
                </Table.Heading>
                <Table.Heading textAlign="centered" >
                  CNPJ
                </Table.Heading>
                <Table.Heading textAlign="centered" >
                  Cadastro
                </Table.Heading>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {companies.map((company, key) => (
                <Table.Row key={key} className="cursor-pointer" onClick={e => {setObjectToEdit(company); history.push("/app/edit-company")}}>
                  <Table.Cell textAlign="centered">
                    {company.isActive && <span className="has-text-success has-text-weight-bold">Ativa</span>}
                    {!company.isActive && <span className="has-text-grey">Inativa</span>}
                  </Table.Cell>
                  <Table.Cell>
                    {company.name}
                  </Table.Cell>
                  <Table.Cell>
                    {company.tradingName}
                  </Table.Cell>
                  <Table.Cell textAlign="centered" >
                    {methods.maskAndSetCnpj(company.cnpj)}
                  </Table.Cell>
                  <Table.Cell textAlign="centered">
                    {methods.dateToString(company.createdAt)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

          </Table>

        </Column>
      </Column.Group>
    )
  },

  SearchUsersResults: (props) => {

    let history = useHistory();

    const users = props.users
    const setObjectToEdit = props.setObjectToEdit

    if (!users[0]) {
      return (
        <Title size={5} className="has-text-centered has-text-danger" >
          Não foram encontrados resultados com os parâmetros informados.
        </Title>
      )
    }

    return (
      <Column.Group>
        <Column className="table-container is-size-6">

          <Table fullwidth hoverable narrow >

            <Table.Head>
              <Table.Row>
                <Table.Heading textAlign="centered" className="table-column-small" >
                  Situação
                </Table.Heading>
                <Table.Heading>
                  Nome
                </Table.Heading>
                <Table.Heading>
                  E-mail
                </Table.Heading>
                {JSON.parse(localStorage.getItem('user')).isSystemAdm &&
                  <Table.Heading>
                    Empresa
                  </Table.Heading>
                }
                <Table.Heading textAlign="centered">
                  Níveis de Acesso
                </Table.Heading>
                {JSON.parse(localStorage.getItem('user')).isSystemAdm &&
                  <Table.Heading textAlign="centered" >
                    Último Acesso
                  </Table.Heading>
                }
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {users.map((user, key) => (
                <Table.Row key={key} className="cursor-pointer" onClick={e => {setObjectToEdit(user); history.push("/app/edit-user")}}>
                  <Table.Cell textAlign="centered">
                    {user.isActive && <span className="has-text-success has-text-weight-bold">Ativo</span>}
                    {!user.isActive && <span className="has-text-danger has-text-weight-bold">Inativo</span>}
                  </Table.Cell>
                  <Table.Cell>
                    {user.name}
                  </Table.Cell>
                  <Table.Cell>
                    {user.email}
                  </Table.Cell>
                  {JSON.parse(localStorage.getItem('user')).isSystemAdm &&
                    <Table.Cell>
                      {user.company}
                    </Table.Cell>
                  }
                  <Table.Cell textAlign="centered">
                    <p>{user.isSystemAdm && <Button as="span" size="small" color="dark" className="m-1" >Administrador do Sistema</Button>}</p>
                    <p>{user.isCompanyAdm && <Button as="span" size="small" color="info" className="m-1" >Administrador da Empresa</Button>}</p>
                    {user.isProfessional && <Button as="span" size="small" color="primary" className="m-1" >Profissional de Saúde</Button>}
                    {user.isSecretary && <Button as="span" size="small" color="success" className="m-1" >Secretário</Button>}
                  </Table.Cell>
                  {JSON.parse(localStorage.getItem('user')).isSystemAdm &&
                    <Table.Cell textAlign="centered">
                      {methods.dateToString(user.lastAccess)}
                    </Table.Cell>
                  }
                </Table.Row>
              ))}
            </Table.Body>

          </Table>

        </Column>
      </Column.Group>
    )
  },

  SearchPatientsResults: (props) => {

    let history = useHistory();

    const patients = props.patients
    const setObjectToEdit = props.setObjectToEdit

    if (!patients[0]) {
      return (
        <Title size={5} className="has-text-centered has-text-danger" >
          Não foram encontrados resultados com os parâmetros informados.
        </Title>
      )
    }

    return (
      <Column.Group>
        <Column className="table-container is-size-6">

          <Table fullwidth hoverable narrow>

            <Table.Head>
              <Table.Row>
                <Table.Heading textAlign="centered" className="table-column-small" >
                  Situação
                </Table.Heading>
                <Table.Heading>
                  Nome
                </Table.Heading>
                <Table.Heading textAlign="centered" >
                  Cadastro
                </Table.Heading>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {patients.map((patient, key) => (
                <Table.Row key={key} className="cursor-pointer" onClick={e => {setObjectToEdit(patient); history.push("/app/edit-patient")}}>
                  <Table.Cell textAlign="centered">
                    {patient.isActive && <span className="has-text-success has-text-weight-bold">Ativo</span>}
                    {!patient.isActive && <span className="has-text-danger has-text-weight-bold">Inativo</span>}
                  </Table.Cell>
                  <Table.Cell>
                    {patient.name}
                  </Table.Cell>
                  <Table.Cell textAlign="centered">
                    {methods.dateToString(patient.createdAt)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

          </Table>

        </Column>
      </Column.Group>
    )
  },

  SearchAttendancesResults: (props) => {

    let history = useHistory();

    const { attendances, setObjectToEdit, getAttendances, setNotification, setFormChanged, setLoading, setRedirectToLogin } = props

    if (!attendances[0]) {
      return (
        <Title size={5} className="has-text-centered has-text-danger" >
          Não foram encontrados resultados com os parâmetros informados.
        </Title>
      )
    }

    return (
      <Column.Group>
        <Column className="table-container is-size-6">

          <Table fullwidth hoverable narrow>

            <Table.Head>
              <Table.Row>
                <Table.Heading>
                  Paciente
                </Table.Heading>
                <Table.Heading>
                  Profissional
                </Table.Heading>
                <Table.Heading>
                  Descrição
                </Table.Heading>
                <Table.Heading textAlign="centered" className="table-column-medium">
                  Valor
                </Table.Heading>
                <Table.Heading textAlign="centered" className="table-column-medium">
                  Pagamento
                </Table.Heading>
                <Table.Heading textAlign="centered" >
                  Agendado para:
                </Table.Heading>
                <Table.Heading textAlign="centered" >
                  Atendido em:
                </Table.Heading>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {attendances.map((attendance, key) => {
                let paid = 0
                if (attendance.payments) {
                  attendance.payments.map((payment) => {
                    if (payment) {
                      paid += payment.value
                    }
                  })
                }
                if (attendance.price == 0 || !attendance.price) {
                  paid = "Sem cobrança"
                } else if (attendance.price > paid) {
                  paid = "Pendente"
                } else if (attendance.price <= paid) {
                  paid = "Pago"
                }
                return (
                  <Table.Row key={key} className="cursor-pointer" onClick={e => {setObjectToEdit(attendance); history.push("/app/edit-attendance")}}>
                    <Table.Cell>
                      {attendance.patient}
                    </Table.Cell>
                    <Table.Cell>
                      {attendance.professional}
                    </Table.Cell>
                    <Table.Cell>
                      {methods.stringCut(attendance.description, 20)}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {attendance.attendancePackageId ?
                        <span className="has-text-primary-dark has-text-weight-bold">Pacote atend.</span>
                        :
                        <span>{methods.numberToPrice(attendance.price)}</span>
                      }                      
                    </Table.Cell>
                    <Table.Cell textAlign="centered">
                      {attendance.attendancePackageId ?
                        <span>-</span>
                        :
                        <Fragment>
                          {paid != "Sem cobrança" ? <span className={paid == "Pago" ? "has-background-success has-text-white mr-2 event-icon" : "has-background-danger has-text-white mr-2 event-icon"} >$</span> : ""}
                          <span className={(paid == "Pendente" && "has-text-danger") || (paid == "Pago" && "has-text-success") || ""}>{paid}</span>
                        </Fragment>
                      }

                    </Table.Cell>
                    <Table.Cell textAlign="centered">
                      {methods.dateToString(attendance.start)}
                    </Table.Cell>
                    <Table.Cell textAlign="centered">
                      {methods.dateToString(attendance.attendanceDateTime)}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>

          </Table>

        </Column>
      </Column.Group>
    )
  },

  SearchAttendancePackagesResults: (props) => {

    let history = useHistory();

    const { attendancePackages, setObjectToEdit, getAttendancePackages, getAttendancesOfOnePackage, setNotification, setFormChanged, setLoading, setRedirectToLogin } = props

    if (!attendancePackages[0]) {
      return (
        <Title size={5} className="has-text-centered has-text-danger" >
          Não foram encontrados resultados com os parâmetros informados.
        </Title>
      )
    }

    const onDelete = (event, attendancePackage) => {
      setNotification({
        withCancel: true,
        success: false,
        title: `Excluir Pacote de Atendimento`,
        text: `Tem certeza que deseja excluir o Pacote de Atendimento?"`,
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setLoading(true)
          let submitInfo = {
            event: event,
            setNotification: setNotification,
            setLoading: setLoading,
            setOpenComponent: null,
            mandatoryFields: [],
            errorList: [],
            ServiceMethod: AttendancePackagesService.deleteAttendancePackage,
            params: {
              attendancePackageId: attendancePackage._id,
            },
            onSuccessText: "Pacote de Atendimento excluído.",
            onErrorText: "Não foi possível excluir o Pacote de Atendimento. Tente novamente",
            onSuccessCallback: (response) => {
              getAttendancePackages()
            },
            onErrorCallback: null,
            setRedirectToLogin: setRedirectToLogin,
          }
          methods.handleSubmit(submitInfo)
        })
      })
    }

    return (
      <Column.Group>
        <Column className="table-container is-size-6">

          <Table fullwidth hoverable narrow>

            <Table.Head>
              <Table.Row>
                <Table.Heading>
                  Nome do Pacote
                </Table.Heading>
                <Table.Heading>
                  Paciente
                </Table.Heading>
                <Table.Heading textAlign="centered">
                  Atend.
                </Table.Heading>
                <Table.Heading textAlign="centered" className="table-column-medium">
                  Valor
                </Table.Heading>
                <Table.Heading textAlign="centered" className="table-column-medium">
                  Pagamento
                </Table.Heading>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {attendancePackages.map((attendancePackage, key) => {
                let paid = 0
                if (attendancePackage.payments) {
                  attendancePackage.payments.map((payment) => {
                    if (payment) {
                      paid += payment.value
                    }
                  })
                }
                if (attendancePackage.price == 0 || !attendancePackage.price) {
                  paid = "Sem cobrança"
                } else if (attendancePackage.price > paid) {
                  paid = "Pendente"
                } else if (attendancePackage.price <= paid) {
                  paid = "Pago"
                }
                return (
                  <Table.Row key={key} className="cursor-pointer" onClick={e => {
                    getAttendancesOfOnePackage(attendancePackage._id)
                    .then((response) => {
                      let attendancePackageToPass = { ...response.attendancePackage, attendancesArray: response.attendancesArray }
                      setObjectToEdit(attendancePackageToPass)
                      history.push("/app/edit-attendance-package")
                    })
                  }}>
                    <Table.Cell>
                      {attendancePackage.name}
                    </Table.Cell>
                    <Table.Cell>
                      {attendancePackage.patient}
                    </Table.Cell>
                    <Table.Cell textAlign="centered">
                      {attendancePackage.attendancesQuantity}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {methods.numberToPrice(attendancePackage.price)}
                    </Table.Cell>
                    <Table.Cell>
                      {paid != "Sem cobrança" ? <span className={paid == "Pago" ? "has-background-success has-text-white mr-2 event-icon" : "has-background-danger has-text-white mr-2 event-icon"} >$</span> : ""}
                      <span className={(paid == "Pendente" && "has-text-danger") || (paid == "Pago" && "has-text-success") || ""}>{paid}</span>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>

          </Table>

        </Column>
      </Column.Group>
    )
  },

  SearchDocumentTemplatesResults: (props) => {

    const { documentTemplates, setOpenEditDocumentTemplate, setObjectToEdit, setNotification, setFormChanged, setLoading, setRedirectToLogin } = props

    if (!documentTemplates[0]) {
      return (
        <Title size={5} className="has-text-centered has-text-danger" >
          Não foram encontrados resultados com os parâmetros informados.
        </Title>
      )
    }

    return (
      <Column.Group>
        <Column className="table-container is-size-6">

          <Table fullwidth hoverable narrow>

            <Table.Head>
              <Table.Row>
                <Table.Heading>
                  Tipo de documento
                </Table.Heading>
                <Table.Heading>
                  {"Descrição (resumo)"}
                </Table.Heading>
                <Table.Heading>
                  Profissional
                </Table.Heading>
                <Table.Heading textAlign="centered" style={{ maxWidth: "150px" }}>
                  Disponível para todos os profissionais
                </Table.Heading>
                <Table.Heading textAlign="centered">
                  Cadastro
                </Table.Heading>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {documentTemplates.map((documentTemplate, key) => {
                return (
                  <Table.Row key={key} className="cursor-pointer" onClick={e => {setOpenEditDocumentTemplate(documentTemplate)}}>
                    <Table.Cell>
                      {documentTemplate.documentType}
                    </Table.Cell>
                    <Table.Cell>
                      {methods.stringCut(documentTemplate.description, 30)}
                    </Table.Cell>
                    <Table.Cell>
                      {documentTemplate && documentTemplate.professionalInfo && documentTemplate.professionalInfo.name}
                    </Table.Cell>
                    <Table.Cell textAlign="centered">
                      {documentTemplate.availableToCompanyProfessionals ?
                      "Sim"
                      :
                      "Não"
                      }
                    </Table.Cell>                    
                    <Table.Cell textAlign="centered">
                      {methods.dateToString(documentTemplate.createdAt)}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>

          </Table>

        </Column>
      </Column.Group>
    )
  },

  PatientTabs: (props) => {
    const activeTab = props.activeTab
    const setActiveTab = props.setActiveTab

    useEffect(() => {
      const elements = document.getElementsByClassName('tabs')
      let el = undefined
      if (elements) {
        el = elements[0]
      }

      const scrollHorizontally = (e) => {
        e = window.event || e
        e.preventDefault()
        el.scrollBy({ left: e.deltaY * 1.5, behavior: 'smooth' })
      }

      if (!el) {
        return
      }

      if (el.addEventListener) {
        el.addEventListener('mousewheel', scrollHorizontally, false)
        el.addEventListener('DOMMouseScroll', scrollHorizontally, false)
      } else {
        el.attachEvent('onmousewheel', scrollHorizontally)
      }

      return () => {
        if (el.removeEventListener) {
          el.removeEventListener('mousewheel', scrollHorizontally)
          el.removeEventListener('DOMMouseScroll', scrollHorizontally)
        } else if (el.detachEvent) {
          el.detachEvent('onmousewheel', scrollHorizontally)
        }
      }
    }, [])

    return (
      <Fragment>
        <Tab.Group kind="boxed" >
          <Tab active={activeTab == "1"} onClick={e => { setActiveTab("1") }}>
            <Icon color="">
              <FontAwesomeIcon icon={faList} />
            </Icon>
            <span>Informações básicas</span>
          </Tab>
          <Tab active={activeTab == "2"} onClick={e => { setActiveTab("2") }}>
            <Icon color="link">
              <FontAwesomeIcon icon={faStethoscope} />
            </Icon>
            <span>Atendimentos avulsos</span>
          </Tab>
          <Tab active={activeTab == "3"} onClick={e => { setActiveTab("3") }}>
            <Icon color="info">
              <FontAwesomeIcon icon={faBoxOpen} />
            </Icon>
            <span>Pacotes de Atendimentos</span>
          </Tab>
          <Tab active={activeTab == "4"} onClick={e => { setActiveTab("4") }}>
            <Icon color="success">
              <FontAwesomeIcon icon={faChartLine} />
            </Icon>
            <span>Evoluções Clínicas</span>
          </Tab>
          <Tab active={activeTab == "5"} onClick={e => { setActiveTab("5") }}>
            <Icon color="primary">
              <FontAwesomeIcon icon={faClipboard} />
            </Icon>
            <span>Prontuário</span>
          </Tab>
        </Tab.Group>
      </Fragment>
    )
  },

  AttendanceTabs: (props) => {
    const activeTab = props.activeTab
    const setActiveTab = props.setActiveTab

    useEffect(() => {
      const el = document.getElementById('attendance-tabs')

      const scrollHorizontally = (e) => {
        e = window.event || e
        e.preventDefault()
        el.scrollBy({ left: event.deltaY * 1.5, behavior: 'smooth' })
      }

      if (!el) {
        return
      }

      if (el.addEventListener) {
        el.addEventListener('mousewheel', scrollHorizontally, false)
        el.addEventListener('DOMMouseScroll', scrollHorizontally, false)
      } else {
        el.attachEvent('onmousewheel', scrollHorizontally)
      }

      return () => {
        if (el.removeEventListener) {
          el.removeEventListener('mousewheel', scrollHorizontally)
          el.removeEventListener('DOMMouseScroll', scrollHorizontally)
        } else if (el.detachEvent) {
          el.detachEvent('onmousewheel', scrollHorizontally)
        }
      }
    }, [])

    return (
      <Fragment>
        <Tab.Group kind="boxed" id="attendance-tabs" >
          <Tab active={activeTab == "1"} onClick={e => { setActiveTab("1") }}>
            <Icon >
              <FontAwesomeIcon icon={faList} size="lg" />
            </Icon>
            <span>Informações Gerais</span>
          </Tab>
          <Tab active={activeTab == "2"} onClick={e => { setActiveTab("2") }}>
            <Icon  color="info">
              <FontAwesomeIcon icon={faFileSignature} size="lg" />
            </Icon>
            <span>Registros e Documentos</span>
          </Tab>
          <Tab active={activeTab == "3"} onClick={e => { setActiveTab("3") }}>
            <Icon  color="success">
              <FontAwesomeIcon icon={faChartLine} size="lg" />
            </Icon>
            <span>Evoluções Clínicas</span>
          </Tab>
          <Tab active={activeTab == "4"} onClick={e => { setActiveTab("4") }}>
            <Icon  color="link">
              <FontAwesomeIcon icon={faClipboardCheck} size="lg" />
            </Icon>
            <span>Outros atendimentos</span>
          </Tab>
        </Tab.Group>
      </Fragment>
    )
  },

  AttendanceRegistersTabs: (props) => { // A princípio não é mais utilizado. Se for o caso, deletar
    const activeTab = props.activeTab
    const setActiveTab = props.setActiveTab

    useEffect(() => {
      const el = document.getElementById('attendance-register-tabs')

      const scrollHorizontally = (e) => {
        e = window.event || e
        e.preventDefault()
        el.scrollBy({ left: event.deltaY * 1.5, behavior: 'smooth' })
      }

      if (!el) {
        return
      }

      if (el.addEventListener) {
        el.addEventListener('mousewheel', scrollHorizontally, false)
        el.addEventListener('DOMMouseScroll', scrollHorizontally, false)
      } else {
        el.attachEvent('onmousewheel', scrollHorizontally)
      }

      return () => {
        if (el.removeEventListener) {
          el.removeEventListener('mousewheel', scrollHorizontally)
          el.removeEventListener('DOMMouseScroll', scrollHorizontally)
        } else if (el.detachEvent) {
          el.detachEvent('onmousewheel', scrollHorizontally)
        }
      }
    }, [])

    return (
      <Fragment>
        <Tab.Group id="attendance-register-tabs" className="secondary-tabs" >
          <Tab active={activeTab == "1"} onClick={e => { setActiveTab("1") }}>
            <span>Anamnese</span>
          </Tab>
          <Tab active={activeTab == "2"} onClick={e => { setActiveTab("2") }}>
            <span>Questionários</span>
          </Tab>
          <Tab active={activeTab == "3"} onClick={e => { setActiveTab("3") }}>
            <span>Procedimentos</span>
          </Tab>
          <Tab active={activeTab == "4"} onClick={e => { setActiveTab("4") }}>
            <span>Diagnósitco</span>
          </Tab>
          <Tab active={activeTab == "5"} onClick={e => { setActiveTab("5") }}>
            <span>Prognóstico</span>
          </Tab>
          <Tab active={activeTab == "6"} onClick={e => { setActiveTab("6") }}>
            <span>Plano terapêutico</span>
          </Tab>
        </Tab.Group>
      </Fragment>
    )
  },

  AttendanceDocumentsTabs: (props) => {
    const activeTab = props.activeTab
    const setActiveTab = props.setActiveTab

    useEffect(() => {
      const el = document.getElementById('attendance-document-tabs')

      const scrollHorizontally = (e) => {
        e = window.event || e
        e.preventDefault()
        el.scrollBy({ left: event.deltaY * 1.5, behavior: 'smooth' })
      }

      if (!el) {
        return
      }

      if (el.addEventListener) {
        el.addEventListener('mousewheel', scrollHorizontally, false)
        el.addEventListener('DOMMouseScroll', scrollHorizontally, false)
      } else {
        el.attachEvent('onmousewheel', scrollHorizontally)
      }

      return () => {
        if (el.removeEventListener) {
          el.removeEventListener('mousewheel', scrollHorizontally)
          el.removeEventListener('DOMMouseScroll', scrollHorizontally)
        } else if (el.detachEvent) {
          el.detachEvent('onmousewheel', scrollHorizontally)
        }
      }
    }, [])

    return (
      <Fragment>
        <Tab.Group id="attendance-document-tabs" className="secondary-tabs" >
          <Tab active={activeTab == "1"} onClick={e => { setActiveTab("1") }}>
            <span>Receituários</span>
          </Tab>
          <Tab active={activeTab == "2"} onClick={e => { setActiveTab("2") }}>
            <span>Requisições de exames</span>
          </Tab>
          <Tab active={activeTab == "3"} onClick={e => { setActiveTab("3") }}>
            <span>Atestados</span>
          </Tab>
          <Tab active={activeTab == "4"} onClick={e => { setActiveTab("4") }}>
            <span>Declarações</span>
          </Tab>
          <Tab active={activeTab == "5"} onClick={e => { setActiveTab("5") }}>
            <span>Laudos</span>
          </Tab>
          <Tab active={activeTab == "6"} onClick={e => { setActiveTab("6") }}>
            <span>Termos de consentimento</span>
          </Tab>
        </Tab.Group>
      </Fragment>
    )
  },

  FinanceTabs: (props) => {
    const activeTab = props.activeTab
    const setActiveTab = props.setActiveTab

    useEffect(() => {
      const el = document.getElementById('finance-tabs')

      const scrollHorizontally = (e) => {
        e = window.event || e
        e.preventDefault()
        el.scrollBy({ left: event.deltaY * 1.5, behavior: 'smooth' })
      }

      if (!el) {
        return
      }

      if (el.addEventListener) {
        el.addEventListener('mousewheel', scrollHorizontally, false)
        el.addEventListener('DOMMouseScroll', scrollHorizontally, false)
      } else {
        el.attachEvent('onmousewheel', scrollHorizontally)
      }

      return () => {
        if (el.removeEventListener) {
          el.removeEventListener('mousewheel', scrollHorizontally)
          el.removeEventListener('DOMMouseScroll', scrollHorizontally)
        } else if (el.detachEvent) {
          el.detachEvent('onmousewheel', scrollHorizontally)
        }
      }
    }, [])

    return (
      <Fragment>
        <Tab.Group kind="boxed" id="finance-tabs" >
          <Tab active={activeTab == "1"} onClick={e => { setActiveTab("1") }}>
            <Icon color="success" >
              <FontAwesomeIcon icon={faMoneyBillTransfer} size="lg" />
            </Icon>
            <span>Fluxo de caixa</span>
          </Tab>
          <Tab active={activeTab == "2"} onClick={e => { setActiveTab("2") }}>
            <Icon  color="info">
              <FontAwesomeIcon icon={faMagnifyingGlassDollar} size="lg" />
            </Icon>
            <span>Receitas e despesas</span>
          </Tab>
          <Tab active={activeTab == "3"} onClick={e => { setActiveTab("3") }}>
            <Icon  color="primary">
              <FontAwesomeIcon icon={faList} size="lg" />
            </Icon>
            <span>Extrato</span>
          </Tab>
        </Tab.Group>
      </Fragment>
    )
  },


  ToogleButton: (props) => {

    const { color, icon, children, setOpen, marginLeft, hasBorders, hasArrowDown } = props

    return (
      <Button color={color || "info"} className={`has-text-weight-semibold${marginLeft ? " ml-2" : ""}`} onClick={e => { if (setOpen) { setOpen(true) } }} style={{ border: hasBorders ? "solid 1px black" : "" }}>
        {icon &&
          <Fragment>
            <Icon size="small" align="right" className="is-hidden-touch">
              <FontAwesomeIcon icon={icon} />
            </Icon>
            <FontAwesomeIcon icon={icon} className="is-hidden-desktop" />
          </Fragment>
        }
        <span className="is-hidden-touch">{children}</span>
        {hasArrowDown &&
          <Fragment>
            <Icon size="small" align="right" className="is-hidden-touch">
              <FontAwesomeIcon icon={faAngleDown} />
            </Icon>
          </Fragment>
        }
      </Button>
    )
  },

  CardWithOverlay: (props) => {
    const cardRef = useRef()

    useEffect(() => {
      setTimeout(() => {
        cardRef.current.style.transform = "translateY(0px)"
        cardRef.current.style.opacity = 1
      }, 1)
    }, [])

    return (
      <Fragment>

        <Section className="overlay-section" >
          <div className="overlay-index-100" onClick={(event) => { if (props.setOpenCard) { props.setOpenCard(false) }; if(props.revokeUrl) {window.URL.revokeObjectURL(props.revokeUrl)} }} />
          <Column.Group vcentered centered className="is-flex is-desktop mt-0">
            <Column ref={cardRef} className={props.cardType == "fullViewer" ? "card-container is-12-tablet is-12-desktop is-10-widescreen is-9-fullhd" : props.cardType == "widerViewer" ? "card-container is-10-tablet is-10-desktop is-9-widescreen is-8-fullhd" : "card-container is-9-tablet is-6-desktop is-5-widescreen is-4-fullhd"}>
              <Card onClick={(event) => { event.stopPropagation() }} >

                <Card.Header className={props.headerClassName ? props.headerClassName : ""}>
                  <Card.Header.Title className="is-size-4" >
                    {props.icon &&
                      <span className={props.titleColorClassName ? `${props.titleColorClassName} px-3` : "has-text-info px-3"}><FontAwesomeIcon icon={props.icon} color={props.iconColor && props.iconColor} /></span>
                    }
                    <span className={props.titleColorClassName ? props.titleColorClassName : "has-text-info"}>{props.title}</span>
                  </Card.Header.Title>
                  <Card.Header.Icon>
                    <Delete size="medium" onClick={e => { if (props.setOpenCard) { props.setOpenCard(false); if(props.revokeUrl) {window.URL.revokeObjectURL(props.revokeUrl)} } }} />
                  </Card.Header.Icon>
                </Card.Header>

                <Card.Content>
                  {props.children}
                </Card.Content>

              </Card>              
            </Column>
          </Column.Group>          
        </Section>
      </Fragment>
    )
  },

  EventPreview: (props) => {

    const eventPreviewRef = props.eventPreviewRef
    const eventPreviewInfo = props.eventPreviewInfo
    const eventPreviewHeight = props.eventPreviewHeight

    return (
      <Box ref={eventPreviewRef} className={`${eventPreviewInfo.left ? "event-tooltip event-tooltip-left" : "event-tooltip event-tooltip-right"} m-0`} style={eventPreviewInfo.left ? { top: eventPreviewInfo.top - eventPreviewHeight - 11, left: eventPreviewInfo.left, } : { top: eventPreviewInfo.top - eventPreviewHeight - 11, right: eventPreviewInfo.right, }}>
        <Title size={6} className={eventPreviewInfo.status == "Aguard. atendimento" && "has-text-info mb-1" || eventPreviewInfo.status == "Atendido" && "has-text-success mb-1" || eventPreviewInfo.status == "Paciente não compareceu" && "has-text-danger mb-1"}>
          <span className={eventPreviewInfo.status == "Atendido" ? "has-background-success has-text-white event-icon" : (eventPreviewInfo.status == "Aguard. atendimento" ? "has-background-info has-text-white event-icon" : "has-background-danger has-text-white event-icon") + " has-text-weight-normal"} >{eventPreviewInfo.status == "Atendido" ? "✓" : (eventPreviewInfo.status == "Aguard. atendimento" ? "⧗" : "✕")}</span>
          <span className="ml-2"><b>{eventPreviewInfo.status}</b></span>
        </Title>
        {eventPreviewInfo.price != 0 ?
          <Title size={6} className={methods.priceToNumber(eventPreviewInfo.price) == 0 ? "has-text-grey mb-1" : methods.priceToNumber(eventPreviewInfo.debt) <= 0 ? "has-text-success mb-1" : "has-text-danger mb-1"}>
            <span className={eventPreviewInfo.attendancePackageId ? "has-background-primary has-text-white event-icon" : methods.priceToNumber(eventPreviewInfo.price) == 0 ? "has-background-grey has-text-white event-icon" : methods.priceToNumber(eventPreviewInfo.debt) <= 0 ? "has-background-success has-text-white event-icon" : "has-background-danger has-text-white event-icon" + " has-text-weight-normal"} >{eventPreviewInfo.attendancePackageId ? "▣" : "$"}</span>
            <span className="ml-2"><b>{eventPreviewInfo.attendancePackageId ? "Pacote de Atendimento" : methods.priceToNumber(eventPreviewInfo.price) == 0 ? "Sem cobrança" : methods.priceToNumber(eventPreviewInfo.debt) <= 0 ? "Pago" : "Pagamento em aberto"}</b></span>
          </Title>
        : ""}
        <hr className={"title-divider my-1"} />
        {eventPreviewInfo.start &&
          <p><b>Horário: </b>{`${DateTime.fromJSDate(eventPreviewInfo.start).toLocaleString(DateTime.TIME_24_SIMPLE)} às ${DateTime.fromJSDate(eventPreviewInfo.end).toLocaleString(DateTime.TIME_24_SIMPLE)}`}</p>
        }
        <p><b>Profissional: </b>{eventPreviewInfo.professional}</p>
        <p><b>Paciente: </b>{eventPreviewInfo.patient}</p>
        <p><b>Descrição: </b>{eventPreviewInfo.description}</p>
        {eventPreviewInfo.attendancePackageId ?
          <p><b>Pacote: </b>{eventPreviewInfo.attendancePackage}</p> :
          <p><b>Preço: </b>{eventPreviewInfo.price}</p>
        }
        
        {methods.priceToNumber(eventPreviewInfo.paid) > 0 &&
          <span className={eventPreviewInfo.paid <= 0 ? "" : "has-text-success"}><p><b>Total pago: </b>{eventPreviewInfo.paid}</p></span>
        }
        {(eventPreviewInfo.price != 0 && methods.priceToNumber(eventPreviewInfo.debt) > 0) &&
          <span className="has-text-danger"><p><b>Em aberto: </b>{eventPreviewInfo.debt}</p></span>
        }
      </Box>
    )
  },

  ProfessionalSwitch: (props) => {
    const [checker, setChecker] = useState(props.professionals[props.index].show)

    const professionalsToSave = props.professionalsToSave
    const setProfessionalsToSave = props.setProfessionalsToSave
    const professionals = props.professionals
    const index = props.index

    return (
      <Components.Switch
        noText={true}
        label={
          <Button as="div" fullwidth className="label-button has-text-white" style={{ backgroundColor: professionals[index].eventsColor }} >
            <span><FontAwesomeIcon icon={faStethoscope} /></span>
            <span className="ml-3">{professionals[index].name}</span>
          </Button>
        }
        labelSize={8}
        leftLabel={true}
        onChange={(checked) => {
          let professionalsArray = professionalsToSave
          professionalsArray[index] = checked
          setProfessionalsToSave(professionalsArray)
          setChecker(checked)
        }}
        checked={checker}
      />
    )
  },

  CalendarFiltersOverlay: (props) => {

    const [statusPending, setStatusPending] = useState(props.filters.statusPending)
    const [statusDone, setStatusDone] = useState(props.filters.statusDone)
    const [statusPatientNotPresent, setStatusPatientNotPresent] = useState(props.filters.statusPatientNotPresent)
    const [noCost, setNoCost] = useState(props.filters.noCost)
    const [paid, setPaid] = useState(props.filters.paid)
    const [notPaid, setNotPaid] = useState(props.filters.notPaid)
    const [professionalsToSave, setProfessionalsToSave] = useState([])

    const filters = props.filters
    const setFilters = props.setFilters
    const openFilters = props.openFilters
    const setOpenFilters = props.setOpenFilters
    const professionals = props.professionals

    const handleStatusPendingChange = (checked) => {
      setStatusPending(checked)
    }
    const handleStatusDoneChange = (checked) => {
      setStatusDone(checked)
    }
    const handleStatusPatientNotPresentChange = (checked) => {
      setStatusPatientNotPresent(checked)
    }
    const handleNoCostChange = (checked) => {
      setNoCost(checked)
    }
    const handlePaidChange = (checked) => {
      setPaid(checked)
    }
    const handleNotPaidChange = (checked) => {
      setNotPaid(checked)
    }

    return (
      <Components.CardWithOverlay
        title="Filtros"
        icon={faFilter}
        setOpenCard={setOpenFilters}
      >
        <div className="mt-2"></div>

        {professionals.map((professional, index) => (
          <Components.ProfessionalSwitch key={index}
            index={index}
            professionalsToSave={professionalsToSave}
            setProfessionalsToSave={setProfessionalsToSave}
            professionals={professionals}
          />
        ))}

        <div className="form-divider"></div>

        <Components.Switch
          noText={true}
          label={
            <Button as="div" fullwidth color="grey-light" className="label-button">
              <span className="has-background-info has-text-white	event-icon mr-2">⧗</span>
              <span>Aguard. atendimento</span>
            </Button>
          }
          labelSize={8}
          leftLabel={true}
          onChange={handleStatusPendingChange}
          checked={statusPending}
        />

        <Components.Switch
          noText={true}
          label={
            <Button as="div" fullwidth color="grey-light" className="label-button">
              <span className="has-background-success has-text-white	event-icon mr-2">✓</span>
              <span>Atendidos</span>
            </Button>
          }
          labelSize={8}
          leftLabel={true}
          onChange={handleStatusDoneChange}
          checked={statusDone}
        />

        <Components.Switch
          noText={true}
          label={
            <Button as="div" fullwidth color="grey-light" className="label-button">
              <span className="has-background-danger has-text-white	event-icon mr-2">✕</span>
              <span>Paciente não compareceu</span>
            </Button>
          }
          labelSize={8}
          leftLabel={true}
          onChange={handleStatusPatientNotPresentChange}
          checked={statusPatientNotPresent}
        />

        <div className="form-divider"></div>

        <Components.Switch
          noText={true}
          label={
            <Button as="div" fullwidth color="grey-light" className="label-button">
              <span className="has-background-grey has-text-white	event-icon mr-2">$</span>
              <span>Sem cobrança</span>
            </Button>
          }
          labelSize={8}
          leftLabel={true}
          onChange={handleNoCostChange}
          checked={noCost}
        />

        <Components.Switch
          noText={true}
          label={
            <Button as="div" fullwidth color="grey-light" className="label-button">
              <span className="has-background-success has-text-white event-icon mr-2">$</span>
              <span>Pago</span>
            </Button>
          }
          labelSize={8}
          leftLabel={true}
          onChange={handlePaidChange}
          checked={paid}
        />

        <Components.Switch
          noText={true}
          label={
            <Button as="div" fullwidth color="grey-light" className="label-button">
              <span className="has-background-danger has-text-white event-icon mr-2">$</span>
              <span>Pendente</span>
            </Button>
          }
          labelSize={8}
          leftLabel={true}
          onChange={handleNotPaidChange}
          checked={notPaid}
        />

        <Button.Group align="centered">
          <Button
            color="info"
            size="medium"
            className="has-text-weight-semibold"
            onClick={e => {
              let professionalsArray = [...professionals]
              professionalsArray.map((professional, index) => {
                if (professionalsToSave[index] == true || professionalsToSave[index] == false) {
                  professionalsArray[index].show = professionalsToSave[index]
                }
              })
              props.setProfessionals(professionalsArray)
              let newFilters = {
                statusPending: statusPending,
                statusDone: statusDone,
                statusPatientNotPresent: statusPatientNotPresent,
                noCost: noCost,
                paid: paid,
                notPaid: notPaid,
              }
              setFilters(newFilters)
              setOpenFilters(false)
            }} >
            <span>Salvar</span>
          </Button>
        </Button.Group>
      </Components.CardWithOverlay>
    )
  },

  ProfessionalEventsColor: (props) => {
    const { professional, index } = props
    const { setProfessionalToEdit } = props
    const { setOpenColorPicker } = props
    const { setColorPickerTopOffset } = props

    const elementRef = useRef(null)

    return (
      <Fragment>
        <Column ref={elementRef} size={12} className="p-0">

          <Column.Group breakpoint="mobile" className="m-0">
            <Column narrow className="pr-0">
              <div
                className="ml-3"
                style={{ width: "24px", height: "24px" }}
                onClick={() => {
                  setProfessionalToEdit({ ...professional, index: index })
                  setOpenColorPicker(true)
                  const offsetTop = elementRef.current.offsetTop
                  const height = elementRef.current.getBoundingClientRect().height                  
                  setColorPickerTopOffset(offsetTop + height)
                }}
              >
                <div className="professional-color" style={{ boxShadow: `${professional.eventsColor} 0px 0px 0px 14px inset, rgb(255, 87, 34) 0px 0px 3px` }}>
                </div>
              </div>
            </Column>
            <Column>
              <Label>{professional.name}</Label>
            </Column>
          </Column.Group>

        </Column>
      </Fragment>
    )
  },

  ColorPicker: (props) => {
    const { colorPickerTopOffset, setOpenColorPicker } = props
    const { professionalToEdit, setProfessionals, setProfessionalsHaveChanges } = props

    const ColorPickerRef = useRef(null)

    const handleChangeComplete = (newColor) => {
      setProfessionals((professionals) => (
        professionals.map((professional, index) => {
          if (index == professionalToEdit.index) {
            professional.eventsColor = newColor.hex
          }
          return professional
        })
      ))
      setProfessionalsHaveChanges(true)
      setOpenColorPicker(false)
    }

    useEffect(() => {
      ColorPickerRef.current.focus()
    }, [])

    return (
      <Box as="button" ref={ColorPickerRef} className="color-picker-container" onBlur={(event) => {if (!event.currentTarget.contains(event.relatedTarget)) {setOpenColorPicker(false)}}}
        style={{ borderWidth: "0px", top: colorPickerTopOffset }}
      >
        <CirclePicker
          colors={calendar.eventsColors}
          color={professionalToEdit.eventsColor}
          onChangeComplete={handleChangeComplete}
          width={210}
          circleSize={24}
          circleSpacing={10}
        />
      </Box>
    )
  },

  CalendarConfigOverlay: (props) => {
    const [eventsColorsToSave, setEventsColorsToSave] = useState(props.eventsColors)
    const [hideSaturdayToSave, setHideSaturdayToSave] = useState(props.hideSaturday)
    const [hideSundayToSave, setHideSundayToSave] = useState(props.hideSunday)
    const [slotMinTimeToSave, setSlotMinTimeToSave] = useState(props.slotMinTime)
    const [slotMaxTimeToSave, setSlotMaxTimeToSave] = useState(props.slotMaxTime)

    const [openColorPicker, setOpenColorPicker] = useState(false)
    const [colorPickerTopOffset, setColorPickerTopOffset] = useState(null)
    const [professionalToEdit, setProfessionalToEdit] = useState(false)
    const [professionalsHaveChanges, setProfessionalsHaveChanges] = useState(false)
    const [professionals, setProfessionals] = useState(props.professionals.map((professional) => { // Necessário para que o estado não seja passado para o estado do props.professionals
      let professionalToHandle = { ...professional }
      return professionalToHandle
    }))

    const { eventsColors, setEventsColors, setHideSaturday, setHideSunday, setSlotMinTime, setSlotMaxTime } = props
    const { setOpenConfig } = props
    const { getAndSetEvents } = props

    const { setFormChanged, setNotification, setLoading, setRedirectToLogin } = props

    const handleSave = (event) => {
      if (eventsColors != eventsColorsToSave) {
        setEventsColors(eventsColorsToSave)
      }              
      setHideSaturday(hideSaturdayToSave)
      setHideSunday(hideSundayToSave)
      setSlotMinTime(slotMinTimeToSave)
      setSlotMaxTime(slotMaxTimeToSave)
      localStorage.setItem("events-colors", eventsColorsToSave)
      localStorage.setItem("hide-saturday", hideSaturdayToSave)
      localStorage.setItem("hide-sunday", hideSundayToSave)
      localStorage.setItem("slot-min-time", slotMinTimeToSave)
      localStorage.setItem("slot-max-time", slotMaxTimeToSave)
      handleProfessionalsChange(event)
      setOpenConfig(false)
    }

    const handleProfessionalsChange = (event) => {
      if (professionalsHaveChanges && eventsColorsToSave == "by-professional") {
        let submitInfo = {
          event: event,
          setNotification: setNotification,
          setLoading: setLoading,
          setOpenComponent: null,
          mandatoryFields: [],
          errorList: [],
          ServiceMethod: UsersService.editProfessionalsEventsColors,
          params: {
            professionals: professionals,
          },
          noSuccessNotification: true,
          onSuccessText: "",
          onErrorText: "",
          onSuccessCallback: (response) => {
            if (eventsColors == eventsColorsToSave) {
              getAndSetEvents()
            }
          },
          onErrorCallback: null,
          setRedirectToLogin: setRedirectToLogin,
        }
  
        methods.handleSubmit(submitInfo)
      }
    }

    return (
      <Components.CardWithOverlay
        title="Configurações"
        icon={faCog}
        setOpenCard={setOpenConfig}
      >
        <div className="mt-2"></div>

        <Column.Group vcentered multiline breakpoint="mobile" className="mb-0">
          <Column size={6}>
            <Label>Cor dos agendamentos:</Label>
          </Column>
          <Column size={6} className="field-column">
            <Label className="ml-1">
              <Radio
                value={"by-professional"}
                checked={eventsColorsToSave == "by-professional"}
                onChange={(event) => {setEventsColorsToSave(event.target.value)}}
              />
              <span className="ml-2 has-text-weight-normal is-size-6">Por profissional</span>
            </Label>
            <Label className="ml-1">
              <Radio
                value={"by-status"}
                checked={eventsColorsToSave == "by-status"}
                onChange={(event) => {setEventsColorsToSave(event.target.value)}}
              />
              <span className="ml-2 has-text-weight-normal is-size-6">Por situação</span>
            </Label>
          </Column>
          {eventsColorsToSave == "by-professional" &&
            <Fragment>
              {professionals.map((professional, index) => (
                  <Components.ProfessionalEventsColor
                    key={index}
                    index={index}
                    professional={professional}
                    setOpenColorPicker={setOpenColorPicker}
                    setProfessionalToEdit={setProfessionalToEdit}
                    setColorPickerTopOffset={setColorPickerTopOffset}
                  />
                ))}
              <Column size={12} className="p-0 m-0">
                <hr className="form-divider mx-3" />
              </Column>
              {openColorPicker &&
                <Components.ColorPicker
                  professionalToEdit={professionalToEdit}
                  colorPickerTopOffset={colorPickerTopOffset}
                  setProfessionalsHaveChanges={setProfessionalsHaveChanges}
                  setOpenColorPicker={setOpenColorPicker}
                  setProfessionals={setProfessionals}
                />
              }
            </Fragment>
          }          
        </Column.Group>

        <div style={{ marginTop: "-0.5rem", marginBottom: "0.75rem" }}>
          <Components.Switch
            noText={true}
            label="Mostrar sábados:"
            leftLabel={true}
            onChange={(checked) => {if (checked) {setHideSaturdayToSave("false")} else {setHideSaturdayToSave("true")}}}
            checked={hideSaturdayToSave != "true"}
          />
        </div>

        <div style={{ marginTop: "-0.5rem", marginBottom: "0.75rem" }}>
          <Components.Switch
            noText={true}
            label="Mostrar domingos:"
            leftLabel={true}
            onChange={(checked) => {if (checked) {setHideSundayToSave("false")} else {setHideSundayToSave("true")}}}
            checked={hideSundayToSave != "true"}
          />
        </div>

        <Column.Group multiline breakpoint="mobile">
          <Column size={6}>
            <Label>Horário inicial:</Label>
          </Column>
          <Column size={6} className="field-column time-input">
            <Field>
              <Control>
                <Select.Container>
                  <Select
                    type="text"
                    value={slotMinTimeToSave}
                    onChange={e => {
                      setSlotMinTimeToSave(e.target.value)
                      if (DateTime.fromISO(slotMaxTimeToSave) < DateTime.fromISO(e.target.value)) {
                        setSlotMaxTimeToSave(e.target.value)
                      }
                    }}
                    name="slot-min-time"
                  >
                    {slotTimesList.map((time, key) => (
                      <Select.Option key={key}>{time}</Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
            </Field>
          </Column>
          <Column size={6}>
            <Label>Horário final:</Label>
          </Column>
          <Column size={6} className="field-column time-input">
            <Field>
              <Control>
                <Select.Container>
                  <Select
                    type="text"
                    value={slotMaxTimeToSave}
                    onChange={e => {
                      setSlotMaxTimeToSave(e.target.value)
                      if (DateTime.fromISO(slotMinTimeToSave) > DateTime.fromISO(e.target.value)) {
                        setSlotMinTimeToSave(e.target.value)
                      }
                    }}
                    name="slot-max-time"
                  >
                    {slotTimesList.map((time, key) => (
                      <Select.Option key={key}>{time}</Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
            </Field>
          </Column>          
        </Column.Group>
        <Button.Group align="centered">
          <Button
            color="info"
            size="medium"
            className="has-text-weight-semibold"
            onClick={handleSave} >
            <span>Salvar</span>
          </Button>
        </Button.Group>
      </Components.CardWithOverlay>
    )
  },

  DatePickerInput: React.forwardRef(({ value, onClick, onChange, icon, disableSelect, dateError, setDateError, setHasUnsavedChanges }, ref) => {
    const [inputValue, setInputValue] = useState(value)
    const inputRef = useRef()

    return (
      <Field ref={ref}>
        <Control iconLeft={icon ? true : false}>
          <Input ref={inputRef}
            readOnly={disableSelect ? true : false}
            static={disableSelect ? true : false}
            color={dateError ? "danger" : ""}
            type="text"
            value={methods.maskDate(value)}
            onClick={disableSelect ? null : (e) => {inputRef.current.select();return onClick(e)}}
            onChange={(e) => { methods.maskAndSetDate(e.target.value, setInputValue, setDateError); onChange(e); if (setHasUnsavedChanges) { setHasUnsavedChanges(true) } }}
          />
          {icon &&
            <Icon size="small" align="left">
              <FontAwesomeIcon icon={icon} color={disableSelect ? "grey" : null} />
            </Icon>
          }
        </Control>
      </Field>
    )
  }),

  MonthOrYearPickerInput: React.forwardRef(({ value, onClick, onChange, icon, disableSelect, dateError, setDateError, setHasUnsavedChanges }, ref) => {
    const [inputValue, setInputValue] = useState(value)

    return (
      <Field ref={ref}>
        <Control iconLeft={icon ? true : false}>
          <Input
            readOnly={true}
            static={disableSelect ? true : false}
            color={dateError ? "danger" : ""}
            type="text"
            value={value}
            onClick={disableSelect ? null : onClick}
            onChange={(e) => { methods.maskAndSetDate(e.target.value, setInputValue, setDateError); onChange(e); if (setHasUnsavedChanges) { setHasUnsavedChanges(true) } }}
          />
          {icon &&
            <Icon size="small" align="left">
              <FontAwesomeIcon icon={icon} color={disableSelect ? "grey" : null} />
            </Icon>
          }
        </Control>
      </Field>
    )
  }),

  TimeInput: (props) => {
    const time = props.time
    const setTime = props.setTime
    const timeError = props.timeError
    const setTimeError = props.setTimeError
    const disableSelect = props.disableSelect
    const setHasUnsavedChanges = props.setHasUnsavedChanges

    const inputRef = useRef()

    return (
      <Field>
        <Control iconLeft>
          <Input ref={inputRef}
            readOnly={disableSelect ? true : false}
            static={disableSelect ? true : false}
            color={timeError && "danger"}
            placeholder="--:--"
            type="text"
            value={time}
            onChange={(e) => { methods.maskAndSetTime(e.target.value, setTime, setTimeError); if (setHasUnsavedChanges) { setHasUnsavedChanges(true) } }}
            onClick={(e) => {inputRef.current.select()}}
          />
          <Icon size="small" align="left" color={disableSelect ? "black" : ""}>
            <FontAwesomeIcon icon={faClock} />
          </Icon>
        </Control>
      </Field>
    )
  },

  NewEventOverlay: (props) => {

    const [description, setDescription] = useState("")
    const [price, setPrice] = useState("R$ ")
    const [professional, setProfessional] = useState("")
    const [professionalId, setProfessionalId] = useState("")
    const [patient, setPatient] = useState("")
    const [loadPatientField, setLoadPatientField] = useState(false)
    const [patientId, setPatientId] = useState("")

    const [newEventDay, setNewEventDay] = useState(props.newEventDay)
    const [newEventDayError, setNewEventDayError] = useState(null)
    const [newEventStart, setNewEventStart] = useState(DateTime.fromJSDate(props.newEventStart).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newEventStartError, setNewEventStartError] = useState(null)
    const [newEventEnd, setNewEventEnd] = useState(DateTime.fromJSDate(props.newEventEnd).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newEventEndError, setNewEventEndError] = useState(null)
    const [timeError, setTimeError] = useState(null)

    const [repeat, setRepeat] = useState(false)
    const [repeatFrequency, setRepeatFrequency] = useState(1)
    const [repeatFrequencyError, setRepeatFrequencyError] = useState(false)
    const [repeatType, setRepeatType] = useState("semana")
    const [monthRepeatType, setMonthRepeatType] = useState("0")
    const [repeatTimes, setRepeatTimes] = useState(2)
    const [repeatTimesError, setRepeatTimesError] = useState(false)

    const [sunday, setSunday] = useState(false)
    const [monday, setMonday] = useState(false)
    const [tuesday , setTuesday] = useState(false)
    const [wednesday , setWednesday] = useState(false)
    const [thursday , setThursday] = useState(false)
    const [friday , setFriday] = useState(false)
    const [saturday , setSaturday] = useState(false)

    const [weekdayOfMonth, setWeekdayOfMonth] = useState(null)
    const [positionOfWeekdayOnMonth, setPositionOfWeekdayOnMonth] = useState(null)
    const [monthPositionText, setMonthPositionText] = useState(null)

    const { setOpenNewPatient, newPatientRegistered, setNewPatientRegistered } = props

    Number.prototype.nth = function() {
      switch (this) {
        case 1: return "1"
        case 2: return "2"
        case 3: return "3"
        case 4: return "4"
        case 5: return "última"
      }
      return "última"
    }
    
    Date.prototype.posOfWeekdayOnMonth = function () {      
      var today = this.getDate()
      var m = this.getMonth()
      var weekday = ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'][this.getDay()]
      var month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][m]
      var weekdayPosition = Math.ceil(today/7).nth()
      if(weekdayPosition != "última") {
        if (weekday == "domingo" || weekday == "sábado") {
          weekdayPosition = weekdayPosition + "º"
        } else {
          weekdayPosition = weekdayPosition + "ª"
        }
      } else if (weekday == "domingo" || weekday == "sábado") {
        weekdayPosition = "último"
      }
      switch (Math.ceil(today/7).nth()) {
        case "1": setPositionOfWeekdayOnMonth(1)
        case "2": setPositionOfWeekdayOnMonth(2)
        case "3": setPositionOfWeekdayOnMonth(3)
        case "4": setPositionOfWeekdayOnMonth(4)
        case "última": setPositionOfWeekdayOnMonth(5)
      }
      setWeekdayOfMonth(weekday)
      return [`${weekday == "domingo" || weekday == "sábado" ? "Todo" : "Toda"}`, weekdayPosition, weekday].join(' ')
    }

    useEffect(() => {
      setPatient(props.newPatientRegistered && props.newPatientRegistered.name || "")
      setPatientId(props.newPatientRegistered && props.newPatientRegistered.id || "")
      setNewPatientRegistered(null)
      setLoadPatientField(true)

      switch (newEventDay.getDay()) {
        case 0:
          setSunday(true)
          break
        case 1:
          setMonday(true)
          break
        case 2:
          setTuesday(true)
          break
        case 3:
          setWednesday(true)
          break
        case 4:
          setThursday(true)
          break
        case 5:
          setFriday(true)
          break
        case 6:
          setSaturday(true)
          break          
      }
    }, [])

    useEffect(() => {
      switch (newEventDay && newEventDay.getDay()) {
        case 0:
          setSunday(true)
          break
        case 1:
          setMonday(true)
          break
        case 2:
          setTuesday(true)
          break
        case 3:
          setWednesday(true)
          break
        case 4:
          setThursday(true)
          break
        case 5:
          setFriday(true)
          break
        case 6:
          setSaturday(true)
          break
      }
      setMonthPositionText(new Date(newEventDay).posOfWeekdayOnMonth())
    }, [newEventDay])

    useEffect(() => {
      const dt = DateTime.fromJSDate(newEventDay)
      if (!sunday && !monday && !tuesday && !wednesday && !thursday && !friday && !saturday) {
        switch (newEventDay.getDay()) {
          case 0:
            setSunday(true)
            break
          case 1:
            setMonday(true)
            break
          case 2:
            setTuesday(true)
            break
          case 3:
            setWednesday(true)
            break
          case 4:
            setThursday(true)
            break
          case 5:
            setFriday(true)
            break
          case 6:
            setSaturday(true)
            break
        }
      } else {
        switch (newEventDay.getDay()) {
          case 0:
            if (!sunday) {
              if (monday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 5 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 6 }).toISO()))
              }
            }
            break
          case 1:
            if (!monday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 5 }).toISO()))
              }
            }
            break
          case 2:
            if (!tuesday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              }
            }
            break
          case 3:
            if (!wednesday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              }
            }
            break
          case 4:
            if (!thursday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              }
            }
            break
          case 5:
            if (!friday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 5 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              }
            }
            break
            case 6:
              if (!saturday) {
                if (sunday) {
                  setNewEventDay(new Date(dt.minus({ days: 6 }).toISO()))
                } else if (monday) {
                  setNewEventDay(new Date(dt.minus({ days: 5 }).toISO()))
                } else if (tuesday) {
                  setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
                } else if (wednesday) {
                  setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
                } else if (thursday) {
                  setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
                } else if (friday) {
                  setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
                }
              }
              break
        }
      }
    }, [sunday, monday, tuesday, wednesday, thursday, friday, saturday])

    useEffect(() => {
      if (DateTime.fromISO(newEventStart) >= DateTime.fromISO(newEventEnd)) {
        setTimeError("A hora de início deve ser menor que a hora de término")
      } else {
        setTimeError(null)
      }
    }, [newEventStart, newEventEnd])

    useEffect(() => {
      if (repeatFrequency == 1 && repeatType == "dias") {
        setRepeatType("dia")
      }
      if (repeatFrequency == 1 && repeatType == "semanas") {
        setRepeatType("semana")
      }
      if (repeatFrequency == 1 && repeatType == "meses") {
        setRepeatType("mês")
      }
      if (repeatFrequency == 1 && repeatType == "anos") {
        setRepeatType("ano")
      }
      if (repeatFrequency > 1 && repeatType == "dia") {
        setRepeatType("dias")
      }
      if (repeatFrequency > 1 && repeatType == "semana") {
        setRepeatType("semanas")
      }
      if (repeatFrequency > 1 && repeatType == "mês") {
        setRepeatType("meses")
      }
      if (repeatFrequency > 1 && repeatType == "ano") {
        setRepeatType("anos")
      }
    }, [repeatFrequency])

    const setOpenNewEvent = props.setOpenNewEvent
    const setFormChanged = props.setFormChanged
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const mandatoryFields = [
      patient,
      professionalId,
      newEventDay,
      newEventStart,
      newEventEnd,
    ]

    const errorList = [
      newEventDayError,
      newEventStartError,
      newEventEndError,
      timeError,
      repeatFrequencyError,
    ]

    const handleSubmit = async (event) => {
      if (!patientId && patient != "") {
        setNotification({
          withCancel: true,
          success: true,
          title: "Paciente não cadastrado",
          text: "Deseja realizar o cadastro do paciente?",
          callback: (() => {}),
          cancelCallback: (() => {
            setNotification(false)
          }),
          confirmCallback: (() => {
            setNotification(false)
            setOpenNewEvent(false)
            setOpenNewPatient({ name: patient })
          })
        })
      } else {
        let eventsArray = []
        if (!repeat) {
          eventsArray = [
            {
              start: new Date(`${DateTime.fromJSDate(newEventDay).toISODate()}T${newEventStart}`),
              end: new Date(`${DateTime.fromJSDate(newEventDay).toISODate()}T${newEventEnd}`),
              description: description,
              price: methods.priceToNumber(price)
            }
          ]
        } else {
          let recurrenceWeekdays = []
          if (sunday) recurrenceWeekdays.push(0)
          if (monday) recurrenceWeekdays.push(1)
          if (tuesday) recurrenceWeekdays.push(2)
          if (wednesday) recurrenceWeekdays.push(3)
          if (thursday) recurrenceWeekdays.push(4)
          if (friday) recurrenceWeekdays.push(5)
          if (saturday) recurrenceWeekdays.push(6)
          let currentWeekdayIndex = 0
          let currentWeek = 0
          let testWeekdayPosition = null
          let testDate = DateTime.fromJSDate(newEventDay)
          let testMonth = null
          let testYear = null
          let weeksToAdd = 0
          for (let i = 0; i < repeatTimes; i++) {
            let timeAddition = {
              days: 0,
              weeks: 0,
              months: 0,
              years: 0,
            }
            let timeSubtraction = {
              days: 0,
              weeks: 0,
              months: 0,
              years: 0,
            }
            let timeToAdd = repeatFrequency * i
            if (/dia/.test(repeatType)) {
              timeAddition.days = timeToAdd
            } else if (/semana/.test(repeatType)) {
              if (!recurrenceWeekdays[0]) {
                timeAddition.weeks = timeToAdd
              } else {
              if (currentWeekdayIndex > recurrenceWeekdays.length - 1) {
                currentWeekdayIndex = 0
                currentWeek++
              }
                if (i == 0) {
                  for (let j = 0; j < 7; j++) {
                    if (newEventDay.getDay() > recurrenceWeekdays[currentWeekdayIndex]) {
                      currentWeekdayIndex++
                    }
                  }
                }
                if (recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay() >= 0) {
                  timeAddition.days = recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay()
                } else {
                  timeSubtraction.days = (recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay()) * (-1)
                }
                timeAddition.weeks = currentWeek * repeatFrequency
                currentWeekdayIndex++
              }
            } else if (/mês|mes/.test(repeatType)) {
              if (/dia/.test(monthRepeatType)) {
                timeAddition.months = timeToAdd
              } else {
                const getNewMonth = (month, monthsToAdd) => {
                  return month + monthsToAdd - 12 * Math.floor((month + monthsToAdd)/12)
                }
                const weekdayPosition = Math.ceil(newEventDay.getDate()/7).nth()
                const originalMonth = newEventDay.getMonth()
                if (timeToAdd != 0) {
                  while (testWeekdayPosition != weekdayPosition || testMonth != getNewMonth(originalMonth, timeToAdd)) {
                    testDate = testDate.plus({ days: 7 })
                    if (weekdayPosition != "última") {
                      testWeekdayPosition = Math.ceil(new Date(`${testDate.toISODate()}T${newEventStart}`).getDate()/7).nth()
                    } else {
                      let testDate2 = testDate.plus({ days: 7 })
                      if (new Date(`${testDate.toISODate()}T${newEventStart}`).getMonth() != new Date(`${testDate2.toISODate()}T${newEventStart}`).getMonth()) {
                        testWeekdayPosition = "última"
                      } else {
                        testWeekdayPosition = Math.ceil(new Date(`${testDate.toISODate()}T${newEventStart}`).getDate()/7).nth()
                      }
                    }
                    testMonth = new Date(`${testDate.toISODate()}T${newEventStart}`).getMonth()
                    testYear = new Date(`${testDate.toISODate()}T${newEventStart}`).getFullYear()
                    weeksToAdd += 1
                  }
                }
                timeAddition.weeks = weeksToAdd
              }
            } else if (/ano/.test(repeatType)) {
              timeAddition.years = timeToAdd
            }
            let eventToPush = {
              start: new Date(`${DateTime.fromJSDate(newEventDay).plus(timeAddition).minus(timeSubtraction).toISODate()}T${newEventStart}`),
              end: new Date(`${DateTime.fromJSDate(newEventDay).plus(timeAddition).minus(timeSubtraction).toISODate()}T${newEventEnd}`),
              description: description,
              price: methods.priceToNumber(price),
              recurrenceWeekdays: recurrenceWeekdays,
            }
            eventsArray.push(eventToPush)
          }
        }
  
        let submitInfo = {
          event: event,
          setNotification: setNotification,
          setLoading: setLoading,
          setOpenComponent: setOpenNewEvent,
          mandatoryFields: mandatoryFields,
          errorList: errorList,
          ServiceMethod: EventsService.newEvent,
          params: {
            patientId: patientId,
            professionalId: professionalId,
            eventsArray: eventsArray,
          },
          onSuccessText: "Agendamento realizado.",
          onErrorText: "Não foi possível realizar o agendamento. Tente novamente",
          onSuccessCallback: () => {
            setLoading(true)
            props.getAndSetEvents()
              .then(() => {
                setLoading(false)
                setFormChanged(false)
              })
          },
          onErrorCallback: null,
          setRedirectToLogin: setRedirectToLogin,
        }
  
        methods.handleSubmit(submitInfo)
      }      
    }

    return (
      <Components.CardWithOverlay
        headerClassName={"has-background-success"}
        title="Novo Agendamento"
        titleColorClassName="has-text-white"
        icon={faPlus}
        setOpenCard={setOpenNewEvent}
      >

        <Column.Group multiline vcentered>

          <Column className="field-column mt-4 mb-3">
            <Column.Group multiline >

              <Column narrow className="field-column date-input" >
                <Label>Data inicial:</Label>
                <DatePicker
                  selected={newEventDay}
                  onChange={(date) => { setNewEventDay(date) }}
                  customInput={
                    <Components.DatePickerInput
                      icon={faCalendar}
                      dateError={newEventDayError}
                      setDateError={setNewEventDayError}
                    />}
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                  strictParsing
                />
              </Column>

              <Column narrow className="field-column time-input" >
                <Label className="label-2px-mt" >Início:</Label>
                <Components.TimeInput
                  time={newEventStart}
                  setTime={setNewEventStart}
                  timeError={newEventStartError}
                  setTimeError={setNewEventStartError}
                />
              </Column>

              <Column narrow className="field-column time-input" >
                <Label className="label-2px-mt" >Fim:</Label>
                <Components.TimeInput
                  time={newEventEnd}
                  setTime={setNewEventEnd}
                  timeError={newEventEndError}
                  setTimeError={setNewEventEndError}
                />
              </Column>

            </Column.Group>
          </Column>

          <Column size={12} className="p-0">
            <Column className="is-narrow pr-0 pl-2 py-2 is-12-touch">
              <Label className="checkbox-container">
                <Checkbox
                  type="checkbox"
                  checked={repeat}
                  onChange={e => {setRepeat(e.target.checked)}}
                />
                <Button as="div" text className="checkbox-button is-size-6 has-text-weight-normal has-background-white ">
                  <span className="checkmark" />
                  <Button color="info" outlined={!repeat} as="div" size="normal" >
                    <Icon>
                      <FontAwesomeIcon icon={faRepeat} />
                    </Icon>
                    <span>Repetir</span>
                  </Button>
                </Button>
              </Label>
            </Column>
          </Column>

          <div>

            <Column size={12} className="field-column">
              <span className="is-size-6 has-text-weight-bold has-text-dark">
                <Column.Group vcentered className="m-0" breakpoint="mobile" >
                  <Column narrow className="p-0" >
                    <span className={`${!repeat ? "has-text-grey-light" : ""}`}>Repetir a cada:</span>
                  </Column>
                  <Column narrow className="p-0" >
                    <Input
                      disabled={!repeat}
                      className="repeat-times ml-2"
                      color={repeatFrequencyError ? "danger" : ""}
                      type="number"
                      min="1"
                      max="30"
                      value={repeatFrequency}
                      onChange={(e) => { setRepeatFrequency(e.target.value); if (isNaN(e.target.value) || e.target.value < 1 || e.target.value > 30) { setRepeatFrequencyError("A frequência deve ser um número de 1 a 30") } else { setRepeatFrequencyError(false) }; if (setFormChanged) { setFormChanged(true) } }}
                    />
                  </Column>
                  <Column narrow className="p-0 ml-2" >
                    <Select.Container>
                      <Select
                        disabled={!repeat}
                        type="text"
                        value={repeatType}
                        onChange={e => { setRepeatType(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                        name="repeat-type"
                      >
                        <Select.Option>{repeatFrequency == 1 ? "dia" : "dias"}</Select.Option>
                        <Select.Option>{repeatFrequency == 1 ? "semana" : "semanas"}</Select.Option>
                        <Select.Option>{repeatFrequency == 1 ? "mês" : "meses"}</Select.Option>
                        <Select.Option>{repeatFrequency == 1 ? "ano" : "anos"}</Select.Option>
                      </Select>
                    </Select.Container>
                  </Column>
                </Column.Group>
              </span>
            </Column>

            {/semana/.test(repeatType) &&
              <Column size={12} className="field-column" >
                <span className="is-size-6 has-text-weight-bold has-text-dark">
                  <Column.Group vcentered className="m-0" breakpoint="mobile" style={{ height: "43px" }} >
                    <Column narrow className="p-0 pr-1" >
                      <span className={`${!repeat ? "has-text-grey-light" : ""}`}>Dias da semana:</span>
                    </Column>
                    <Column narrow className="p-0" >
                      <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (sunday ? "#1a73e8" : "#e7e7e7"), color: (sunday ? "#ffffff" : "#000000") }}onClick={(e) => setSunday(!sunday)}>D</div>
                      <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (monday ? "#1a73e8" : "#e7e7e7"), color: (monday ? "#ffffff" : "#000000") }}onClick={(e) => setMonday(!monday)}>S</div>
                      <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (tuesday ? "#1a73e8" : "#e7e7e7"), color: (tuesday ? "#ffffff" : "#000000") }}onClick={(e) => setTuesday(!tuesday)}>T</div>
                      <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (wednesday ? "#1a73e8" : "#e7e7e7"), color: (wednesday ? "#ffffff" : "#000000") }}onClick={(e) => setWednesday(!wednesday)}>Q</div>
                      <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (thursday ? "#1a73e8" : "#e7e7e7"), color: (thursday ? "#ffffff" : "#000000") }}onClick={(e) => setThursday(!thursday)}>Q</div>
                      <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (friday ? "#1a73e8" : "#e7e7e7"), color: (friday ? "#ffffff" : "#000000") }}onClick={(e) => setFriday(!friday)}>S</div>
                      <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (saturday ? "#1a73e8" : "#e7e7e7"), color: (saturday ? "#ffffff" : "#000000") }}onClick={(e) => setSaturday(!saturday)}>S</div>
                    </Column>
                  </Column.Group>
                </span>
              </Column>
            }

            {/mês|mes/.test(repeatType) &&
              <Column size={12} className="field-column" >
                <span className="is-size-6 has-text-weight-bold has-text-dark">
                  <Column.Group vcentered className="m-0" breakpoint="mobile" style={{ height: "43px" }} >
                    <Column narrow className="p-0 pr-1" >
                      <span>Dia do mês:</span>
                    </Column>
                    <Column narrow className="p-0" >
                      <Select.Container>
                        <Select
                          disabled={!repeat}
                          type="text"
                          value={monthRepeatType}
                          onChange={e => { setMonthRepeatType(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                          name="month-repeat-type"
                        >
                          <Select.Option>{monthPositionText}</Select.Option>
                          <Select.Option>{newEventDay && `Todo dia ${newEventDay.getDate()}`}</Select.Option>
                        </Select>
                      </Select.Container>
                    </Column>
                  </Column.Group>
                </span>
              </Column>
            }

            <Column size={12} className="field-column">
              <span className="is-size-6 has-text-weight-bold has-text-dark">
                <Column.Group vcentered className="m-0" breakpoint="mobile" >
                  <Column narrow className="p-0 pr-1" >
                    <span className={`${!repeat ? "has-text-grey-light" : ""}`}>Total de agendamentos:</span>
                  </Column>
                  <Column narrow className="p-0" >
                    <Input
                      disabled={!repeat}
                      className="repeat-times ml-2"
                      color={repeatTimesError ? "danger" : ""}
                      type="number"
                      min="1"
                      max="60"
                      value={repeatTimes}
                      onChange={(e) => { setRepeatTimes(e.target.value); if (isNaN(e.target.value) || e.target.value < 1 || e.target.value > 60) { setRepeatTimesError("A quantidade de atendimentos deve ser um número de 1 a 60") } else { setRepeatTimesError(false) }; if (setFormChanged) { setFormChanged(true) } }}
                    />
                  </Column>
                  <Column narrow className="p-0 ml-2" >
                    <span className="has-text-weight-normal">{repeatTimes == 1 ? "agendamento" : "agendamentos"}</span>
                  </Column>
                </Column.Group>
              </span>
            </Column>

          </div>
          
          <Column size={12} className="field-column" >
            <hr className="form-divider mx-2" />
            <Label>
              Paciente:
              <span className="has-text-danger"> *</span>
              <Button size="small" color="success"
                style={{ height: "20px", border: "1px solid green", marginLeft: "5px" }}
                onClick={() => {
                  setOpenNewEvent(false)
                  setOpenNewPatient({ name: patient })
                }}
              >
                <Icon>
                  <FontAwesomeIcon icon={faPlus} />
                </Icon>
                <span className="ml-1">Cadastrar</span>
              </Button>
            </Label>
            {loadPatientField &&
              <Components.FieldWithSearch
                value={patient}
                getValue={setPatient}
                placeholder="Digite parte do nome do paciente:"
                resultId={patientId}
                setResultId={setPatientId}
                searchAndSetDropdownItensCallback={methods.searchAndSetPatientsDropdown}
              />
            }
          </Column>

          <Column size={12} className="field-column" >
            <Label>Profissional:<span className="has-text-danger"> *</span></Label>
            <Components.FieldWithSearch
              getValue={setProfessional}
              placeholder="Digite parte do nome do profissional:"
              resultId={professionalId}
              setResultId={setProfessionalId}
              searchAndSetDropdownItensCallback={methods.searchAndSetProfessionalsDropdown}
            />
          </Column>

          <Column size={12} className="p-0" >
            <Components.GenericInput
              size={12}
              label="Descrição:"
              placeholder="Pequena descrição do atendimento"
              elementName="attendance-description"
              value={description}
              setValue={setDescription}
              setFormChanged={setFormChanged}
            />
          </Column>

          <Column size={repeat ? 8 : 12} className="p-0" >
            <Components.GenericInput
              size={12}
              label={`Preço${repeat ? " por atendimento" : ""}:`}
              placeholder="R$ 0,00"
              elementName="price"
              value={price}
              setValue={setPrice}
              maskAndSetValue={methods.maskAndSetPrice}
              setFormChanged={setFormChanged}
            />
          </Column>

          {repeat &&
            <Column size={4} className="p-0" >
              <Components.GenericInput
                disableSelect={true}
                size={12}
                label="Total:"
                placeholder="Valor do atendimento"
                elementName="price"
                value={methods.numberToPrice((methods.priceToNumber(price) * repeatTimes).toFixed(2))}
                setValue={null}
                setFormChanged={setFormChanged}
              />
            </Column>
          }

        </Column.Group>

        <Button.Group align="centered">
          <Button
            color="info"
            size="medium"
            className="has-text-weight-semibold"
            onClick={e => {
              handleSubmit(e)
            }} >
            <span>Agendar</span>
          </Button>
        </Button.Group>

      </Components.CardWithOverlay>
    )
  },

  NewPatientOverlay: (props) => {
    const [name, setName] = useState(props.openNewPatient.name || "")
    const [nameError, setNameError] = useState(null)
  
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(null)
  
    const [gender, setGender] = useState("")
  
    const [rgIdentifier, setRgIdentifier] = useState("")
    const [rgIdentifierError, setRgIdentifierError] = useState(null)
    const [rgDigit, setRgDigit] = useState("")
    const [rgUf, setRgUf] = useState("")
  
    const [cpf, setCpf] = useState("")
    const [cpfError, setCpfError] = useState(null)
  
    const [birthday, setBirthday] = useState("")
    const [birthdayError, setBirthdayError] = useState(null)
    
    const [areaCode, setAreaCode] = useState("")
    const [areaCodeError, setAreaCodeError] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberError, setPhoneNumberError] = useState(null)
  
    const [cep, setCep] = useState("")
    const [cepError, setCepError] = useState(null)
    const [street, setStreet] = useState("")
    const [adressNumber, setAdressNumber] = useState("")
    const [complement, setComplement] = useState("")
    const [neighborhood, setNeighborhood] = useState("")
    const [city, setCity] = useState("")
    const [uf, setUf] = useState("")

    const [showFields, setShowFields] = useState(props.showFields || false)  

    const { setOpenNewPatient, setNewPatientRegistered, setOpenNewEvent, setNotification, setFormChanged, setLoading, setRedirectToLogin } = props
  
    const mandatoryFields = [
      name,
    ]
  
    const errorList = [
      nameError,
      emailError,
      cpfError,
      birthdayError,
      areaCodeError,
      phoneNumberError,
      cepError,
    ]
  
    const handleSubmit = async (event) => {
      event.preventDefault()
  
      if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
        setNotification({
          success: false,
          text: "Favor preencher todos os campos obrigatórios",
          callback: () => {
            setNotification(false)
          }
        })
      } else if (methods.hasErrors(errorList)) {
        setNotification({
          success: false,
          text: methods.errorsToShow(errorList),
          callback: () => {
            setNotification(false)
          }
        })
      } else {
        setLoading(true)
        let newPatient = {
          name: name,
          email: email,
          areaCode: areaCode.replace(/\D/g,""),
          phoneNumber: phoneNumber.replace(/\D/g,""),
          gender: gender,
          rgIdentifier: rgIdentifier,
          rgDigit: rgDigit,
          rgUf: rgUf,
          cpf: cpf.replace(/\D/g,""),
          birthday: methods.stringToDate(birthday),
          cep: cep.replace(/\D/g,""),
          street: street,
          adressNumber: adressNumber,
          complement: complement,
          neighborhood: neighborhood,
          city: city,
          uf: uf,
        }
        await PatientsService.newPatient(newPatient)
        .then( (response) => {
          setLoading(false)
          setNotification({
            success: true,
            text: "Paciente cadastrado.",
            callback: () => {
              setNotification(false)
              setFormChanged(false)
              setOpenNewPatient(false)
              if (setNewPatientRegistered) {setNewPatientRegistered(response.data)}
              if (setOpenNewEvent) {setOpenNewEvent(true)}
            }
          })
        })
        .catch(function (error) {
          console.log(error)
          try {
            if (error.response.status == 401) {
              setLoading(false)
              setNotification({
                success: false,
                text: "Erro de autenticação. Efetue o login novamente.",
                callback: ( () => {
                  setNotification(false)
                  UsersService.logout()
                  setRedirectToLogin(true)
                })
              })
            } else {
              setLoading(false)
              setNotification({
                success: false,
                text: error.response.data,
                callback: ( () => {
                  setNotification(false)
                })
              })
            }
          } catch {          
            setLoading(false)
            setNotification({
              success: false,
              text: "Não foi possível cadastrar. Tente novamente",
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        })
      }
    }
  
    return (
      <Components.CardWithOverlay
        headerClassName={"has-background-success"}
        title="Novo Paciente"
        titleColorClassName="has-text-white"
        icon={faPlus}
        setOpenCard={setOpenNewPatient}
      >
  
        <form onSubmit={handleSubmit} >

          <Column.Group multiline className="m-0">

            <Components.UserName
              required={true}
              columnSize={12}
              name={name}
              nameError={nameError}
              setName={setName}
              setNameError={setNameError}
              setFormChanged={setFormChanged}
            />
            
            <Components.Phone
              required={false}
              columnSize={12}
              areaCode={areaCode}
              areaCodeError={areaCodeError}
              setAreaCode={setAreaCode}
              setAreaCodeError={setAreaCodeError}
              phoneNumber={phoneNumber}
              phoneNumberError={phoneNumberError}
              setPhoneNumber={setPhoneNumber}
              setPhoneNumberError={setPhoneNumberError}
              setFormChanged={setFormChanged}
            />

          </Column.Group>

          {!showFields &&
            <Button outlined color="success" className="mt-3 ml-3"
              onClick={() => setShowFields(true)}
            >
              <Icon >
                <FontAwesomeIcon icon={faEye} />
              </Icon>
              <span>Mostrar mais campos</span>              
            </Button>
          ||
          <Column.Group multiline className="m-0">

            <Components.Email
              required={false}
              columnSize={12}
              email={email}
              emailError={emailError}
              setEmail={setEmail}
              setEmailError={setEmailError}
              setFormChanged={setFormChanged}
            />

            <Components.Gender
              columnSize={12}
              gender={gender}
              setGender={setGender}
            />

            <Components.Rg
              required={false}
              columnSize={12}
              rgIdentifier={rgIdentifier}
              rgIdentifierError={rgIdentifierError}
              setRgIdentifier={setRgIdentifier}
              setRgIdentifierError={setRgIdentifierError}
              rgDigit={rgDigit}
              setRgDigit={setRgDigit}
              rgUf={rgUf}
              setRgUf={setRgUf}
              setFormChanged={setFormChanged}
            />

            <Components.Cpf
              required={false}
              columnSize={12}
              cpf={cpf}
              cpfError={cpfError}
              setCpf={setCpf}
              setCpfError={setCpfError}
              setFormChanged={setFormChanged}
            />

            <Components.Date
              required={false}
              columnSize={12}
              label="Data de Nascimento:"
              elementName="birthday"
              date={birthday}
              dateError={birthdayError}
              setDate={setBirthday}
              setDateError={setBirthdayError}
              setFormChanged={setFormChanged}
            />

            <Components.Cep
              required={false}
              columnSize={12}
              cep={cep}
              setCep={setCep}
              cepError={cepError}
              setCepError={setCepError}
              setStreet={setStreet}
              setAdressNumber={setAdressNumber}
              setComplement={setComplement}
              setNeighborhood={setNeighborhood}
              setCity={setCity}
              setUf={setUf}
              setLoading={setLoading}
              setFormChanged={setFormChanged}
            />

            <Components.Street
              required={false}
              columnSize={12}
              street={street}
              setStreet={setStreet}
              setFormChanged={setFormChanged}
            />

            <Components.AdressNumber
              required={false}
              columnSize={12}
              adressNumber={adressNumber}
              setAdressNumber={setAdressNumber}
              setFormChanged={setFormChanged}
            />

            <Components.Complement
              required={false}
              columnSize={12}
              complement={complement}
              setComplement={setComplement}
              setFormChanged={setFormChanged}
            />

            <Components.Neighborhood
              required={false}
              columnSize={12}
              neighborhood={neighborhood}
              setNeighborhood={setNeighborhood}
              setFormChanged={setFormChanged}
            />

            <Components.CityAndUf
              required={false}
              columnSize={12}
              city={city}
              setCity={setCity}
              uf={uf}
              setUf={setUf}
              setFormChanged={setFormChanged}
            />

          </Column.Group>
          }

          <div className="mb-3"></div>

          <Components.SubmitButton icon={faEdit}>
            Cadastrar
          </Components.SubmitButton>

        </form>

      </Components.CardWithOverlay>

    )
  },

  NewAttendanceOverlay: (props) => {
    const [description, setDescription] = useState("")
    const [price, setPrice] = useState("R$ ")
    const [patient, setPatient] = useState("")
    const [patientId, setPatientId] = useState("")
    const [newAttendanceDay, setNewAttendanceDay] = useState(new Date(Date.now()))
    const [newAttendanceDayError, setNewAttendanceDayError] = useState(null)
    const [newAttendanceTime, setNewAttendanceTime] = useState(DateTime.fromJSDate(new Date(Date.now())).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newAttendanceTimeError, setNewAttendanceTimeError] = useState(null)

    let history = useHistory()

    const setOpenNewAttendance = props.setOpenNewAttendance
    const setFormChanged = props.setFormChanged
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin
    const setObjectToEdit = props.setObjectToEdit

    const mandatoryFields = [
      patientId,
      newAttendanceDay,
      newAttendanceTime,
    ]

    const errorList = [
      newAttendanceDayError,
      newAttendanceTimeError,
    ]

    const handleSubmit = async (event) => {
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: null,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: AttendancesService.newAttendance,
        params: {
          patientId: patientId,
          attendanceDateTime: new Date(`${DateTime.fromJSDate(newAttendanceDay).toISODate()}T${newAttendanceTime}`),
          description: description,
          price: methods.priceToNumber(price)
        },
        onSuccessText: "Atendimento cadastrado. Iniciando atendimento...",
        onErrorText: "Não foi possível cadastrar o atendimento. Tente novamente",
        onSuccessCallback: (response) => {
          setObjectToEdit(response.data)
          history.push("/app/edit-attendance")
        },
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }

      methods.handleSubmit(submitInfo)
    }

    return (
      <Components.CardWithOverlay
        headerClassName={"has-background-success"}
        title="Novo Atendimento avulso"
        titleColorClassName="has-text-white"
        icon={faPlus}
        setOpenCard={setOpenNewAttendance}
      >
        <Column.Group multiline>

          <Column className="field-column mt-4 mb-3">
            <Column.Group multiline >

              <Column className="field-column date-input">
                <Label>Data:</Label>
                <DatePicker
                  selected={newAttendanceDay}
                  onChange={(date) => { setNewAttendanceDay(date); setNewAttendanceDayError(null) }}
                  customInput={
                    <Components.DatePickerInput
                      icon={faCalendar}
                      dateError={newAttendanceDayError}
                      setDateError={setNewAttendanceDayError}
                    />}
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                  strictParsing
                />
              </Column>

              <Column narrow className="field-column" >
                <Label className="label-2px-mt" >Horário:</Label>
                <Components.TimeInput
                  time={newAttendanceTime}
                  setTime={setNewAttendanceTime}
                  timeError={newAttendanceTimeError}
                  setTimeError={setNewAttendanceTimeError}
                />
              </Column>

            </Column.Group>
          </Column>

          <Column size={12} className="field-column" >
            <Label>Paciente:<span className="has-text-danger"> *</span></Label>
            <Components.FieldWithSearch
              getValue={setPatient}
              placeholder="Digite parte do nome do paciente:"
              resultId={patientId}
              setResultId={setPatientId}
              searchAndSetDropdownItensCallback={methods.searchAndSetPatientsDropdown}
            />
          </Column>

          <Column size={12} className="p-0" >
            <Components.GenericInput
              size={12}
              label="Descrição:"
              placeholder="Pequena descrição do atendimento"
              elementName="attendance-description"
              value={description}
              setValue={setDescription}
              setFormChanged={setFormChanged}
            />
          </Column>

          <Column size={12} className="p-0" >
            <Components.GenericInput
              size={12}
              label="Preço:"
              placeholder="R$ 0,00"
              elementName="price"
              value={price}
              setValue={setPrice}
              maskAndSetValue={methods.maskAndSetPrice}
              setFormChanged={setFormChanged}
            />
          </Column>

        </Column.Group>

        <Button.Group align="centered">
          <Button
            color="info"
            size="medium"
            className="has-text-weight-semibold"
            onClick={e => {
              handleSubmit(e)
            }} >
            <span>Iniciar Atendimento</span>
          </Button>
        </Button.Group>

      </Components.CardWithOverlay>
    )
  },

  NewDocumentTemplateOverlay: (props) => {
    const [availableToCompanyProfessionals, setAvailableToCompanyProfessionals] = useState(false)
    const [documentType, setDocumentType] = useState("- - Selecione - -")
    const [documentTypeError, setDocumentTypeError] = useState("Escolha um tipo de documento")
    const [description, setDescription] = useState("")
    const [documentTemplate, setDocumentTemplate] = useState("")

    const [showTextEditor, setShowTextEditor] = useState(false)
    const [showQuestionnaire, setShowQuestionnaire] = useState(false)
    const [showProcedure, setShowProcedure] = useState(false)

    const professionalInfo = { professionalId: JSON.parse(localStorage.getItem('user'))._id }

    const openNewDocumentTemplate = props.openNewDocumentTemplate
    const setOpenNewDocumentTemplate = props.setOpenNewDocumentTemplate
    const getDocumentTemplates = props.getDocumentTemplates

    const setFormChanged = props.setFormChanged
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin
    const setObjectToEdit = props.setObjectToEdit

    useEffect( () => {
      if (openNewDocumentTemplate) {
        setDocumentType(openNewDocumentTemplate.title)
      }
    }, [])

    useEffect( () => {
      if (
        documentType == "Anamnese" ||
        documentType == "Diagnóstico" ||
        documentType == "Prognóstico" ||
        documentType == "Plano Terapêutico" ||
        documentType == "Receituário" ||
        documentType == "Requisição de exames" ||
        documentType == "Atestado" ||
        documentType == "Declaração" ||
        documentType == "Laudo" ||
        documentType == "Termo de consentimento"        
      ) {
        if (!showTextEditor) {
          if (openNewDocumentTemplate) {
            setDocumentTemplate(openNewDocumentTemplate.documentTemplate)
          } else {
            setDocumentTemplate(EditorState.createEmpty())
          }
        }
        setShowTextEditor(true)
        setShowQuestionnaire(false)
        setShowProcedure(false)
        setDocumentTypeError(null)
      } else if (documentType == "Questionário") {
        if (!showQuestionnaire) {
          if (openNewDocumentTemplate) {
            setDocumentTemplate([openNewDocumentTemplate.documentTemplate])
          } else {
            setDocumentTemplate([ { name: "Questionário", questions: [{ question: "", answerType: "Subjetiva", answer: "", alternatives: [{ alternative: "Opção 1", chosen: true }, { alternative: "Opção 2", chosen: false }] }] }])
          }
        }
        setShowTextEditor(false)
        setShowQuestionnaire(true)
        setShowProcedure(false)
        setDocumentTypeError(null)
      } else if (documentType == "Procedimento") {
        if (!showProcedure) {
          if (openNewDocumentTemplate) {
            setDocumentTemplate([openNewDocumentTemplate.documentTemplate])
          } else {
            setDocumentTemplate([{ name: "", notes: "", }])
          }
        }
        setShowTextEditor(false)
        setShowQuestionnaire(false)
        setShowProcedure(true)
        setDocumentTypeError(null)
      } else {
        setShowTextEditor(false)
        setShowQuestionnaire(false)
        setShowProcedure(false)
        setDocumentTypeError("Selecione um tipo de documento")
      }
    }, [documentType])

    const mandatoryFields = [description]
    const errorList = [documentTypeError]

    const handleSubmit = async (event) => {
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setOpenNewDocumentTemplate,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: DocumentTemplatesService.newDocumentTemplate,
        params: {
          availableToCompanyProfessionals: availableToCompanyProfessionals,
          documentType: documentType,
          description: description,
          documentTemplate: documentType == "Anamnese" ||
                            documentType == "Diagnóstico" ||
                            documentType == "Prognóstico" ||
                            documentType == "Plano Terapêutico" ||
                            documentType == "Receituário" ||
                            documentType == "Requisição de exames" ||
                            documentType == "Atestado" ||
                            documentType == "Declaração" ||
                            documentType == "Laudo" ||
                            documentType == "Termo de consentimento"
                            ? 
                            JSON.stringify(convertToRaw(documentTemplate.getCurrentContent()))
                            :
                            documentTemplate,
        },
        onSuccessText: "Modelo de documento cadastrado",
        onErrorText: "Não foi possível cadastrar o modelo de documento. Tente novamente",
        onSuccessCallback: () => {
          if (getDocumentTemplates) getDocumentTemplates()
        },
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }

      methods.handleSubmit(submitInfo)
    }

    return (
      <Components.CardWithOverlay
        cardType="fullViewer"
        headerClassName={"has-background-success"}
        title="Novo Modelo de Documento"
        titleColorClassName="has-text-white"
        icon={faPlus}
        setOpenCard={setOpenNewDocumentTemplate}
      >
        <div style={{ minHeight: "600px" }}>
          <Column.Group multiline>

            <Column size={12}>
              <Label>Disponível para todos os profissionais:</Label>
              <Label className="ml-1">
                <Radio
                  value={true}
                  checked={availableToCompanyProfessionals}
                  onChange={(event) => {setAvailableToCompanyProfessionals(true)}}
                />
                <span className="ml-2 has-text-weight-normal is-size-6">Sim</span>
              </Label>
              <Label className="ml-3">
                <Radio
                  value={false}
                  checked={!availableToCompanyProfessionals}
                  onChange={(event) => {setAvailableToCompanyProfessionals(false)}}
                />
                <span className="ml-2 has-text-weight-normal is-size-6">Não</span>
              </Label>
            </Column>

            <Column size={12} className="field-column">
              <Label>Tipo de documento:</Label>
              <Dropdown>
                <Dropdown.Trigger>
                  <Button onClick={(e) => e.preventDefault()} >
                    <span>{documentType}</span>
                    <Icon>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Icon>
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Content>
                    <Dropdown.Item onClick={() => {setDocumentType("- - Selecione - -")}}>- - Selecione - -</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Anamnese")}}>Anamnese</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Questionário")}}>Questionário</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Procedimento")}}>Procedimento</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Diagnóstico")}}>Diagnóstico</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Prognóstico")}}>Prognóstico</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Plano Terapêutico")}}>Plano Terapêutico</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Receituário")}}>Receituário</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Requisição de exames")}}>Requisição de exames</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Atestado")}}>Atestado</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Declaração")}}>Declaração</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Laudo")}}>Laudo</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Termo de consentimento")}}>Termo de consentimento</Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Menu>
              </Dropdown>
            </Column>

            <Column size={12} className="p-0" >
              <Components.GenericInput
                required={true}
                size={12}
                label="Descrição:"
                placeholder="Breve descrição do modelo"
                elementName={null}
                value={description}
                setValue={setDescription}
                setFormChanged={setFormChanged}
              />
            </Column>

            {showTextEditor &&
              <Column size={12} >
                <Label>Modelo de {documentType}:</Label>
                <Components.TextEditor
                  attendance={professionalInfo}
                  editorState={documentTemplate}
                  setEditorState={setDocumentTemplate}
                  setFormChanged={setFormChanged}
                  setHasUnsavedChanges={null}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              </Column>
            }

            {showQuestionnaire &&
              <Column size={12} >
                <Label>Modelo de {documentType}:</Label>
                <Components.Questionnaires
                  attendance={professionalInfo}
                  questionnaires={documentTemplate}
                  setQuestionnaires={setDocumentTemplate}
                  isDocumentTemplateEditor={true}
                  setFormChanged={setFormChanged}
                  setHasUnsavedChanges={null}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              </Column>
            }

            {showProcedure &&
              <Column size={12} >
                <Label>Modelo de {documentType}:</Label>
                <Components.Procedures
                    attendance={professionalInfo}
                    procedures={documentTemplate}
                    setProcedures={setDocumentTemplate}
                    isDocumentTemplateEditor={true}
                    setFormChanged={setFormChanged}
                    setHasUnsavedChanges={null}
                    setNotification={setNotification}
                    setLoading={setLoading}
                    setRedirectToLogin={setRedirectToLogin}
                  />
              </Column>
            }

          </Column.Group>

          <Button.Group align="centered">
            <Button
              color="info"
              size="medium"
              className="has-text-weight-semibold"
              onClick={e => {
                handleSubmit(e)
              }} >
              <span>Salvar</span>
            </Button>
          </Button.Group>

        </div>

      </Components.CardWithOverlay>
    )
  },

  EditDocumentTemplateOverlay: (props) => {
    const [availableToCompanyProfessionals, setAvailableToCompanyProfessionals] = useState(props.openEditDocumentTemplate.availableToCompanyProfessionals || false)
    const [documentType, setDocumentType] = useState(props.openEditDocumentTemplate.documentType || "- - Selecione - -")
    const [documentTypeError, setDocumentTypeError] = useState(null)
    const [description, setDescription] = useState(props.openEditDocumentTemplate.description || "")
    const [documentTemplate, setDocumentTemplate] = useState(props.openEditDocumentTemplate.documentTemplate)

    const [showTextEditor, setShowTextEditor] = useState(false)
    const [showQuestionnaire, setShowQuestionnaire] = useState(false)
    const [showProcedure, setShowProcedure] = useState(false)

    const professionalInfo = { professionalId: JSON.parse(localStorage.getItem('user'))._id, isDocumentTemplate: true }

    const setOpenEditDocumentTemplate = props.setOpenEditDocumentTemplate
    
    const setDocumentTemplates = props.setDocumentTemplates
    const setOpenSelectDocumentTemplatesToOverwrite = props.setOpenSelectDocumentTemplatesToOverwrite
    const getDocumentTemplates = props.getDocumentTemplates

    const setFormChanged = props.setFormChanged
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    useEffect( () => {
      if (
        documentType == "Anamnese" ||
        documentType == "Diagnóstico" ||
        documentType == "Prognóstico" ||
        documentType == "Plano Terapêutico" ||
        documentType == "Receituário" ||
        documentType == "Requisição de exames" ||
        documentType == "Atestado" ||
        documentType == "Declaração" ||
        documentType == "Laudo" ||
        documentType == "Termo de consentimento"
      ) {
        if (!showTextEditor && !showQuestionnaire && !showProcedure) {
          if(!documentTemplate.entityMap) {
            setDocumentTemplate(EditorState.createWithContent(convertFromRaw({ ...documentTemplate, entityMap: {} })))
          } else {
            setDocumentTemplate(EditorState.createWithContent(convertFromRaw(documentTemplate)))
          }
        } else if (!showTextEditor) {
          setDocumentTemplate(EditorState.createEmpty())
        }
        setShowTextEditor(true)
        setShowQuestionnaire(false)
        setShowProcedure(false)
        setDocumentTypeError(null)
      } else if (documentType == "Questionário") {
        if (!showQuestionnaire && (showTextEditor || showProcedure)) {
          setDocumentTemplate([ { name: "Questionário", questions: [{ question: "", answerType: "Subjetiva", answer: "", alternatives: [{ alternative: "Opção 1", chosen: true }, { alternative: "Opção 2", chosen: false }] }] }])
        }
        setShowTextEditor(false)
        setShowQuestionnaire(true)
        setShowProcedure(false)
        setDocumentTypeError(null)
      } else if (documentType == "Procedimento") {
        if (!showProcedure && (showTextEditor || showQuestionnaire)) {
          setDocumentTemplate([{ name: "", notes: "", }])
        }
        setShowTextEditor(false)
        setShowQuestionnaire(false)
        setShowProcedure(true)
        setDocumentTypeError(null)
      } else {
        setShowTextEditor(false)
        setShowQuestionnaire(false)
        setShowProcedure(false)
        setDocumentTypeError("Selecione um tipo de documento")
      }
    }, [documentType])

    const mandatoryFields = []
    const errorList = [documentTypeError]

    const onDelete = (event) => {
      setNotification({
        withCancel: true,
        success: false,
        title: `Excluir Modelo de Documento`,
        text: `Tem certeza que deseja excluir o Modelo de Documento?"`,
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setLoading(true)
          let submitInfo = {
            event: event,
            setNotification: setNotification,
            setLoading: setLoading,
            setOpenComponent: setOpenEditDocumentTemplate,
            mandatoryFields: [],
            errorList: [],
            ServiceMethod: DocumentTemplatesService.deleteDocumentTemplate,
            params: {
              documentTemplateId: props.openEditDocumentTemplate._id,
            },
            onSuccessText: "Modelo de Documento excluído.",
            onErrorText: "Não foi possível excluir o Modelo de Documento. Tente novamente",
            onSuccessCallback: () => {
              if (setDocumentTemplates) setDocumentTemplates(null)
              if (setOpenSelectDocumentTemplatesToOverwrite) {
                setOpenSelectDocumentTemplatesToOverwrite(null)
                getDocumentTemplates()
              }
            },
            onErrorCallback: null,
            setRedirectToLogin: setRedirectToLogin,
          }
          methods.handleSubmit(submitInfo)
        })
      })
    }

    const handleSubmit = async (event) => {
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setOpenEditDocumentTemplate,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: DocumentTemplatesService.editDocumentTemplate,
        params: {
          documentTemplateId: props.openEditDocumentTemplate._id,
          availableToCompanyProfessionals: availableToCompanyProfessionals,
          documentType: documentType,
          description: description,
          documentTemplate: documentType == "Anamnese" ||
                            documentType == "Diagnóstico" ||
                            documentType == "Prognóstico" ||
                            documentType == "Plano Terapêutico" ||
                            documentType == "Receituário" ||
                            documentType == "Requisição de exames" ||
                            documentType == "Atestado" ||
                            documentType == "Declaração" ||
                            documentType == "Laudo" ||
                            documentType == "Termo de consentimento"
                            ? 
                            JSON.stringify(convertToRaw(documentTemplate.getCurrentContent()))
                            :
                            documentTemplate,
        },
        onSuccessText: "Modelo de documento atualizado",
        onErrorText: "Não foi possível atualizar o modelo de documento. Tente novamente",
        onSuccessCallback: () => {
          if (setDocumentTemplates) setDocumentTemplates(null)
          if (setOpenSelectDocumentTemplatesToOverwrite) {
            setOpenSelectDocumentTemplatesToOverwrite(null)
            getDocumentTemplates()
          }
        },
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }

      methods.handleSubmit(submitInfo)
    }

    return (
      <Components.CardWithOverlay
        cardType="fullViewer"
        headerClassName={"has-background-warning"}
        title="Editar Modelo de Documento"
        titleColorClassName="has-text-black"
        icon={faEdit}
        setOpenCard={setOpenEditDocumentTemplate}
      >
        <div style={{ minHeight: "600px" }}>
          <Column.Group multiline>

            <Column size={12}>
              <Label>Disponível para todos os profissionais:</Label>
              <Label className="ml-1">
                <Radio
                  value={true}
                  checked={availableToCompanyProfessionals}
                  onChange={(event) => {setAvailableToCompanyProfessionals(true)}}
                />
                <span className="ml-2 has-text-weight-normal is-size-6">Sim</span>
              </Label>
              <Label className="ml-3">
                <Radio
                  value={false}
                  checked={!availableToCompanyProfessionals}
                  onChange={(event) => {setAvailableToCompanyProfessionals(false)}}
                />
                <span className="ml-2 has-text-weight-normal is-size-6">Não</span>
              </Label>
            </Column>

            <Column size={12} className="field-column">
              <Label>Tipo de documento:</Label>
              <Dropdown>
                <Dropdown.Trigger>
                  <Button onClick={(e) => e.preventDefault()} >
                    <span>{documentType}</span>
                    <Icon>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Icon>
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Content>
                    <Dropdown.Item onClick={() => {setDocumentType("- - Selecione - -")}}>- - Selecione - -</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Anamnese")}}>Anamnese</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Questionário")}}>Questionário</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Procedimento")}}>Procedimento</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Diagnóstico")}}>Diagnóstico</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Prognóstico")}}>Prognóstico</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Plano Terapêutico")}}>Plano Terapêutico</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Receituário")}}>Receituário</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Requisição de exames")}}>Requisição de exames</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Atestado")}}>Atestado</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Declaração")}}>Declaração</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Laudo")}}>Laudo</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Termo de consentimento")}}>Termo de consentimento</Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Menu>
              </Dropdown>
            </Column>

            <Column size={12} className="p-0" >
              <Components.GenericInput
                size={12}
                label="Descrição:"
                placeholder="Breve descrição do modelo"
                elementName={null}
                value={description}
                setValue={setDescription}
                setFormChanged={setFormChanged}
              />
            </Column>

            {showTextEditor &&
              <Column size={12} >
                <Label>Modelo de {documentType}:</Label>
                <Components.TextEditor
                  attendance={professionalInfo}
                  editorState={documentTemplate}
                  setEditorState={setDocumentTemplate}
                  setFormChanged={setFormChanged}
                  setHasUnsavedChanges={null}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              </Column>
            }

            {showQuestionnaire &&
              <Column size={12} >
                <Label>Modelo de {documentType}:</Label>
                <Components.Questionnaires
                  isDocumentTemplate={true}
                  attendance={professionalInfo}
                  questionnaires={documentTemplate}
                  setQuestionnaires={setDocumentTemplate}
                  isDocumentTemplateEditor={true}
                  setFormChanged={setFormChanged}
                  setHasUnsavedChanges={null}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              </Column>
            }

            {showProcedure &&
              <Column size={12} >
                <Label>Modelo de {documentType}:</Label>
                <Components.Procedures
                    isDocumentTemplate={true}
                    attendance={professionalInfo}
                    procedures={documentTemplate}
                    setProcedures={setDocumentTemplate}
                    isDocumentTemplateEditor={true}
                    setFormChanged={setFormChanged}
                    setHasUnsavedChanges={null}
                    setNotification={setNotification}
                    setLoading={setLoading}
                    setRedirectToLogin={setRedirectToLogin}
                  />
              </Column>
            }

          </Column.Group>

          <Button.Group align="right">
            <Button
              color="danger"
              outlined
              onClick={onDelete}
            >
              <Icon >
                <FontAwesomeIcon icon={faTrash} />
              </Icon>
              <span>Excluir modelo</span>
            </Button>
          </Button.Group>

          <Button.Group align="centered">
            <Button
              color="info"
              size="medium"
              className="has-text-weight-semibold"
              onClick={e => {
                handleSubmit(e)
              }} >
              <span>Salvar</span>
            </Button>
          </Button.Group>

        </div>

      </Components.CardWithOverlay>
    )
  },

  NewUserOverlay: (props) => {
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(null)
  
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(null)
  
    const [gender, setGender] = useState("")
  
    const [rgIdentifier, setRgIdentifier] = useState("")
    const [rgIdentifierError, setRgIdentifierError] = useState(null)
    const [rgDigit, setRgDigit] = useState("")
    const [rgUf, setRgUf] = useState("")
  
    const [cpf, setCpf] = useState("")
    const [cpfError, setCpfError] = useState(null)
  
    const [birthday, setBirthday] = useState("")
    const [birthdayError, setBirthdayError] = useState(null)
    
    const [areaCode, setAreaCode] = useState("")
    const [areaCodeError, setAreaCodeError] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberError, setPhoneNumberError] = useState(null)
  
    const [cep, setCep] = useState("")
    const [cepError, setCepError] = useState(null)
    const [street, setStreet] = useState("")
    const [adressNumber, setAdressNumber] = useState("")
    const [complement, setComplement] = useState("")
    const [neighborhood, setNeighborhood] = useState("")
    const [city, setCity] = useState("")
    const [uf, setUf] = useState("")
  
    const [isSystemAdm, setIsSystemAdm] = useState(false)
    const [isCompanyAdm, setIsCompanyAdm] = useState(false)
    const [isProfessional, setIsProfessional] = useState(false)
    const [isSecretary, setIsSecretary] = useState(false)
    const [accessLevelsError, setAccessLevelsError] = useState(null)
  
    const [companyId, setCompanyId] = useState("")
    const [companyIdError, setCompanyIdError] = useState(null)
  
    const [professionalTitle, setProfessionalTitle] = useState("")
    const [councilName, setCouncilName] = useState("")
    const [councilRegister, setCouncilRegister] = useState("")

    let history = useHistory()

    const { setOpenNewUser, setFormChanged, setObjectToEdit, setNotification, setLoading, redirectToLogin, setRedirectToLogin } = props

    const mandatoryFields = [
      name,
      email,
    ]
  
    const errorList = [
      nameError,
      emailError,
      cpfError,
      birthdayError,
      areaCodeError,
      phoneNumberError,
      cepError,
      accessLevelsError,
      companyIdError,
    ]

    const handleSubmit = async (event) => {
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setOpenNewUser,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: UsersService.newUser,
        params: {
          name: name,
          email: email,
          areaCode: areaCode.replace(/\D/g,""),
          phoneNumber: phoneNumber.replace(/\D/g,""),
          gender: gender,
          rgIdentifier: rgIdentifier,
          rgDigit: rgDigit,
          rgUf: rgUf,
          cpf: cpf.replace(/\D/g,""),
          birthday: methods.stringToDate(birthday),
          cep: cep.replace(/\D/g,""),
          street: street,
          adressNumber: adressNumber,
          complement: complement,
          neighborhood: neighborhood,
          city: city,
          uf: uf,
          isSystemAdm: isSystemAdm,
          isCompanyAdm: isCompanyAdm,
          isProfessional: isProfessional,
          isSecretary: isSecretary,
          companyId: companyId,
          professionalTitle: professionalTitle,
          councilName: councilName,
          councilRegister: councilRegister,
        },
        onSuccessText: `Usuário cadastrado. Foi enviado um e-mail para ${email} contendo a senha provisória.`,
        onErrorText: "Não foi possível cadastrar o usuário. Tente novamente",
        onSuccessCallback: null,
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }

      methods.handleSubmit(submitInfo)
    }

    useEffect( () => {
      if (accessLevelsError) {
        if (isSystemAdm || isCompanyAdm || isProfessional || isSecretary) {
          setAccessLevelsError(null)
        }
      } else {
        if (!isSystemAdm && !isCompanyAdm && !isProfessional && !isSecretary) {
          setAccessLevelsError("Selecione pelo menos um nível de acesso")
        }
      }
    }, [isSystemAdm, isCompanyAdm, isProfessional, isSecretary])
  
    useEffect( () => {
      if (JSON.parse(localStorage.getItem('user')).isSystemAdm) {
        if (companyIdError) {
          if (((isCompanyAdm || isProfessional || isSecretary) && companyId) || (!isCompanyAdm && !isProfessional && !isSecretary)) {
            setCompanyIdError(null)
          }
        } else {
          if (((isCompanyAdm || isProfessional || isSecretary) && !companyId)) {
            setCompanyIdError("Selecione uma empresa para vincular o usuário")
          }
        }
      }
    }, [isCompanyAdm, isProfessional, isSecretary, companyId])

    useEffect( () => {
      const eventListener = (event) => {
        event.preventDefault()
        if (formChangedRef.current) {
          let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
          event.returnValue = confirmationMessage
          return confirmationMessage
        }
      }
      window.addEventListener('beforeunload', eventListener)
      return () => {
        window.removeEventListener('beforeunload', eventListener);
      };
    }, [])

    return (
      <Components.CardWithOverlay
        cardType="widerViewer"
        headerClassName={"has-background-success"}
        title="Novo Usuário"
        titleColorClassName="has-text-white"
        icon={faPlus}
        setOpenCard={setOpenNewUser}
      >

        <Column.Group multiline className="mt-2">

          <Components.UserName
            required={true}
            columnSize={12}
            name={name}
            nameError={nameError}
            setName={setName}
            setNameError={setNameError}
            setFormChanged={setFormChanged}
          />

          <Components.Email
            required={true}
            columnSize={12}
            email={email}
            emailError={emailError}
            setEmail={setEmail}
            setEmailError={setEmailError}
            setFormChanged={setFormChanged}
          />

          <Components.Phone
            required={false}
            columnSize={12}
            areaCode={areaCode}
            areaCodeError={areaCodeError}
            setAreaCode={setAreaCode}
            setAreaCodeError={setAreaCodeError}
            phoneNumber={phoneNumber}
            phoneNumberError={phoneNumberError}
            setPhoneNumber={setPhoneNumber}
            setPhoneNumberError={setPhoneNumberError}
            setFormChanged={setFormChanged}
          />

          <Components.Gender
            columnSize={12}
            gender={gender}
            setGender={setGender}
          />

          <Components.Rg
            required={false}
            columnSize={12}
            rgIdentifier={rgIdentifier}
            rgIdentifierError={rgIdentifierError}
            setRgIdentifier={setRgIdentifier}
            setRgIdentifierError={setRgIdentifierError}
            rgDigit={rgDigit}
            setRgDigit={setRgDigit}
            rgUf={rgUf}
            setRgUf={setRgUf}
            setFormChanged={setFormChanged}
          />

          <Components.Cpf
            required={false}
            columnSize={12}
            cpf={cpf}
            cpfError={cpfError}
            setCpf={setCpf}
            setCpfError={setCpfError}
            setFormChanged={setFormChanged}
          />

          <Components.Date
            required={false}
            columnSize={12}
            label="Data de Nascimento:"
            elementName="birthday"
            date={birthday}
            dateError={birthdayError}
            setDate={setBirthday}
            setDateError={setBirthdayError}
            setFormChanged={setFormChanged}
          />

        </Column.Group>

        <Column.Group multiline>

          <Components.Cep
            required={false}
            columnSize={12}
            cep={cep}
            setCep={setCep}
            cepError={cepError}
            setCepError={setCepError}
            setStreet={setStreet}
            setAdressNumber={setAdressNumber}
            setComplement={setComplement}
            setNeighborhood={setNeighborhood}
            setCity={setCity}
            setUf={setUf}
            setLoading={setLoading}
            setFormChanged={setFormChanged}
          />

          <Components.Street
            required={false}
            columnSize={12}
            street={street}
            setStreet={setStreet}
            setFormChanged={setFormChanged}
          />

          <Components.AdressNumber
            required={false}
            columnSize={12}
            adressNumber={adressNumber}
            setAdressNumber={setAdressNumber}
            setFormChanged={setFormChanged}
          />

          <Components.Complement
            required={false}
            columnSize={12}
            complement={complement}
            setComplement={setComplement}
            setFormChanged={setFormChanged}
          />

          <Components.Neighborhood
            required={false}
            columnSize={12}
            neighborhood={neighborhood}
            setNeighborhood={setNeighborhood}
            setFormChanged={setFormChanged}
          />

          <Components.CityAndUf
            required={false}
            columnSize={12}
            city={city}
            setCity={setCity}
            uf={uf}
            setUf={setUf}
            setFormChanged={setFormChanged}
          />

          <Components.CheckboxContainer
            notNarrow={true}
            required={true}
            label="Níveis de acesso:"
          >
            {JSON.parse(localStorage.getItem('user')).isSystemAdm &&
              <Components.Checkbox key={0} color="dark" checked={isSystemAdm} onChange={setIsSystemAdm} setFormChanged={setFormChanged} >
                Administrador do sistema
              </Components.Checkbox>
            }

            <Components.Checkbox key={1} color="info" checked={isCompanyAdm} onChange={setIsCompanyAdm} setFormChanged={setFormChanged} >
              Administrador da empresa
            </Components.Checkbox>

            <Components.Checkbox key={2} color="primary" checked={isProfessional} onChange={setIsProfessional} setFormChanged={setFormChanged} >
              Profissional de Saúde
            </Components.Checkbox>
            
            <Components.Checkbox key={3} color="success" checked={isSecretary} onChange={setIsSecretary} setFormChanged={setFormChanged} >
              Secretário
            </Components.Checkbox>

          </Components.CheckboxContainer>

        </Column.Group>

        {((JSON.parse(localStorage.getItem('user')).isSystemAdm) && (isCompanyAdm || isProfessional || isSecretary)) &&
          <Fragment>

            <Column.Group multiline>

            <Components.InputWithSearchCompany
              required={true}
              companyId={companyId}
              setCompanyId={setCompanyId}
            />

            </Column.Group>

          </Fragment>
        }


        {isProfessional &&
          <Fragment>
            <Column.Group multiline>

              <Components.ProfessionalTitle
                required={false}
                professionalTitle={professionalTitle}
                setProfessionalTitle={setProfessionalTitle}
                setFormChanged={setFormChanged}
              />

              <Components.CouncilName
                required={false}
                councilName={councilName}
                setCouncilName={setCouncilName}
                setFormChanged={setFormChanged}
              />

              <Components.CouncilRegister
                required={false}
                councilRegister={councilRegister}
                setCouncilRegister={setCouncilRegister}
                setFormChanged={setFormChanged}
              />

            </Column.Group>
          </Fragment>
        }

        <Button.Group align="centered">
          <Button
            color="info"
            size="medium"
            className="has-text-weight-semibold"
            onClick={e => {
              handleSubmit(e)
            }} >
            <span>Cadastrar</span>
          </Button>
        </Button.Group>

      </Components.CardWithOverlay>
    )
  },

  NewCompanyOverlay: (props) => {
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(null)
  
    const [tradingName, setTradingName] = useState("")
    const [tradingNameError, setTradingNameError] = useState(null)
  
    const [cnpj, setCnpj] = useState("")
    const [cnpjError, setCnpjError] = useState(null)
  
    const [areaPhysio, setAreaPhysio] = useState(false)
    const [areaOccupational, setAreaOccupational] = useState(false)
    const [areaPilates, setAreaPilates] = useState(false)
    const [areaAesthetics, setAreaAesthetics] = useState(false)
    const [areaOthers, setAreaOthers] = useState(false)
  
    const [areaCode, setAreaCode] = useState("")
    const [areaCodeError, setAreaCodeError] = useState(null)  
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberError, setPhoneNumberError] = useState(null)
  
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(null)
  
    const [cep, setCep] = useState("")
    const [cepError, setCepError] = useState(null)
    const [street, setStreet] = useState("")
    const [adressNumber, setAdressNumber] = useState("")
    const [complement, setComplement] = useState("")
    const [neighborhood, setNeighborhood] = useState("")
    const [city, setCity] = useState("")
    const [uf, setUf] = useState("")
  
    const [logoImage, setLogoImage] = useState(null)
    const [logoImageError, setLogoImageError] = useState(null)
    const [logoImageName, setLogoImageName] = useState(null)
    const [slogan, setSlogan] = useState("")

    let history = useHistory()

    const { setOpenNewCompany, setFormChanged, setObjectToEdit, setNotification, setLoading, redirectToLogin, setRedirectToLogin } = props

    const mandatoryFields = [
      tradingName
    ]
  
    const errorList = [
      nameError,
      tradingNameError,
      cnpjError,
      areaCodeError,
      phoneNumberError,
      emailError,
      cepError,
      logoImageError,
    ]

    const handleSubmit = async (event) => {
      const formData = new FormData()
      formData.append("name", name);
      formData.append("tradingName", tradingName);
      formData.append("cnpj", cnpj.replace(/\D/g,""));
      formData.append("areaPhysio", areaPhysio);
      formData.append("areaOccupational", areaOccupational);
      formData.append("areaPilates", areaPilates);
      formData.append("areaAesthetics", areaAesthetics);
      formData.append("areaOthers", areaOthers);
      formData.append("areaCode", areaCode.replace(/\D/g,""));
      formData.append("phoneNumber", phoneNumber.replace(/\D/g,""));
      formData.append("email", email);
      formData.append("cep", cep.replace(/\D/g,""));
      formData.append("street", street);
      formData.append("adressNumber", adressNumber);
      formData.append("complement", complement);
      formData.append("neighborhood", neighborhood);
      formData.append("city", city);
      formData.append("uf", uf);
      formData.append("logoImage", logoImage);
      formData.append("slogan", slogan);
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setOpenNewCompany,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: CompaniesService.newCompany,
        params: formData,
        onSuccessText: "Empresa cadastrada.",
        onErrorText: "Não foi possível cadastrar a empresa. Tente novamente",
        onSuccessCallback: null,
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }
      methods.handleSubmit(submitInfo)
    }

    return (
      <Components.CardWithOverlay
        cardType="widerViewer"
        headerClassName={"has-background-success"}
        title="Nova Empresa"
        titleColorClassName="has-text-white"
        icon={faPlus}
        setOpenCard={setOpenNewCompany}
      >

        <Column.Group multiline className="mt-2">

          <Components.CompanyName
            required={false}
            name={name}
            nameError={nameError}
            setName={setName}
            setNameError={setNameError}
            setFormChanged={setFormChanged}
          />

          <Components.TradingName
            required={true}
            tradingName={tradingName}
            tradingNameError={tradingNameError}
            setTradingName={setTradingName}
            setTradingNameError={setTradingNameError}
            setFormChanged={setFormChanged}
          />

          <Components.GenericInput
            required={false}
            elementName="slogan"
            value={slogan}
            setValue={setSlogan}
            setFormChanged={setFormChanged}
          >
            Slogan:
          </Components.GenericInput>
          
          <Components.ImageUpload
            file={logoImage}
            setFile={setLogoImage}
            fileError={logoImageError}
            setFileError={setLogoImageError}
            fileMaxSize={5*1024*1024}
            fileName={logoImageName}
            setFileName={setLogoImageName}
            setFormChanged={setFormChanged}
          >
            Logomarca:
          </Components.ImageUpload>

          <Components.Cnpj
            required={false}
            cnpj={cnpj}
            cnpjError={cnpjError}
            setCnpj={setCnpj}
            setCnpjError={setCnpjError}
            setFormChanged={setFormChanged}
          />

          <Components.CheckboxContainer
            required={false}
            label="Áreas:"
          >

            <Components.Checkbox checked={areaPhysio} onChange={setAreaPhysio} setFormChanged={setFormChanged} >
              Fisioterapia
            </Components.Checkbox>

            <Components.Checkbox checked={areaOccupational} onChange={setAreaOccupational} setFormChanged={setFormChanged} >
              Terapia Ocupacional
            </Components.Checkbox>

            <Components.Checkbox checked={areaPilates} onChange={setAreaPilates} setFormChanged={setFormChanged} >
              Pilates
            </Components.Checkbox>
            
            <Components.Checkbox checked={areaAesthetics} onChange={setAreaAesthetics} setFormChanged={setFormChanged} >
              Estética
            </Components.Checkbox>

            <Components.Checkbox checked={areaOthers} onChange={setAreaOthers} setFormChanged={setFormChanged} >
              Outros
            </Components.Checkbox>

          </Components.CheckboxContainer>

          <Components.Phone
            required={false}
            areaCode={areaCode}
            areaCodeError={areaCodeError}
            setAreaCode={setAreaCode}
            setAreaCodeError={setAreaCodeError}
            phoneNumber={phoneNumber}
            phoneNumberError={phoneNumberError}
            setPhoneNumber={setPhoneNumber}
            setPhoneNumberError={setPhoneNumberError}
            setFormChanged={setFormChanged}
          />

          <Components.Email
            required={false}
            email={email}
            emailError={emailError}
            setEmail={setEmail}
            setEmailError={setEmailError}
            setFormChanged={setFormChanged}
          />

          <Components.Cep
            required={false}
            cep={cep}
            setCep={setCep}
            cepError={cepError}
            setCepError={setCepError}
            setStreet={setStreet}
            setAdressNumber={setAdressNumber}
            setComplement={setComplement}
            setNeighborhood={setNeighborhood}
            setCity={setCity}
            setUf={setUf}
            setLoading={setLoading}
            setFormChanged={setFormChanged}
          />

          <Components.Street
            required={false}
            street={street}
            setStreet={setStreet}
            setFormChanged={setFormChanged}
          />

          <Components.AdressNumber
            required={false}
            adressNumber={adressNumber}
            setAdressNumber={setAdressNumber}
            setFormChanged={setFormChanged}
          />

          <Components.Complement
            required={false}
            complement={complement}
            setComplement={setComplement}
            setFormChanged={setFormChanged}
          />

          <Components.Neighborhood
            required={false}
            neighborhood={neighborhood}
            setNeighborhood={setNeighborhood}
            setFormChanged={setFormChanged}
          />

          <Components.CityAndUf
            required={false}
            city={city}
            setCity={setCity}
            uf={uf}
            setUf={setUf}
            setFormChanged={setFormChanged}
          />

        </Column.Group>

        <Button.Group align="centered">
          <Button
            color="info"
            size="medium"
            className="has-text-weight-semibold"
            onClick={e => {
              handleSubmit(e)
            }} >
            <span>Cadastrar</span>
          </Button>
        </Button.Group>

      </Components.CardWithOverlay>
    )
  },

  EventClickOverlay: (props) => {

    const [date, setDate] = useState(props.eventClickInfo.date)
    const [dateError, setDateError] = useState(null)
    const [oldStart, setOldStart] = useState(DateTime.fromJSDate(props.eventClickInfo.start).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [start, setStart] = useState(DateTime.fromJSDate(props.eventClickInfo.start).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [startError, setStartError] = useState(null)
    const [oldEnd, setOldEnd] = useState(DateTime.fromJSDate(props.eventClickInfo.end).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [end, setEnd] = useState(DateTime.fromJSDate(props.eventClickInfo.end).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [endError, setEndError] = useState(null)
    const [timeError, setTimeError] = useState(null)
    const [patient, setPatient] = useState(props.eventClickInfo.patient)
    const [patientId, setPatientId] = useState(props.eventClickInfo.patientId)
    const [professional, setProfessional] = useState(props.eventClickInfo.professional)
    const [professionalId, setProfessionalId] = useState(props.eventClickInfo.professionalId)
    const [description, setDescription] = useState(props.eventClickInfo.description)
    const [status, setStatus] = useState(props.eventClickInfo.status)
    const [oldPrice, setOldPrice] = useState(props.eventClickInfo.price)
    const [price, setPrice] = useState(props.eventClickInfo.price)
    const [paid, setPaid] = useState(props.eventClickInfo.paid)

    let debt = price != 0 ? methods.priceToNumber(price) - methods.priceToNumber(paid) : 0
    debt = methods.numberToPrice(debt.toFixed(2))

    const [paymentValue, setPaymentValue] = useState(methods.numberToPrice(methods.priceToNumber(props.eventClickInfo.price) - methods.priceToNumber(props.eventClickInfo.paid)))
    const [paymentMethod, setPaymentMethod] = useState("Dinheiro")
    const [paymentOtherMethodDescription, setPaymentOtherMethodDescription] = useState("")
    const [paymentDate, setPaymentDate] = useState(new Date(Date.now()))
    const [paymentDateError, setPaymentDateError] = useState(null)

    const [editEvent, setEditEvent] = useState(false)
    const [registerPayment, setRegisterPayment] = useState(false)

    const [radioOptionChecked, setRadioOptionChecked] = useState("this-event")
    
    const history = useHistory()

    const eventClickInfo = props.eventClickInfo
    const setEventClickInfo = props.setEventClickInfo
    const setOpenPayments = props.setOpenPayments

    const setFormChanged = props.setFormChanged
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin
    const setObjectToEdit = props.setObjectToEdit

    const disableSelect = () => { }

    const mandatoryFields = [
      patientId,
      professionalId,
    ]

    const errorList = [
      dateError,
      timeError,
    ]

    const handleEditEvent = async (event) => {
      if (eventClickInfo.recurrenceId) {
        const dateDiff = new Date(new Date(date).getTime() - new Date(eventClickInfo.start.getTime())).getTime() / 1000 / 60 / 60 / 24
        const weekdayNumber = new Date(eventClickInfo.start).getDay()
        const thisWeekday = weekdayNumber == 0 ? "domingo" : weekdayNumber == 1 ? "segunda-feira" : weekdayNumber == 2 ? "terça-feira" : weekdayNumber == 3 ? "quarta-feira" : weekdayNumber == 4 ? "quinta-feira" : weekdayNumber == 5 ? "sexta-feira" : "sábado"
        setNotification({
          radioOptionChecked: radioOptionChecked,
          setRadioOptionChecked: setRadioOptionChecked,
          radioOptions: eventClickInfo.recurrenceId ? [
            {
              value: "this-event",
              text: "Este agendamento"
            },
            {
              value: "this-weekday",
              text: `Agendamentos de ${thisWeekday} deste grupo de agendamentos`
            },
            {
              value: "this-and-next",
              text: "Este e os agendamentos seguintes deste grupo de agendamentos"
            },
            {
              value: "all",
              text: "Todos os agendamentos deste grupo de agendamentos"
            },
          ] : undefined,
          withCancel: true,
          success: true,
          title: `Editar agendamento ${eventClickInfo.recurrenceId ? "recorrente" : ""}`,
          text: (
            <Fragment>
              {dateDiff != 0 &&
                <div className="mb-3">{`Dias a ${dateDiff >= 0 ? "avançar" : "retroceder"}:`} <span className="has-text-weight-normal">{`${dateDiff < 0 ? dateDiff * (-1) : dateDiff} dia${dateDiff < (-1) || dateDiff > 1  ? "s" : ""}`}</span></div>
              }
              {(start != oldStart || end != oldEnd) &&
                <div className="mb-3">Novo horário:<span className="has-text-weight-normal">{` das ${start} às ${end}`}</span></div>
              }
              {price != oldPrice &&
                <div className="mb-3">Novo preço: <span className="has-text-weight-normal">{price}</span></div>
              }
            </Fragment>
          ),
          cancelCallback: (() => {
            setNotification(false)
          }),
          confirmCallback: ((localRadioOptionChecked) => {
            let submitInfo = {
              event: event,
              setNotification: setNotification,
              setLoading: setLoading,
              setOpenComponent: setEventClickInfo,
              mandatoryFields: mandatoryFields,
              errorList: errorList,
              ServiceMethod: EventsService.editEvent,
              params: {
                eventId: eventClickInfo.id,
                recurrenceId: eventClickInfo.recurrenceId,
                editOption: localRadioOptionChecked,
                weekdayNumber: weekdayNumber,
                patientId: patientId,
                professionalId: professionalId,
                start: new Date(`${DateTime.fromJSDate(date).toISODate()}T${start}`),
                end: new Date(`${DateTime.fromJSDate(date).toISODate()}T${end}`),
                description: description,
                status: status,
                price: methods.priceToNumber(price)
              },
              onSuccessText: "Agendamento atualizado.",
              onErrorText: "Não foi possível atualizar o agendamento. Tente novamente",
              onSuccessCallback: () => {
                setLoading(true)
                props.getAndSetEvents()
                  .then(() => {
                    setLoading(false)
                    setFormChanged(false)
                  })
              },
              onErrorCallback: null,
              setRedirectToLogin: setRedirectToLogin,
            }
            methods.handleSubmit(submitInfo)
          })
        })
      } else {
        let submitInfo = {
          event: event,
          setNotification: setNotification,
          setLoading: setLoading,
          setOpenComponent: setEventClickInfo,
          mandatoryFields: mandatoryFields,
          errorList: errorList,
          ServiceMethod: EventsService.editEvent,
          params: {
            eventId: eventClickInfo.id,
            patientId: patientId,
            professionalId: professionalId,
            start: new Date(`${DateTime.fromJSDate(date).toISODate()}T${start}`),
            end: new Date(`${DateTime.fromJSDate(date).toISODate()}T${end}`),
            description: description,
            status: status,
            price: methods.priceToNumber(price)
          },
          onSuccessText: "Agendamento atualizado.",
          onErrorText: "Não foi possível atualizar o agendamento. Tente novamente",
          onSuccessCallback: () => {
            setLoading(true)
            props.getAndSetEvents()
              .then(() => {
                setLoading(false)
                setFormChanged(false)
              })
          },
          onErrorCallback: null,
          setRedirectToLogin: setRedirectToLogin,
        }
        methods.handleSubmit(submitInfo)
      }
    }

    const handleRegisterPayment = async (event) => {
      let hasPaymentValue = methods.priceToNumber(paymentValue) > 0
      if (!hasPaymentValue) {
        setNotification({
          success: false,
          title: "Atenção",
          text: "O valor do pagamento deve ser maior que zero.",
          callback: (() => {
            setNotification(false)
          }),
        })
      } else {
        let submitInfo = {
          event: event,
          setNotification: setNotification,
          setLoading: setLoading,
          setOpenComponent: setEventClickInfo,
          mandatoryFields: [
            paymentValue,
          ],
          errorList: errorList,
          ServiceMethod: EventsService.registerEventPayment,
          params: {
            eventId: eventClickInfo.id,
            value: methods.priceToNumber(paymentValue),
            method: paymentMethod,
            otherMethodDescription: paymentMethod == "Outro" ? paymentOtherMethodDescription : undefined,
            date: paymentDate,
          },
          onSuccessText: "Pagamento cadastrado.",
          onErrorText: "Não foi possível cadastrar o pagamento. Tente novamente",
          onSuccessCallback: () => {
            setLoading(true)
            props.getAndSetEvents()
              .then(() => {
                setLoading(false)
                setFormChanged(false)
              })
          },
          onErrorCallback: null,
          setRedirectToLogin: setRedirectToLogin,
        }

        methods.handleSubmit(submitInfo)
      }
    }

    const handleDeleteEvent = async (event) => {
      let attended = methods.priceToNumber(paid) == 0 && status != "Atendido"
      const weekdayNumber = new Date(eventClickInfo.start).getDay()
      const thisWeekday = weekdayNumber == 0 ? "domingo" : weekdayNumber == 1 ? "segunda-feira" : weekdayNumber == 2 ? "terça-feira" : weekdayNumber == 3 ? "quarta-feira" : weekdayNumber == 4 ? "quinta-feira" : weekdayNumber == 5 ? "sexta-feira" : "sábado"
      setNotification({
        radioOptionChecked: radioOptionChecked,
        setRadioOptionChecked: setRadioOptionChecked,
        radioOptions: eventClickInfo.recurrenceId ? [
          {
            value: "this-event",
            text: "Este agendamento"
          },
          {
            value: "this-weekday",
            text: `Agendamentos de ${thisWeekday} deste grupo de agendamentos`
          },
          {
            value: "this-and-next",
            text: "Este e os agendamentos seguintes deste grupo de agendamentos"
          },
          {
            value: "all",
            text: "Todos os agendamentos deste grupo de agendamentos"
          },
        ] : undefined,
        withCancel: true,
        success: false,
        title: `Excluir agendamento ${eventClickInfo.recurrenceId ? "recorrente" : ""}`,
        text: `${attended ? "" : "O atendimento já foi realizado. "}Tem certeza que deseja excluir o${eventClickInfo.recurrenceId ? "s" : ""} agendamento${eventClickInfo.recurrenceId ? "s" : ""}?`,
        callback: (() => {
          setNotification(false)
        }),
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: ((localRadioOptionChecked) => {
          setNotification(null)
          let submitInfo = {
            event: event,
            setNotification: setNotification,
            setLoading: setLoading,
            setOpenComponent: setEventClickInfo,
            mandatoryFields: [],
            errorList: [],
            ServiceMethod: EventsService.deleteEvent,
            params: {
              eventId: eventClickInfo.id,
              recurrenceId: eventClickInfo.recurrenceId,
              deleteOption: localRadioOptionChecked,
              weekdayNumber: weekdayNumber,
            },
            onSuccessText: "Agendamento excluído.",
            onErrorText: "Não foi possível excluir o agendamento. Tente novamente",
            onSuccessCallback: () => {
              setLoading(true)
              props.getAndSetEvents()
                .then(() => {
                  setLoading(false)
                  setFormChanged(false)
                })
            },
            onErrorCallback: () => {
              props.getAndSetEvents()
                .then(() => {
                  setLoading(false)
                  setFormChanged(false)
                })
            },
            setRedirectToLogin: setRedirectToLogin,
          }

          methods.handleSubmit(submitInfo)
        })
      })
    }

    const handleEditAttendance = async (event) => {
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setEventClickInfo,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: AttendancesService.getAttendanceById,
        params: {
          id: eventClickInfo.id,
        },
        onErrorText: "Não foi possível iniciar o atendimento. Tente novamente",
        noSuccessNotification: true,
        onSuccessCallback: (response) => {
          setObjectToEdit(response.data)
          history.push("/app/edit-attendance")
        },
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }
      methods.handleSubmit(submitInfo)
    }

    const getAttendancesOfOnePackage = async (attendancePackageId) => {
      try {
        setLoading(true)
        return await AttendancePackagesService.getAttendancesOfOnePackage({ attendancePackageId: attendancePackageId})
        .then( (response) => {
          return response.data
          setLoading(false)
        })
      } catch (error) {
        console.log(error)
        try {
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setLoading(false)
            setNotification({
              success: false,
              text: error.response.data,
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {          
          setLoading(false)
          setNotification({
            success: false,
            text: "Não foi possível carregar as informações. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      }
    }

    useEffect(() => {
      if (DateTime.fromISO(start) >= DateTime.fromISO(end)) {
        setTimeError("A hora de início deve ser menor que a hora de término")
      } else {
        setTimeError(null)
      }
    }, [start, end])

    return (
      <Components.CardWithOverlay
        headerClassName={editEvent && "has-background-warning" || registerPayment && "has-background-success"}
        title={!(editEvent || registerPayment) && "Detalhes" || !(registerPayment) && "Editar" || "Pagamento"}
        titleColorClassName={editEvent && "has-text-dark" || registerPayment && "has-text-white"}
        icon={!(editEvent || registerPayment) && faCalendarCheck || !(registerPayment) && faEdit || faMoneyCheckAlt}
        setOpenCard={setEventClickInfo}
      >

        <Column.Group multiline>

          <Column size={12} className="field-column mt-4 mb-3" >
            {(!editEvent || eventClickInfo.hasRegisteredAttendance) &&
              <Title size={6} className={status == "Aguard. atendimento" && "has-text-info mb-0" || status == "Atendido" && "has-text-success mb-0" || status == "Paciente não compareceu" && "has-text-danger mb-0"}>
                <span className="has-text-black mr-2">Situação: </span>
                <FontAwesomeIcon icon={status == "Aguard. atendimento" && faHourglassHalf || status == "Atendido" && faCheck || status == "Paciente não compareceu" && faTimes} />
                <span className="ml-2"><b>{status}{status == "Atendido" ? (eventClickInfo.hasRegisteredAttendance ? <span>{` (atendimento cadastrado)`}</span> : <span className="has-text-grey">{` (atendimento não cadastrado)`}</span>) : null}</b></span>
              </Title>
              ||
              <Field>
                <Label>Situação:</Label>
                <Control expanded>
                  <Select.Container fullwidth>
                    <Select
                      type="text"
                      value={status}
                      onChange={e => { setStatus(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                      name="status"
                    >
                      <Select.Option>Aguard. atendimento</Select.Option>
                      <Select.Option>Atendido</Select.Option>
                      <Select.Option>Paciente não compareceu</Select.Option>
                    </Select>
                  </Select.Container>
                </Control>
              </Field>
            }
          </Column>

          <Column className="field-column py-0">
            <Column.Group multiline className="mb-0" >

              <Column narrow className="field-column date-input" >
                {!editEvent &&
                  <Label>Data: <span className="has-text-weight-normal mr-2">{methods.dateToString(date)}</span></Label>
                  ||
                  <Fragment>
                    <Label>Data:</Label>
                    <DatePicker
                      selected={date}
                      onChange={(date) => { setDate(date); setDateError(null) }}
                      customInput={
                        <Components.DatePickerInput
                          disableSelect={!editEvent && disableSelect}
                          icon={editEvent && faCalendar}
                          dateError={dateError}
                          setDateError={setDateError}
                        />
                      }
                      dateFormat="dd/MM/yyyy"
                      locale="pt-BR"
                      strictParsing
                    />
                  </Fragment>
                }
              </Column>

              <Column narrow className="field-column time-input" >
                {!editEvent &&
                  <Label>Início: <span className="has-text-weight-normal mr-2">{start}</span></Label>
                  ||
                  <Fragment>
                    <Label className="label-2px-mt" >Início:</Label>
                    <Components.TimeInput
                      time={start}
                      setTime={setStart}
                      timeError={startError}
                      setTimeError={setStartError}
                    />
                  </Fragment>
                }
              </Column>

              <Column narrow className="field-column time-input" >
                {!editEvent &&
                  <Label>Fim: <span className="has-text-weight-normal mr-2">{end}</span></Label>
                  ||
                  <Fragment>
                    <Label className="label-2px-mt" >Fim:</Label>
                    <Components.TimeInput
                      time={end}
                      setTime={setEnd}
                      timeError={endError}
                      setTimeError={setEndError}
                    />
                  </Fragment>
                }
              </Column>

            </Column.Group>
          </Column>

          <Column size={12} className="field-column" >
            {(!editEvent || eventClickInfo.hasRegisteredAttendance) &&
              <Fragment>
                <Label>Paciente: <span className="has-text-weight-normal mr-2">{patient}</span>{eventClickInfo.patientPhone && <Generic as="a" className="is-underlined"><FontAwesomeIcon className="mr-2" icon={faPhoneAlt} />{eventClickInfo.patientPhone}</Generic>}</Label>
              </Fragment>
              ||
              <Fragment>
                <Label>Paciente:<span className="has-text-danger"> *</span></Label>
                <Components.FieldWithSearch
                  value={patient}
                  getValue={setPatient}
                  placeholder="Digite parte do nome do paciente:"
                  resultId={patientId}
                  setResultId={setPatientId}
                  searchAndSetDropdownItensCallback={methods.searchAndSetPatientsDropdown}
                />
              </Fragment>
            }
          </Column>

          <Column size={12} className="field-column" >
            {(!editEvent || eventClickInfo.hasRegisteredAttendance) &&
              <Label>Profissional: <span className="has-text-weight-normal">{professional}</span></Label>
              ||
              <Fragment>
                <Label>Profissional:<span className="has-text-danger"> *</span></Label>
                <Components.FieldWithSearch
                  value={professional}
                  getValue={setProfessional}
                  placeholder="Digite parte do nome do profissional:"
                  resultId={professionalId}
                  setResultId={setProfessionalId}
                  searchAndSetDropdownItensCallback={methods.searchAndSetProfessionalsDropdown}
                />
              </Fragment>
            }
          </Column>

          <Column size={12} className={!editEvent && "field-column" || "p-0"} >
            {!editEvent &&
              <Label>Descrição: <span className="has-text-weight-normal">{description}</span></Label>
              ||
              <Components.GenericInput
                size={12}
                label="Descrição:"
                placeholder="Pequena descrição do atendimento"
                elementName="attendance-description"
                value={description}
                setValue={setDescription}
                setFormChanged={setFormChanged}
              />
            }
          </Column>

          <Column size={12} className={!editEvent && "field-column" || "p-0"} >
            {!editEvent &&
              <Fragment>
                {props.eventClickInfo.attendancePackageId
                  ? <Fragment>
                      <Label>Pacote:
                        <a className="has-text-weight-bold"
                          onClick={e => {
                            getAttendancesOfOnePackage(props.eventClickInfo.attendancePackageId)
                            .then((response) => {
                              let attendancePackageToPass = { ...response.attendancePackage, attendancesArray: response.attendancesArray }
                              setObjectToEdit(attendancePackageToPass)
                              history.push("/app/edit-attendance-package")
                              props.setFocusedItem("attendance-package")
                            })
                          }}
                        >{" " + props.eventClickInfo.attendancePackage + " (clique para ver)"}</a>
                      </Label>
                      <Label className="has-text-primary"><span className="has-background-primary has-text-white mr-2 event-icon">$</span>O pagamento deve ser verificado no Pacote de Atendimentos</Label>
                    </Fragment>
                  : <Label>Preço: <span className="has-text-weight-normal">{price}</span></Label>
                }
              </Fragment>

              ||
              <Components.GenericInput
                size={12}
                label="Preço:"
                placeholder="R$ 0,00"
                elementName="price"
                value={price}
                setValue={setPrice}
                maskAndSetValue={methods.maskAndSetPrice}
                setFormChanged={setFormChanged}
              />
            }
          </Column>

          {(!editEvent && methods.priceToNumber(paid) > 0) &&
            <Column size={12} className="field-column" >
              <span className={paid <= 0 ? "has-text-weight-bold is-size-6" : "has-text-weight-bold has-text-success is-size-6"} >Total Pago: <span className="has-text-weight-normal mr-2" >{paid}</span></span>
              <Button color="info"
                style={{ height: "23px", border: "1px solid black", marginLeft: "5px" }}
                onClick={() => {
                  setOpenPayments(eventClickInfo)
                }}
              >
                <Icon>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </Icon>
                <span className="ml-1">Ver Pagamentos</span>
              </Button>
            </Column>
          }

          {(!editEvent && price != 0 && methods.priceToNumber(eventClickInfo.debt) > 0) &&
            <Column size={12} className="field-column" >
              <span className="has-text-danger has-text-weight-bold is-size-6" >Em aberto: <span className="has-text-weight-normal mr-2" >{eventClickInfo.debt}</span></span>
              <Button
                style={{ height: "23px", border: "1px solid black", marginLeft: "5px" }}
                color="success"
                onClick={e => { setRegisterPayment(true) }}
              >
                <Icon >
                  <FontAwesomeIcon icon={faDollarSign} />
                </Icon>
                <span>Cadastrar pgto.</span>
              </Button>
            </Column>
          }

          {registerPayment &&
            <Fragment>

              <Column size={12} className="p-0" >

                <hr className="form-divider mx-2" />

                <Components.GenericInput
                  size={12}
                  label="Valor do pagamento:"
                  placeholder="R$ "
                  elementName="paymentValue"
                  value={paymentValue}
                  setValue={setPaymentValue}
                  maskAndSetValue={methods.maskAndSetPrice}
                  setFormChanged={setFormChanged}
                />
              </Column>

              <Column size={12} className="field-column" >
                <Field>
                  <Label>Forma de pagamento:</Label>
                  <Control expanded>
                    <Select.Container fullwidth>
                      <Select
                        type="text"
                        value={paymentMethod}
                        onChange={e => { setPaymentMethod(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                        name="paymentMethod"
                      >
                        <Select.Option>Dinheiro</Select.Option>
                        <Select.Option>Cartão de débito</Select.Option>
                        <Select.Option>Cartão de crédito - vencimento</Select.Option>
                        <Select.Option>Cartão de crédito - parcelado</Select.Option>
                        <Select.Option>Pix</Select.Option>
                        <Select.Option>Boleto bancário</Select.Option>
                        <Select.Option>Outro</Select.Option>
                      </Select>
                    </Select.Container>
                  </Control>
                </Field>
              </Column>

            </Fragment>
          }

          {(registerPayment && paymentMethod == "Outro") &&
            <Column size={12} className="p-0" >
              <Components.GenericInput
                size={12}
                label="Outro:"
                placeholder="Descreva a forma de pagamento:"
                elementName="otherMethod"
                value={paymentOtherMethodDescription}
                setValue={setPaymentOtherMethodDescription}
                setFormChanged={setFormChanged}
              />
            </Column>
          }

          {registerPayment &&
            <Column size={6} className="field-column" >
              <Label>Data do pagamento:</Label>
              <DatePicker
                selected={paymentDate}
                onChange={(date) => { setPaymentDate(date); setPaymentDateError(null) }}
                customInput={
                  <Components.DatePickerInput
                    icon={faCalendar}
                    dateError={paymentDateError}
                    setDateError={setPaymentDateError}
                  />}
                dateFormat="dd/MM/yyyy"
                locale="pt-BR"
                strictParsing
              />
            </Column>
          }

        </Column.Group>

        {!(editEvent || registerPayment) &&
          <Column.Group centered multiline>

            {JSON.parse(localStorage.getItem('user')).isProfessional &&
              <Column size={6} className="py-2">
                <Button
                  className="fullwidth-button has-text-weight-semibold"
                  color="info"
                  onClick={e => {
                    handleEditAttendance(e)
                    props.setFocusedItem("attendance")
                  }}
                >
                  <Level breakpoint="mobile" >
                    <Level.Item align="left">
                      <Icon >
                        <FontAwesomeIcon icon={faStethoscope} />
                      </Icon>
                    </Level.Item>
                    <Level.Item>
                      <span>Atender paciente</span>
                    </Level.Item>
                  </Level>
                </Button>
              </Column>
            }

            <Column size={6} className="py-2">
              <Button
                className="fullwidth-button has-text-weight-semibold"
                color="warning"
                onClick={e => { setEditEvent(true) }}
              >
                <Level breakpoint="mobile" >
                  <Level.Item align="left">
                    <Icon >
                      <FontAwesomeIcon icon={faEdit} />
                    </Icon>
                  </Level.Item>
                  <Level.Item>
                    <span>Editar agendamento</span>
                  </Level.Item>
                </Level>
              </Button>
            </Column>

            <Column size={6} className="py-2">
              <Button
                className="fullwidth-button has-text-weight-semibold"
                color="danger"
                onClick={handleDeleteEvent}
              >
                <Level breakpoint="mobile" >
                  <Level.Item align="left">
                    <Icon >
                      <FontAwesomeIcon icon={faTrash} />
                    </Icon>
                  </Level.Item>
                  <Level.Item>
                    <span>Excluir</span>
                  </Level.Item>
                </Level>
              </Button>
            </Column>

          </Column.Group>
        }

        {(editEvent || registerPayment) &&
          <Button.Group align="centered">

            <Button
              className="has-text-weight-semibold"
              color="success"
              onClick={editEvent ? handleEditEvent : handleRegisterPayment}
            >
              <span>{editEvent ? "Atualizar" : "Cadastrar"}</span>
            </Button>

            <Button
              className="has-text-weight-semibold ml-5"
              color="danger"
              onClick={e => { setEventClickInfo(null) }}
            >
              <span>Cancelar</span>
            </Button>

          </Button.Group>
        }

      </Components.CardWithOverlay>
    )
  },

  NewAttendance: (props) => {

    const [description, setDescription] = useState("")
    const [price, setPrice] = useState("R$ ")
    const [patient, setPatient] = useState("")
    const [patientId, setPatientId] = useState("")
    const [newAttendanceDay, setNewAttendanceDay] = useState(new Date(Date.now()))
    const [newAttendanceDayError, setNewAttendanceDayError] = useState(null)
    const [newAttendanceTime, setNewAttendanceTime] = useState(DateTime.fromJSDate(new Date(Date.now())).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newAttendanceTimeError, setNewAttendanceTimeError] = useState(null)

    let history = useHistory()

    const setFormChanged = props.setFormChanged
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin
    const setObjectToEdit = props.setObjectToEdit

    const mandatoryFields = [
      patientId,
      newAttendanceDay,
      newAttendanceTime,
    ]

    const errorList = [
      newAttendanceDayError,
      newAttendanceTimeError,
    ]

    const handleSubmit = async (event) => {
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: null,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: AttendancesService.newAttendance,
        params: {
          patientId: patientId,
          attendanceDateTime: new Date(`${DateTime.fromJSDate(newAttendanceDay).toISODate()}T${newAttendanceTime}`),
          description: description,
          price: methods.priceToNumber(price)
        },
        onSuccessText: "Atendimento cadastrado. Iniciando atendimento...",
        onErrorText: "Não foi possível cadastrar o atendimento. Tente novamente",
        onSuccessCallback: (response) => {
          setObjectToEdit(response.data)
          history.push("/app/edit-attendance")
        },
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }

      methods.handleSubmit(submitInfo)
    }

    return (
      <Fragment>
        <Column.Group multiline>

          <Column className="field-column mt-4 mb-3">
            <Column.Group multiline >

              <Column className="field-column date-input">
                <Label>Data:</Label>
                <DatePicker
                  selected={newAttendanceDay}
                  onChange={(date) => { setNewAttendanceDay(date); setNewAttendanceDayError(null) }}
                  customInput={
                    <Components.DatePickerInput
                      icon={faCalendar}
                      dateError={newAttendanceDayError}
                      setDateError={setNewAttendanceDayError}
                    />}
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                  strictParsing
                />
              </Column>

              <Column narrow className="field-column" >
                <Label className="label-2px-mt" >Horário:</Label>
                <Components.TimeInput
                  time={newAttendanceTime}
                  setTime={setNewAttendanceTime}
                  timeError={newAttendanceTimeError}
                  setTimeError={setNewAttendanceTimeError}
                />
              </Column>

            </Column.Group>
          </Column>

          <Column size={12} className="field-column" >
            <Label>Paciente:<span className="has-text-danger"> *</span></Label>
            <Components.FieldWithSearch
              getValue={setPatient}
              placeholder="Digite parte do nome do paciente:"
              resultId={patientId}
              setResultId={setPatientId}
              searchAndSetDropdownItensCallback={methods.searchAndSetPatientsDropdown}
            />
          </Column>

          <Column size={12} className="p-0" >
            <Components.GenericInput
              size={12}
              label="Descrição:"
              placeholder="Pequena descrição do atendimento"
              elementName="attendance-description"
              value={description}
              setValue={setDescription}
              setFormChanged={setFormChanged}
            />
          </Column>

          <Column size={12} className="p-0" >
            <Components.GenericInput
              size={12}
              label="Preço:"
              placeholder="R$ 0,00"
              elementName="price"
              value={price}
              setValue={setPrice}
              maskAndSetValue={methods.maskAndSetPrice}
              setFormChanged={setFormChanged}
            />
          </Column>

        </Column.Group>

        <Button.Group align="centered">
          <Button
            color="info"
            size="medium"
            className="has-text-weight-semibold"
            onClick={e => {
              handleSubmit(e)
            }} >
            <span>Iniciar Atendimento</span>
          </Button>
        </Button.Group>

      </Fragment>
    )
  },

  RegisterPaymentOverlay: (props) => {

    const [price, setPrice] = useState(props.paymentInfoToRegister.price)
    const [paid, setPaid] = useState(props.paymentInfoToRegister.paid)
    const [debt, setDebt] = useState(props.paymentInfoToRegister.debt)
    const [id, setId] = useState(props.paymentInfoToRegister.id)

    const [paymentValue, setPaymentValue] = useState(methods.numberToPrice(methods.priceToNumber(price) - methods.priceToNumber(paid)))
    const [paymentMethod, setPaymentMethod] = useState("Dinheiro")
    const [paymentOtherMethodDescription, setPaymentOtherMethodDescription] = useState("")
    const [paymentDate, setPaymentDate] = useState(new Date(Date.now()))
    const [paymentDateError, setPaymentDateError] = useState(null)

    const history = useHistory()

    const setPaymentInfoToRegister = props.setPaymentInfoToRegister
    const paymentType = props.paymentType
    const setFormChanged = props.setFormChanged
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin
    const setObjectToEdit = props.setObjectToEdit

    const mandatoryFields = [
      paymentValue,
      paymentMethod,
      paymentDate,
    ]

    const errorList = [
      paymentDateError,
    ]

    const handleRegisterPayment = async (event) => {
      let hasPaymentValue = methods.priceToNumber(paymentValue) > 0
      if (!hasPaymentValue) {
        setNotification({
          success: false,
          title: "Atenção",
          text: "O valor do pagamento deve ser maior que zero.",
          callback: (() => {
            setNotification(false)
          }),
        })
      } else {
        let submitInfo = {
          event: event,
          setNotification: setNotification,
          setLoading: setLoading,
          setOpenComponent: setPaymentInfoToRegister,
          mandatoryFields: mandatoryFields,
          errorList: errorList,
          ServiceMethod: props.ServiceMethod,
          params: {
            id: id,
            value: methods.priceToNumber(paymentValue),
            method: paymentMethod,
            otherMethodDescription: paymentMethod == "Outro" ? paymentOtherMethodDescription : undefined,
            date: paymentDate,
          },
          onSuccessText: "Pagamento cadastrado.",
          onErrorText: "Não foi possível cadastrar o pagamento. Tente novamente",
          onSuccessCallback: () => {

/*             getAttendancesOfOnePackage(attendancePackage._id)
            .then((response) => {
              let attendancePackageToPass = { ...response.attendancePackage, attendancesArray: response.attendancesArray }
              setObjectToEdit(attendancePackageToPass)
              history.push("/app/edit-attendance-package")
            }) */

            let anotherSubmitInfo = {
              event: null,
              setNotification: setNotification,
              setLoading: setLoading,
              setOpenComponent: null,
              mandatoryFields: [],
              errorList: [],
              ServiceMethod: paymentType == "atendimento" ? AttendancesService.getAttendanceById
                           : paymentType == "pacote de atendimento" ? AttendancePackagesService.getAttendancesOfOnePackage
                           : null,
              params: paymentType == "atendimento" ? { id: id }
                    : paymentType == "pacote de atendimento" ? { attendancePackageId: id }
                    : null,
              onErrorText: "Não foi possível carregar as informações. Tente novamente",
              noSuccessNotification: true,
              onSuccessCallback: (response) => {
                if (paymentType == "atendimento") {setObjectToEdit(response.data);history.push("/app/edit-attendance")}
                else if (paymentType == "pacote de atendimento") {
                  let attendancePackageToPass = { ...response.data.attendancePackage, attendancesArray: response.data.attendancesArray }
                  setObjectToEdit(attendancePackageToPass)
                  history.push("/app/edit-attendance-package")}
                setPaymentInfoToRegister(null)
              },
              onErrorCallback: null,
              setRedirectToLogin: setRedirectToLogin,
            }
            methods.handleSubmit(anotherSubmitInfo)
          },
          onErrorCallback: null,
          setRedirectToLogin: setRedirectToLogin,
        }

        methods.handleSubmit(submitInfo)
      }
    }

    return (
      <Components.CardWithOverlay
        headerClassName="has-background-success"
        title="Pagamento"
        titleColorClassName="has-text-white"
        icon={faMoneyCheckAlt}
        setOpenCard={setPaymentInfoToRegister}
      >

        <Column.Group multiline>

          <Column size={12} className="field-column mt-4" >
            <Label>Preço: <span className="has-text-weight-normal">{price}</span></Label>
          </Column>

          <Column size={12} className="field-column" >
            <Label className={methods.priceToNumber(paid) > 0 ? "has-text-success" : ""}>Total pago: <span className="has-text-weight-normal">{paid}</span></Label>
          </Column>

          <Column size={12} className="field-column" >
            <Label className="has-text-danger">Em aberto: <span className="has-text-weight-normal">{debt}</span></Label>
          </Column>

          <Column size={12} className="p-0" >

            <hr className="form-divider mx-2" />

            <Components.GenericInput
              size={12}
              label="Valor do pagamento:"
              placeholder="R$ "
              elementName="paymentValue"
              value={paymentValue}
              setValue={setPaymentValue}
              maskAndSetValue={methods.maskAndSetPrice}
              setFormChanged={setFormChanged}
            />
          </Column>

          <Column size={12} className="field-column" >
            <Field>
              <Label>Forma de pagamento:</Label>
              <Control expanded>
                <Select.Container fullwidth>
                  <Select
                    type="text"
                    value={paymentMethod}
                    onChange={e => { setPaymentMethod(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                    name="paymentMethod"
                  >
                    <Select.Option>Dinheiro</Select.Option>
                    <Select.Option>Cartão de débito</Select.Option>
                    <Select.Option>Cartão de crédito</Select.Option>
                    <Select.Option>Pix</Select.Option>
                    <Select.Option>Boleto bancário</Select.Option>
                    <Select.Option>Outro</Select.Option>
                  </Select>
                </Select.Container>
              </Control>
            </Field>
          </Column>

          {paymentMethod == "Outro" &&
            <Column size={12} className="p-0" >
              <Components.GenericInput
                size={12}
                label="Outro:"
                placeholder="Descreva a forma de pagamento:"
                elementName="otherMethod"
                value={paymentOtherMethodDescription}
                setValue={setPaymentOtherMethodDescription}
                setFormChanged={setFormChanged}
              />
            </Column>
          }

          <Column size={6} className="field-column" >
            <Label>Data do pagamento:</Label>
            <DatePicker
              selected={paymentDate}
              onChange={(date) => { setPaymentDate(date); setPaymentDateError(null) }}
              customInput={
                <Components.DatePickerInput
                  icon={faCalendar}
                  dateError={paymentDateError}
                  setDateError={setPaymentDateError}
                />}
              dateFormat="dd/MM/yyyy"
              locale="pt-BR"
              strictParsing
            />
          </Column>

        </Column.Group>

        <Button.Group align="centered">

          <Button
            className="has-text-weight-semibold"
            color="success"
            onClick={handleRegisterPayment}
          >
            <span>Cadastrar</span>
          </Button>

          <Button
            className="has-text-weight-semibold ml-5"
            color="danger"
            onClick={e => { setPaymentInfoToRegister(false) }}
          >
            <span>Cancelar</span>
          </Button>

        </Button.Group>

      </Components.CardWithOverlay>
    )
  },

  PaymentsOverlay: (props) => {
    const { attendance, price, paid, debt, setOpenPayments, eventClickInfo, attendancePackage, setEventClickInfo, getAndSetEvents, setFormChanged, setNotification, setLoading, setRedirectToLogin, setObjectToEdit } = props
    const payments = attendance ? attendance.payments : eventClickInfo ? eventClickInfo.payments : attendancePackage.payments
    const id = attendance ? attendance._id : eventClickInfo ? eventClickInfo.id : attendancePackage._id

    let history = useHistory()

    const onDelete = (event, paymentId) => {
      setNotification({
        withCancel: true,
        success: false,
        title: "Excluir Pagamento",
        text: "Tem certeza que deseja excluir o pagamento?",
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setLoading(true)
          let submitInfo = {
            event: event,
            setNotification: setNotification,
            setLoading: setLoading,
            setOpenComponent: null,
            mandatoryFields: [],
            errorList: [],
            ServiceMethod: attendance || eventClickInfo ? AttendancesService.deleteAttendancePayment : attendancePackage ? AttendancePackagesService.deleteAttendancePackagePayment : null,
            params: {
              id: id,
              paymentId: paymentId,
            },
            onSuccessText: "Pagamento excluído.",
            onErrorText: "Não foi possível excluir o pagamento. Tente novamente",
            onSuccessCallback: () => {
              if (getAndSetEvents) {
                getAndSetEvents()
                setEventClickInfo(null)
                setOpenPayments(null)
              } else {
                let submitInfo = {
                  event: null,
                  setNotification: setNotification,
                  setLoading: setLoading,
                  setOpenComponent: null,
                  mandatoryFields: [],
                  errorList: [],
                  ServiceMethod: attendance || eventClickInfo ? AttendancesService.getAttendanceById : attendancePackage ? AttendancePackagesService.getAttendancesOfOnePackage : null,
                  params: attendance || eventClickInfo ? { id: attendance._id } : attendancePackage ? { attendancePackageId: id } : null,
                  noSuccessNotification: true,
                  onSuccessText: null,
                  onErrorText: null,
                  onSuccessCallback: (response) => {
                    if (attendance || eventClickInfo) {
                      setObjectToEdit(response.data);history.push("/app/edit-attendance")
                    }
                    else if (attendancePackage) {
                      let attendancePackageToPass = { ...response.data.attendancePackage, attendancesArray: response.data.attendancesArray }
                      setObjectToEdit(attendancePackageToPass)
                      history.push("/app/edit-attendance-package")}
                  },
                  onErrorCallback: null,
                  setRedirectToLogin: setRedirectToLogin,
                }
                methods.handleSubmit(submitInfo)
              }
            },
            onErrorCallback: null,
            setRedirectToLogin: setRedirectToLogin,
          }
          methods.handleSubmit(submitInfo)
        })
      })
    }

    return (
      <Components.CardWithOverlay
        headerClassName="has-background-success"
        title="Pagamentos"
        titleColorClassName="has-text-white"
        icon={faMoneyCheckAlt}
        setOpenCard={setOpenPayments}
      >

        <Column.Group multiline>

          <Column size={12} className="field-column mt-4" >
            <Label>Preço: <span className="has-text-weight-normal">{price || eventClickInfo.price}</span></Label>
          </Column>

          <Column size={12} className="field-column" >
            <Label className={methods.priceToNumber(paid || eventClickInfo.paid) > 0 ? "has-text-success" : ""}>Total pago: <span className="has-text-weight-normal">{paid || eventClickInfo.paid}</span></Label>
          </Column>

          <Column size={12} className="field-column" >
            <Label className="has-text-danger">Em aberto: <span className="has-text-weight-normal">{debt || eventClickInfo.debt}</span></Label>
          </Column>

          {payments &&
            <Column size={12}>
              <Table fullwidth hoverable narrow style={{ lineHeight: "1", fontSize: "0.875rem" }}>

                <Table.Head>
                  <Table.Row style={{ lineHeight: "1" }}>
                    <Table.Heading textAlign="centered" className="table-column-medium">
                      Valor
                    </Table.Heading>
                    <Table.Heading>
                      Forma
                    </Table.Heading>
                    <Table.Heading>
                      Data
                    </Table.Heading>
                    <Table.Heading textAlign="centered" className="table-column-small" >
                      Excluir
                    </Table.Heading>
                  </Table.Row>
                </Table.Head>

                <Table.Body>
                  {payments.map((payment, key) => (
                    <Table.Row key={key} style={{ lineHeight: "1" }}>
                      <Table.Cell textAlign="right">
                        {methods.numberToPrice(payment.value)}
                      </Table.Cell>
                      <Table.Cell>
                        {payment.otherMethodDescription ? payment.otherMethodDescription : payment.method}
                      </Table.Cell>
                      <Table.Cell>
                        {methods.dateToString(payment.date)}
                      </Table.Cell>
                      <Table.Cell textAlign="centered">
                        <Icon className="cursor-pointer" color="danger" onClick={event => onDelete(event, payment._id)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Icon>
                      </Table.Cell>
                    </Table.Row>
                  ))}                
                </Table.Body>

              </Table>
            </Column>
          }
          

        </Column.Group>        

      </Components.CardWithOverlay>
    )
  },

  AttendanceGeneralInfo: (props) => {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
    const [openPayments, setOpenPayments] = useState(false)

    const attendance = props.attendance
    const setAttendance = props.setAttendance
    const attendanceDay = props.attendanceDay
    const setAttendanceDay = props.setAttendanceDay
    const attendanceDayError = props.attendanceDayError
    const setAttendanceDayError = props.setAttendanceDayError
    const attendanceTime = props.attendanceTime
    const setAttendanceTime = props.setAttendanceTime
    const attendanceTimeError = props.attendanceTimeError
    const setAttendanceTimeError = props.setAttendanceTimeError
    const patient = props.patient
    const setPatient = props.setPatient
    const patientId = props.patientId
    const setPatientId = props.setPatientId
    const description = props.description
    const setDescription = props.setDescription
    const price = props.price
    const setPrice = props.setPrice

    const setObjectToEdit = props.setObjectToEdit
    const setPaymentInfoToRegister = props.setPaymentInfoToRegister
    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    let history = useHistory()

    let paid = 0
    if (props.attendance.payments) {
      props.attendance.payments.map((payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    paid = methods.numberToPrice(paid.toFixed(2))

    let debt = price != 0 ? methods.priceToNumber(price) - methods.priceToNumber(paid) : 0
    debt = methods.numberToPrice(debt.toFixed(2))

    useEffect(() => {
      if (price == "" || price == 0 || !price) {
        setPrice("R$ 0,00")
      }
    }, [])

    const mandatoryFields = [
      patientId,
      attendanceDay,
      attendanceTime,
    ]

    const errorList = [
      attendanceDayError,
      attendanceTimeError,
    ]

    const onDelete = (event) => {
      setNotification({
        withCancel: true,
        success: false,
        title: `Excluir Atendimento`,
        text: `Tem certeza que deseja excluir o Atendimento?"`,
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setLoading(true)
          let submitInfo = {
            event: event,
            setNotification: setNotification,
            setLoading: setLoading,
            setOpenComponent: null,
            mandatoryFields: [],
            errorList: [],
            ServiceMethod: AttendancesService.deleteAttendance,
            params: {
              attendanceId: attendance._id,
            },
            onSuccessText: "Atendimento excluído.",
            onErrorText: "Não foi possível excluir o Atendimento. Tente novamente",
            onSuccessCallback: (response) => {
              history.push("/app/attendance")
            },
            onErrorCallback: null,
            setRedirectToLogin: setRedirectToLogin,
          }
          methods.handleSubmit(submitInfo)
        })
      })
    }

    const getAttendancesOfOnePackage = async (attendancePackageId) => {
      try {
        setLoading(true)
        return await AttendancePackagesService.getAttendancesOfOnePackage({ attendancePackageId: attendancePackageId})
        .then( (response) => {
          return response.data
          setLoading(false)
        })
      } catch (error) {
        console.log(error)
        try {
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setLoading(false)
            setNotification({
              success: false,
              text: error.response.data,
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {          
          setLoading(false)
          setNotification({
            success: false,
            text: "Não foi possível carregar as informações. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      }
    }

    return (
      <Fragment>
        <Column.Group multiline>

          <Column className="field-column mt-4 mb-3">
            <Column.Group multiline >

              <Column className="field-column date-input">
                {user._id != props.attendance.professionalId &&
                  <Label>Data: <span className="has-text-weight-normal mr-2">{methods.dateToString(attendanceDay)}</span></Label>
                  ||
                  <Fragment>
                    <Label>Data:</Label>
                    <DatePicker
                      selected={attendanceDay}
                      onChange={(date) => { setAttendanceDay(date); setAttendanceDayError(null) }}
                      customInput={
                        <Components.DatePickerInput
                          disableSelect={user._id != props.attendance.professionalId}
                          icon={faCalendar}
                          dateError={attendanceDayError}
                          setDateError={setAttendanceDayError}
                          setHasUnsavedChanges={setHasUnsavedChanges}
                        />}
                      dateFormat="dd/MM/yyyy"
                      locale="pt-BR"
                      strictParsing
                    />
                  </Fragment>
                }
              </Column>

              <Column narrow className="field-column" >
                {user._id != props.attendance.professionalId &&
                  <Label>Horário: <span className="has-text-weight-normal mr-2">{attendanceTime}</span></Label>
                  ||
                  <Fragment>
                    <Label className="label-2px-mt" >Horário:</Label>
                    <Components.TimeInput
                      disableSelect={user._id != props.attendance.professionalId}
                      time={attendanceTime}
                      setTime={setAttendanceTime}
                      timeError={attendanceTimeError}
                      setTimeError={setAttendanceTimeError}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                    />
                  </Fragment>
                }
              </Column>

            </Column.Group>
          </Column>

          <Column size={12} className="field-column" >
            {user._id != props.attendance.professionalId &&
              <Label>Paciente: <span className="has-text-weight-normal mr-2">{patient}</span></Label>
              ||
              <Fragment>
                <Label>Paciente:{user._id == props.attendance.professionalId && <span className="has-text-danger"> *</span>}</Label>
                <Components.FieldWithSearch
                  disableSelect={user._id != props.attendance.professionalId}
                  value={patient}
                  getValue={setPatient}
                  placeholder="Digite parte do nome do paciente:"
                  resultId={patientId}
                  setResultId={setPatientId}
                  searchAndSetDropdownItensCallback={methods.searchAndSetPatientsDropdown}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              </Fragment>
            }
          </Column>

          <Column size={12} className={user._id != props.attendance.professionalId ? "field-column" : "p-0"} >
            {user._id != props.attendance.professionalId &&
              <Label>Descrição: <span className="has-text-weight-normal mr-2">{description}</span></Label>
              ||
              <Components.GenericInput
                disableSelect={user._id != props.attendance.professionalId}
                size={12}
                label="Descrição:"
                placeholder="Pequena descrição do atendimento"
                elementName="attendance-description"
                value={description}
                setValue={setDescription}
                setFormChanged={setFormChanged}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            }
          </Column>

          {!attendance.attendancePackageId ?
            <Fragment>
              <Column size={12} className={user._id != props.attendance.professionalId ? "field-column" : "p-0"} >
                {user._id != props.attendance.professionalId &&
                  <Label>Preço: <span className="has-text-weight-normal mr-2">{price}</span></Label>
                  ||
                  <Components.GenericInput
                    disableSelect={user._id != props.attendance.professionalId}
                    size={12}
                    label="Preço:"
                    placeholder="R$ 0,00"
                    elementName="price"
                    value={price}
                    setValue={setPrice}
                    maskAndSetValue={methods.maskAndSetPrice}
                    setFormChanged={setFormChanged}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                }
              </Column>

              {methods.priceToNumber(paid) > 0 &&
                <Column size={12} className="field-column" >
                  <span className={paid <= 0 ? "has-text-weight-bold is-size-6" : "has-text-weight-bold has-text-success is-size-6"} >Total Pago: <span className="has-text-weight-normal mr-2" >{paid}</span></span>
                  <Button color="info"
                    style={{ height: "23px", border: "1px solid black", marginLeft: "5px" }}
                    onClick={() => {
                      setOpenPayments(attendance)
                    }}
                  >
                    <Icon>
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </Icon>
                    <span className="ml-1">Ver Pagamentos</span>
                  </Button>
                </Column>
              }

              {(price != 0 && methods.priceToNumber(debt) > 0) &&
                <Fragment>
                  <Column size={12} className="field-column" >
                    <span className="has-text-danger has-text-weight-bold is-size-6" >Em aberto: <span className="has-text-weight-normal mr-2" >{debt}</span></span>
                    <Button
                      style={{ height: "23px", border: "1px solid black", marginLeft: "5px" }}
                      color="success"
                      onClick={e => {
                        setPaymentInfoToRegister({
                          price: price,
                          paid: paid,
                          debt: debt,
                          id: props.attendance._id
                        })
                      }}
                    >
                      <Icon >
                        <FontAwesomeIcon icon={faDollarSign} />
                      </Icon>
                      <span>Cadastrar pgto.</span>
                    </Button>
                  </Column>
                </Fragment>
              }
            </Fragment>
            :
            <Column size={12}>
              <Label>Pacote:
                <a className="has-text-weight-bold"
                  onClick={e => {
                    getAttendancesOfOnePackage(attendance.attendancePackageId)
                    .then((response) => {
                      let attendancePackageToPass = { ...response.attendancePackage, attendancesArray: response.attendancesArray }
                      setObjectToEdit(attendancePackageToPass)
                      history.push("/app/edit-attendance-package")
                      props.setFocusedItem("attendance-package")
                    })
                  }}
                >{" " + attendance.attendancePackage + " (clique para ver)"}</a>
              </Label>
              <Label className="has-text-primary"><span className="has-background-primary has-text-white mr-2 event-icon">$</span>O pagamento deve ser verificado no Pacote de Atendimentos</Label>
            </Column>
          }

          <Column size={12}>
            <Button.Group align="right">
              <Button
                color="danger"
                outlined
                onClick={onDelete}
              >
                <Icon >
                  <FontAwesomeIcon icon={faTrash} />
                </Icon>
                <span>Excluir atendimento</span>
              </Button>
            </Button.Group>
          </Column>

        </Column.Group>

        {openPayments &&
          <Components.PaymentsOverlay
            attendance={attendance}
            setAttendance={setAttendance}
            price={price}
            paid={paid}
            debt={debt}
            setOpenPayments={setOpenPayments}
            setObjectToEdit={setObjectToEdit}
            setFormChanged={setFormChanged}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </Fragment>
    )
  },

  CustomTextEditorButton: (props) => {
    const label = props.label
    const onClick = props.onClick

    return (
      <button className="text-editor-button"
        onClick={onClick}
      >
        {label}
      </button>

    )
  },

  TextEditor: (props) => {
    const [{ plugins, Toolbar }] = useState(() => {
      const staticToolbarPlugin = createStaticToolbarPlugin()
      const { Toolbar } = staticToolbarPlugin;
      const textAlignmentPlugin = createTextAlignmentPlugin()
      const plugins = [staticToolbarPlugin, textAlignmentPlugin];
      return {
        plugins,
        Toolbar
      }
    })

    const editorState = props.editorState
    const setEditorState = props.setEditorState
    const attendance = props.attendance

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const onChange = (editorState) => {
      setEditorState(editorState)
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
    }

    const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command)
      if (newState) {
        onChange(newState)
        return 'handled'
      }
      return 'not-handled'
    }

    const _onHeaderClick = () => {
      onChange(RichUtils.toggleBlockType(editorState, 'header-three'));
    }

    return (
      <Fragment>
        <div className="editor">
          <Editor
            editorState={editorState}
            plugins={plugins}
            readOnly={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
            placeholder="Digite aqui..."
            handleKeyCommand={handleKeyCommand}
            onChange={onChange}
            customStyleMap={{
              SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
              SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' }
            }}
          />
          <Toolbar>
            {(externalProps) => (
              <div>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <Components.CustomTextEditorButton label="Título" onClick={_onHeaderClick} />
              </div>
            )}
          </Toolbar>
        </div>
      </Fragment>

    )
  },

  RegisterCard: (props) => {
    const title = props.title
    const attendance = props.attendance
    const editorState = props.editorState
    const setEditorState = props.setEditorState
    const documentTemplates = props.documentTemplates
    const getDocumentTemplates = props.getDocumentTemplates
    const openSelectDocumentTemplates = props.openSelectDocumentTemplates
    const setOpenSelectDocumentTemplates = props.setOpenSelectDocumentTemplates
    const editAttendanceLoaded = props.editAttendanceLoaded
    const setOpenNewDocumentTemplate = props.setOpenNewDocumentTemplate

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const elementRef = props.elementRef

    useEffect(() => {
      if (editAttendanceLoaded &&  editAttendanceLoaded.current)
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }, [])

    return (
      <Card ref={elementRef} id="0" className="mb-5 has-border" style={{ maxWidth: "200%", marginLeft: "-0.75rem", width: "calc(100% + 1.5rem)" }} >

        <Card.Header className="has-background-light">
          <Card.Header.Title className="is-size-5 ml-4 is-block" >
            <Column.Group multiline vcentered breakpoint="mobile" className="m-0">
              <Column className="p-1">
                <span className="pr-3">{title}</span>
              </Column>
              <Column narrow className="p-1">
                <Button.Group className="mb-0">
                  <Button
                    color="primary"
                    outlined
                    size="normal"
                    className="mb-0"
                    onClick={e => {setOpenSelectDocumentTemplates(title)}} >
                    <Icon size="small" align="left" className="is-hidden-touch">
                      <FontAwesomeIcon icon={faFileLines} />
                    </Icon>
                    <FontAwesomeIcon icon={faFileLines} className="is-hidden-desktop" />
                    <span className="is-hidden-touch">Importar modelo</span>
                  </Button>
                  <Button
                    color="info"
                    outlined
                    size="normal"
                    className="mb-0"
                    onClick={e => {setOpenNewDocumentTemplate({ title: title, documentTemplate: editorState })}} >
                    <Icon size="small" align="left" className="is-hidden-touch">
                      <FontAwesomeIcon icon={faCloudUpload} />
                    </Icon>
                    <FontAwesomeIcon icon={faCloudUpload} className="is-hidden-desktop" />
                    <span className="is-hidden-touch">Salvar modelo</span>
                  </Button>
                </Button.Group>
              </Column>
            </Column.Group>
          </Card.Header.Title>
          
          {JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId &&
            <Card.Header.Icon>
              <Delete size="medium" onClick={e => {
                setNotification({
                  withCancel: true,
                  success: false,
                  title: `Excluir ${title}`,
                  text: `Tem certeza que deseja excluir ${title == "Anamnese" ? "a" : "o"} ${title}?`,
                  cancelCallback: ( () => {
                    setNotification(false)
                  }),
                  confirmCallback: ( () => {
                    setNotification(false)
                    setEditorState(null)
                  })
                })
              }} />
            </Card.Header.Icon>            
          }
        </Card.Header>

        <Card.Content className="p-0">
          <Components.TextEditor
            attendance={attendance}
            editorState={editorState}
            setEditorState={setEditorState}
            setFormChanged={setFormChanged}
            setHasUnsavedChanges={setHasUnsavedChanges}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        </Card.Content>

        {openSelectDocumentTemplates &&
          <Components.SelectDocumentTemplates
            documentType={title}
            openSelectDocumentTemplates={openSelectDocumentTemplates}
            setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
            documentTemplates={documentTemplates}
            setDocument={setEditorState}
            setFormChanged={setFormChanged}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </Card>
    )
  },

  QuestionAlternative: (props) => {
    const attendance = props.attendance
    const alternative = props.alternative
    const alternativeIndex = props.alternativeIndex

    const question = props.question
    const questionIndex = props.questionIndex

    const questionnaireIndex = props.questionnaireIndex

    const setQuestionnaires = props.setQuestionnaires

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const onRadioChange = (event) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setQuestionnaires((questionnaires) => (
        questionnaires.map((questionnaire, index) => {
          if (index == questionnaireIndex) {
            questionnaire.questions = questionnaire.questions.map((question, index) => {
              if (index == questionIndex) {
                question.alternatives = question.alternatives.map((alternative, index) => {
                  if (index == alternativeIndex) {
                    alternative.chosen = true
                  } else {
                    alternative.chosen = false
                  }
                  return alternative
                })
              }
              return question
            })
          }
          return questionnaire
        })
      ))
    }

    const onCheckboxChange = (checked) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setQuestionnaires((questionnaires) => (
        questionnaires.map((questionnaire, index) => {
          if (index == questionnaireIndex) {
            questionnaire.questions = questionnaire.questions.map((question, index) => {
              if (index == questionIndex) {
                question.alternatives = question.alternatives.map((alternative, index) => {
                  if (index == alternativeIndex) {
                    alternative.chosen = checked
                  }
                  return alternative
                })
              }
              return question
            })
          }
          return questionnaire
        })
      ))
    }

    const onAlternativeChange = (value) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setQuestionnaires((questionnaires) => (
        questionnaires.map((questionnaire, index) => {
          if (index == questionnaireIndex) {
            questionnaire.questions = questionnaire.questions.map((question, index) => {
              if (index == questionIndex) {
                question.alternatives = question.alternatives.map((alternative, index) => {
                  if (index == alternativeIndex) {
                    alternative.alternative = value
                  }
                  return alternative
                })
              }
              return question
            })
          }
          return questionnaire
        })
      ))
    }

    const handleDeleteAlternative = () => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setNotification({
        withCancel: true,
        success: false,
        title: "Excluir alternativa",
        text: "Tem certeza que deseja excluir a alternativa?",
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setQuestionnaires((questionnaires) => (
            questionnaires.map((questionnaire, index) => {
              if (index == questionnaireIndex) {
                questionnaire.questions = questionnaire.questions.map((question, index) => {
                  if (index == questionIndex) {
                    question.alternatives = question.alternatives.filter((alternative, index) => {
                      return index !== alternativeIndex
                    })
                  }
                  return question
                })
              }
              return questionnaire
            })
          ))
        })
      })
    }

    return (
      <Label>
        <Column.Group vcentered breakpoint="mobile" className="m-0">
          <Column narrow className="py-0 pl-3" style={{ paddingRight: "7px" }} >
            {question.answerType == "Múltipla" &&
              <Checkbox
                disabled={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
                checked={alternative.chosen}
                onChange={(event) => { onCheckboxChange(event.target.checked) }}
              />
              ||
              <Radio
                disabled={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
                value={alternativeIndex}
                checked={alternative.chosen}
                onChange={onRadioChange}
              />
            }
          </Column>
          <Column className="p-0">
            <Components.GenericInput
              disableSelect={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
              size={12}
              inputSize={null}
              label={null}
              placeholder="Descreva a alternativa..."
              elementName={null}
              value={alternative.alternative}
              setValue={onAlternativeChange}
              maskAndSetValue={null}
              setFormChanged={setFormChanged}
            />
          </Column>
          {JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId &&
            <Column narrow className="pl-0">
              <Delete onClick={handleDeleteAlternative} />
            </Column>
          }
        </Column.Group>
      </Label>
    )
  },

  Question: (props) => {
    const [typeIcon, setTypeIcon] = useState(faAlignLeft)

    const attendance = props.attendance
    const question = props.question
    const questionIndex = props.questionIndex

    const questionnaireIndex = props.questionnaireIndex

    const setQuestionnaires = props.setQuestionnaires

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const onTypeChange = (value) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setQuestionnaires((questionnaires) => (
        questionnaires.map((questionnaire, index) => {
          if (index == questionnaireIndex) {
            questionnaire.questions = questionnaire.questions.map((question, index) => {
              if (index == questionIndex) {
                question.answerType = value
                if (value == "Objetiva") {
                  question.alternatives = question.alternatives.map((alternative, index) => {
                    if (index == 0) {
                      alternative.chosen = true
                    } else {
                      alternative.chosen = false
                    }
                    return alternative
                  })
                }
              }
              return question
            })
          }
          return questionnaire
        })
      ))
    }

    const onCheckboxChange = (checked) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setQuestionnaires((questionnaires) => (
        questionnaires.map((questionnaire, index) => {
          if (index == questionnaireIndex) {
            questionnaire.questions = questionnaire.questions.map((question, index) => {
              if (index == questionIndex) {
                question.alternatives = question.alternatives.map((alternative, index) => {
                  if (index == alternativeIndex) {
                    alternative.chosen = checked
                  }
                  return alternative
                })
              }
              return question
            })
          }
          return questionnaire
        })
      ))
    }

    const onQuestionChange = (value) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setQuestionnaires((questionnaires) => (
        questionnaires.map((questionnaire, index) => {
          if (index == questionnaireIndex) {
            questionnaire.questions = questionnaire.questions.map((question, index) => {
              if (index == questionIndex) {
                question.question = value
              }
              return question
            })
          }
          return questionnaire
        })
      ))
    }

    const onAnswerChange = (value) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setQuestionnaires((questionnaires) => (
        questionnaires.map((questionnaire, index) => {
          if (index == questionnaireIndex) {
            questionnaire.questions = questionnaire.questions.map((question, index) => {
              if (index == questionIndex) {
                question.answer = value
              }
              return question
            })
          }
          return questionnaire
        })
      ))
    }

    const handleDeleteQuestion = () => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setNotification({
        withCancel: true,
        success: false,
        title: "Excluir questão",
        text: "Tem certeza que deseja excluir a questão?",
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setQuestionnaires((questionnaires) => (
            questionnaires.map((questionnaire, index) => {
              if (index == questionnaireIndex) {
                questionnaire.questions = questionnaire.questions.filter((question, index) => {
                  return index !== questionIndex
                })
              }
              return questionnaire
            })
          ))
        })
      })
    }

    useEffect(() => {
      if (question.answerType == "Subjetiva") {
        setTypeIcon(faAlignLeft)
      } else if (question.answerType == "Objetiva") {
        setTypeIcon(faCircleDot)
      } else if (question.answerType == "Múltipla") {
        setTypeIcon(faSquareCheck)
      }
    }, [])

    return (
      <Fragment>
        <Column.Group vcentered breakpoint="mobile" className="mx-0 mt-0 mb-3">
          <Column narrow className="py-0 pr-0">
            <Label>{`Questão ${questionIndex + 1}`}</Label>
          </Column>
          <Column className="py-0">
            {JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId &&
              <Dropdown>
                <Dropdown.Trigger>
                  <Button size="small">
                    <Icon size="small">
                      <FontAwesomeIcon icon={typeIcon} />
                    </Icon>
                    <span>{question.answerType}</span>
                    <Icon>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Icon>
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Content>
                    <Dropdown.Item onClick={() => { onTypeChange("Subjetiva"); setTypeIcon(faAlignLeft) }}>
                      <Icon>
                        <FontAwesomeIcon icon={faAlignLeft} />
                      </Icon>
                      <span>Subjetiva</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => { onTypeChange("Objetiva"); setTypeIcon(faCircleDot) }}>
                      <Icon>
                        <FontAwesomeIcon icon={faCircleDot} />
                      </Icon>
                      <span>Objetiva</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => { onTypeChange("Múltipla"); setTypeIcon(faSquareCheck) }}>
                      <Icon>
                        <FontAwesomeIcon icon={faSquareCheck} />
                      </Icon>
                      <span>Múltipla</span>
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Menu>
              </Dropdown>
              ||
              <Button>{question.answerType}</Button>
            }
          </Column>
          {JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId &&
            <Column narrow className="py-0">
              <Delete onClick={handleDeleteQuestion} />
            </Column>
          }
        </Column.Group>

        <Column.Group vcentered breakpoint="mobile" className="m-0">
          <Column narrow className="py-0 pl-2 pr-0">
            <Icon color="danger">
              <FontAwesomeIcon icon={faCircleQuestion} />
            </Icon>
          </Column>
          <Column className="p-0">
            <Components.GenericInput
              isTextarea
              rows={2}
              disableSelect={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
              size={12}
              inputSize={null}
              label={null}
              placeholder="Digite a questão..."
              elementName="question"
              value={question.question}
              setValue={onQuestionChange}
              maskAndSetValue={null}
              setFormChanged={setFormChanged}
            />
          </Column>
        </Column.Group>

        {question.answerType == "Subjetiva" &&
          <Column.Group vcentered breakpoint="mobile" className="m-0">
            <Column narrow className="py-0 pl-2 pr-0">
              <Icon color="success">
                <FontAwesomeIcon icon={faCircleCheck} />
              </Icon>
            </Column>
            <Column className="p-0">
              <Components.GenericInput
                isTextarea
                rows={2}
                disableSelect={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
                size={12}
                inputSize={null}
                label={null}
                placeholder="Digite a resposta..."
                elementName={null}
                value={question.answer}
                setValue={onAnswerChange}
                maskAndSetValue={null}
                setFormChanged={setFormChanged}
              />
            </Column>
          </Column.Group>
        }

        {(question.answerType == "Objetiva" || question.answerType == "Múltipla") &&
          <Fragment>
            {question.alternatives.map((alternative, index) => {
              return (
                <Components.QuestionAlternative
                  key={index}
                  attendance={attendance}
                  alternative={alternative}
                  alternativeIndex={index}
                  question={question}
                  questionIndex={questionIndex}
                  questionnaireIndex={questionnaireIndex}
                  setQuestionnaires={setQuestionnaires}
                  setFormChanged={setFormChanged}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              )
            })}
            {JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId &&
              <Button
                style={{ marginLeft: "2.5rem" }}
                className="mt-1"
                outlined
                size="small"
                color="success"
                onClick={(e) => {
                  setQuestionnaires((questionnaires) => (
                    questionnaires.map((questionnaire, index) => {
                      if (index == questionnaireIndex) {
                        questionnaire.questions.map((question, index) => {
                          if (index == questionIndex) {
                            question.alternatives = [
                              ...question.alternatives,
                              {
                                alternative: "",
                                chosen: false,
                              }
                            ]
                          }
                        })
                      }
                      return questionnaire
                    })
                  ))
                }}
              >
                <Icon align="left">
                  <FontAwesomeIcon icon={faPlus} />
                </Icon>
                <span>Adicionar alternativa</span>
              </Button>
            }           
          </Fragment>
        }

        <hr className="form-divider"></hr>
      </Fragment>
    )
  },

  QuestionnaireCard: (props) => {
    const title = props.title
    const attendance = props.attendance
    const questionnaire = props.questionnaire
    const questionnaireIndex = props.questionnaireIndex
    const setQuestionnaires = props.setQuestionnaires
    const documentTemplates = props.documentTemplates
    const getDocumentTemplates = props.getDocumentTemplates
    const openSelectDocumentTemplates = props.openSelectDocumentTemplates
    const setOpenSelectDocumentTemplates = props.setOpenSelectDocumentTemplates
    const openSelectDocumentTemplatesIndex = props.openSelectDocumentTemplatesIndex
    const setOpenSelectDocumentTemplatesIndex = props.setOpenSelectDocumentTemplatesIndex
    const editAttendanceLoaded = props.editAttendanceLoaded
    const setOpenEditDocumentTemplate = props.setOpenEditDocumentTemplate
    const isDocumentTemplateEditor = props.isDocumentTemplateEditor

    const setOpenNewDocumentTemplate = props.setOpenNewDocumentTemplate

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const elementRef = useRef(null)

    useEffect(() => {
      if (editAttendanceLoaded && editAttendanceLoaded.current)
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }, [])

    const onTitleChange = (value) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setQuestionnaires((questionnaires) => (
        questionnaires.map((questionnaire, index) => {
          if (index == questionnaireIndex) {
            questionnaire.name = value
          }
          return questionnaire
        })
      ))
    }

    return (
      <Card ref={elementRef} className="mb-5 has-border" style={{ maxWidth: "200%", marginLeft: "-0.75rem", width: "calc(100% + 1.5rem)" }} >

        <Card.Header className="has-background-light">
          <Card.Header.Title className="is-size-5 ml-4 is-block" >
            <Column.Group multiline vcentered breakpoint="mobile" className="m-0">
              <Column className="p-1">
                <span className="pr-3">Questionário nº{` ${questionnaireIndex + 1}`}</span>
              </Column>
              {!props.isDocumentTemplateEditor &&
              <Fragment>
                <Column narrow className="p-1">
                  <Button.Group className="mb-0">
                    <Button
                      color="primary"
                      outlined
                      size="normal"
                      className="mb-0"
                      onClick={e => {setOpenSelectDocumentTemplates(title);setOpenSelectDocumentTemplatesIndex(questionnaireIndex)}} >
                      <Icon size="small" align="left" className="is-hidden-touch">
                        <FontAwesomeIcon icon={faFileLines} />
                      </Icon>
                      <FontAwesomeIcon icon={faFileLines} className="is-hidden-desktop" />
                      <span className="is-hidden-touch">Importar modelo</span>
                    </Button>
                    <Button
                      color="info"
                      outlined
                      size="normal"
                      className="mb-0"
                      onClick={e => {setOpenNewDocumentTemplate({ title: title, documentTemplate: questionnaire })}} >
                      <Icon size="small" align="left" className="is-hidden-touch">
                        <FontAwesomeIcon icon={faCloudUpload} />
                      </Icon>
                      <FontAwesomeIcon icon={faCloudUpload} className="is-hidden-desktop" />
                      <span className="is-hidden-touch">Salvar modelo</span>
                    </Button>
                  </Button.Group>
                </Column>
              </Fragment>
              }
            </Column.Group>            
          </Card.Header.Title>

          {JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId &&
            <Card.Header.Icon>
              {!attendance.isDocumentTemplate &&
                <Delete size="medium" onClick={e => {
                  setNotification({
                    withCancel: true,
                    success: false,
                    title: "Excluir Questionário",
                    text: "Tem certeza que deseja excluir o questionário?",
                    cancelCallback: (() => {
                      setNotification(false)
                    }),
                    confirmCallback: (() => {
                      setNotification(false)
                      setQuestionnaires((questionnaires) => (
                        questionnaires.filter((questionnaire, index) => {
                          return index !== questionnaireIndex
                        })
                      ))
                    })
                  })
                }} />
              }              
            </Card.Header.Icon>            
          }
        </Card.Header>

        <Card.Content>
          <Column.Group className="mt-2">
            <Column narrow>
              <span className="has-text-weight-bold">Nome do questionário:</span>
            </Column>
            <Components.GenericInput
              disableSelect={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
              size={0}
              inputSize={null}
              placeholder="Nome do questionário..."
              elementName={null}
              value={questionnaire.name}
              setValue={onTitleChange}
              maskAndSetValue={null}
              setFormChanged={setFormChanged}
            />
          </Column.Group>

          <hr className="card-divider" />
          
          {questionnaire.questions && questionnaire.questions.map((question, index) => (
            <Components.Question
              key={index}
              attendance={attendance}
              question={question}
              questionIndex={index}
              questionnaireIndex={questionnaireIndex}
              setQuestionnaires={setQuestionnaires}
              setFormChanged={setFormChanged}
              setHasUnsavedChanges={setHasUnsavedChanges}
              setNotification={setNotification}
              setLoading={setLoading}
              setRedirectToLogin={setRedirectToLogin}
            />
          ))}
          {JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId &&
            <Button
              outlined
              color="success"
              className="has-text-weight-bold"
              onClick={(e) => {
                setQuestionnaires((questionnaires) => (
                  questionnaires.map((questionnaire, index) => {
                    if (index == questionnaireIndex) {
                      questionnaire.questions = [
                        ...questionnaire.questions,
                        {
                          question: "",
                          answerType: "Subjetiva",
                          answer: "",
                          alternatives: [
                            {
                              alternative: "Opção 1",
                              chosen: true,
                            },
                            {
                              alternative: "Opção 2",
                              chosen: false,
                            },
                          ]
                        }
                      ]
                    }
                    return questionnaire
                  })
                ))
              }}
            >
              <Icon align="left">
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <span>Nova Questão</span>
            </Button>
          }
          
        </Card.Content>

        {openSelectDocumentTemplates && openSelectDocumentTemplatesIndex == questionnaireIndex &&
          <Components.SelectDocumentTemplates
            documentType={title}
            openSelectDocumentTemplates={openSelectDocumentTemplates}
            setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
            documentTemplates={documentTemplates}
            setDocument={setQuestionnaires}
            questionnaireIndex={questionnaireIndex}
            setFormChanged={setFormChanged}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </Card>
    )
  },

  Questionnaires: (props) => {

    const title = props.title
    const attendance = props.attendance
    const questionnaires = props.questionnaires
    const setQuestionnaires = props.setQuestionnaires
    const documentTemplates = props.documentTemplates
    const getDocumentTemplates = props.getDocumentTemplates
    const openSelectDocumentTemplates = props.openSelectDocumentTemplates
    const setOpenSelectDocumentTemplates = props.setOpenSelectDocumentTemplates
    const openSelectDocumentTemplatesIndex = props.openSelectDocumentTemplatesIndex
    const setOpenSelectDocumentTemplatesIndex = props.setOpenSelectDocumentTemplatesIndex
    const editAttendanceLoaded = props.editAttendanceLoaded
    const setOpenEditDocumentTemplate = props.setOpenEditDocumentTemplate
    const isDocumentTemplateEditor = props.isDocumentTemplateEditor
    
    const setOpenSelectDocumentTemplatesToOverwrite = props.setOpenSelectDocumentTemplatesToOverwrite
    const setOpenNewDocumentTemplate = props.setOpenNewDocumentTemplate

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    return (
      <Fragment>
        {questionnaires.map((questionnaire, index) => {
          return (
            <Components.QuestionnaireCard
              isDocumentTemplate={props.isDocumentTemplate}
              key={index}
              title={title}
              attendance={attendance}
              questionnaireIndex={index}
              setQuestionnaires={setQuestionnaires}
              questionnaire={questionnaire}
              documentTemplates={documentTemplates}
              getDocumentTemplates={getDocumentTemplates}
              openSelectDocumentTemplates={openSelectDocumentTemplates}
              setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
              openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
              setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
              editAttendanceLoaded={editAttendanceLoaded}
              setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
              isDocumentTemplateEditor={isDocumentTemplateEditor}
              setOpenSelectDocumentTemplatesToOverwrite={setOpenSelectDocumentTemplatesToOverwrite}
              setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
              setFormChanged={setFormChanged}
              setHasUnsavedChanges={setHasUnsavedChanges}
              setNotification={setNotification}
              setLoading={setLoading}
              setRedirectToLogin={setRedirectToLogin}
            />
          )
        })}
      </Fragment>
    )
  },

  Measurement: (props) => {
    const [typeIcon, setTypeIcon] = useState(faAlignLeft)
    const [dateError, setDateError] = useState(false)
    const [inputValueError, setInputValueError] = useState(null)

    const attendance = props.attendance
    const measurement = props.measurement
    const measurementIndex = props.measurementIndex

    const clinicalExam = props.clinicalExam
    const clinicalExamIndex = props.clinicalExamIndex

    const setClinicalExams = props.setClinicalExams

    const refresh = props.refresh
    const setRefresh = props.setRefresh

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const onDateChange = (date) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setClinicalExams((clinicalExams) => (
        clinicalExams.map((clinicalExam, index) => {
          if (index == clinicalExamIndex) {
            clinicalExam.measurements = clinicalExam.measurements.map((measurement, index) => {
              if (index == measurementIndex) {
                measurement.date = date
              }
              return measurement
            })
          }
          return clinicalExam
        })
      ))
      setRefresh(refresh+1)
    }

    const onSelectValueChange = (value) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setClinicalExams((clinicalExams) => (
        clinicalExams.map((clinicalExam, index) => {
          if (index == clinicalExamIndex) {
            clinicalExam.measurements = clinicalExam.measurements.map((measurement, index) => {
              if (index == measurementIndex) {
                measurement.value = value
              }
              return measurement
            })
          }
          return clinicalExam
        })
      ))
      setRefresh(refresh+1)
    }

    const onInputValueChange = (value) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setClinicalExams((clinicalExams) => (
        clinicalExams.map((clinicalExam, index) => {
          if (index == clinicalExamIndex) {
            clinicalExam.measurements = clinicalExam.measurements.map((measurement, index) => {
              if (index == measurementIndex) {
                measurement.value = value
              }
              return measurement
            })
          }
          return clinicalExam
        })
      ))
      if (/^\d/.test(value)) {
        setInputValueError(null)
      } else {
        setInputValueError("A medição deve iniciar com um número")
      }
      setRefresh(refresh+1)
    }

    const handleDeleteMeasurement = () => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setNotification({
        withCancel: true,
        success: false,
        title: "Excluir medição",
        text: "Tem certeza que deseja excluir a medição?",
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setClinicalExams((clinicalExams) => (
            clinicalExams.map((clinicalExam, index) => {
              if (index == clinicalExamIndex) {
                clinicalExam.measurements = clinicalExam.measurements.filter((measurement, index) => {
                  return index !== measurementIndex
                })
              }
              return clinicalExam
            })
          ))
        })
      })
      setRefresh(refresh+1)
    }

    return (
      <Fragment>

        <Column.Group vcentered breakpoint="mobile" className="m-0">

          <Column narrow className="py-0 pr-0 small-column">
            <Label>Medição nº {measurementIndex + 1}:</Label>
          </Column>
          <Column narrow className="py-0 date-input">
            <DatePicker
              selected={new Date(measurement.date)}
              onChange={(date) => { onDateChange(date) }}
              customInput={
                <Components.DatePickerInput
                  disableSelect={!attendance || JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
                  icon={faCalendar}
                  dateError={dateError}
                  setDateError={setDateError}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />}
              dateFormat="dd/MM/yyyy"
              locale="pt-BR"
              strictParsing
            />
          </Column>
          <Column className="p-0">
          </Column>
          {attendance && JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId &&
            <Column narrow className="py-0">
              <Delete onClick={handleDeleteMeasurement} />
            </Column>
          }

        </Column.Group>

        <Column.Group vcentered breakpoint="mobile" className="m-0">

          <Column narrow className="small-column">
            <Label>Valor medido: </Label>
          </Column>

          {
            quantityTypes.some((quantityType) => {
              if (quantityType.name == clinicalExam.quantityType) {
                return quantityType.values.length > 0
              } else {
                return false
              }
            })
            &&
            <Column className="field-column" style={{ fontSize: "0.875rem" }}>
              {attendance && JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId ?
                <Select.Container fullwidth>
                  <Select
                    type="text"
                    value={measurement.value}
                    onChange={(event) => onSelectValueChange(event.target.value)}
                  >
                    {quantityTypes.map((quantityType, index) => {
                      if (quantityType.name == clinicalExam.quantityType) {
                        return quantityType.values.map((value, index) => (
                          <Select.Option key={index}>{value}</Select.Option>
                        ))
                      }
                    })}
                  </Select>
                </Select.Container>
                :
                <Button>{measurement.value}</Button>
              }
              
            </Column>
            ||
            <Components.GenericInput
              disableSelect={!attendance || JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
              size={0}
              inputSize={null}
              label={null}
              placeholder="Valor medido..."
              elementName={null}
              value={measurement.value}
              setValue={onInputValueChange}
              valueError={inputValueError}
              maskAndSetValue={null}
              setFormChanged={setFormChanged}
            />
          }

        </Column.Group>

        <hr className="form-divider"></hr>

      </Fragment>
    )
  },


  ClinicalExamCard: (props) => {

    const attendance = props.attendance

    const clinicalExam = props.clinicalExam
    const clinicalExamIndex = props.clinicalExamIndex
    const setClinicalExams = props.setClinicalExams

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const [refresh, setRefresh] = useState(0)

    const [chartData, setChartData] = useState([])
    const [YAxisTicks, setYAxisTicks] = useState([])
    const [YAxisLabel, setYAxisLabel] = useState("")

    useEffect(() => {
      let chartDataToPush = []
      let YAxisTicksToPush = []
  
      quantityTypes.map((quantityType, index) => {
        if (quantityType.name == clinicalExam.quantityType) {
          if (quantityType.values[0]) {
            quantityType.values.map((value, index) => {
              YAxisTicksToPush.push(index + 1)
            })
          }
        }
      })
      if (YAxisTicksToPush[0]) setYAxisTicks(YAxisTicksToPush)
      else setYAxisTicks(null)
  
      clinicalExam.measurements.map((measurement) => {
        let dataValue = measurement.value
        quantityTypes.map((quantityType, index) => {
          if (quantityType.name == clinicalExam.quantityType) {
            if (quantityType.values[0]) {
              quantityType.values.map((value, index) => {
                if (dataValue == value) {
                  dataValue = quantityType.values.length - index
                }
              })
            }
          }
        })
        dataValue = dataValue.toString()
        dataValue = dataValue.replace(/(\d*)(,)(\d)/, "$1.$3")
        chartDataToPush.push({ "time": DateTime.fromJSDate(new Date(measurement.date)).ts, "Valor medido": parseFloat(dataValue) })
      })
      setChartData(chartDataToPush.sort((a, b) => b.time - a.time))
      setYAxisLabel(`${clinicalExam.quantityType} ${clinicalExam.unit != "" && clinicalExam.unit ? `(${clinicalExam.unit})` : ""}`)
    }, [refresh])

    const valueToQuantityTypeFormatter = (initialValue) => {
      let formattedValue = initialValue
      quantityTypes.map((quantityType, index) => {
        if (quantityType.name == clinicalExam.quantityType) {
          if (quantityType.values[0]) {
            let reverseValues = quantityType.values.toReversed()
            reverseValues.map((value, index) => {
              if (initialValue == index + 1)
              formattedValue = value
            })
          }
        }
      })
      return formattedValue
    }

    const onParameterChange = (value) => {
      setHasUnsavedChanges(true)
      setClinicalExams((clinicalExams) => (
        clinicalExams.map((clinicalExam, index) => {
          if (index == clinicalExamIndex) {
            clinicalExam.parameter = value
          }
          return clinicalExam
        })
      ))
      setRefresh(refresh+1)
    }

    const onQuantityTypeChange = (value) => {
      setHasUnsavedChanges(true)
      setClinicalExams((clinicalExams) => (
        clinicalExams.map((clinicalExam, index) => {
          if (index == clinicalExamIndex) {
            clinicalExam.quantityType = value
            quantityTypes.map((quantityType, index) => {
              if (quantityType.name == value) {
                if (quantityType.units[0]) {
                  clinicalExam.unit = quantityType.units[0]
                } else {
                  clinicalExam.unit = ""
                }
                if (quantityType.values[0]) {
                  clinicalExam.measurements.map((measurement, index) => {
                    clinicalExam.measurements[index].value = quantityType.values[0]
                  })
                } else {
                  clinicalExam.measurements.map((measurement, index) => {
                    clinicalExam.measurements[index].value = ""
                  })
                }
              }
            })
          }
          return clinicalExam
        })
      ))
      setRefresh(refresh+1)
    }

    const onUnitChange = (value) => {
      setHasUnsavedChanges(true)
      setClinicalExams((clinicalExams) => (
        clinicalExams.map((clinicalExam, index) => {
          if (index == clinicalExamIndex) {
            clinicalExam.unit = value
          }
          return clinicalExam
        })
      ))
      setRefresh(refresh+1)
    }

    const onDelete = (event) => {
      setNotification({
        withCancel: true,
        success: false,
        title: "Excluir Exame",
        text: "Tem certeza que deseja excluir o exame?",
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          if (clinicalExam._id) {
            let submitInfo = {
              event: event,
              setNotification: setNotification,
              setLoading: setLoading,
              setOpenComponent: null,
              mandatoryFields: [],
              errorList: [],
              ServiceMethod: ClinicalExamsService.deleteClinicalExam,
              params: {
                clinicalExamId: clinicalExam._id,
              },
              onSuccessText: "Exame clínico excluído.",
              onErrorText: "Não foi possível excluir o exame clínico. Tente novamente",
              onSuccessCallback: (response) => {
                setClinicalExams((clinicalExams) => (
                  clinicalExams.filter((clinicalExam, index) => {
                    return index !== clinicalExamIndex
                  })
                ))
              },
              onErrorCallback: null,
              setRedirectToLogin: setRedirectToLogin,
            }

            methods.handleSubmit(submitInfo)
          } else {
            setClinicalExams((clinicalExams) => (
              clinicalExams.filter((clinicalExam, index) => {
                return index !== clinicalExamIndex
              })
            ))
          }
        })
      })
      setRefresh(refresh+1)
    }

    return (
      <Card className="mb-5 has-border" style={{ maxWidth: "200%", marginLeft: "-0.75rem", width: "calc(100% + 1.5rem)" }} >

        <Card.Header className="has-background-light">
          <Card.Header.Title className="is-size-4" >
            {attendance &&
              <Components.GenericInput
                disableSelect={!attendance || JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
                size={12}
                inputSize="medium"
                label={null}
                placeholder="Descrição do exame..."
                elementName="exam"
                value={!attendance && (!clinicalExam.parameter ? "Teste clínico sem nome" : clinicalExam.parameter != "" ? clinicalExam.parameter : "Teste clínico sem nome") || clinicalExam.parameter}
                setValue={onParameterChange}
                maskAndSetValue={null}
                fontWeight="bold"
                setFormChanged={setFormChanged}
              />
              ||
              <span className="ml-5">{!clinicalExam.parameter ? "Teste clínico sem nome" : clinicalExam.parameter != "" ? clinicalExam.parameter : "Teste clínico sem nome"}</span>
            }
          </Card.Header.Title>
          {(attendance && JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId) &&
            <Card.Header.Icon>
              <Delete size="medium" onClick={onDelete} />
            </Card.Header.Icon>
          }
        </Card.Header>

        <Card.Content>

          {clinicalExam.measurements[0] &&
            <Fragment>
              <Title className="is-size-5 has-text-centered mt-2 mb-2">Gráfico de Evolução</Title>
              <Box className="chart-container">
                <ResponsiveContainer>
                  <LineChart data={chartData} margin={{ top: 20, right: 80, left: 0, bottom: 0 }} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="time"
                      scale="time"
                      type="number"
                      domain={["auto", "auto"]}
                      tickFormatter={unixTime => DateTime.fromMillis(unixTime).toLocaleString(DateTime.DATE_SHORT)}
                      tick={{ fontSize: 12 }}
                      />
                    <YAxis 
                      label={{ value: YAxisLabel, angle: -90, position: 'insideLeft', fontSize: 13 }}
                      tickFormatter={valueToQuantityTypeFormatter}
                      allowDecimals={false}
                      type="number"
                      ticks={YAxisTicks}
                      tick={{ fontSize: 12 }}
                      domain={[0, "dataMax"]}
                      width={100}
                    />
                    <Line
                      name="Valor"
                      type="monotone"
                      dataKey="Valor medido"
                      strokeWidth={2}
                    />
                    <Tooltip
                      formatter={valueToQuantityTypeFormatter}
                      labelFormatter={unixTime => DateTime.fromMillis(unixTime).setLocale('pt-br').toFormat("cccc, dd/LL/yyyy")}                      
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Fragment>
          }

          <Column className="field-column" style={{ fontSize: "0.875rem" }}>
            <Label>Indicador:</Label>
            {attendance && JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId ?
              <Select.Container fullwidth>
                <Select
                  type="text"
                  value={clinicalExam.quantityType}
                  onChange={(event) => onQuantityTypeChange(event.target.value)}
                >
                  {quantityTypes.map((quantityType, index) => (
                    <Select.Option key={index}>{quantityType.name}</Select.Option>
                  ))}
                </Select>
              </Select.Container>
              :
              <Button>{clinicalExam.quantityType}</Button>
            }
            
          </Column>

          {
            quantityTypes.some((quantityType) => {
              if (quantityType.name == clinicalExam.quantityType) {
                return quantityType.units.length > 0
              } else {
                return false
              }
            })
            &&
            <Column className="field-column" style={{ fontSize: "0.875rem" }}>
              <Label>Unidade de medida:</Label>
              {attendance && JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId ?
                <Select.Container fullwidth>
                  <Select
                    type="text"
                    value={clinicalExam.unit}
                    onChange={(event) => onUnitChange(event.target.value)}
                  >
                    {quantityTypes.map((quantityType, index) => {
                      if (quantityType.name == clinicalExam.quantityType) {
                        return quantityType.units.map((unit, index) => (
                          <Select.Option key={index}>{unit}</Select.Option>
                        ))
                      }
                    })}
                  </Select>
                </Select.Container>
                :
                <Button>{clinicalExam.unit}</Button>
              }
            </Column>
          }

          {clinicalExam.quantityType == "Outro" &&
            <Components.GenericInput
              disableSelect={!attendance || JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
              size={12}
              inputSize={null}
              label="Unidade de medida:"
              placeholder="Unidade de medida..."
              elementName={null}
              value={clinicalExam.unit}
              setValue={onUnitChange}
              maskAndSetValue={null}
              setFormChanged={setFormChanged}
            />
          }

          <hr className="form-divider"></hr>

          {clinicalExam.measurements && clinicalExam.measurements.map((measurement, index) => (
            <Components.Measurement
              key={index}
              attendance={attendance}
              measurement={measurement}
              measurementIndex={index}
              clinicalExam={clinicalExam}
              clinicalExamIndex={clinicalExamIndex}
              setClinicalExams={setClinicalExams}
              refresh={refresh}
              setRefresh={setRefresh}
              setFormChanged={setFormChanged}
              setHasUnsavedChanges={setHasUnsavedChanges}
              setNotification={setNotification}
              setLoading={setLoading}
              setRedirectToLogin={setRedirectToLogin}
            />
          ))}

          {attendance && JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId &&
            <Button
              outlined
              color="success"
              className="has-text-weight-bold"
              onClick={(e) => {
                setClinicalExams((clinicalExams) => (
                  clinicalExams.map((clinicalExam, index) => {
                    if (index == clinicalExamIndex) {
                      let newValue = undefined
                      quantityTypes.map((quantityType, index) => {
                        if (quantityType.name == clinicalExam.quantityType) {
                          if (quantityType.values[0]) {
                            newValue = quantityType.values[0]
                          } else {
                            newValue = ""
                          }
                        }
                      }),
                        clinicalExam.measurements = [
                          ...clinicalExam.measurements,
                          {
                            date: new Date(Date.now()),
                            value: newValue,
                            notes: "",
                          }
                        ]
                    }
                    return clinicalExam
                  })
                ))
                setRefresh(refresh+1)
              }}
            >
              <Icon align="left">
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <span>Nova Medição</span>
            </Button>
          }

        </Card.Content>

      </Card>
    )
  },

  ClinicalExams: (props) => {

    const clinicalExams = props.clinicalExams
    const setClinicalExams = props.setClinicalExams
    const attendance = props.attendance

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    return (
      <Fragment>
        {clinicalExams.map((clinicalExam, index) => {
          return (
            <Components.ClinicalExamCard
              key={index}
              attendance={attendance}
              clinicalExamIndex={index}
              setClinicalExams={setClinicalExams}
              clinicalExam={clinicalExam}
              setFormChanged={setFormChanged}
              setHasUnsavedChanges={setHasUnsavedChanges}
              setNotification={setNotification}
              setLoading={setLoading}
              setRedirectToLogin={setRedirectToLogin}
            />
          )
        })}
      </Fragment>
    )
  },

  ProcedureCard: (props) => {
    const title = props.title
    const attendance = props.attendance
    const procedure = props.procedure
    const procedureIndex = props.procedureIndex
    const setProcedures = props.setProcedures
    const documentTemplates = props.documentTemplates
    const getDocumentTemplates = props.getDocumentTemplates
    const openSelectDocumentTemplates = props.openSelectDocumentTemplates
    const setOpenSelectDocumentTemplates = props.setOpenSelectDocumentTemplates
    const openSelectDocumentTemplatesIndex = props.openSelectDocumentTemplatesIndex
    const setOpenSelectDocumentTemplatesIndex = props.setOpenSelectDocumentTemplatesIndex
    const editAttendanceLoaded = props.editAttendanceLoaded
    const setOpenEditDocumentTemplate = props.setOpenEditDocumentTemplate
    const isDocumentTemplateEditor = props.isDocumentTemplateEditor

    const setOpenNewDocumentTemplate = props.setOpenNewDocumentTemplate

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const elementRef = useRef(null)

    useEffect(() => {
      if (editAttendanceLoaded && editAttendanceLoaded.current)
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }, [])

    const onNameChange = (value) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setProcedures((procedures) => (
        procedures.map((procedure, index) => {
          if (index == procedureIndex) {
            procedure.name = value
          }
          return procedure
        })
      ))
    }

    const onNotesChange = (value) => {
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(true)
      }
      setProcedures((procedures) => (
        procedures.map((procedure, index) => {
          if (index == procedureIndex) {
            procedure.notes = value
          }
          return procedure
        })
      ))
    }

    const onDelete = (event) => {
      setNotification({
        withCancel: true,
        success: false,
        title: "Excluir Procedimento",
        text: "Tem certeza que deseja excluir o procedimento?",
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setProcedures((procedures) => (
            procedures.filter((procedure, index) => {
              return index !== procedureIndex
            })
          ))
        })
      })
    }

    return (
      <Card ref={elementRef} className="mb-5 has-border" style={{ maxWidth: "200%", marginLeft: "-0.75rem", width: "calc(100% + 1.5rem)" }} >

        <Card.Header className="has-background-light">
          <Card.Header.Title className="is-size-5 ml-4 is-block" >
            <Column.Group multiline vcentered breakpoint="mobile" className="m-0">
              <Column className="p-1">
                <span className="pr-3">Procedimento nº{` ${procedureIndex + 1}`}</span>
              </Column>
              {!props.isDocumentTemplateEditor &&
              <Fragment>
                <Column narrow className="p-1">
                  <Button.Group className="mb-0">
                    <Button
                      color="primary"
                      outlined
                      size="normal"
                      className="mb-0"
                      onClick={e => {setOpenSelectDocumentTemplates(title);setOpenSelectDocumentTemplatesIndex(procedureIndex)}} >
                      <Icon size="small" align="left" className="is-hidden-touch">
                        <FontAwesomeIcon icon={faFileLines} />
                      </Icon>
                      <FontAwesomeIcon icon={faFileLines} className="is-hidden-desktop" />
                      <span className="is-hidden-touch">Importar modelo</span>
                    </Button>
                      <Button
                        color="info"
                        outlined
                        size="normal"
                        className="mb-0"
                        onClick={e => {setOpenNewDocumentTemplate({ title: title, documentTemplate: procedure })}} >
                        <Icon size="small" align="left" className="is-hidden-touch">
                          <FontAwesomeIcon icon={faCloudUpload} />
                        </Icon>
                        <FontAwesomeIcon icon={faCloudUpload} className="is-hidden-desktop" />
                        <span className="is-hidden-touch">Salvar modelo</span>
                      </Button>
                  </Button.Group>                
                </Column>
              </Fragment>
              }              
            </Column.Group>            
          </Card.Header.Title>
          
          {(JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId && !attendance.isDocumentTemplate) &&
            <Card.Header.Icon>
              <Delete size="medium" onClick={onDelete} />
            </Card.Header.Icon>
          }
        </Card.Header>

        <Card.Content>

          <Components.GenericInput
            disableSelect={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
            size={0}
            inputSize={null}
            label="Procedimento realizado:"
            placeholder="Nome do procedimento..."
            elementName="procedure"
            value={procedure.name}
            setValue={onNameChange}
            maskAndSetValue={null}
            setFormChanged={setFormChanged}
          />

          <Components.GenericInput
            isTextarea
            rows={2}
            disableSelect={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
            size={12}
            inputSize={null}
            label="Observações:"
            placeholder="Observações..."
            elementName={null}
            value={procedure.notes}
            setValue={onNotesChange}
            maskAndSetValue={null}
            setFormChanged={setFormChanged}
          />

        </Card.Content>

        {openSelectDocumentTemplates && openSelectDocumentTemplatesIndex == procedureIndex &&
          <Components.SelectDocumentTemplates
            documentType={title}
            openSelectDocumentTemplates={openSelectDocumentTemplates}
            setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
            documentTemplates={documentTemplates}
            setDocument={setProcedures}
            procedureIndex={procedureIndex}
            setFormChanged={setFormChanged}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </Card>
    )
  },

  Procedures: (props) => {
    const title = props.title
    const attendance = props.attendance
    const procedures = props.procedures
    const setProcedures = props.setProcedures
    const documentTemplates = props.documentTemplates
    const getDocumentTemplates = props.getDocumentTemplates
    const openSelectDocumentTemplates = props.openSelectDocumentTemplates
    const setOpenSelectDocumentTemplates = props.setOpenSelectDocumentTemplates
    const openSelectDocumentTemplatesIndex = props.openSelectDocumentTemplatesIndex
    const setOpenSelectDocumentTemplatesIndex = props.setOpenSelectDocumentTemplatesIndex
    const editAttendanceLoaded = props.editAttendanceLoaded
    const setOpenEditDocumentTemplate = props.setOpenEditDocumentTemplate
    const isDocumentTemplateEditor = props.isDocumentTemplateEditor

    const setOpenSelectDocumentTemplatesToOverwrite = props.setOpenSelectDocumentTemplatesToOverwrite
    const setOpenNewDocumentTemplate = props.setOpenNewDocumentTemplate

    const setFormChanged = props.setFormChanged
    const setHasUnsavedChanges = props.setHasUnsavedChanges
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    return (
      <Fragment>
        {procedures.map((procedure, index) => {
          return (
            <Components.ProcedureCard
              isDocumentTemplate={props.isDocumentTemplate}
              key={index}
              title={title}
              attendance={attendance}
              procedureIndex={index}
              setProcedures={setProcedures}
              procedure={procedure}
              documentTemplates={documentTemplates}
              getDocumentTemplates={getDocumentTemplates}
              openSelectDocumentTemplates={openSelectDocumentTemplates}
              setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
              openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
              setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
              editAttendanceLoaded={editAttendanceLoaded}
              setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
              isDocumentTemplateEditor={isDocumentTemplateEditor}
              setOpenSelectDocumentTemplatesToOverwrite={setOpenSelectDocumentTemplatesToOverwrite}
              setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
              setFormChanged={setFormChanged}
              setHasUnsavedChanges={setHasUnsavedChanges}
              setNotification={setNotification}
              setLoading={setLoading}
              setRedirectToLogin={setRedirectToLogin}
            />
          )
        })}
      </Fragment>
    )
  },

  PdfEditorAndGeneratorCard: (props) => {
    const [date, setDate] = useState(props.attendance.attendanceDateTime ? new Date(props.attendance.attendanceDateTime) : new Date(Date.now()))
    const [dateError, setDateError] = useState(null)

    const [src, setSrc] = useState(null)

    const { editorTitle, editorState, editorIndex, setEditorStatesArray, logoImage, patient, attendance, documentTemplates, getDocumentTemplates, openSelectDocumentTemplates, setOpenSelectDocumentTemplates, openSelectDocumentTemplatesIndex, setOpenSelectDocumentTemplatesIndex, editAttendanceLoaded, setOpenEditDocumentTemplate, setOpenNewDocumentTemplate } = props
    const { setFormChanged, setHasUnsavedChanges, setNotification, setLoading, setRedirectToLogin } = props

    const elementRef = useRef(null)

    useEffect(() => {
      if (editAttendanceLoaded && editAttendanceLoaded.current)
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }, [])

    const onTextChange = (state) => {
      setHasUnsavedChanges(true)
      setEditorStatesArray((editorStatesArray) => (
        editorStatesArray.map((editorState, index) => {
          if (index == editorIndex) {
            editorState = state
          }
          return editorState
        })
      ))
    }

    const onDelete = (event) => {
      setNotification({
        withCancel: true,
        success: false,
        title: `Excluir ${editorTitle}`,
        text: `Tem certeza que deseja excluir ${editorTitle}?`,
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setEditorStatesArray((editorStatesArray) => (
            editorStatesArray.filter((editorState, index) => {
              return index !== editorIndex
            })
          ))
        })
      })
    }

    return (
      <Card ref={elementRef} className="mb-5 has-border" style={{ maxWidth: "200%", marginLeft: "-0.75rem", width: "calc(100% + 1.5rem)" }} >

        <Card.Header className="has-background-light">
          <Card.Header.Title className="is-size-5 pl-5 is-block" >
            <Column.Group multiline vcentered breakpoint="mobile" className="m-0">
              <Column className="p-1">
                <span className="pr-3">{editorTitle} nº {editorIndex + 1}</span>
              </Column>
              <Column narrow className="p-1">
                <Button.Group className="mb-0">
                  <Button
                    color="primary"
                    outlined
                    size="normal"
                    className="mb-0"
                    onClick={e => {setOpenSelectDocumentTemplates(editorTitle);setOpenSelectDocumentTemplatesIndex(editorIndex)}} >
                    <Icon size="small" align="left" className="is-hidden-touch">
                      <FontAwesomeIcon icon={faFileLines} />
                    </Icon>
                    <FontAwesomeIcon icon={faFileLines} className="is-hidden-desktop" />
                    <span className="is-hidden-touch">Importar modelo</span>
                  </Button>
                  <Button
                    color="info"
                    outlined
                    size="normal"
                    className="mb-0"
                    onClick={e => {setOpenNewDocumentTemplate({ title: editorTitle, documentTemplate: editorState })}} >
                    <Icon size="small" align="left" className="is-hidden-touch">
                      <FontAwesomeIcon icon={faCloudUpload} />
                    </Icon>
                    <FontAwesomeIcon icon={faCloudUpload} className="is-hidden-desktop" />
                    <span className="is-hidden-touch">Salvar modelo</span>
                  </Button>
                </Button.Group>                
              </Column>
            </Column.Group>

            {src &&
              <Components.CardWithOverlay
                cardType="widerViewer"
                title={
                  <Fragment>
                    <span className="mr-2">{editorTitle}</span>
                    <Button
                      color="success"
                      className="has-text-weight-bold is-hidden-touch"
                      onClick={event => {
                        const a = document.createElement("a")
                        document.body.appendChild(a)
                        a.style = "display: none"
                        a.href = src
                        a.download = `${editorTitle} (${patient}).pdf`
                        a.click()
                      }}
                    >
                      <Icon align="left">
                        <FontAwesomeIcon icon={faDownload} />
                      </Icon>
                      <span>Baixar</span>
                    </Button>
                  </Fragment>
                }
                icon={faFilePdf}
                iconColor="red"
                setOpenCard={setSrc}
                revokeUrl={src}
              >
                <Generic className="is-hidden-touch">
                  <iframe src={src} style={{ width: "100%", height: "75vh", border: "none" }}/>
                </Generic>
                <Generic className="is-hidden-desktop">
                  <Label>
                    {`${editorTitle} (${patient}).pdf`}
                  </Label>
                  <Button.Group align="centered">
                    <Button
                      color="success"
                      className="has-text-weight-bold mt-2"
                      onClick={event => {
                        const a = document.createElement("a")
                        document.body.appendChild(a)
                        a.style = "display: none"
                        a.href = src
                        a.download = `${editorTitle} (${patient}).pdf`
                        a.click()
                        setSrc(null)
                      }}
                    >
                      <Icon align="left">
                        <FontAwesomeIcon icon={faDownload} />
                      </Icon>
                      <span>Baixar</span>
                    </Button>
                  </Button.Group>
                </Generic>
                
              </Components.CardWithOverlay>
            }

          </Card.Header.Title>

          {JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId && 
            <Card.Header.Icon>
              <Delete size="medium" onClick={onDelete} />
            </Card.Header.Icon>
          }

        </Card.Header>

        <Card.Content>
          <Column.Group vcentered className="mb-0 ml-1">
            <Column narrow>
              <span className="has-text-weight-bold">Data:</span>
            </Column>
              <Column className="px-2 py-1 date-input is-size-6 mr-3">
              <DatePicker
                selected={date}
                onChange={(date) => {setDate(date)}}
                customInput={
                  <Components.DatePickerInput
                    disableSelect={JSON.parse(localStorage.getItem('user'))._id != attendance.professionalId}
                    icon={faCalendar}
                    dateError={dateError}
                    setDateError={setDateError}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />}
                dateFormat="dd/MM/yyyy"
                locale="pt-BR"
                strictParsing
              />
            </Column>
          </Column.Group>
          <Components.TextEditor
            editorState={editorState}
            setEditorState={onTextChange}
            attendance={attendance}
            setFormChanged={setFormChanged}
            setHasUnsavedChanges={setHasUnsavedChanges}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />

          <Button.Group align='centered'>
            <Button
              color="primary"
              size="normal"
              className="mt-5 has-text-weight-bold"
              onClick={event => {
                methods.createPdfFromState({
                  editorState: editorState,
                  attendance: attendance,
                  logoImage: logoImage,
                  title: editorTitle,
                  date: date,
                  setSrc: setSrc,
                })
              }}
            >
              <Icon align="left">
                <FontAwesomeIcon icon={faFilePdf} />
              </Icon>
              <span>Emitir PDF</span>
            </Button>
          </Button.Group>

        </Card.Content>

        {openSelectDocumentTemplates && openSelectDocumentTemplatesIndex == editorIndex &&
          <Components.SelectDocumentTemplates
            documentType={editorTitle}
            openSelectDocumentTemplates={openSelectDocumentTemplates}
            setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
            documentTemplates={documentTemplates}
            setDocument={setEditorStatesArray}
            editorIndex={editorIndex}
            hasStatesArray={true}
            setFormChanged={setFormChanged}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </Card>
    )
  },

  PdfEditors: (props) => {

    const { editorTitle, editorStatesArray, setEditorStatesArray, logoImage, patient, attendance, documentTemplates, getDocumentTemplates, openSelectDocumentTemplates, setOpenSelectDocumentTemplates, openSelectDocumentTemplatesIndex, setOpenSelectDocumentTemplatesIndex, editAttendanceLoaded, setOpenEditDocumentTemplate, setOpenSelectDocumentTemplatesToOverwrite, setOpenNewDocumentTemplate } = props
    const { setFormChanged, setHasUnsavedChanges, setNotification, setLoading, setRedirectToLogin } = props

    return (
      <Fragment>
        {editorStatesArray.map((editorState, index) => {
          return (
            <Components.PdfEditorAndGeneratorCard
              key={index}
              editorTitle={editorTitle}
              editorIndex={index}
              setEditorStatesArray={setEditorStatesArray}
              editorState={editorState}
              logoImage={logoImage}
              patient={patient}
              attendance={attendance}
              documentTemplates={documentTemplates}
              getDocumentTemplates={getDocumentTemplates}
              openSelectDocumentTemplates={openSelectDocumentTemplates}
              setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
              openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
              setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
              editAttendanceLoaded={editAttendanceLoaded}
              setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
              setOpenSelectDocumentTemplatesToOverwrite={setOpenSelectDocumentTemplatesToOverwrite}
              setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
              setFormChanged={setFormChanged}
              setHasUnsavedChanges={setHasUnsavedChanges}
              setNotification={setNotification}
              setLoading={setLoading}
              setRedirectToLogin={setRedirectToLogin}
            />
          )
        })}
      </Fragment>
    )
  },

  DocumentsCard: (props) => {
    return (
      <Card className="mb-5 has-border" style={{ maxWidth: "200%", marginLeft: "-0.75rem", width: "calc(100% + 1.5rem)" }} >

        <Card.Header className="has-background-light">
          <Card.Header.Title className="is-size-5 ml-4" >
            Arquivos
          </Card.Header.Title>
        </Card.Header>

        <Card.Content>
          {props.children}
        </Card.Content>

      </Card>
    )
  },

  DocumentUpload: (props) => {

    const required = props.required
    const inputSize = props.inputSize
    const label = props.label
    const fileMaxSize = props.fileMaxSize
    const fileType = props.fileType
    const fileTypeRegEx = props.fileTypeRegEx

    const { document, documentIndex, setNewDocumentsArray, editAttendanceLoaded } = props
    const { setFormChanged, setHasUnsavedChanges, setNotification, setLoading, setRedirectToLogin } = props

    const elementRef = useRef(null)

    useEffect(() => {
      if (editAttendanceLoaded && editAttendanceLoaded.current)
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }, [])

    const onFileChange = (newFile) => {
      setNewDocumentsArray((newDocumentsArray) => (
        newDocumentsArray.map((document, index) => {
          if (index == documentIndex) {
            try {
              if (fileTypeRegEx.test(newFile.type)) {
                if (newFile.size > fileMaxSize) {
                  if (fileMaxSize < (1024 * 1024)) {
                    document.error = (`O arquivo deve ter no máximo ${(fileMaxSize / 1024).toFixed(0)} KB`)
                  } else {
                    document.error = (`O arquivo deve ter no máximo ${(fileMaxSize / 1048576).toFixed(0)} MB`)
                  }
                } else {
                  document.error = null
                }
                document.file = newFile
                document.name = newFile.name
              } else {
                document.error = `O arquivo deve ser do tipo ${fileType}`
                document.file = newFile
                document.name = newFile.name
              }
              return document
            } catch (error) {
              console.log(error)
            }
          }
          return document
        })
      ))
    }

    const onDelete = (event) => {
      if (document.file) {
        setNotification({
          withCancel: true,
          success: false,
          title: `Excluir arquivo`,
          text: `Tem certeza que deseja excluir o arquivo${document.name ? " " + document.name : ""}?`,
          cancelCallback: (() => {
            setNotification(false)
          }),
          confirmCallback: (() => {
            setNotification(false)
            setNewDocumentsArray((newDocumentsArray) => (
              newDocumentsArray.filter((document, index) => {
                return index !== documentIndex
              })
            ))
          })
        })
      } else {
        setNewDocumentsArray((newDocumentsArray) => (
          newDocumentsArray.filter((document, index) => {
            return index !== documentIndex
          })
        ))
      }
    }

    return (
      <Column.Group ref={elementRef} breakpoint="mobile">
        <Column className="field-column" >
          <Field>
            <Label >{label}{required && <span className="has-text-danger"> *</span>}</Label>
            <Control tooltip={document.error} className="has-tooltip-danger" iconRight>
              <RbxFile hasName fullwidth color="primary" textColor={document.error && "danger"}>
                <RbxFile.Label>
                  <RbxFile.Input
                    id={`drop-area${documentIndex}`}
                    size={inputSize}
                    name="file-input"
                    onChange={e => {
                      onFileChange(e.target.files[0])
                      if (setFormChanged) { setFormChanged(true) }
                    }}
                  />
                  <RbxFile.CTA>
                    <RbxFile.Icon>
                      <FontAwesomeIcon icon={faUpload} />
                    </RbxFile.Icon>
                    <RbxFile.Label as="span" textColor={document.error && "danger"}>Escolha um arquivo</RbxFile.Label>
                  </RbxFile.CTA>
                  <RbxFile.Name>{document.name}</RbxFile.Name>
                </RbxFile.Label>
              </RbxFile>
              {document.error &&
                <Icon size="small" align="right">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </Icon>
              }
            </Control>
          </Field>
        </Column>
        <Column narrow>
          <Delete
            size="medium"
            onClick={onDelete}
          />
        </Column>
      </Column.Group>
      
    )
  },

  DocumentUploadsArray: (props) => {

    const { required, inputSize, label, newDocumentsArray, setNewDocumentsArray, fileMaxSize, fileType, fileTypeRegEx, editAttendanceLoaded } = props
    const { setFormChanged, setHasUnsavedChanges, setNotification, setLoading, setRedirectToLogin } = props

    return (
      <Fragment>
        {newDocumentsArray.map((document, index) => {
          return (
            <Components.DocumentUpload
              key={index}
              required={required}
              inputSize={inputSize}
              label={label}
              document={document}
              documentIndex={index}
              setNewDocumentsArray={setNewDocumentsArray}           
              fileMaxSize={fileMaxSize}
              fileType={fileType}
              fileTypeRegEx={fileTypeRegEx}
              editAttendanceLoaded={editAttendanceLoaded}
              setFormChanged={setFormChanged}
              setNotification={setNotification}
            />
          )
        })}
      </Fragment>
    )
  },

  DocumentPreviewContainersArray: (props) => {
    const [documentToView, setDocumentToView] = useState(null)

    const { oldDocumentsArray, setOldDocumentsArray, attendance, onlyView } = props
    const { setFormChanged, setHasUnsavedChanges, setNotification, setLoading, setRedirectToLogin } = props

    const onDelete = (oldDocument) => {
      setNotification({
        withCancel: true,
        success: false,
        title: `Excluir arquivo`,
        text: `Tem certeza que deseja excluir o arquivo ${oldDocument.filename}?`,
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (async () => {
          setLoading(true)
          setNotification(false)
          let documentsArrayToSave = oldDocumentsArray.filter(document => {
            return document.id != oldDocument.id
          })
          let documentIdsArrayToSave = documentsArrayToSave.map(document => {
            return document.id
          })
          await AttendancesService.deleteAttendanceDocument({
            attendanceId: attendance._id,
            documentId: oldDocument.id,
            documentIdsArray: documentIdsArrayToSave
          })
          .then(() => {
            const getAttendanceDocuments = async () => {
              return await AttendancesService.getAttendanceDocuments({
                attendanceId: attendance._id
              })
              .then((response) => {
                return response
              })
            }
            getAttendanceDocuments()
            .then( (response) => {
              setLoading(false)
              if (setOldDocumentsArray) setOldDocumentsArray(response.data)
            })
            setNotification({
              success: true,
              text: "Arquivo excluído.",
              callback: () => {
                setNotification(false)
              }
            })
          })
          .catch(function (error) {
            console.log(error)
            try {
              if (error.response.status == 401) {
                setLoading(false)
                setNotification({
                  success: false,
                  text: "Erro de autenticação. Efetue o login novamente.",
                  callback: ( () => {
                    setNotification(false)
                    UsersService.logout()
                    setRedirectToLogin(true)
                  })
                })
              } else {
                setLoading(false)
                setNotification({
                  success: false,
                  text: error.response.data,
                  callback: ( () => {
                    eventDropInfo.revert()
                    setNotification(false)
                    setOpenNewEvent(false)
                  })
                })
              }
            } catch {          
              setLoading(false)
              setNotification({
                success: false,
                text: "Não foi possível excluir o arquivo. Tente novamente",
                callback: ( () => {
                  setNotification(false)
                  setOpenNewEvent(false)
                })
              })
            }
          })
        })
      })
    }

    return (
      <Column.Group multiline>
        {oldDocumentsArray.map((oldDocument, index) => (
          <Column size={6} key={index}>
            <Card className="has-text-weight-bold" style={{ boxShadow: "0.25em 0.5em 0.75em 0.125em rgba(#000, 0.3)", backgroundColor: "#fff" }}>
              <Card.Header>
                <Card.Header.Title>
                  <Column.Group vcentered breakpoint="mobile" className="m-0" >
                    <Column className="p-1" narrow>
                      <span>
                        {methods.stringCut(oldDocument.filename, 14)}
                      </span>
                    </Column>
                    <Column className="p-1" narrow>
                      <Button
                        size="small"
                        color="success"
                        className="has-text-weight-bold"
                        onClick={event => {
                          const a = document.createElement("a")
                          document.body.appendChild(a)
                          a.style = "display: none"
                          a.href = oldDocument.base64metadata
                          a.download = oldDocument.filename
                          a.click()
                        }}
                      >
                        <Icon align="left">
                          <FontAwesomeIcon icon={faDownload} />
                        </Icon>
                      </Button>
                    </Column>
                    <Column className="p-1" narrow>
                      <Button
                        size="small"
                        color="info"
                        className="has-text-weight-bold"
                        onClick={event => {
                          setDocumentToView(oldDocument)
                        }}
                      >
                        <Icon align="left">
                          <FontAwesomeIcon icon={faEye} />
                        </Icon>
                      </Button>
                    </Column>
                  </Column.Group>
                </Card.Header.Title>
                {!onlyView &&
                  <Card.Header.Icon>
                    <Icon color="danger" size="large" align="right">
                      <FontAwesomeIcon icon={faTrash} size="lg" style={{ cursor: "pointer" }}
                        onClick={event => {onDelete(oldDocument)}}
                      />
                    </Icon>
                  </Card.Header.Icon>
                }
              </Card.Header>
              <Card.Content className="p-2" style={{ height: "25vw", minHeight: "180px" }}>
                {
                  /pdf/.test(oldDocument.contentType) ?
                    <Fragment>
                      <iframe className="is-hidden-touch" src={oldDocument.base64metadata} style={{ width: "100%", height: "calc(100%)" }}/>
                      <Section className="is-hidden-desktop" >
                        <Column.Group vcentered >
                          <Column>
                            <span>
                              <Icon align="left" color="danger">
                                <FontAwesomeIcon icon={faFilePdf} />
                              </Icon>
                              <span>Baixe para visualizar</span>
                            </span>
                          </Column>
                        </Column.Group>
                      </Section>

                    </Fragment>
                  : /image/.test(oldDocument.contentType) ?
                    <img src={oldDocument.base64metadata} style={{ maxHeight: "calc(100%)", display: "block", marginLeft: "auto", marginRight: "auto" }} />
                  : <span style={{ maxHeight: "calc(100%)", display: "flex" }}>
                      Visualização não disponível
                    </span>
                }
              </Card.Content>
            </Card>
          </Column>
        ))}
        {documentToView &&
          <Components.CardWithOverlay
            className="is-hidden-touch"
            cardType="widerViewer"
            title={
              <Column.Group vcentered breakpoint="mobile" className="m-0" >
                <Column className="p-1" narrow>
                  <span>
                    {documentToView.filename}
                  </span>
                </Column>
                <Column className="p-1" narrow>
                  <Button
                    size="small"
                    color="success"
                    className="has-text-weight-bold"
                    onClick={event => {
                      const a = document.createElement("a")
                      document.body.appendChild(a)
                      a.style = "display: none"
                      a.href = documentToView.base64metadata
                      a.download = documentToView.filename
                      a.click()
                    }}
                  >
                    <Icon align="left">
                      <FontAwesomeIcon icon={faDownload} />
                    </Icon>
                    <span>Baixar</span>
                  </Button>
                </Column>
              </Column.Group>
            }
            icon={null}
            setOpenCard={setDocumentToView}
          >
            {
              /pdf/.test(documentToView.contentType) ?
                <Fragment>
                  <iframe className="is-hidden-touch" src={documentToView.base64metadata} style={{ width: "100%", height: "75vh" }}/>
                  <Section className="is-hidden-desktop" >
                    <Column.Group vcentered >
                      <Column>
                        <span>
                          <Icon align="left" color="danger">
                            <FontAwesomeIcon icon={faFilePdf} />
                          </Icon>
                          <span>Baixe para visualizar</span>
                        </span>
                      </Column>
                    </Column.Group>
                  </Section>

                </Fragment>
              : /image/.test(documentToView.contentType) ?
                <img src={documentToView.base64metadata} style={{ maxHeight: "calc(100% - 22px)", display: "block", marginLeft: "auto", marginRight: "auto" }} />
              : <span style={{ maxHeight: "calc(100% - 22px)", display: "flex" }}>
                  Visualização não disponível
                </span>
            }
          </Components.CardWithOverlay>
        }
      </Column.Group>
    )
  },

  TextViewerFromEditorState: (props) => {
    const { title, editorState } = props

    return (
      <Fragment>
        <Title size={5} className="mb-2">{title}</Title>
        <Editor
          editorState={EditorState.createWithContent(convertFromRaw(editorState))}
          readOnly={true}
          onChange={() => {}}
        />
      </Fragment>
    )
  },

  QuestionAlternativeViewer: (props) => {
    const alternative = props.alternative
    const alternativeIndex = props.alternativeIndex
    const question = props.question

    return (
      <Label>
        <Column.Group vcentered breakpoint="mobile" className="m-0">
          <Column narrow className="py-0 pl-3" style={{ paddingRight: "7px" }} >
            {question.answerType == "Múltipla" &&
              <Checkbox
                disabled={false}
                checked={alternative.chosen}
                onChange={() => {}}
              />
              ||
              <Radio
                disabled={false}
                value={alternativeIndex}
                checked={alternative.chosen}
                onChange={() => {}}
              />
            }
          </Column>
          <Column className="p-0">
            <span className="has-text-weight-normal">{alternative.alternative}</span>
          </Column>
        </Column.Group>
      </Label>
    )
  },

  QuestionViewer: (props) => {
    const [typeIcon, setTypeIcon] = useState(faAlignLeft)

    const question = props.question
    const questionIndex = props.questionIndex

    useEffect(() => {
      if (question.answerType == "Subjetiva") {
        setTypeIcon(faAlignLeft)
      } else if (question.answerType == "Objetiva") {
        setTypeIcon(faCircleDot)
      } else if (question.answerType == "Múltipla") {
        setTypeIcon(faSquareCheck)
      }
    }, [])

    return (
      <Fragment>

        <Title size={6} className="mb-3 has-text-danger-dark">
          {`Questão ${questionIndex + 1} - ${question.answerType}:`}
        </Title>

        <Title size={6} className="mb-2">{question.question}</Title>

        {question.answerType == "Subjetiva" &&
          <Title size={6} className="has-text-weight-normal mb-5">{question.answer}</Title>
        }

        {(question.answerType == "Objetiva" || question.answerType == "Múltipla") &&
          <Fragment>
            {question.alternatives.map((alternative, index) => {
              return (
                <Components.QuestionAlternativeViewer
                  key={index}
                  alternative={alternative}
                  alternativeIndex={index}
                  question={question}
                />
              )
            })}
            <span className="has-text-white">.</span>
          </Fragment>
        }

      </Fragment>
    )
  },

  QuestionnaireViewer: (props) => {

    const questionnaire = props.questionnaire
    const questionnaireIndex = props.questionnaireIndex

    return (
      <Fragment>
        
        <Title size={5} className="mb-5">Questionário nº{` ${questionnaireIndex + 1}${questionnaire.name && ` - ${questionnaire.name}`}`}</Title>

        {questionnaire.questions && questionnaire.questions.map((question, index) => (
          <Components.QuestionViewer
            key={index}
            question={question}
            questionIndex={index}
          />
        ))}

      </Fragment>
      
    )
  },
  
  PreviousAttendanceCard: (props) => {
    const [showAttendance, setShowAttendance] = useState(false)
    const [documentsArray, setDocumentsArray] = useState(null)
    const [documentsLoading, setDocumentsLoading] = useState(false)

    const { attendance, focusedItem, setFocusedItem, setObjectToEdit, setFormChanged, setHasUnsavedChanges, setNotification, setLoading, setRedirectToLogin } = props

    let history = useHistory()

    let paid = 0
    if (props.attendance && props.attendance.payments) {
      props.attendance.payments.map((payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    paid = methods.numberToPrice(paid.toFixed(2))

    let debt = attendance.price != 0 ? attendance.price - methods.priceToNumber(paid) : 0
    debt = methods.numberToPrice(debt.toFixed(2))

    const dateTime = attendance.attendanceDateTime ? DateTime.fromJSDate(new Date(attendance.attendanceDateTime)) : attendance.start ? DateTime.fromJSDate(new Date(attendance.start)) : null

    const getAttendancesOfOnePackage = async (attendancePackageId) => {
      try {
        setLoading(true)
        return await AttendancePackagesService.getAttendancesOfOnePackage({ attendancePackageId: attendancePackageId})
        .then( (response) => {
          return response.data
          setLoading(false)
        })
      } catch (error) {
        console.log(error)
        try {
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setLoading(false)
            setNotification({
              success: false,
              text: error.response.data,
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {          
          setLoading(false)
          setNotification({
            success: false,
            text: "Não foi possível carregar as informações. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      }
    }

    useEffect( () => {
      const getDocuments = async () => {
        setDocumentsLoading(true)
        await AttendancesService.getAttendanceDocuments({
          attendanceId: attendance._id
        })
        .then( (response) => {
          setDocumentsArray(response.data)
          setDocumentsLoading(false)
        })
      }
      if (showAttendance && !documentsArray && attendance.documentIds && attendance.documentIds[0]) {
        getDocuments()
      }
    }, [showAttendance])

    return (
      <Card className="mb-5 has-border" style={{ maxWidth: "200%", marginLeft: "-0.75rem", width: "calc(100% + 1.5rem)", backgroundColor: attendance.status == undefined ? "" : attendance.status == "Paciente não compareceu" ? "#ffdce3" : attendance.attendanceDateTime ? "#d4fdea" : "#cfe9ff" }} >
        <Card.Header style={{ cursor: "pointer" }} onClick={e => {setShowAttendance(!showAttendance)}}>
          <Card.Header.Title className="is-size-5 ml-4" style={{ display: "block" }} >
            <Title size={5} className="mb-2">
              {attendance.description && attendance.description != "" && attendance.description || "Atendimento"}
            </Title>
            <Title size={6} className={attendance.status == "Paciente não compareceu" ? "has-text-danger mb-2" : attendance.attendanceDateTime ? "has-text-success mb-2" : "has-text-info mb-2"}>
              <span className="has-text-black mr-2">Situação: </span>
              {attendance.status == "Paciente não compareceu" ? "✕ Paciente não compareceu" : attendance.attendanceDateTime ? "✓ Atendido" : "⧗ Aguard. Atendimento"}
            </Title>
            <Title size={6} className="mb-2">
              <span>{`Profissional: ${attendance.professional}`}</span>
            </Title>
            <Title size={6} className="mb-2 has-text-weight-normal">
              <span className="mr-2 has-text-info"><FontAwesomeIcon icon={faCalendar} size="lg"/></span>
              <span>{attendance.status == "Paciente não compareceu" ? "Agendado para: " : attendance.attendanceDateTime ? "Atendido em: " : "Agendado para: "}</span>
              {`${dateTime ? `${dateTime.toLocaleString(DateTime.DATE_HUGE)}, às ${dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}` : "Atendimento não agendado"}`}
            </Title>
            {attendance.attendancePackageId
              ? <Fragment>
                  <Title size={6} className="has-text-weight-normal mb-2">Pacote:
                    <a className="has-text-weight-bold"
                      onClick={e => {
                        getAttendancesOfOnePackage(attendance.attendancePackageId)
                        .then((response) => {
                          let attendancePackageToPass = { ...response.attendancePackage, attendancesArray: response.attendancesArray }
                          setObjectToEdit(attendancePackageToPass)
                          history.push("/app/edit-attendance-package")
                          setFocusedItem("attendance-package")
                        })
                      }}
                    >{" " + attendance.attendancePackage + " (clique para ver)"}</a>
                  </Title>
                  <Label className="has-text-primary"><span className="has-background-primary has-text-white mr-2 event-icon">$</span>O pagamento deve ser verificado no Pacote de Atendimentos</Label>
                </Fragment>
              : <Title size={6} className="has-text-weight-normal mb-2">Preço: <span className="has-text-weight-normal">{methods.numberToPrice(attendance.price)}</span></Title>
            }
            {methods.priceToNumber(paid) > 0 && <Title size={6} className="has-text-weight-normal has-text-success mb-2">Pago: <span className="has-text-weight-normal mr-2" >{paid}</span></Title>}
            {methods.priceToNumber(debt) > 0 &&<Title size={6} className="has-text-weight-normal has-text-danger">Em aberto: <span className="has-text-weight-normal mr-2" >{debt}</span></Title>}
          </Card.Header.Title>
          <Card.Header.Icon>
            <Icon style={{ cursor: "pointer" }} color="info" size="large" align="right">
              <FontAwesomeIcon icon={!showAttendance && faPlus || faMinus} size="lg"/>
            </Icon>
          </Card.Header.Icon>
        </Card.Header>

        {showAttendance &&
          <Card.Content className="has-background-light">

            {focusedItem == "patient" &&
              <Button
                color="link"
                onClick={e => {setObjectToEdit(attendance); history.push("/app/edit-attendance"); setFocusedItem("attendance")}}
              >
                <Icon >
                  <FontAwesomeIcon icon={faEdit} />
                </Icon>
                <span>Editar Atendimento</span>
              </Button>
            }

            <hr className="card-divider" />

            {attendance.anamnesis &&
              <Fragment>
                <Components.TextViewerFromEditorState
                  title={"Anamnese"}
                  editorState={attendance.anamnesis}
                />
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.questionnaires && attendance.questionnaires[0] &&
              <Fragment>
                {attendance.questionnaires.map((questionnaire, index) => (
                  <Components.QuestionnaireViewer
                    key={index}
                    questionnaire={questionnaire}
                    questionnaireIndex={index}
                  />
                ))}
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.procedures && attendance.procedures[0] &&
              <Fragment>
                {attendance.procedures.map((procedure, index) => (
                  <Fragment key={index}>
                    <Title size={5} className="mb-2">{`Procedimento nº ${index + 1}`}</Title>
                    <div className="mb-4">{procedure.name}</div>
                    {procedure.notes &&
                      <Fragment>                        
                        <Title size={6} className="mb-2">Observações:</Title>
                        <span>{procedure.notes}</span>
                      </Fragment>
                    }
                  </Fragment>
                ))}
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.diagnosis &&
              <Fragment>
                <Components.TextViewerFromEditorState
                  title={"Diagnóstico"}
                  editorState={attendance.diagnosis}
                />
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.prognosis &&
              <Fragment>
                <Components.TextViewerFromEditorState
                  title={"Prognóstico"}
                  editorState={attendance.prognosis}
                />
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.therapeuticPlan &&
              <Fragment>
                <Components.TextViewerFromEditorState
                  title={"Plano Terapêutico"}
                  editorState={attendance.therapeuticPlan}
                />
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.prescriptions && attendance.prescriptions[0] &&
              <Fragment>
                {attendance.prescriptions.map((prescription, index) => (
                  <Fragment key={index}>
                    <Components.TextViewerFromEditorState
                      title={`Receituário nº ${index + 1}`}
                      editorState={prescription}
                    />
                  </Fragment>
                ))}
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.examRequests && attendance.examRequests[0] &&
              <Fragment>
                {attendance.examRequests.map((examRequest, index) => (
                  <Fragment key={index}>
                    <Components.TextViewerFromEditorState
                      title={`Requisição nº ${index + 1}`}
                      editorState={examRequest}
                    />
                  </Fragment>
                ))}
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.attests && attendance.attests[0] &&
              <Fragment>
                {attendance.attests.map((attest, index) => (
                  <Fragment key={index}>
                    <Components.TextViewerFromEditorState
                      title={`Atestado nº ${index + 1}`}
                      editorState={attest}
                    />
                  </Fragment>
                ))}
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.declarations && attendance.declarations[0] &&
              <Fragment>
                {attendance.declarations.map((declaration, index) => (
                  <Fragment key={index}>
                    <Components.TextViewerFromEditorState
                      title={`Declaração nº ${index + 1}`}
                      editorState={declaration}
                    />
                  </Fragment>
                ))}
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.reports && attendance.reports[0] &&
              <Fragment>
                {attendance.reports.map((report, index) => (
                  <Fragment key={index}>
                    <Components.TextViewerFromEditorState
                      title={`Laudo nº ${index + 1}`}
                      editorState={report}
                    />
                  </Fragment>
                ))}
                <hr className="card-divider" />
              </Fragment>
            }

            {attendance.consentTerms && attendance.consentTerms[0] &&
              <Fragment>
                {attendance.consentTerms.map((consentTerm, index) => (
                  <Fragment key={index}>
                    <Components.TextViewerFromEditorState
                      title={`Termo de consentimento nº ${index + 1}`}
                      editorState={consentTerm}
                    />
                  </Fragment>
                ))}
                <hr className="card-divider" />
              </Fragment>
            }

            {documentsArray && documentsArray[0] &&
              <Fragment>
                <Title size={5} className="mb-2">{"Arquivos"}</Title>
                <Components.DocumentPreviewContainersArray
                  onlyView={true}
                  oldDocumentsArray={documentsArray}
                  setOldDocumentsArray={null}
                  attendance={attendance}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              </Fragment>

            }

            {documentsLoading &&
              <Fragment>
                <Title size={5} className="mb-2">{"Arquivos"}</Title>
                <div className="image-loader" />
              </Fragment>
            }

          </Card.Content>
        }

      </Card>
    )
  },

  PreviousAttendancePackageCard: (props) => {
    const [showAttendancePackage, setShowAttendancePackage] = useState(false)
    const [attendancesArray, setAttendancesArray] = useState(null)

    const { attendancePackage, focusedItem, setFocusedItem, setObjectToEdit, setFormChanged, setHasUnsavedChanges, setNotification, setLoading, setRedirectToLogin } = props

    let history = useHistory()

    let paid = 0
    if (props.attendancePackage && props.attendancePackage.payments) {
      props.attendancePackage.payments.map((payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    paid = methods.numberToPrice(paid.toFixed(2))

    let debt = attendancePackage.price != 0 ? attendancePackage.price - methods.priceToNumber(paid) : 0
    debt = methods.numberToPrice(debt.toFixed(2))

    const dateTime = DateTime.fromJSDate(new Date(attendancePackage.createdAt))

    const getAttendancesOfOnePackage = async (attendancePackageId) => {
      try {
        setLoading(true)
        return await AttendancePackagesService.getAttendancesOfOnePackage({ attendancePackageId: attendancePackageId})
        .then( (response) => {
          return response.data
          setLoading(false)
        })
      } catch (error) {
        console.log(error)
        try {
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setLoading(false)
            setNotification({
              success: false,
              text: error.response.data,
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {
          setLoading(false)
          setNotification({
            success: false,
            text: "Não foi possível carregar as informações. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      }
    }

    useEffect( () => {
      const getAttendances = async () => {
        setLoading(true)
        await AttendancePackagesService.getAttendancesOfOnePackage({
          attendancePackageId: attendancePackage._id
        })
        .then( (response) => {
          setAttendancesArray(response.data.attendancesArray)
          setLoading(false)
        })
      }
      if (showAttendancePackage && !attendancesArray && attendancePackage.attendanceIds && attendancePackage.attendanceIds[0]) {
        getAttendances()
      }
    }, [showAttendancePackage])

    return (
      <Card className="mb-5 has-border" style={{ maxWidth: "200%", marginLeft: "-0.75rem", width: "calc(100% + 1.5rem)" }} >

        <Card.Header className="has-background-light" style={{ cursor: "pointer" }} onClick={e => {setShowAttendancePackage(!showAttendancePackage)}}>
          <Card.Header.Title className="is-size-5 ml-4" style={{ display: "block" }} >
            <Title size={5} className="mb-2">
              {attendancePackage.name && attendancePackage.name != "" && attendancePackage.name || "Pacote de Atendimentos"}
            </Title>
            <Title size={6} className="has-text-weight-normal mb-2">
              <span className="has-text-weight-bold">Quantidade:</span>{` ${attendancePackage.attendancesQuantity} atendimento${attendancePackage.attendancesQuantity > 1 ? "s" : ""}`}
            </Title>
            <Title size={6} className="has-text-weight-normal mb-2">
              <span className="has-text-weight-bold">Cadastro:</span>{` ${dateTime.toLocaleString(DateTime.DATE_HUGE)}, às ${dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}`}
            </Title>
            <Title size={6} className="has-text-weight-normal mb-2">Preço: <span className="has-text-weight-normal mr-2" >{methods.numberToPrice(attendancePackage.price)}</span></Title>
            {methods.priceToNumber(paid) > 0 && <Title size={6} className="has-text-weight-normal has-text-success mb-2">Pago: <span className="has-text-weight-normal mr-2" >{paid}</span></Title>}
            {methods.priceToNumber(debt) > 0 &&<Title size={6} className="has-text-weight-normal has-text-danger">Em aberto: <span className="has-text-weight-normal mr-2" >{debt}</span></Title>}
          </Card.Header.Title>
          <Card.Header.Icon>
            <Icon style={{ cursor: "pointer" }} color="info" size="large" align="right">
              <FontAwesomeIcon icon={!showAttendancePackage && faPlus || faMinus} size="lg"/>
            </Icon>
          </Card.Header.Icon>
        </Card.Header>

        {showAttendancePackage &&
          <Card.Content>

            <Button
              color="link"
              onClick={e => {
                getAttendancesOfOnePackage(attendancePackage._id)
                .then((response) => {
                  let attendancePackageToPass = { ...response.attendancePackage, attendancesArray: response.attendancesArray }
                  setObjectToEdit(attendancePackageToPass)
                  history.push("/app/edit-attendance-package")
                  props.setFocusedItem("attendance-package")
                })
              }}
            >
              <Icon >
                <FontAwesomeIcon icon={faEdit} />
              </Icon>
              <span>Editar Pacote de Atendimentos</span>
            </Button>

            <hr className="card-divider" />

            {(attendancesArray && attendancesArray[0]) &&
              attendancesArray.map((attendance, attendanceIndex) => (
                <Card key={attendanceIndex} className="my-3" style={{ backgroundColor: attendance.status == undefined ? "" : attendance.status == "Atendido" ? "#d4fdea" : attendance.status == "Aguard. atendimento" ? "#cfe9ff" : "#ffdce3" }} >
                  <Card.Content style={{ paddingTop: "0.25rem", paddingBottom: "0.25rem", overflow: "initial" }} >
          
                    <Column.Group breakpoint="mobile" vcentered className="m-0">
                      <Column narrow className="p-0 is-hidden-touch">
                        {attendance.status != undefined && <span className={attendance.status == "Atendido" ? "has-background-success has-text-white ml-2 event-icon" : (attendance.status == "Aguard. atendimento" ? "has-background-info has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon") } >{attendance.status == "Atendido" ? "✓" : (attendance.status == "Aguard. atendimento" ? "⧗" : "✕")}</span>}
                      </Column>
                      <Column narrow className="p-0 ml-1">
                        <span className="is-size-5 has-text-weight-bold is-hidden-touch" style={{ color: "#0074d3" }} >
                          Atend. {attendanceIndex + 1}:
                        </span>
                      </Column>
                      <Column className="p-0 ml-1">
                        {true &&
                          <Column.Group vcentered multiline breakpoint="mobile" className="my-0 ml-2" >
          
                            <Column narrow className="p-0 is-hidden-desktop">
                              {attendance.status != undefined && <span className={attendance.status == "Atendido" ? "has-background-success has-text-white ml-2 event-icon" : (attendance.status == "Aguard. atendimento" ? "has-background-info has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon") } >{attendance.status == "Atendido" ? "✓" : (attendance.status == "Aguard. atendimento" ? "⧗" : "✕")}</span>}
                            </Column>
                            <Column className="p-0 ml-1 is-hidden-desktop">
                              <span className="is-size-5 has-text-weight-bold" style={{ color: "#0074d3" }} >
                                Atend. {attendanceIndex + 1}:
                              </span>
                            </Column>
          
                            <Column narrow className="field-column date-input pl-0" >
                              <DatePicker
                                selected={new Date(attendance.start)}
                                onChange={(date) => { onDateChange(date) }}
                                customInput={
                                  <Components.DatePickerInput
                                    disableSelect={true}
                                    icon={faCalendar}
                                    dateError={attendance.dateError}
                                    setDateError={null}
                                  />}
                                dateFormat="dd/MM/yyyy"
                                locale="pt-BR"
                                strictParsing
                              />
                            </Column>
          
                            <Column narrow className="field-column pl-0" style={{ width: "220px", marginTop: "2px" }} >
                              <Column.Group vcentered breakpoint="mobile" className="m-0">
                                <Column className="p-0">
                                  <Components.TimeInput
                                    disableSelect={true}
                                    time={DateTime.fromJSDate((new Date(attendance.start))).toLocaleString(DateTime.TIME_24_SIMPLE) || ""}
                                    setTime={null}
                                    timeError={attendance.startTimeError}
                                    setTimeError={null}
                                  />
                                </Column>
                                <Column narrow className="py-0 px-2">
                                  às
                                </Column>
                                <Column className="p-0">
                                  <Components.TimeInput
                                    disableSelect={true}
                                    time={DateTime.fromJSDate((new Date(attendance.end))).toLocaleString(DateTime.TIME_24_SIMPLE) || ""}
                                    setTime={null}
                                    timeError={attendance.endTimeError}
                                    setTimeError={null}
                                  />
                                </Column>              
                              </Column.Group>
                            </Column>
          
                            {attendance.description != "" &&
                              <Column className="pl-0 py-1 is-12-touch" >
                                <Components.GenericInput
                                  noPadding={true}
                                  required={false}
                                  isTextarea={false}
                                  rows={1}
                                  disableSelect={true}
                                  size={12}
                                  inputSize={null}
                                  fontWeight={null}
                                  label={null}
                                  placeholder="Descrição do atendimento..."
                                  elementName="attendance-description"
                                  value={attendance.description}
                                  setValue={null}
                                  maskAndSetValue={null}
                                  setFormChanged={setFormChanged}
                                />
                              </Column>
                            }
          
                          </Column.Group>
                        }
                      </Column>
                    </Column.Group>
          
                    {attendance.professionalName && attendance.professionalName != "" &&
                      <Column.Group vcentered className="m-0">          
                        <Column className="p-0 mr-2 mb-2">
                          <Label className="ml-5">Profissional: <span className="has-text-weight-normal">{attendance.professionalName}</span></Label>
                        </Column>
                      </Column.Group>
                    }
                    {focusedItem == "patient" &&
                      <Button
                        className="ml-5 mb-2"
                        size="small"
                        color="link"
                        onClick={e => {setObjectToEdit(attendance); history.push("/app/edit-attendance"); setFocusedItem("attendance")}}
                      >
                        <Icon >
                          <FontAwesomeIcon icon={faEdit} />
                        </Icon>
                        <span>Editar Atendimento</span>
                      </Button>
                    }
          
                  </Card.Content>
                </Card>
              ))
            }

          </Card.Content>
        }

      </Card>
    )
  },

  CustomAttendanceCard: (props) => {

    const [newEventStart, setNewEventStart] = useState(props.customAttendance.startTime || "08:00")
    const [newEventEnd, setNewEventEnd] = useState(props.customAttendance.endTime || "08:00")
    const [timeError, setTimeError] = useState(null)

    const { attendanceAlreadyExists, attendancePackageId, onlyOneProfessional, customAttendance, customAttendanceIndex, setCustomAttendancesArray, showAttendanceScheduler, attendancesQuantity, setAttendancesQuantity, refreshComponent, setRefreshComponent, setFormChanged, setNotification, setLoading, setRedirectToLogin } = props

    const onProfessionalIdChange = (value) => {
      setCustomAttendancesArray((customAttendancesArray) => (
        customAttendancesArray.map((customAttendance, index) => {
          if (index == customAttendanceIndex) {
            customAttendance.professionalId = value
          }
          return customAttendance
        })
      ))
    }

    const onDescriptionChange = (value) => {
      setCustomAttendancesArray((customAttendancesArray) => (
        customAttendancesArray.map((customAttendance, index) => {
          if (index == customAttendanceIndex) {
            customAttendance.description = value
          }
          return customAttendance
        })
      ))
    }

    const onDateChange = (value) => {
      setCustomAttendancesArray((customAttendancesArray) => (
        customAttendancesArray.map((customAttendance, index) => {
          if (index == customAttendanceIndex) {
            customAttendance.date = value
          }
          return customAttendance
        })
      ))
    }

    const onDateErrorChange = (value) => {
      setCustomAttendancesArray((customAttendancesArray) => (
        customAttendancesArray.map((customAttendance, index) => {
          if (index == customAttendanceIndex) {
            customAttendance.dateError = value
          }
          return customAttendance
        })
      ))
    }

    const onStartTimeChange = (value) => {
      setCustomAttendancesArray((customAttendancesArray) => (
        customAttendancesArray.map((customAttendance, index) => {
          if (index == customAttendanceIndex) {
            customAttendance.startTime = value
          }
          return customAttendance
        })
      ))
      setNewEventStart(value)
    }

    const onStartTimeErrorChange = (value) => {
      setCustomAttendancesArray((customAttendancesArray) => (
        customAttendancesArray.map((customAttendance, index) => {
          if (index == customAttendanceIndex) {
            customAttendance.startTimeError = value
          }
          return customAttendance
        })
      ))
    }

    const onEndTimeChange = (value) => {
      setCustomAttendancesArray((customAttendancesArray) => (
        customAttendancesArray.map((customAttendance, index) => {
          if (index == customAttendanceIndex) {
            customAttendance.endTime = value
          }
          return customAttendance
        })
      ))
      setNewEventEnd(value)
    }

    const onEndTimeErrorChange = (value) => {
      setCustomAttendancesArray((customAttendancesArray) => (
        customAttendancesArray.map((customAttendance, index) => {
          if (index == customAttendanceIndex) {
            customAttendance.endTimeError = value
          }
          return customAttendance
        })
      ))
    }

    const onDelete = (event) => {
      let previousAttendancesQuantity = attendancesQuantity
      setNotification({
        withCancel: true,
        success: false,
        title: `Excluir Atendimento ${customAttendanceIndex + 1}`,
        text: `${customAttendance.attendanceDateTime ? "O atendimento já foi realizado. " : ""}Tem certeza que deseja excluir o Atendimento ${customAttendanceIndex + 1}?`,
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setLoading(true)
          setNotification(false)
          setCustomAttendancesArray((customAttendancesArray) => {
            setRefreshComponent(
              customAttendancesArray.filter((customAttendance, index) => {
                return index !== customAttendanceIndex
              })
            )
            return []
          })
          setAttendancesQuantity(attendancesQuantity - 1)
          if(attendanceAlreadyExists) {
            let submitInfo = {
              event: event,
              setNotification: setNotification,
              setLoading: setLoading,
              setOpenComponent: null,
              mandatoryFields: [],
              errorList: [],
              ServiceMethod: AttendancesService.deleteAttendance,
              params: {
                attendanceId: customAttendance._id,
                attendancePackageId: attendancePackageId,
              },
              onSuccessText: null,
              onErrorText: null,
              onSuccessCallback: null,
              onErrorCallback: null,
              setRedirectToLogin: setRedirectToLogin,
            }
            methods.handleSubmit(submitInfo)
            let submitInfo2 = {
              event: event,
              setNotification: setNotification,
              setLoading: setLoading,
              setOpenComponent: null,
              mandatoryFields: [],
              errorList: [],
              ServiceMethod: AttendancePackagesService.deleteAttendanceFromAttendancePackage,
              params: {
                attendancePackageId: attendancePackageId,
                attendanceId: customAttendance._id,
              },
              onSuccessText: `Atendimento excluído. O número de atendimentos do pacote agora é ${previousAttendancesQuantity - 1}. Para adicionar mais atendimentos, clique no botão 'Adicionar atendimentos' ao fim da página.`,
              onErrorText: "Não foi possível excluir o Atendimento. Tente novamente",
              onSuccessCallback: null,
              onErrorCallback: null,
              setRedirectToLogin: setRedirectToLogin,
            }
            methods.handleSubmit(submitInfo2)
          }
          setLoading(false)
        })
      })
    }

    useEffect(() => {
      let timeError = null
      if (DateTime.fromISO(customAttendance.startTime) >= DateTime.fromISO(customAttendance.endTime) && !customAttendance.timeError) {
        timeError = "A hora de início deve ser menor que a hora de término"
        setCustomAttendancesArray((customAttendancesArray) => (
          customAttendancesArray.map((customAttendance, index) => {
            if (index == customAttendanceIndex) {
              customAttendance.timeError = timeError
            }
            return customAttendance
          })
        ))
      } else if (!(DateTime.fromISO(customAttendance.startTime) >= DateTime.fromISO(customAttendance.endTime)) && customAttendance.timeError) {
        timeError = null
        setCustomAttendancesArray((customAttendancesArray) => (
          customAttendancesArray.map((customAttendance, index) => {
            if (index == customAttendanceIndex) {
              customAttendance.timeError = timeError
            }
            return customAttendance
          })
        ))
      }
    }, [newEventStart, newEventEnd])

    return (
      <Card className="my-3" style={{ backgroundColor: customAttendance.status == undefined ? "" : customAttendance.status == "Atendido" ? "#d4fdea" : customAttendance.status == "Aguard. atendimento" ? "#cfe9ff" : "#ffdce3" }} >
        <Card.Content style={{ paddingTop: "0.25rem", paddingBottom: "0.25rem", overflow: "initial" }} >

          <Column.Group breakpoint="mobile" vcentered className="m-0">
            <Column narrow className="p-0 is-hidden-touch">
              {customAttendance.status != undefined && <span className={customAttendance.status == "Atendido" ? "has-background-success has-text-white ml-2 event-icon" : (customAttendance.status == "Aguard. atendimento" ? "has-background-info has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon") } >{customAttendance.status == "Atendido" ? "✓" : (customAttendance.status == "Aguard. atendimento" ? "⧗" : "✕")}</span>}
            </Column>
            <Column narrow className="p-0 ml-1">
              <span className="is-size-5 has-text-weight-bold is-hidden-touch" style={{ color: "#0074d3" }} >
                Atend. {customAttendanceIndex + 1}:
              </span>
            </Column>
            <Column className="p-0 ml-1">
              {showAttendanceScheduler &&
                <Column.Group vcentered multiline breakpoint="mobile" className="my-0 ml-2"  >

                  <Column narrow className="p-0 is-hidden-desktop">
                    {customAttendance.status != undefined && <span className={customAttendance.status == "Atendido" ? "has-background-success has-text-white ml-2 event-icon" : (customAttendance.status == "Aguard. atendimento" ? "has-background-info has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon") } >{customAttendance.status == "Atendido" ? "✓" : (customAttendance.status == "Aguard. atendimento" ? "⧗" : "✕")}</span>}
                  </Column>
                  <Column className="p-0 ml-1 is-hidden-desktop">
                    <span className="is-size-5 has-text-weight-bold" style={{ color: "#0074d3" }} >
                      Atend. {customAttendanceIndex + 1}:
                    </span>
                  </Column>

                  <Column narrow className="field-column pl-0" style={{ minWidth: "132px", maxWidth: "132px" }} >
                    <DatePicker
                      selected={customAttendance.date}
                      onChange={(date) => { onDateChange(date); onDateErrorChange(null) }}
                      customInput={
                        <Components.DatePickerInput
                          icon={faCalendar}
                          dateError={customAttendance.dateError}
                          setDateError={onDateErrorChange}
                        />}
                      dateFormat="dd/MM/yyyy"
                      locale="pt-BR"
                      strictParsing
                    />
                  </Column>

                  <Column narrow className="field-column pl-0" style={{ width: "220px", marginTop: "2px" }} >
                    <Column.Group vcentered breakpoint="mobile" className="m-0">
                      <Column className="p-0">
                        <Components.TimeInput
                          time={customAttendance.startTime || ""}
                          setTime={onStartTimeChange}
                          timeError={customAttendance.startTimeError}
                          setTimeError={onStartTimeErrorChange}
                        />
                      </Column>
                      <Column narrow className="py-0 px-2">
                        às
                      </Column>
                      <Column className="p-0">
                        <Components.TimeInput
                          time={customAttendance.endTime || ""}
                          setTime={onEndTimeChange}
                          timeError={customAttendance.endTimeError}
                          setTimeError={onEndTimeErrorChange}
                        />
                      </Column>              
                    </Column.Group>
                  </Column>

                  <Column className="pl-0 py-1 is-12-touch" >
                    <Components.GenericInput
                      noPadding={true}
                      required={false}
                      isTextarea={false}
                      rows={1}
                      disableSelect={false}
                      size={12}
                      inputSize={null}
                      fontWeight={null}
                      label={null}
                      placeholder="Descrição do atendimento..."
                      elementName="attendance-description"
                      value={customAttendance.description}
                      setValue={onDescriptionChange}
                      maskAndSetValue={null}
                      setFormChanged={setFormChanged}
                    />
                  </Column>

                </Column.Group>                
              }

            </Column>
            <Column narrow className="p-0 pl-3" >
              <Delete size="medium" onClick={onDelete} />
            </Column>
          </Column.Group>

          <Column.Group vcentered className="m-0">

            {!onlyOneProfessional &&
              <Column className="p-0 mr-2 mb-2">
                <Label>Profissional:<span className="has-text-danger"> *</span></Label>
                <Components.FieldWithSearch
                  value={customAttendance.professionalName}
                  getValue={null}
                  placeholder="Digite parte do nome do profissional:"
                  resultId={customAttendance.professionalId}
                  setResultId={onProfessionalIdChange}
                  searchAndSetDropdownItensCallback={methods.searchAndSetProfessionalsDropdown}
                />
              </Column>
            }

          </Column.Group>

        </Card.Content>
      </Card>
    )
  },

  NewPackageTemplate: (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [price, setPrice] = useState("")
    const [attendancesQuantity, setAttendancesQuantity] = useState("")
    const [refreshCustomAttendances, setRefreshCustomAttendances] = useState("")
    const [showCustomAttendances, setShowCustomAttendances] = useState(false)
    const [customAttendancesArray, setCustomAttendancesArray] = useState([])

    const { setOpenNewPackageTemplate, getPackageTemplates, setFormChanged, setNotification, setLoading, setRedirectToLogin } = props

    useEffect(() => {
      if (refreshCustomAttendances != "") {
        setCustomAttendancesArray([])
        if (attendancesQuantity > 0) {
          for (let i = 0; i < attendancesQuantity; i++) {
            setCustomAttendancesArray((customAttendancesArray) => {
              customAttendancesArray.push({
                professionalId: null,
                description: ""
              })
              return customAttendancesArray
            })
          }
        }
      }
    }, [refreshCustomAttendances])

    useEffect(() => {
      if (showCustomAttendances && !attendancesQuantity > 0) {
        setAttendancesQuantity(1)
        setRefreshCustomAttendances(refreshCustomAttendances + "x")
      }
    }, [showCustomAttendances])

    const changeAttendancesQuantity = (value) => {
      setAttendancesQuantity(value)
      setRefreshCustomAttendances(refreshCustomAttendances + "x")
    }

    const handleSubmit = async (event) => {
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setOpenNewPackageTemplate,
        mandatoryFields: [name],
        errorList: [],
        ServiceMethod: AttendancePackageTemplatesService.newAttendancePackageTemplate,
        params: {
          name: name,
          description: description,
          price: methods.priceToNumber(price),
          attendancesQuantity: attendancesQuantity,
          customAttendancesArray: customAttendancesArray,
        },
        onSuccessText: "Modelo de Pacote cadastrado.",
        onErrorText: "Não foi possível atualizar o modelo de pacote. Tente novamente",
        onSuccessCallback: async (response) => {
          await getPackageTemplates()
        },
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }
      methods.handleSubmit(submitInfo)
    }

    return (
      <Fragment>
        <Components.CardWithOverlay
          cardType="widerViewer"
          headerClassName={"has-background-success"}
          title="Novo Modelo de Pacote"
          titleColorClassName="has-text-white"
          icon={faPlus}
          setOpenCard={setOpenNewPackageTemplate}
        >
          <Column.Group vcentered multiline className="mt-0">
            <Components.GenericInput
              required={true}
              isTextarea={false}
              rows={1}
              disableSelect={false}
              size={6}
              inputSize="medium"
              fontWeight="bold"
              label="Nome do pacote:"
              placeholder="Nome do pacote..."
              elementName="package-model-name"
              value={name}
              setValue={setName}
              maskAndSetValue={null}
              setFormChanged={setFormChanged}
            />
            <Components.GenericInput
              isTextarea
              rows={1}
              disableSelect={false}
              size={6}
              inputSize={null}
              label="Descrição:"
              placeholder="Descrição..."
              elementName="package-model-description"
              value={description}
              setValue={setDescription}
              maskAndSetValue={null}
              setFormChanged={setFormChanged}
            />
            <Components.GenericInput
              disableSelect={false}
              size={6}
              inputSize={null}
              label="Preço:"
              placeholder="R$ ..."
              elementName="price"
              value={price}
              setValue={setPrice}
              maskAndSetValue={methods.maskAndSetPrice}
              setFormChanged={setFormChanged}
            />
            <Components.GenericInput
              disableSelect={false}
              size={6}
              inputSize={null}
              label="Quantidade de atendimentos:"
              placeholder="Número de atendimentos..."
              value={attendancesQuantity}
              setValue={changeAttendancesQuantity}
              maskAndSetValue={methods.maskAndSetAttendancesQuantity}
              setFormChanged={setFormChanged}
            />
            <Column size={12}>
              <Components.Checkbox checked={showCustomAttendances} onChange={setShowCustomAttendances} setFormChanged={setFormChanged} color="primary" noPadding={true} >
                Personalizar Atendimentos
              </Components.Checkbox>
            </Column>
            <Column size={12}>
              {showCustomAttendances &&
                <Fragment>
                  {customAttendancesArray.map((customAttendance, index) => (
                    <Components.CustomAttendanceCard
                      key={index}
                      customAttendance={customAttendance}
                      customAttendanceIndex={index}
                      setCustomAttendancesArray={setCustomAttendancesArray}
                      setFormChanged={setFormChanged}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  ))}
                  <Button
                    color="success"
                    size="small"
                    className="has-text-weight-semibold"
                    onClick={() => {
                      setShowCustomAttendances(showCustomAttendances.toString() + "att")
                      setAttendancesQuantity(parseInt(attendancesQuantity) + 1)
                      setCustomAttendancesArray((customAttendancesArray) => {
                        customAttendancesArray.push({
                          professionalId: null,
                          description: ""
                        })
                        return customAttendancesArray
                      })
                    }}
                  >
                    <Icon size="small">
                      <FontAwesomeIcon icon={faPlus} />
                    </Icon>
                    <span>Adicionar atendimento</span>
                  </Button>
                </Fragment>
              }
            </Column>
          </Column.Group>
          <Button.Group align="centered">
            <Button
              color="info"
              size="medium"
              className="has-text-weight-semibold"
              onClick={handleSubmit}
            >
              <span>Salvar</span>
            </Button>
          </Button.Group>
        </Components.CardWithOverlay>
      </Fragment>
    )
  },

  PackageTemplateResults: (props) => {

    const { packageTemplates, setOpenEditPackageTemplate, getPackageTemplates, setFormChanged, setNotification, setLoading, setRedirectToLogin } = props

    const onDelete = (event, packageTemplate) => {
      setNotification({
        withCancel: true,
        success: false,
        title: `Excluir Modelo de Pacote`,
        text: `Tem certeza que deseja excluir o Modelo de Pacote "${packageTemplate.name}?"`,
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          setNotification(false)
          setLoading(true)
          let submitInfo = {
            event: event,
            setNotification: setNotification,
            setLoading: setLoading,
            setOpenComponent: null,
            mandatoryFields: [],
            errorList: [],
            ServiceMethod: AttendancePackageTemplatesService.deleteAttendancePackageTemplate,
            params: {
              packageTemplateId: packageTemplate._id,
            },
            onSuccessText: "Modelo de pacote excluído.",
            onErrorText: "Não foi possível excluir o Modelo de pacote. Tente novamente",
            onSuccessCallback: (response) => {
              getPackageTemplates()
            },
            onErrorCallback: null,
            setRedirectToLogin: setRedirectToLogin,
          }
          methods.handleSubmit(submitInfo)
        })
      })
    }

    if (!packageTemplates[0]) {
      return (
        <Title size={5} className="has-text-centered has-text-info" >
          Ainda não há modelos de pacotes de atendimentos cadastrados.
        </Title>
      )
    }

    return (
      <Column.Group>
        <Column className="table-container is-size-6">

          <Table fullwidth hoverable narrow>

            <Table.Head>
              <Table.Row>
                <Table.Heading textAlign="centered" >
                  Nome
                </Table.Heading>
                <Table.Heading>
                  Descrição
                </Table.Heading>
                <Table.Heading textAlign="centered" style={{ width: "105px" }}>
                  Preço
                </Table.Heading>
                <Table.Heading textAlign="centered" >
                  Atend.
                </Table.Heading>
                <Table.Heading textAlign="centered" className="table-column-small" >
                  Excluir
                </Table.Heading>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {packageTemplates.map((packageTemplate, key) => {
                return (
                  <Table.Row key={key} className="cursor-pointer" >
                    <Table.Cell textAlign="centered" className="has-text-weight-bold" onClick={e => setOpenEditPackageTemplate(packageTemplate)}>
                      {packageTemplate.name}
                    </Table.Cell>
                    <Table.Cell onClick={e => setOpenEditPackageTemplate(packageTemplate)}>
                      {methods.stringCut(packageTemplate.description, 50)}
                    </Table.Cell>
                    <Table.Cell onClick={e => setOpenEditPackageTemplate(packageTemplate)}>
                      {methods.numberToPrice(packageTemplate.price)}
                    </Table.Cell>
                    <Table.Cell textAlign="centered" onClick={e => setOpenEditPackageTemplate(packageTemplate)}>
                      {packageTemplate.attendancesQuantity}
                    </Table.Cell>
                    <Table.Cell textAlign="centered">
                      <Icon className="cursor-pointer" color="danger" onClick={event => onDelete(event, packageTemplate)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Icon>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>

          </Table>

        </Column>
      </Column.Group>
    )
  },

  PackageTemplatesToChoose: (props) => {

    const { packageTemplates, setChosenPackageTemplate, setOpenPackageTemplatesToChoose, setOpenNewAttendancePackage, setFormChanged, setNotification, setLoading, setRedirectToLogin } = props

    return (
      <Components.CardWithOverlay
        cardType="widerViewer"
        headerClassName={"has-background-success"}
        title="Novo Pacote de Atendimentos"
        titleColorClassName="has-text-white"
        icon={faPlus}
        setOpenCard={setOpenPackageTemplatesToChoose}
      >

        <Column.Group>
          <Column className="table-container is-size-6">

            <Table fullwidth hoverable narrow>
              
              <Table.Head>
                <Table.Row>
                  <Table.Heading textAlign="centered" >
                    Nome
                  </Table.Heading>
                  <Table.Heading textAlign="centered" >
                    Descrição
                  </Table.Heading>
                  <Table.Heading textAlign="centered">
                    Atend.
                  </Table.Heading>
                  <Table.Heading textAlign="centered">
                    Preço
                  </Table.Heading>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                {!packageTemplates[0] &&
                  <Table.Row>
                    <Table.Cell textAlign="centered" className="has-text-weight-bold">
                      <Title size={5} className="has-text-centered has-text-info" >
                        Ainda não há modelos de pacotes de atendimentos cadastrados.
                      </Title>
                    </Table.Cell>
                  </Table.Row>
                  ||
                  packageTemplates.map((packageTemplate, key) => {
                    return (
                      <Table.Row key={key} className="cursor-pointer" onClick={() => {setChosenPackageTemplate(packageTemplate); setOpenNewAttendancePackage(true)}}>
                        <Table.Cell textAlign="centered" className="has-text-weight-bold">
                          {packageTemplate.name}
                        </Table.Cell>
                        <Table.Cell>
                          {methods.stringCut(packageTemplate.description, 50)}
                        </Table.Cell>
                        <Table.Cell textAlign="centered">
                          {packageTemplate.attendancesQuantity}
                        </Table.Cell>
                        <Table.Cell>
                          {methods.numberToPrice(packageTemplate.price)}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                }                
              </Table.Body>

            </Table>

          </Column>
        </Column.Group>

      </Components.CardWithOverlay>
    )
  },

  EditPackageTemplate: (props) => {
    const [refreshComponent, setRefreshComponent] = useState(null)

    const [name, setName] = useState(props.openEditPackageTemplate.name)
    const [description, setDescription] = useState(props.openEditPackageTemplate.description)
    const [price, setPrice] = useState(methods.numberToPrice(props.openEditPackageTemplate.price))
    const [attendancesQuantity, setAttendancesQuantity] = useState(props.openEditPackageTemplate.attendancesQuantity)
    const [refreshCustomAttendances, setRefreshCustomAttendances] = useState("")
    const [showCustomAttendances, setShowCustomAttendances] = useState(false)
    const [customAttendancesArray, setCustomAttendancesArray] = useState(props.openEditPackageTemplate.customAttendancesArray)

    const { openEditPackageTemplate, setOpenEditPackageTemplate, getPackageTemplates, setFormChanged, setNotification, setLoading, setRedirectToLogin } = props

    useEffect(() => {
      if (refreshCustomAttendances != "") {
        setCustomAttendancesArray([])
        if (attendancesQuantity > 0) {
          for (let i = 0; i < attendancesQuantity; i++) {
            setCustomAttendancesArray((customAttendancesArray) => {
              customAttendancesArray.push({
                professionalId: null,
                description: ""
              })
              return customAttendancesArray
            })
          }
        }
      }
    }, [refreshCustomAttendances])

    useEffect(() => {
      if (showCustomAttendances && !attendancesQuantity > 0) {
        setAttendancesQuantity(1)
        setRefreshCustomAttendances(refreshCustomAttendances + "x")
      }
    }, [showCustomAttendances])

    useEffect(() => {
      if (refreshComponent)
        setCustomAttendancesArray(refreshComponent)
    }, [refreshComponent])

    const changeAttendancesQuantity = (value) => {
      setAttendancesQuantity(value)
      setRefreshCustomAttendances(refreshCustomAttendances + "x")
    }

    const handleSubmit = async (event) => {
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setOpenEditPackageTemplate,
        mandatoryFields: [name],
        errorList: [],
        ServiceMethod: AttendancePackageTemplatesService.editAttendancePackageTemplate,
        params: {
          packageTemplateId: openEditPackageTemplate._id,
          name: name,
          description: description,
          price: methods.priceToNumber(price),
          attendancesQuantity: attendancesQuantity,
          customAttendancesArray: customAttendancesArray,
        },
        onSuccessText: "Modelo de Pacote atualizado.",
        onErrorText: "Não foi possível atualizar o modelo de pacote. Tente novamente",
        onSuccessCallback: (response) => {
          getPackageTemplates()
        },
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }
      methods.handleSubmit(submitInfo)
    }

    return (
      <Components.CardWithOverlay
        cardType="widerViewer"
        headerClassName={"has-background-warning"}
        title="Editar Modelo de Pacote"
        titleColorClassName="has-text-dark"
        icon={faEdit}
        setOpenCard={setOpenEditPackageTemplate}
      >
        <Column.Group vcentered multiline className="mt-0">
          <Components.GenericInput
            required={true}
            isTextarea={false}
            rows={1}
            disableSelect={false}
            size={6}
            inputSize="medium"
            fontWeight="bold"
            label="Nome do pacote:"
            placeholder="Nome do pacote..."
            elementName="package-model-name"
            value={name}
            setValue={setName}
            maskAndSetValue={null}
            setFormChanged={setFormChanged}
          />
          <Components.GenericInput
            isTextarea
            rows={1}
            disableSelect={false}
            size={6}
            inputSize={null}
            label="Descrição:"
            placeholder="Descrição..."
            elementName="package-model-description"
            value={description}
            setValue={setDescription}
            maskAndSetValue={null}
            setFormChanged={setFormChanged}
          />
          <Components.GenericInput
            disableSelect={false}
            size={6}
            inputSize={null}
            label="Preço:"
            placeholder="R$ ..."
            elementName="price"
            value={price}
            setValue={setPrice}
            maskAndSetValue={methods.maskAndSetPrice}
            setFormChanged={setFormChanged}
          />
          <Components.GenericInput
            disableSelect={false}
            size={6}
            inputSize={null}
            label="Quantidade de atendimentos:"
            placeholder="Número de atendimentos..."
            value={attendancesQuantity}
            setValue={changeAttendancesQuantity}
            maskAndSetValue={methods.maskAndSetAttendancesQuantity}
            setFormChanged={setFormChanged}
          />
          <Column size={12}>
            <Components.Checkbox checked={showCustomAttendances} onChange={setShowCustomAttendances} setFormChanged={setFormChanged} color="primary" noPadding={true} >
              Personalizar Atendimentos
            </Components.Checkbox>
          </Column>
          <Column size={12}>
            {showCustomAttendances &&
              <Fragment>
                {customAttendancesArray.map((customAttendance, index) => (
                  <Components.CustomAttendanceCard
                    key={index}
                    customAttendance={customAttendance}
                    customAttendanceIndex={index}
                    setCustomAttendancesArray={setCustomAttendancesArray}
                    attendancesQuantity={attendancesQuantity}
                    setAttendancesQuantity={setAttendancesQuantity}
                    refreshComponent={refreshComponent}
                    setRefreshComponent={setRefreshComponent}
                    setFormChanged={setFormChanged}
                    setNotification={setNotification}
                    setLoading={setLoading}
                    setRedirectToLogin={setRedirectToLogin}
                  />
                ))}
                <Button
                  color="success"
                  size="small"
                  className="has-text-weight-semibold"
                  onClick={() => {
                    setShowCustomAttendances(showCustomAttendances.toString() + "att")
                    setAttendancesQuantity(parseInt(attendancesQuantity) + 1)
                    setCustomAttendancesArray((customAttendancesArray) => {
                      customAttendancesArray.push({
                        professionalId: null,
                        description: ""
                      })
                      return customAttendancesArray
                    })
                  }}
                >
                  <Icon size="small">
                    <FontAwesomeIcon icon={faPlus} />
                  </Icon>
                  <span>Adicionar atendimento</span>
                </Button>
              </Fragment>
            }
          </Column>
        </Column.Group>
        <Button.Group align="centered">
          <Button
            color="info"
            size="medium"
            className="has-text-weight-semibold"
            onClick={handleSubmit}
          >
            <span>Salvar</span>
          </Button>
        </Button.Group>
      </Components.CardWithOverlay>
    )
  },

  NewAttendancePackage: (props) => {

    const [refreshComponent, setRefreshComponent] = useState(null)

    const [patient, setPatient] = useState("")
    const [patientId, setPatientId] = useState(null)
    const [name, setName] = useState((props.chosenPackageTemplate && props.chosenPackageTemplate.name) || "")
    const [description, setDescription] = useState((props.chosenPackageTemplate && props.chosenPackageTemplate.description) || "")
    const [price, setPrice] = useState((props.chosenPackageTemplate && methods.numberToPrice(props.chosenPackageTemplate.price)) || "")
    const [attendancesQuantity, setAttendancesQuantity] = useState((props.chosenPackageTemplate && props.chosenPackageTemplate.attendancesQuantity) || "")
    const [refreshCustomAttendances, setRefreshCustomAttendances] = useState("")
    const [customAttendancesArray, setCustomAttendancesArray] = useState((props.chosenPackageTemplate && props.chosenPackageTemplate.customAttendancesArray) || [])
    const [onlyOneProfessional, setOnlyOneProfessional] = useState(props.chosenPackageTemplate.customAttendancesArray.filter(customAttendance => customAttendance.professionalId)[0] ? false : true)
    const [professional, setProfessional] = useState("")
    const [professionalId, setProfessionalId] = useState(null)
    const [showAttendanceScheduler, setShowAttendanceScheduler] = useState(true)

    // States referentes ao sistema de datas:

    const [newEventDay, setNewEventDay] = useState(new Date(Date.now()))
    const [newEventDayError, setNewEventDayError] = useState(null)
    const [newEventStart, setNewEventStart] = useState(DateTime.fromJSDate((new Date(new Date(Date.now()).setHours(8, 0, 0)))).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newEventStartError, setNewEventStartError] = useState(null)
    const [newEventEnd, setNewEventEnd] = useState(DateTime.fromJSDate((new Date(new Date(Date.now()).setHours(9, 0, 0)))).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newEventEndError, setNewEventEndError] = useState(null)
    const [timeError, setTimeError] = useState(null)

    const [repeat, setRepeat] = useState(true)
    const [repeatFrequency, setRepeatFrequency] = useState(1)
    const [repeatFrequencyError, setRepeatFrequencyError] = useState(false)
    const [repeatType, setRepeatType] = useState("semana")
    const [monthRepeatType, setMonthRepeatType] = useState("0")
    const [repeatTimes, setRepeatTimes] = useState(2)
    const [repeatTimesError, setRepeatTimesError] = useState(false)

    const [sunday, setSunday] = useState(false)
    const [monday, setMonday] = useState(false)
    const [tuesday , setTuesday] = useState(false)
    const [wednesday , setWednesday] = useState(false)
    const [thursday , setThursday] = useState(false)
    const [friday , setFriday] = useState(false)
    const [saturday , setSaturday] = useState(false)

    const [weekdayOfMonth, setWeekdayOfMonth] = useState(null)
    const [positionOfWeekdayOnMonth, setPositionOfWeekdayOnMonth] = useState(null)
    const [monthPositionText, setMonthPositionText] = useState(null)
    const [generateDatesState, setGenerateDatesState] = useState(0)

    const [refresher, setRefresher] = useState(0)

    const { chosenPackageTemplate, setOpenNewAttendancePackage, getAndSetEvents, setFormChanged, setNotification, setLoading, setRedirectToLogin } = props

    useEffect(() => {
      if (refreshCustomAttendances != "") {
        if (attendancesQuantity > 0) {
          setCustomAttendancesArray((customAttendancesArray) => {
            for (let i = 0; i < attendancesQuantity; i++) {
              customAttendancesArray.push({
                professionalName: "",
                professionalId: null,
                description: ""
              })
            }
            return customAttendancesArray
          })
          setRefresher(refresher + 1)
        }
        //setGenerateDatesState(generateDatesState + 1)
      }
    }, [refreshCustomAttendances])

    const changeAttendancesQuantity = (value) => {
      setCustomAttendancesArray([])
      setAttendancesQuantity(value)
      setRefreshCustomAttendances(refreshCustomAttendances + "x")
    }

    useEffect(() => {
      if (refreshComponent)
        setCustomAttendancesArray(refreshComponent)
    }, [refreshComponent])

    // Funções referentes ao sistema de datas:

    Number.prototype.nth = function() {
      switch (this) {
        case 1: return "1"
        case 2: return "2"
        case 3: return "3"
        case 4: return "4"
        case 5: return "última"
      }
      return "última"
    }
    
    Date.prototype.posOfWeekdayOnMonth = function () {      
      var today = this.getDate()
      var m = this.getMonth()
      var weekday = ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'][this.getDay()]
      var month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][m]
      var weekdayPosition = Math.ceil(today/7).nth()
      if(weekdayPosition != "última") {
        if (weekday == "domingo" || weekday == "sábado") {
          weekdayPosition = weekdayPosition + "º"
        } else {
          weekdayPosition = weekdayPosition + "ª"
        }
      } else if (weekday == "domingo" || weekday == "sábado") {
        weekdayPosition = "último"
      }
      switch (Math.ceil(today/7).nth()) {
        case "1": setPositionOfWeekdayOnMonth(1)
        case "2": setPositionOfWeekdayOnMonth(2)
        case "3": setPositionOfWeekdayOnMonth(3)
        case "4": setPositionOfWeekdayOnMonth(4)
        case "última": setPositionOfWeekdayOnMonth(5)
      }
      setWeekdayOfMonth(weekday)
      return [`${weekday == "domingo" || weekday == "sábado" ? "Todo" : "Toda"}`, weekdayPosition, weekday].join(' ')
    }

    useEffect(() => {
      switch (newEventDay.getDay()) {
        case 0:
          setSunday(true)
          break
        case 1:
          setMonday(true)
          break
        case 2:
          setTuesday(true)
          break
        case 3:
          setWednesday(true)
          break
        case 4:
          setThursday(true)
          break
        case 5:
          setFriday(true)
          break
        case 6:
          setSaturday(true)
          break          
      }
    }, [])

    useEffect(() => {
      const weekday = newEventDay ? newEventDay.getDay() : null
      if (weekday == 0 && !sunday) {
        setSunday(true)
      } else if (weekday != 0 && sunday) {
        setSunday(false)
      }
      if (weekday == 1 && !monday) {
        setMonday(true)
      } else if (weekday != 1 && monday) {
        setMonday(false)
      }
      if (weekday == 2 && !tuesday) {
        setTuesday(true)
      } else if (weekday != 2 && tuesday) {
        setTuesday(false)
      }
      if (weekday == 3 && !wednesday) {
        setWednesday(true)
      } else if (weekday != 3 && wednesday) {
        setWednesday(false)
      }
      if (weekday == 4 && !thursday) {
        setThursday(true)
      } else if (weekday != 4 && thursday) {
        setThursday(false)
      }
      if (weekday == 5 && !friday) {
        setFriday(true)
      } else if (weekday != 5 && friday) {
        setFriday(false)
      }
      if (weekday == 6 && !saturday) {
        setSaturday(true)
      } else if (weekday != 6 && saturday) {
        setSaturday(false)
      }
      setMonthPositionText(new Date(newEventDay).posOfWeekdayOnMonth())
      //setGenerateDatesState(generateDatesState + 1)
    }, [newEventDay])

    useEffect(() => {
      const dt = DateTime.fromJSDate(newEventDay)
      if (!sunday && !monday && !tuesday && !wednesday && !thursday && !friday && !saturday) {
        switch (newEventDay && newEventDay.getDay()) {
          case 0:
            setSunday(true)
            break
          case 1:
            setMonday(true)
            break
          case 2:
            setTuesday(true)
            break
          case 3:
            setWednesday(true)
            break
          case 4:
            setThursday(true)
            break
          case 5:
            setFriday(true)
            break
          case 6:
            setSaturday(true)
            break
        }
      } else {
        switch (newEventDay.getDay()) {
          case 0:
            if (!sunday) {
              if (monday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 5 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 6 }).toISO()))
              }
            }
            break
          case 1:
            if (!monday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 5 }).toISO()))
              }
            }
            break
          case 2:
            if (!tuesday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              }
            }
            break
          case 3:
            if (!wednesday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              }
            }
            break
          case 4:
            if (!thursday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              }
            }
            break
          case 5:
            if (!friday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 5 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              }
            }
            break
          case 6:
            if (!saturday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 6 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 5 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              }
            }
          break
        }
      }
      //setGenerateDatesState(generateDatesState + 1)
    }, [sunday, monday, tuesday, wednesday, thursday, friday, saturday])

    useEffect(() => {
      if (DateTime.fromISO(newEventStart) >= DateTime.fromISO(newEventEnd)) {
        setTimeError("A hora de início deve ser menor que a hora de término")
      } else {
        setTimeError(null)
        if (newEventStart != "" && !newEventStartError && newEventEnd != "" && !newEventEndError) {
          //setGenerateDatesState(generateDatesState + 1)
        }
      }
    }, [newEventStart, newEventEnd])

    useEffect(() => {
      if (repeatFrequency == 1 && repeatType == "dias") {
        setRepeatType("dia")
      }
      if (repeatFrequency == 1 && repeatType == "semanas") {
        setRepeatType("semana")
      }
      if (repeatFrequency == 1 && repeatType == "meses") {
        setRepeatType("mês")
      }
      if (repeatFrequency == 1 && repeatType == "anos") {
        setRepeatType("ano")
      }
      if (repeatFrequency > 1 && repeatType == "dia") {
        setRepeatType("dias")
      }
      if (repeatFrequency > 1 && repeatType == "semana") {
        setRepeatType("semanas")
      }
      if (repeatFrequency > 1 && repeatType == "mês") {
        setRepeatType("meses")
      }
      if (repeatFrequency > 1 && repeatType == "ano") {
        setRepeatType("anos")
      }
      //setGenerateDatesState(generateDatesState + 1)
    }, [repeatFrequency])

    const generateDates = () => {
      let eventsArray = []
      let recurrenceWeekdays = []
      if (sunday) recurrenceWeekdays.push(0)
      if (monday) recurrenceWeekdays.push(1)
      if (tuesday) recurrenceWeekdays.push(2)
      if (wednesday) recurrenceWeekdays.push(3)
      if (thursday) recurrenceWeekdays.push(4)
      if (friday) recurrenceWeekdays.push(5)
      if (saturday) recurrenceWeekdays.push(6)
      let currentWeekdayIndex = 0
      let currentWeek = 0
      let testWeekdayPosition = null
      let testDate = DateTime.fromJSDate(newEventDay)
      let testMonth = null
      let testYear = null
      let weeksToAdd = 0
      for (let i = 0; i < attendancesQuantity; i++) {
        let timeAddition = {
          days: 0,
          weeks: 0,
          months: 0,
          years: 0,
        }
        let timeSubtraction = {
          days: 0,
          weeks: 0,
          months: 0,
          years: 0,
        }
        let timeToAdd = repeatFrequency * i
        if (/dia/.test(repeatType)) {
          timeAddition.days = timeToAdd
        } else if (/semana/.test(repeatType)) {
          if (!recurrenceWeekdays[0]) {
            timeAddition.weeks = timeToAdd
          } else {
            if (currentWeekdayIndex > recurrenceWeekdays.length - 1) {
              currentWeekdayIndex = 0
              currentWeek++
            }
            if (i == 0) {
              for (let j = 0; j < 7; j++) {
                if (newEventDay.getDay() > recurrenceWeekdays[currentWeekdayIndex]) {
                  currentWeekdayIndex++
                }
              }
            }
            if (recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay() >= 0) {
              timeAddition.days = recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay()
            } else {
              timeSubtraction.days = (recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay()) * (-1)
            }
            timeAddition.weeks = currentWeek * repeatFrequency
            currentWeekdayIndex++
          }
        } else if (/mês|mes/.test(repeatType)) {
          if (/dia/.test(monthRepeatType)) {
            timeAddition.months = timeToAdd
          } else {
            const getNewMonth = (month, monthsToAdd) => {
              return month + monthsToAdd - 12 * Math.floor((month + monthsToAdd)/12)
            }
            const weekdayPosition = Math.ceil(newEventDay.getDate()/7).nth()
            const originalMonth = newEventDay.getMonth()
            if (timeToAdd != 0) {
              while (testWeekdayPosition != weekdayPosition || testMonth != getNewMonth(originalMonth, timeToAdd)) {
                testDate = testDate.plus({ days: 7 })
                if (weekdayPosition != "última") {
                  testWeekdayPosition = Math.ceil(new Date(`${testDate.toISODate()}T${newEventStart}`).getDate()/7).nth()
                } else {
                  let testDate2 = testDate.plus({ days: 7 })
                  if (new Date(`${testDate.toISODate()}T${newEventStart}`).getMonth() != new Date(`${testDate2.toISODate()}T${newEventStart}`).getMonth()) {
                    testWeekdayPosition = "última"
                  } else {
                    testWeekdayPosition = Math.ceil(new Date(`${testDate.toISODate()}T${newEventStart}`).getDate()/7).nth()
                  }
                }
                testMonth = new Date(`${testDate.toISODate()}T${newEventStart}`).getMonth()
                testYear = new Date(`${testDate.toISODate()}T${newEventStart}`).getFullYear()
                weeksToAdd += 1
              }
            }
            timeAddition.weeks = weeksToAdd
          }
        } else if (/ano/.test(repeatType)) {
          timeAddition.years = timeToAdd
        }
        let eventToPush = {
          start: new Date(`${DateTime.fromJSDate(newEventDay).plus(timeAddition).minus(timeSubtraction).toISODate()}T${newEventStart}`),
          end: new Date(`${DateTime.fromJSDate(newEventDay).plus(timeAddition).minus(timeSubtraction).toISODate()}T${newEventEnd}`),
        }
        eventsArray.push(eventToPush)
      }
      setCustomAttendancesArray((customAttendancesArray) => {
        setRefreshComponent(
          customAttendancesArray.map((customAttendance, index) => {
            customAttendance.date = eventsArray[index].start
            customAttendance.startTime = newEventStart
            customAttendance.endTime = newEventEnd
            return customAttendance
          })
        )
        return []
      })
    }

    useEffect(() => {
      if (generateDatesState != 0 && newEventDay) {
        generateDates()
      }
    }, [generateDatesState, ]) //repeatType, monthRepeatType

    const handleSubmit = async (event) => {
      let params = {
        patientId: patientId,
        name: name,
        description: description,
        price: methods.priceToNumber(price),
        attendancesQuantity: attendancesQuantity,
        scheduleAttendances: showAttendanceScheduler,
      }
      let mandatoryFields = [patientId, name, price, attendancesQuantity]
      let errorList = [timeError, newEventDayError, newEventStartError, newEventEndError, repeatFrequencyError]
      if (showAttendanceScheduler) {
        params.attendancesArray = [ ...customAttendancesArray ]
        if (onlyOneProfessional) {
          mandatoryFields.push(professionalId)
          params.attendancesArray = params.attendancesArray.map((customAttendance) => {
            customAttendance.professionalId = professionalId
            return customAttendance
          })
        } else {
          params.attendancesArray.map((customAttendance) => {
            mandatoryFields.push(customAttendance.professionalId)
          })
        }
        params.attendancesArray.map((customAttendance) => {
          if (customAttendance.date || customAttendance.startTime || customAttendance.endTime) {
            mandatoryFields.push(customAttendance.date)
            mandatoryFields.push(customAttendance.startTime)
            mandatoryFields.push(customAttendance.endTime)
          }
          errorList.push(customAttendance.dateError)
          errorList.push(customAttendance.startTimeError)
          errorList.push(customAttendance.endTimeError)
          if (DateTime.fromISO(customAttendance.startTime) >= DateTime.fromISO(customAttendance.endTime)) {
            customAttendance.timeError = "A hora de início deve ser menor que a hora de término"
          } else {
            customAttendance.timeError = null
          }
          errorList.push(customAttendance.timeError)
        })
        params.attendancesArray = params.attendancesArray.map((customAttendance) => {
          customAttendance.start = new Date(`${DateTime.fromJSDate(customAttendance.date).toISODate()}T${customAttendance.startTime}`)
          customAttendance.end = new Date(`${DateTime.fromJSDate(customAttendance.date).toISODate()}T${customAttendance.endTime}`)
          return customAttendance
        })
      }
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setOpenNewAttendancePackage,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: AttendancePackagesService.newAttendancePackage,
        params: params,
        onSuccessText: "Pacote de atendimentos cadastrado.",
        onErrorText: "Não foi possível cadastrar o pacote de atendimentos. Tente novamente",
        onSuccessCallback: () => {if(getAndSetEvents){getAndSetEvents()}},
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }
      methods.handleSubmit(submitInfo)
    }

    return (
      <Fragment>

        <div className="is-hidden">{refresher}</div>

        <Components.CardWithOverlay
          cardType="fullViewer"
          headerClassName={"has-background-success"}
          title="Novo Pacote de Atendimentos"
          titleColorClassName="has-text-white"
          icon={faPlus}
          setOpenCard={setOpenNewAttendancePackage}
        >
          <Column.Group vcentered multiline className="mt-0">
            <Column size={12}>
              <Label>Paciente:<span className="has-text-danger"> *</span></Label>
              <Components.FieldWithSearch
                value={patient}
                getValue={setPatient}
                placeholder="Digite parte do nome do paciente:"
                resultId={patientId}
                setResultId={setPatientId}
                searchAndSetDropdownItensCallback={methods.searchAndSetPatientsDropdown}
              />
            </Column>
            <Components.GenericInput
              required={true}
              isTextarea={false}
              rows={1}
              disableSelect={false}
              size={6}
              inputSize={null}
              fontWeight="bold"
              label="Nome do pacote:"
              placeholder="Nome do pacote..."
              elementName="package-model-name"
              value={name}
              setValue={setName}
              maskAndSetValue={null}
              setFormChanged={setFormChanged}
            />
            <Components.GenericInput
              isTextarea
              rows={1}
              disableSelect={false}
              size={6}
              inputSize={null}
              label="Descrição:"
              placeholder="Descrição..."
              elementName="package-model-description"
              value={description}
              setValue={setDescription}
              maskAndSetValue={null}
              setFormChanged={setFormChanged}
            />
            <Components.GenericInput
              required={true}
              disableSelect={false}
              size={6}
              inputSize={null}
              label="Preço:"
              placeholder="R$ ..."
              elementName="price"
              value={price}
              setValue={setPrice}
              maskAndSetValue={methods.maskAndSetPrice}
              setFormChanged={setFormChanged}
            />
            <Components.GenericInput
              required={true}
              disableSelect={false}
              size={6}
              inputSize={null}
              label="Quantidade de atendimentos:"
              placeholder="Número de atendimentos..."
              value={attendancesQuantity}
              setValue={changeAttendancesQuantity}
              maskAndSetValue={methods.maskAndSetAttendancesQuantity}
              setFormChanged={setFormChanged}
            />

            <Column size={12}>
              <hr className="form-divider mb-0" />
            </Column>

            <Column size={12}>
              <Column.Group multiline vcentered>

                <Fragment>

                  <div style={{ border: "2px solid #ccc", borderRadius: "5px", backgroundColor: "#eaffea", marginBottom: "1rem", width: "100%" }}>

                    {showAttendanceScheduler &&
                      <Fragment>

                        <Column size={12}>

                          <Column.Group multiline vcentered>

                            <Column className="field-column mt-4 mb-3">
                              <Column.Group multiline breakpoint="mobile" >

                                <Column narrow className="field-column date-input" >
                                  <Label>Data inicial:</Label>
                                  <DatePicker
                                    selected={newEventDay}
                                    onChange={(date) => { setNewEventDay(date) }}
                                    customInput={
                                      <Components.DatePickerInput
                                        icon={faCalendar}
                                        dateError={newEventDayError}
                                        setDateError={setNewEventDayError}
                                      />}
                                    dateFormat="dd/MM/yyyy"
                                    locale="pt-BR"
                                    strictParsing
                                  />
                                </Column>

                                <Column narrow className="field-column" style={{ width: "190px" }} >
                                  <Column.Group breakpoint="mobile" className="m-0">
                                    <Column className="p-0">
                                      <Label className="label-2px-mt" >Início:</Label>
                                      <Components.TimeInput
                                        time={newEventStart}
                                        setTime={setNewEventStart}
                                        timeError={newEventStartError}
                                        setTimeError={setNewEventStartError}
                                      />
                                    </Column>
                                    <Column className="p-0">
                                      <Label className="label-2px-mt" >Fim:</Label>
                                      <Components.TimeInput
                                        time={newEventEnd}
                                        setTime={setNewEventEnd}
                                        timeError={newEventEndError}
                                        setTimeError={setNewEventEndError}
                                      />
                                    </Column>              
                                  </Column.Group>
                                </Column>

                                <Column narrow className="field-column" >
                                  <span className="is-size-6 has-text-weight-bold has-text-dark">
                                    <Column.Group multiline vcentered className="m-0" breakpoint="mobile" >
                                      <Column narrow className="p-0" >
                                        <Column.Group multiline breakpoint="mobile" className="m-0">
                                          <Column narrow className="p-0 mr-2" style={{ marginTop: "2px" }}>
                                            <Label className="mr-2">Repetir a cada:</Label>
                                            <Input
                                              className="repeat-times"
                                              color={repeatFrequencyError ? "danger" : ""}
                                              type="number"
                                              min="1"
                                              max="30"
                                              value={repeatFrequency}
                                              onChange={(e) => { setRepeatFrequency(e.target.value); if (isNaN(e.target.value) || e.target.value < 1 || e.target.value > 30) { setRepeatFrequencyError("A frequência deve ser um número de 1 a 30") } else { setRepeatFrequencyError(false) }; if (setFormChanged) { setFormChanged(true) } }}
                                            />
                                            <Select.Container>
                                              <Select
                                                type="text"
                                                value={repeatType}
                                                onChange={e => { setRepeatType(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                                                name="repeat-type"
                                              >
                                                <Select.Option>{repeatFrequency == 1 ? "dia" : "dias"}</Select.Option>
                                                <Select.Option>{repeatFrequency == 1 ? "semana" : "semanas"}</Select.Option>
                                                <Select.Option>{repeatFrequency == 1 ? "mês" : "meses"}</Select.Option>
                                                <Select.Option>{repeatFrequency == 1 ? "ano" : "anos"}</Select.Option>
                                              </Select>
                                            </Select.Container>
                                          </Column>
                                          <Column narrow className="p-0">
                                            {/semana/.test(repeatType) &&
                                              <span className="is-size-6 has-text-weight-bold has-text-dark">
                                                <Label>                                                
                                                  Dias da semana:
                                                </Label>
                                                <div className="weekday-button" style={{ backgroundColor: (sunday ? "#1a73e8" : "#e7e7e7"), color: (sunday ? "#ffffff" : "#000000") }}onClick={(e) => setSunday(!sunday)}>D</div>
                                                <div className="weekday-button" style={{ backgroundColor: (monday ? "#1a73e8" : "#e7e7e7"), color: (monday ? "#ffffff" : "#000000") }}onClick={(e) => setMonday(!monday)}>S</div>
                                                <div className="weekday-button" style={{ backgroundColor: (tuesday ? "#1a73e8" : "#e7e7e7"), color: (tuesday ? "#ffffff" : "#000000") }}onClick={(e) => setTuesday(!tuesday)}>T</div>
                                                <div className="weekday-button" style={{ backgroundColor: (wednesday ? "#1a73e8" : "#e7e7e7"), color: (wednesday ? "#ffffff" : "#000000") }}onClick={(e) => setWednesday(!wednesday)}>Q</div>
                                                <div className="weekday-button" style={{ backgroundColor: (thursday ? "#1a73e8" : "#e7e7e7"), color: (thursday ? "#ffffff" : "#000000") }}onClick={(e) => setThursday(!thursday)}>Q</div>
                                                <div className="weekday-button" style={{ backgroundColor: (friday ? "#1a73e8" : "#e7e7e7"), color: (friday ? "#ffffff" : "#000000") }}onClick={(e) => setFriday(!friday)}>S</div>
                                                <div className="weekday-button" style={{ backgroundColor: (saturday ? "#1a73e8" : "#e7e7e7"), color: (saturday ? "#ffffff" : "#000000") }}onClick={(e) => setSaturday(!saturday)}>S</div>
                                              </span> 
                                            }
                                            {/mês|mes/.test(repeatType) &&
                                              <span className="is-size-6 has-text-weight-bold has-text-dark">
                                                <Label>
                                                  <span>Dia do mês:</span>
                                                </Label>
                                                <Select.Container>
                                                  <Select
                                                    type="text"
                                                    value={monthRepeatType}
                                                    onChange={e => { setMonthRepeatType(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                                                    name="month-repeat-type"
                                                  >
                                                    <Select.Option>{monthPositionText}</Select.Option>
                                                    <Select.Option>{newEventDay && `Todo dia ${newEventDay.getDate()}`}</Select.Option>
                                                  </Select>
                                                </Select.Container>
                                              </span>
                                            }
                                          </Column>
                                        </Column.Group>                                  
                                      </Column>
                                    </Column.Group>
                                  </span>
                                </Column>

                              </Column.Group>
                            </Column>

                          </Column.Group>

                        </Column>

                        <Column>
                          <Button.Group align="centered">
                            <Button
                              color="success"
                              size="normal"
                              className="has-text-weight-semibold"
                              onClick={() => {setGenerateDatesState(generateDatesState + 1)}}
                            >
                              <span>Gerar agendamentos</span>
                            </Button>
                          </Button.Group>
                        </Column>

                      </Fragment>
                    }

                  </div>

                  <Column size={12}>
                    <Column.Group multiline vcentered>
                      <Components.Checkbox color="info" checked={onlyOneProfessional} onChange={setOnlyOneProfessional} setFormChanged={setFormChanged} >
                        <span>Apenas um profissional</span>
                      </Components.Checkbox>

                      {onlyOneProfessional &&
                        <Column className="field-column pl-0">
                          <Components.FieldWithSearch
                            disableSelect={onlyOneProfessional == "false"}
                            value={professional}
                            getValue={setProfessional}
                            placeholder="Digite parte do nome do profissional:"
                            resultId={professionalId}
                            setResultId={setProfessionalId}
                            searchAndSetDropdownItensCallback={methods.searchAndSetProfessionalsDropdown}
                          />
                        </Column>
                      }
                    </Column.Group>
                  </Column>

                  <Column size={12} className="py-0" style={{ backgroundColor: "#aadcff", borderRadius: "8px", minHeight: "360px" }}>
                    {customAttendancesArray.map((customAttendance, index) => (
                      <Components.CustomAttendanceCard
                        key={index}
                        onlyOneProfessional={onlyOneProfessional}
                        customAttendance={customAttendance}
                        customAttendanceIndex={index}
                        setCustomAttendancesArray={setCustomAttendancesArray}
                        showAttendanceScheduler={true}
                        attendancesQuantity={attendancesQuantity}
                        setAttendancesQuantity={setAttendancesQuantity}
                        refreshComponent={refreshComponent}
                        setRefreshComponent={setRefreshComponent}
                        setFormChanged={setFormChanged}
                        setNotification={setNotification}
                        setLoading={setLoading}
                        setRedirectToLogin={setRedirectToLogin}
                      />
                    ))}
                  </Column>

                  <Column size={12} >
                    <Button
                      color="success"
                      size="small"
                      className="has-text-weight-semibold"
                      onClick={() => {
                        setAttendancesQuantity(parseInt(attendancesQuantity) + 1)
                        setCustomAttendancesArray((customAttendancesArray) => {
                          customAttendancesArray.push({
                            professionalId: null,
                            description: "",
                            date: null,
                          })
                          return customAttendancesArray
                        })
                      }}
                    >
                      <Icon size="small">
                        <FontAwesomeIcon icon={faPlus} />
                      </Icon>
                      <span>Adicionar atendimento</span>
                    </Button> 
                  </Column>
                </Fragment>

              </Column.Group>
            </Column>
            
          </Column.Group>
          <Button.Group align="centered">
            <Button
              color="info"
              size="medium"
              className="has-text-weight-semibold"
              onClick={handleSubmit}
            >
              <span>Salvar</span>
            </Button>
          </Button.Group>
        </Components.CardWithOverlay>
      </Fragment>
    )
  },

  EditAttendancePackage: (props) => {

    const [refreshComponent, setRefreshComponent] = useState(null)

    const [attendancePackageId, setAttendancePackageId] = useState(props.attendancePackage && props.attendancePackage._id)
    const [patient, setPatient] = useState(props.attendancePackage && props.attendancePackage.patient)
    const [patientId, setPatientId] = useState(props.attendancePackage && props.attendancePackage.patientId)
    const [name, setName] = useState((props.attendancePackage && props.attendancePackage.name) || "")
    const [description, setDescription] = useState((props.attendancePackage && props.attendancePackage.description) || "")
    const [price, setPrice] = useState((props.attendancePackage && methods.numberToPrice(props.attendancePackage.price)) || "")
    const [attendancesQuantity, setAttendancesQuantity] = useState((props.attendancePackage && props.attendancePackage.attendancesQuantity) || "")
    const [refreshCustomAttendances, setRefreshCustomAttendances] = useState("")
    const [customAttendancesArray, setCustomAttendancesArray] = useState(props.attendancePackage && props.attendancePackage.attendancesArray)
    const [onlyOneProfessional, setOnlyOneProfessional] = useState(props.attendancePackage && props.attendancePackage.attendancesArray.filter(customAttendance => customAttendance.professionalId)[0] ? false : true)
    const [professional, setProfessional] = useState("")
    const [professionalId, setProfessionalId] = useState(null)
    const [showAttendanceScheduler, setShowAttendanceScheduler] = useState(props.attendancePackage && props.attendancePackage.attendancesArray && props.attendancePackage.attendancesArray[0] ? true : false)
    const [openPayments, setOpenPayments] = useState(false)

    // States referentes ao sistema de datas:

    const [newEventDay, setNewEventDay] = useState(new Date(Date.now()))
    const [newEventDayError, setNewEventDayError] = useState(null)
    const [newEventStart, setNewEventStart] = useState(DateTime.fromJSDate((new Date(new Date(Date.now()).setHours(8, 0, 0)))).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newEventStartError, setNewEventStartError] = useState(null)
    const [newEventEnd, setNewEventEnd] = useState(DateTime.fromJSDate((new Date(new Date(Date.now()).setHours(9, 0, 0)))).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newEventEndError, setNewEventEndError] = useState(null)
    const [timeError, setTimeError] = useState(null)

    const [repeat, setRepeat] = useState(true)
    const [repeatFrequency, setRepeatFrequency] = useState(1)
    const [repeatFrequencyError, setRepeatFrequencyError] = useState(false)
    const [repeatType, setRepeatType] = useState("semana")
    const [monthRepeatType, setMonthRepeatType] = useState("0")
    const [repeatTimes, setRepeatTimes] = useState(2)
    const [repeatTimesError, setRepeatTimesError] = useState(false)

    const [sunday, setSunday] = useState(false)
    const [monday, setMonday] = useState(false)
    const [tuesday , setTuesday] = useState(false)
    const [wednesday , setWednesday] = useState(false)
    const [thursday , setThursday] = useState(false)
    const [friday , setFriday] = useState(false)
    const [saturday , setSaturday] = useState(false)

    const [weekdayOfMonth, setWeekdayOfMonth] = useState(null)
    const [positionOfWeekdayOnMonth, setPositionOfWeekdayOnMonth] = useState(null)
    const [monthPositionText, setMonthPositionText] = useState(null)
    const [generateDatesState, setGenerateDatesState] = useState(0)

    const [refresher, setRefresher] = useState(0)
    const [renderCount, setRenderCount] = useState(0)

    const [paymentInfoToRegister, setPaymentInfoToRegister] = useState(null)

    const { attendancePackage, setOpenNewAttendancePackage, getAndSetEvents, setObjectToEdit, setFormChanged, setNotification, setLoading, setRedirectToLogin } = props

    let history = useHistory()

    let paid = 0
    if (props.attendancePackage && props.attendancePackage.payments) {
      props.attendancePackage.payments.map((payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    paid = methods.numberToPrice(paid.toFixed(2))

    let debt = price != 0 ? methods.priceToNumber(price) - methods.priceToNumber(paid) : 0
    debt = methods.numberToPrice(debt.toFixed(2))

    useEffect(() => {
      if (refreshCustomAttendances != "") {
        if (attendancesQuantity > 0) {
          setCustomAttendancesArray((customAttendancesArray) => {
            for (let i = 0; i < attendancesQuantity; i++) {
              customAttendancesArray.push({
                professionalName: "",
                professionalId: null,
                description: ""
              })
            }
            return customAttendancesArray
          })
          setRefresher(refresher + 1)
        }
        //setGenerateDatesState(generateDatesState + 1)
      }
    }, [refreshCustomAttendances])

    const changeAttendancesQuantity = (value) => {
      setCustomAttendancesArray([])
      setAttendancesQuantity(value)
      setRefreshCustomAttendances(refreshCustomAttendances + "x")
    }

    useEffect(() => {
      if (refreshComponent)
        setCustomAttendancesArray(refreshComponent)
    }, [refreshComponent])

    // Funções referentes ao sistema de datas:

    Number.prototype.nth = function() {
      switch (this) {
        case 1: return "1"
        case 2: return "2"
        case 3: return "3"
        case 4: return "4"
        case 5: return "última"
      }
      return "última"
    }
    
    Date.prototype.posOfWeekdayOnMonth = function () {      
      var today = this.getDate()
      var m = this.getMonth()
      var weekday = ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'][this.getDay()]
      var month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][m]
      var weekdayPosition = Math.ceil(today/7).nth()
      if(weekdayPosition != "última") {
        if (weekday == "domingo" || weekday == "sábado") {
          weekdayPosition = weekdayPosition + "º"
        } else {
          weekdayPosition = weekdayPosition + "ª"
        }
      } else if (weekday == "domingo" || weekday == "sábado") {
        weekdayPosition = "último"
      }
      switch (Math.ceil(today/7).nth()) {
        case "1": setPositionOfWeekdayOnMonth(1)
        case "2": setPositionOfWeekdayOnMonth(2)
        case "3": setPositionOfWeekdayOnMonth(3)
        case "4": setPositionOfWeekdayOnMonth(4)
        case "última": setPositionOfWeekdayOnMonth(5)
      }
      setWeekdayOfMonth(weekday)
      return [`${weekday == "domingo" || weekday == "sábado" ? "Todo" : "Toda"}`, weekdayPosition, weekday].join(' ')
    }

    useEffect(() => {
      switch (newEventDay.getDay()) {
        case 0:
          setSunday(true)
          break
        case 1:
          setMonday(true)
          break
        case 2:
          setTuesday(true)
          break
        case 3:
          setWednesday(true)
          break
        case 4:
          setThursday(true)
          break
        case 5:
          setFriday(true)
          break
        case 6:
          setSaturday(true)
          break          
      }
    }, [])

    useEffect(() => {
      if (newEventDay) {
        const weekday = newEventDay.getDay()
        if (weekday == 0 && !sunday) {
          setSunday(true)
        } else if (weekday != 0 && sunday) {
          setSunday(false)
        }
        if (weekday == 1 && !monday) {
          setMonday(true)
        } else if (weekday != 1 && monday) {
          setMonday(false)
        }
        if (weekday == 2 && !tuesday) {
          setTuesday(true)
        } else if (weekday != 2 && tuesday) {
          setTuesday(false)
        }
        if (weekday == 3 && !wednesday) {
          setWednesday(true)
        } else if (weekday != 3 && wednesday) {
          setWednesday(false)
        }
        if (weekday == 4 && !thursday) {
          setThursday(true)
        } else if (weekday != 4 && thursday) {
          setThursday(false)
        }
        if (weekday == 5 && !friday) {
          setFriday(true)
        } else if (weekday != 5 && friday) {
          setFriday(false)
        }
        if (weekday == 6 && !saturday) {
          setSaturday(true)
        } else if (weekday != 6 && saturday) {
          setSaturday(false)
        }
        setMonthPositionText(new Date(newEventDay).posOfWeekdayOnMonth())
        //setGenerateDatesState(generateDatesState + 1)
      }
    }, [newEventDay])

    useEffect(() => {
      const dt = DateTime.fromJSDate(newEventDay)
      if (!sunday && !monday && !tuesday && !wednesday && !thursday && !friday && !saturday) {
        switch (newEventDay.getDay()) {
          case 0:
            setSunday(true)
            break
          case 1:
            setMonday(true)
            break
          case 2:
            setTuesday(true)
            break
          case 3:
            setWednesday(true)
            break
          case 4:
            setThursday(true)
            break
          case 5:
            setFriday(true)
            break
          case 6:
            setSaturday(true)
            break
        }
      } else {
        switch (newEventDay.getDay()) {
          case 0:
            if (!sunday) {
              if (monday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 5 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 6 }).toISO()))
              }
            }
            break
          case 1:
            if (!monday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 5 }).toISO()))
              }
            }
            break
          case 2:
            if (!tuesday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              }
            }
            break
          case 3:
            if (!wednesday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              }
            }
            break
          case 4:
            if (!thursday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              }
            }
            break
          case 5:
            if (!friday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 5 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              }
            }
            break
          case 6:
            if (!saturday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 6 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 5 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              }
            }
          break
        }
      }
      //setGenerateDatesState(generateDatesState + 1)
    }, [sunday, monday, tuesday, wednesday, thursday, friday, saturday])

    useEffect(() => {
      if (DateTime.fromISO(newEventStart) >= DateTime.fromISO(newEventEnd)) {
        setTimeError("A hora de início deve ser menor que a hora de término")
      } else {
        setTimeError(null)
        if (newEventStart != "" && !newEventStartError && newEventEnd != "" && !newEventEndError) {
          //setGenerateDatesState(generateDatesState + 1)
        }
      }
    }, [newEventStart, newEventEnd])

    useEffect(() => {
      if (repeatFrequency == 1 && repeatType == "dias") {
        setRepeatType("dia")
      }
      if (repeatFrequency == 1 && repeatType == "semanas") {
        setRepeatType("semana")
      }
      if (repeatFrequency == 1 && repeatType == "meses") {
        setRepeatType("mês")
      }
      if (repeatFrequency == 1 && repeatType == "anos") {
        setRepeatType("ano")
      }
      if (repeatFrequency > 1 && repeatType == "dia") {
        setRepeatType("dias")
      }
      if (repeatFrequency > 1 && repeatType == "semana") {
        setRepeatType("semanas")
      }
      if (repeatFrequency > 1 && repeatType == "mês") {
        setRepeatType("meses")
      }
      if (repeatFrequency > 1 && repeatType == "ano") {
        setRepeatType("anos")
      }
      //setGenerateDatesState(generateDatesState + 1)
    }, [repeatFrequency])

    const generateDates = () => {
      let eventsArray = []
      let recurrenceWeekdays = []
      if (sunday) recurrenceWeekdays.push(0)
      if (monday) recurrenceWeekdays.push(1)
      if (tuesday) recurrenceWeekdays.push(2)
      if (wednesday) recurrenceWeekdays.push(3)
      if (thursday) recurrenceWeekdays.push(4)
      if (friday) recurrenceWeekdays.push(5)
      if (saturday) recurrenceWeekdays.push(6)
      let currentWeekdayIndex = 0
      let currentWeek = 0
      let testWeekdayPosition = null
      let testDate = DateTime.fromJSDate(newEventDay)
      let testMonth = null
      let testYear = null
      let weeksToAdd = 0
      for (let i = 0; i < attendancesQuantity; i++) {
        let timeAddition = {
          days: 0,
          weeks: 0,
          months: 0,
          years: 0,
        }
        let timeSubtraction = {
          days: 0,
          weeks: 0,
          months: 0,
          years: 0,
        }
        let timeToAdd = repeatFrequency * i
        if (/dia/.test(repeatType)) {
          timeAddition.days = timeToAdd
        } else if (/semana/.test(repeatType)) {
          if (!recurrenceWeekdays[0]) {
            timeAddition.weeks = timeToAdd
          } else {
            if (currentWeekdayIndex > recurrenceWeekdays.length - 1) {
              currentWeekdayIndex = 0
              currentWeek++
            }
            if (i == 0) {
              for (let j = 0; j < 7; j++) {
                if (newEventDay.getDay() > recurrenceWeekdays[currentWeekdayIndex]) {
                  currentWeekdayIndex++
                }
              }
            }
            if (recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay() >= 0) {
              timeAddition.days = recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay()
            } else {
              timeSubtraction.days = (recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay()) * (-1)
            }
            timeAddition.weeks = currentWeek * repeatFrequency
            currentWeekdayIndex++
          }
        } else if (/mês|mes/.test(repeatType)) {
          if (/dia/.test(monthRepeatType)) {
            timeAddition.months = timeToAdd
          } else {
            const getNewMonth = (month, monthsToAdd) => {
              return month + monthsToAdd - 12 * Math.floor((month + monthsToAdd)/12)
            }
            const weekdayPosition = Math.ceil(newEventDay.getDate()/7).nth()
            const originalMonth = newEventDay.getMonth()
            if (timeToAdd != 0) {
              while (testWeekdayPosition != weekdayPosition || testMonth != getNewMonth(originalMonth, timeToAdd)) {
                testDate = testDate.plus({ days: 7 })
                if (weekdayPosition != "última") {
                  testWeekdayPosition = Math.ceil(new Date(`${testDate.toISODate()}T${newEventStart}`).getDate()/7).nth()
                } else {
                  let testDate2 = testDate.plus({ days: 7 })
                  if (new Date(`${testDate.toISODate()}T${newEventStart}`).getMonth() != new Date(`${testDate2.toISODate()}T${newEventStart}`).getMonth()) {
                    testWeekdayPosition = "última"
                  } else {
                    testWeekdayPosition = Math.ceil(new Date(`${testDate.toISODate()}T${newEventStart}`).getDate()/7).nth()
                  }
                }
                testMonth = new Date(`${testDate.toISODate()}T${newEventStart}`).getMonth()
                testYear = new Date(`${testDate.toISODate()}T${newEventStart}`).getFullYear()
                weeksToAdd += 1
              }
            }
            timeAddition.weeks = weeksToAdd
          }
        } else if (/ano/.test(repeatType)) {
          timeAddition.years = timeToAdd
        }
        let eventToPush = {
          start: new Date(`${DateTime.fromJSDate(newEventDay).plus(timeAddition).minus(timeSubtraction).toISODate()}T${newEventStart}`),
          end: new Date(`${DateTime.fromJSDate(newEventDay).plus(timeAddition).minus(timeSubtraction).toISODate()}T${newEventEnd}`),
        }
        eventsArray.push(eventToPush)
      }
      setCustomAttendancesArray((customAttendancesArray) => {
        setRefreshComponent(
          customAttendancesArray.map((customAttendance, index) => {
            if (customAttendance._id && renderCount < 1) {
              if (customAttendance.start) {
                customAttendance.date = new Date(customAttendance.start)
                customAttendance.startTime = DateTime.fromJSDate(new Date(`${customAttendance.start}`)).toLocaleString(DateTime.TIME_24_SIMPLE)
                customAttendance.endTime = DateTime.fromJSDate(new Date(`${customAttendance.end}`)).toLocaleString(DateTime.TIME_24_SIMPLE)
              } else {
                customAttendance.date = null
                customAttendance.startTime = ""
                customAttendance.endTime = ""
              }

              return customAttendance
            } else {
              customAttendance.date = eventsArray[index].start
              customAttendance.startTime = newEventStart
              customAttendance.endTime = newEventEnd
              return customAttendance
            }
          })
        )
        return []
      })
      setRenderCount(renderCount+1)
    }

    useEffect(() => {
      if (newEventDay) generateDates()
    }, [generateDatesState, ]) //repeatType, monthRepeatType

    const onDelete = (event) => {
      setNotification({
        withCancel: true,
        success: false,
        title: "Excluir Pacote de Atendimentos",
        text: "Tem certeza que deseja excluir o Pacote de Atendimentos? Todos os atendimentos relacionados que foram realizados e/ou agendados também serão excluídos.",
        cancelCallback: (() => {
          setNotification(false)
        }),
        confirmCallback: (() => {
          if (customAttendancesArray.some((attendance) => attendance.attendanceDateTime)) {
            setNotification({
              withCancel: true,
              success: false,
              title: "Há atendimentos realizados",
              text: "Há atendimentos que já foram realizados e cadastrados que também serão excluídos. Prosseguir mesmo assim?",
              cancelCallback: (() => {
                setNotification(false)
              }),
              confirmCallback: (() => {
                setNotification(false)
                setLoading(true)
                let submitInfo = {
                  event: event,
                  setNotification: setNotification,
                  setLoading: setLoading,
                  setOpenComponent: null,
                  mandatoryFields: [],
                  errorList: [],
                  ServiceMethod: AttendancePackagesService.deleteAttendancePackage,
                  params: {
                    attendancePackageId: attendancePackageId,
                  },
                  onSuccessText: "Pacote de Atendimentos excluído.",
                  onErrorText: "Não foi possível excluir o Pacote de Atendimentos. Tente novamente",
                  onSuccessCallback: (response) => {
                    history.push("/app/attendance-package")
                  },
                  onErrorCallback: null,
                  setRedirectToLogin: setRedirectToLogin,
                }
                methods.handleSubmit(submitInfo)
              })
            })
          } else {
            setNotification(false)
            setLoading(true)
            let submitInfo = {
              event: event,
              setNotification: setNotification,
              setLoading: setLoading,
              setOpenComponent: null,
              mandatoryFields: [],
              errorList: [],
              ServiceMethod: AttendancePackagesService.deleteAttendancePackage,
              params: {
                attendancePackageId: attendancePackageId,
              },
              onSuccessText: "Pacote de Atendimentos excluído.",
              onErrorText: "Não foi possível excluir o Pacote de Atendimentos. Tente novamente",
              onSuccessCallback: (response) => {
                history.push("/app/attendance-package")
              },
              onErrorCallback: null,
              setRedirectToLogin: setRedirectToLogin,
            }
            methods.handleSubmit(submitInfo)
          }
        })
      })
    }

    const handleSubmit = async (event) => {
      let params = {
        attendancePackageId,
        patientId: patientId,
        name: name,
        description: description,
        price: methods.priceToNumber(price),
        attendancesQuantity: attendancesQuantity,
      }
      let mandatoryFields = [patientId, name, price, attendancesQuantity]
      let errorList = [timeError, newEventDayError, newEventStartError, newEventEndError, repeatFrequencyError]
      if (showAttendanceScheduler) {
        params.attendancesArray = [ ...customAttendancesArray ]
        if (onlyOneProfessional) {
          mandatoryFields.push(professionalId)
          params.attendancesArray = params.attendancesArray.map((customAttendance) => {
            customAttendance.professionalId = professionalId
            return customAttendance
          })
        } else {
          params.attendancesArray.map((customAttendance) => {
            mandatoryFields.push(customAttendance.professionalId)
          })
        }
        params.attendancesArray.map((customAttendance) => {
          if (customAttendance.date || customAttendance.startTime || customAttendance.endTime) {
            mandatoryFields.push(customAttendance.date)
            mandatoryFields.push(customAttendance.startTime)
            mandatoryFields.push(customAttendance.endTime)
          }
          errorList.push(customAttendance.dateError)
          errorList.push(customAttendance.startTimeError)
          errorList.push(customAttendance.endTimeError)
          if (DateTime.fromISO(customAttendance.startTime) >= DateTime.fromISO(customAttendance.endTime)) {
            customAttendance.timeError = "A hora de início deve ser menor que a hora de término"
          } else {
            customAttendance.timeError = null
          }
          errorList.push(customAttendance.timeError)
        })
        params.attendancesArray = params.attendancesArray.map((customAttendance) => {
          customAttendance.start = new Date(`${DateTime.fromJSDate(customAttendance.date).toISODate()}T${customAttendance.startTime}`)
          customAttendance.end = new Date(`${DateTime.fromJSDate(customAttendance.date).toISODate()}T${customAttendance.endTime}`)
          return customAttendance
        })
      }
      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setOpenNewAttendancePackage,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: AttendancePackagesService.editAttendancePackage,
        params: params,
        onSuccessText: "Pacote de atendimentos cadastrado.",
        onErrorText: "Não foi possível cadastrar o pacote de atendimentos. Tente novamente",
        onSuccessCallback: () => {if(getAndSetEvents){getAndSetEvents()}},
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }
      methods.handleSubmit(submitInfo)
    }

    return (
      <Fragment>
        
        <Card>
          <Card.Content>
          
            <div className="is-hidden">{refresher}</div>

            <Column.Group vcentered multiline className="mt-0">
              <Column size={12}>
                <Label>Paciente:<span className="has-text-danger"> *</span></Label>
                <Components.FieldWithSearch
                  value={patient}
                  getValue={setPatient}
                  placeholder="Digite parte do nome do paciente:"
                  resultId={patientId}
                  setResultId={setPatientId}
                  searchAndSetDropdownItensCallback={methods.searchAndSetPatientsDropdown}
                />
              </Column>
              <Components.GenericInput
                required={true}
                isTextarea={false}
                rows={1}
                disableSelect={false}
                size={6}
                inputSize={null}
                fontWeight="bold"
                label="Nome do pacote:"
                placeholder="Nome do pacote..."
                elementName="package-model-name"
                value={name}
                setValue={setName}
                maskAndSetValue={null}
                setFormChanged={setFormChanged}
              />
              <Components.GenericInput
                isTextarea
                rows={1}
                disableSelect={false}
                size={6}
                inputSize={null}
                label="Descrição:"
                placeholder="Descrição..."
                elementName="package-model-description"
                value={description}
                setValue={setDescription}
                maskAndSetValue={null}
                setFormChanged={setFormChanged}
              />
              <Components.GenericInput
                required={true}
                disableSelect={false}
                size={6}
                inputSize={null}
                label="Preço:"
                placeholder="R$ ..."
                elementName="price"
                value={price}
                setValue={setPrice}
                maskAndSetValue={methods.maskAndSetPrice}
                setFormChanged={setFormChanged}
              />
              <Components.GenericInput
                readOnly={true}
                disableSelect={true}
                required={true}
                size={6}
                inputSize={null}
                label="Quantidade de atendimentos:"
                placeholder="Número de atendimentos..."
                value={attendancesQuantity}
                setValue={changeAttendancesQuantity}
                maskAndSetValue={methods.maskAndSetAttendancesQuantity}
                setFormChanged={setFormChanged}
              />

              {methods.priceToNumber(paid) > 0 &&
                <Column size={12} className="field-column" >
                  <span className={paid <= 0 ? "has-text-weight-bold is-size-6" : "has-text-weight-bold has-text-success is-size-6"} >Total Pago: <span className="has-text-weight-normal mr-2" >{paid}</span></span>
                  <Button color="info"
                    style={{ height: "23px", border: "1px solid black", marginLeft: "5px" }}
                    onClick={() => {
                      setOpenPayments(true)
                    }}
                  >
                    <Icon>
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </Icon>
                    <span className="ml-1">Ver Pagamentos</span>
                  </Button>
                </Column>
              }

              {(price != 0 && methods.priceToNumber(debt) > 0) &&
                <Fragment>
                  <Column size={12} className="field-column" >
                    <span className="has-text-danger has-text-weight-bold is-size-6" >Em aberto: <span className="has-text-weight-normal mr-2" >{debt}</span></span>
                    <Button
                      style={{ height: "23px", border: "1px solid black", marginLeft: "5px" }}
                      color="success"
                      onClick={e => {
                        setPaymentInfoToRegister({
                          price: price,
                          paid: paid,
                          debt: debt,
                          id: attendancePackage._id
                        })
                      }}
                    >
                      <Icon >
                        <FontAwesomeIcon icon={faDollarSign} />
                      </Icon>
                      <span>Cadastrar pgto.</span>
                    </Button>
                  </Column>
                </Fragment>
              }

              {paymentInfoToRegister &&
                <Components.RegisterPaymentOverlay
                  paymentInfoToRegister={paymentInfoToRegister}
                  setPaymentInfoToRegister={setPaymentInfoToRegister}
                  setObjectToEdit={setObjectToEdit}
                  ServiceMethod={AttendancePackagesService.registerAttendancePackagePayment}
                  paymentType="pacote de atendimento"
                  setFormChanged={setFormChanged}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              {openPayments &&
                <Components.PaymentsOverlay
                  attendancePackage={attendancePackage}
                  price={price}
                  paid={paid}
                  debt={debt}
                  setOpenPayments={setOpenPayments}
                  setObjectToEdit={setObjectToEdit}
                  setFormChanged={setFormChanged}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              <Column size={12}>
                <hr className="form-divider mb-0" />
              </Column>

              <Column size={12}>
                <Column.Group multiline vcentered>         

                  {showAttendanceScheduler &&
                    <Fragment>                      

                      <div style={{ border: "2px solid #ccc", borderRadius: "5px", backgroundColor: "#eaffea", marginBottom: "1rem", width: "100%" }}>

                        {showAttendanceScheduler &&
                          <Fragment>

                            <Column size={12}>

                              <Column.Group multiline vcentered>

                                <Column className="field-column mt-4 mb-3">
                                  <Column.Group multiline breakpoint="mobile" >

                                    <Column narrow className="field-column date-input" >
                                      <Label>Data inicial:</Label>
                                      <DatePicker
                                        selected={newEventDay}
                                        onChange={(date) => { setNewEventDay(date) }}
                                        customInput={
                                          <Components.DatePickerInput
                                            icon={faCalendar}
                                            dateError={newEventDayError}
                                            setDateError={setNewEventDayError}
                                          />}
                                        dateFormat="dd/MM/yyyy"
                                        locale="pt-BR"
                                        strictParsing
                                      />
                                    </Column>

                                    <Column narrow className="field-column" style={{ width: "190px" }} >
                                      <Column.Group breakpoint="mobile" className="m-0">
                                        <Column className="p-0">
                                          <Label className="label-2px-mt" >Início:</Label>
                                          <Components.TimeInput
                                            time={newEventStart}
                                            setTime={setNewEventStart}
                                            timeError={newEventStartError}
                                            setTimeError={setNewEventStartError}
                                          />
                                        </Column>
                                        <Column className="p-0">
                                          <Label className="label-2px-mt" >Fim:</Label>
                                          <Components.TimeInput
                                            time={newEventEnd}
                                            setTime={setNewEventEnd}
                                            timeError={newEventEndError}
                                            setTimeError={setNewEventEndError}
                                          />
                                        </Column>              
                                      </Column.Group>
                                    </Column>

                                    <Column narrow className="field-column" >
                                      <span className="is-size-6 has-text-weight-bold has-text-dark">
                                        <Column.Group multiline vcentered className="m-0" breakpoint="mobile" >
                                          <Column narrow className="p-0" >
                                            <Column.Group multiline breakpoint="mobile" className="m-0">
                                              <Column narrow className="p-0 mr-2" style={{ marginTop: "2px" }}>
                                                <Label className="mr-2">Repetir a cada:</Label>
                                                <Input
                                                  className="repeat-times"
                                                  color={repeatFrequencyError ? "danger" : ""}
                                                  type="number"
                                                  min="1"
                                                  max="30"
                                                  value={repeatFrequency}
                                                  onChange={(e) => { setRepeatFrequency(e.target.value); if (isNaN(e.target.value) || e.target.value < 1 || e.target.value > 30) { setRepeatFrequencyError("A frequência deve ser um número de 1 a 30") } else { setRepeatFrequencyError(false) }; if (setFormChanged) { setFormChanged(true) } }}
                                                />
                                                <Select.Container>
                                                  <Select
                                                    type="text"
                                                    value={repeatType}
                                                    onChange={e => { setRepeatType(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                                                    name="repeat-type"
                                                  >
                                                    <Select.Option>{repeatFrequency == 1 ? "dia" : "dias"}</Select.Option>
                                                    <Select.Option>{repeatFrequency == 1 ? "semana" : "semanas"}</Select.Option>
                                                    <Select.Option>{repeatFrequency == 1 ? "mês" : "meses"}</Select.Option>
                                                    <Select.Option>{repeatFrequency == 1 ? "ano" : "anos"}</Select.Option>
                                                  </Select>
                                                </Select.Container>
                                              </Column>
                                              <Column narrow className="p-0">
                                                {/semana/.test(repeatType) &&
                                                  <span className="is-size-6 has-text-weight-bold has-text-dark">
                                                    <Label>                                                
                                                      Dias da semana:
                                                    </Label>
                                                    <div className="weekday-button" style={{ backgroundColor: (sunday ? "#1a73e8" : "#e7e7e7"), color: (sunday ? "#ffffff" : "#000000") }}onClick={(e) => setSunday(!sunday)}>D</div>
                                                    <div className="weekday-button" style={{ backgroundColor: (monday ? "#1a73e8" : "#e7e7e7"), color: (monday ? "#ffffff" : "#000000") }}onClick={(e) => setMonday(!monday)}>S</div>
                                                    <div className="weekday-button" style={{ backgroundColor: (tuesday ? "#1a73e8" : "#e7e7e7"), color: (tuesday ? "#ffffff" : "#000000") }}onClick={(e) => setTuesday(!tuesday)}>T</div>
                                                    <div className="weekday-button" style={{ backgroundColor: (wednesday ? "#1a73e8" : "#e7e7e7"), color: (wednesday ? "#ffffff" : "#000000") }}onClick={(e) => setWednesday(!wednesday)}>Q</div>
                                                    <div className="weekday-button" style={{ backgroundColor: (thursday ? "#1a73e8" : "#e7e7e7"), color: (thursday ? "#ffffff" : "#000000") }}onClick={(e) => setThursday(!thursday)}>Q</div>
                                                    <div className="weekday-button" style={{ backgroundColor: (friday ? "#1a73e8" : "#e7e7e7"), color: (friday ? "#ffffff" : "#000000") }}onClick={(e) => setFriday(!friday)}>S</div>
                                                    <div className="weekday-button" style={{ backgroundColor: (saturday ? "#1a73e8" : "#e7e7e7"), color: (saturday ? "#ffffff" : "#000000") }}onClick={(e) => setSaturday(!saturday)}>S</div>
                                                  </span> 
                                                }
                                                {/mês|mes/.test(repeatType) &&
                                                  <span className="is-size-6 has-text-weight-bold has-text-dark">
                                                    <Label>
                                                      <span>Dia do mês:</span>
                                                    </Label>
                                                    <Select.Container>
                                                      <Select
                                                        type="text"
                                                        value={monthRepeatType}
                                                        onChange={e => { setMonthRepeatType(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                                                        name="month-repeat-type"
                                                      >
                                                        <Select.Option>{monthPositionText}</Select.Option>
                                                        <Select.Option>{newEventDay && `Todo dia ${newEventDay.getDate()}`}</Select.Option>
                                                      </Select>
                                                    </Select.Container>
                                                  </span>
                                                }
                                              </Column>
                                            </Column.Group>                                  
                                          </Column>
                                        </Column.Group>
                                      </span>
                                    </Column>

                                  </Column.Group>
                                </Column>

                              </Column.Group>

                            </Column>

                            <Column>
                              <Button.Group align="centered">
                                <Button
                                  color="success"
                                  size="normal"
                                  className="has-text-weight-semibold"
                                  onClick={() => {setGenerateDatesState(generateDatesState + 1)}}
                                >
                                  <span>Gerar agendamentos</span>
                                </Button>
                              </Button.Group>
                            </Column>

                          </Fragment>
                        }

                      </div>

                      <Column size={12}>
                        <Column.Group multiline vcentered>
                          <Components.Checkbox color="info" checked={onlyOneProfessional} onChange={setOnlyOneProfessional} setFormChanged={setFormChanged} >
                            <span>Apenas um profissional</span>
                          </Components.Checkbox>

                          {onlyOneProfessional &&
                            <Column className="field-column pl-0">
                              <Components.FieldWithSearch
                                disableSelect={onlyOneProfessional == "false"}
                                value={professional}
                                getValue={setProfessional}
                                placeholder="Digite parte do nome do profissional:"
                                resultId={professionalId}
                                setResultId={setProfessionalId}
                                searchAndSetDropdownItensCallback={methods.searchAndSetProfessionalsDropdown}
                              />
                            </Column>
                          }
                        </Column.Group>
                      </Column>

                      <Column size={12} className="py-2" style={{ backgroundColor: "#aadcff", borderRadius: "8px", minHeight: "360px" }}>
                        {customAttendancesArray.map((customAttendance, index) => (
                          <Components.CustomAttendanceCard
                            key={index}
                            attendanceAlreadyExists={true}
                            attendancePackageId={attendancePackageId}
                            onlyOneProfessional={onlyOneProfessional}
                            customAttendance={customAttendance}
                            customAttendanceIndex={index}
                            setCustomAttendancesArray={setCustomAttendancesArray}
                            showAttendanceScheduler={showAttendanceScheduler}
                            attendancesQuantity={attendancesQuantity}
                            setAttendancesQuantity={setAttendancesQuantity}
                            refreshComponent={refreshComponent}
                            setRefreshComponent={setRefreshComponent}
                            setFormChanged={setFormChanged}
                            setNotification={setNotification}
                            setLoading={setLoading}
                            setRedirectToLogin={setRedirectToLogin}
                          />
                        ))}
                      </Column>

                      <Column size={12} >
                        <Button
                          color="success"
                          size="small"
                          className="has-text-weight-semibold"
                          onClick={() => {
                            setAttendancesQuantity(parseInt(attendancesQuantity) + 1)
                            setCustomAttendancesArray((customAttendancesArray) => {
                              customAttendancesArray.push({
                                professionalId: null,
                                description: ""
                              })
                              return customAttendancesArray
                            })
                          }}
                        >
                          <Icon size="small">
                            <FontAwesomeIcon icon={faPlus} />
                          </Icon>
                          <span>Adicionar atendimento</span>
                        </Button> 
                      </Column>
                    </Fragment>
                  }

                </Column.Group>
              </Column>
              
            </Column.Group>

            <Button.Group align="centered">
              <Button
                color="info"
                size="medium"
                className="has-text-weight-semibold"
                onClick={handleSubmit}
              >
                <span>Salvar</span>
              </Button>
            </Button.Group>

            <Button.Group align="right">
              <Button
                outlined
                color="danger"
                onClick={onDelete}
              >
                <Icon >
                  <FontAwesomeIcon icon={faTrash} />
                </Icon>
                <span>Excluir Pacote</span>
              </Button>
            </Button.Group>

          </Card.Content>
        </Card>        

      </Fragment>
    )
  },

  ManageAttendancePackageTemplates: (props) => {

    const [openNewPackageTemplate, setOpenNewPackageTemplate] = useState(false)
    const [openEditPackageTemplate, setOpenEditPackageTemplate] = useState(false)
  
    const { packageTemplates, setPackageTemplates, setOpenManageAttendancePackageTemplates, getPackageTemplates, setFormChanged, setNotification, setLoading, setRedirectToLogin } = props
  
    useEffect( () => {
      const eventListener = (event) => {
        event.preventDefault()
        if (formChangedRef.current) {
          let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
          event.returnValue = confirmationMessage
          return confirmationMessage
        }
      }
      window.addEventListener('beforeunload', eventListener)
      return () => {
        window.removeEventListener('beforeunload', eventListener);
      }
    }, [])
  
    return (

      <Components.CardWithOverlay
        cardType="fullViewer"
        headerClassName={"has-background-primary"}
        title={
          <Fragment>
            <span className="mr-2">Gerenciar Modelos de Pacotes</span>
            <Components.ToogleButton
              color="success"
              hasBorders={true}
              icon={faPlus}
              setOpen={setOpenNewPackageTemplate}
            >
              Novo Modelo
            </Components.ToogleButton>
          </Fragment>
        }
        titleColorClassName="has-text-white"
        icon={faCog}
        setOpenCard={setOpenManageAttendancePackageTemplates}
      >

        <Components.PackageTemplateResults
          packageTemplates={packageTemplates}
          setOpenEditPackageTemplate={setOpenEditPackageTemplate}
          getPackageTemplates={getPackageTemplates}
          setFormChanged={setFormChanged}
          setNotification={setNotification}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
  
        {openNewPackageTemplate &&
          <Components.NewPackageTemplate
            setOpenNewPackageTemplate={setOpenNewPackageTemplate}
            getPackageTemplates={getPackageTemplates}
            setFormChanged={setFormChanged}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }
  
        {openEditPackageTemplate &&
          <Components.EditPackageTemplate
            openEditPackageTemplate={openEditPackageTemplate}
            setOpenEditPackageTemplate={setOpenEditPackageTemplate}
            getPackageTemplates={getPackageTemplates}
            setFormChanged={setFormChanged}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </Components.CardWithOverlay>
    )
  },

  SelectDocumentTemplates: (props) => {
    const [showSystemTemplates, setShowSystemTemplates] = useState(JSON.parse(localStorage.getItem('showSystemTemplates')) != null ? JSON.parse(localStorage.getItem('showSystemTemplates')) : true)
    const [showOtherProfessionalTemplates, setShowOtherProfessionalTemplates] = useState(JSON.parse(localStorage.getItem('showOtherProfessionalTemplates')) != null ? JSON.parse(localStorage.getItem('showOtherProfessionalTemplates')) : true)
  
    const { documentType, openSelectDocumentTemplates, setOpenSelectDocumentTemplates, documentTemplates, setDocument, questionnaireIndex, procedureIndex, editorIndex, hasStatesArray, setFormChanged, setNotification, setLoading, setRedirectToLogin } = props
  
    useEffect( () => {
      const eventListener = (event) => {
        event.preventDefault()
        if (formChangedRef.current) {
          let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
          event.returnValue = confirmationMessage
          return confirmationMessage
        }
      }
      window.addEventListener('beforeunload', eventListener)
      return () => {
        window.removeEventListener('beforeunload', eventListener);
      }
    }, [])

    useEffect( () => {
      localStorage.setItem("showSystemTemplates", showSystemTemplates)
    }, [showSystemTemplates])

    useEffect( () => {
      localStorage.setItem("showOtherProfessionalTemplates", showOtherProfessionalTemplates)
    }, [showOtherProfessionalTemplates])

    const handleSetDocument = (documentTemplate) => {
      if (documentType == "Questionário") {
        setDocument((questionnaires) => (
          questionnaires.map((questionnaire, index) => {
            if (index == questionnaireIndex) {
              questionnaire = {
                name: documentTemplate[0].name,
                questions: documentTemplate[0].questions.map(question => ({
                  question: question.question,
                  answerType: question.answerType,
                  answer: question.answer,
                  alternatives: question.alternatives.map(alternative => ({
                    alternative: alternative.alternative,
                    chosen: alternative.chosen,
                  }))
                }))
              }
            }
            return questionnaire
          })
        ))
      } else if (documentType == "Procedimento") {
        setDocument((procedures) => (
          procedures.map((procedure, index) => {
            if (index == procedureIndex) {
              procedure = {
                name: documentTemplate[0].name,
                notes: documentTemplate[0].notes
              }
            }
            return procedure
          })
        ))
      } else if (hasStatesArray) {
        setDocument((editorStatesArray) => (
          editorStatesArray.map((editorState, index) => {
            if (index == editorIndex) {
              if (!documentTemplate.entityMap) {
                editorState = EditorState.createWithContent(convertFromRaw({ ...documentTemplate, entityMap: {} }))
              } else {
                editorState = EditorState.createWithContent(convertFromRaw(documentTemplate))
              }
            }
            return editorState
          })
        ))
      } else {
        if (!documentTemplate.entityMap) {
          setDocument(EditorState.createWithContent(convertFromRaw({ ...documentTemplate, entityMap: {} })))
        } else {
          setDocument(EditorState.createWithContent(convertFromRaw(documentTemplate)))
        }
      }
      setOpenSelectDocumentTemplates(null)
    }
  
    if (openSelectDocumentTemplates == documentType) {
      return (

        <Components.CardWithOverlay
          cardType="widerViewer"
          headerClassName={"has-background-primary"}
          title={
            <Fragment>
              <span className="mr-2">Importar modelo de {documentType}</span>
            </Fragment>
          }
          titleColorClassName="has-text-white"
          icon={faFileLines}
          setOpenCard={setOpenSelectDocumentTemplates}
        >
  
          {documentTemplates.some(documentTemplate => documentTemplate.documentType == documentType) &&
            <Column.Group multiline>
              
              <Column size={12} className="mt-3">
                <Components.Checkbox checked={showSystemTemplates} onChange={setShowSystemTemplates} setFormChanged={setFormChanged} color="info" noPadding={true} >
                  Mostrar modelos do sistema
                </Components.Checkbox>
                <Components.Checkbox checked={showOtherProfessionalTemplates} onChange={setShowOtherProfessionalTemplates} setFormChanged={setFormChanged} color="info" noPadding={true} >
                  Mostrar modelos de outros profissionais
                </Components.Checkbox>
              </Column>

              <Column size={12}>
                <span className="has-text-weight-bold">Selecione um modelo:</span>
              </Column>
              <Column className="table-container is-size-6">
  
                <Table fullwidth hoverable narrow>
  
                  <Table.Head>
                    <Table.Row>
                      <Table.Heading>
                        {"Descrição (resumo)"}
                      </Table.Heading>
                      <Table.Heading>
                        Profissional
                      </Table.Heading>
                      <Table.Heading textAlign="centered" style={{ maxWidth: "150px" }}>
                        Disponível para todos os profissionais
                      </Table.Heading>
                    </Table.Row>
                  </Table.Head>
  
                  <Table.Body>
                    {documentTemplates.map((documentTemplate, key) => {
                      if (
                        documentTemplate.documentType == documentType &&
                        ((documentTemplate.professionalId == JSON.parse(localStorage.getItem('user'))._id || (showOtherProfessionalTemplates && documentTemplate.availableToCompanyProfessionals == true))
                        ||
                        (documentTemplate.isSystemTemplate && showSystemTemplates))
                      )
                      return (
                        <Table.Row key={key} className="cursor-pointer" onClick={e => {handleSetDocument(documentTemplate.documentTemplate)}}>
                          <Table.Cell>
                            {methods.stringCut(documentTemplate.description, 50)}
                          </Table.Cell>
                          <Table.Cell>
                            {documentTemplate && documentTemplate.professionalInfo && documentTemplate.professionalInfo.name}
                          </Table.Cell>
                          <Table.Cell textAlign="centered">
                            {documentTemplate.availableToCompanyProfessionals ?
                            "Sim"
                            :
                            "Não"
                            }
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
  
                </Table>
  
              </Column>
            </Column.Group>
            ||
            <Title size={5} className="has-text-centered has-text-danger mt-3" >
              Não foram encontrados resultados com os parâmetros informados.
            </Title>
          }
  
        </Components.CardWithOverlay>
      )
    } else {
      return null
    }
    
  },

  NewRevenueOrExpenseOverlay: (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [value, setValue] = useState("R$ ")
    const [valueError, setValueError] = useState("O valor deve ser maior que zero")
    const [valueReceived, setValueReceived] = useState(false)
    const [loadNameField, setLoadNameField] = useState(false)

    const [paymentValue, setPaymentValue] = useState(methods.numberToPrice(0))
    const [paymentMethod, setPaymentMethod] = useState("Dinheiro")
    const [paymentOtherMethodDescription, setPaymentOtherMethodDescription] = useState("")

    const [newEventDay, setNewEventDay] = useState(props.newEventDay)
    const [newEventDayError, setNewEventDayError] = useState(null)
    const [newEventStart, setNewEventStart] = useState(DateTime.fromJSDate(props.newEventStart).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newEventStartError, setNewEventStartError] = useState(null)
    const [newEventEnd, setNewEventEnd] = useState(DateTime.fromJSDate(props.newEventEnd).toLocaleString(DateTime.TIME_24_SIMPLE))
    const [newEventEndError, setNewEventEndError] = useState(null)
    const [timeError, setTimeError] = useState(null)

    const [repeat, setRepeat] = useState(false)
    const [repeatFrequency, setRepeatFrequency] = useState(1)
    const [repeatFrequencyError, setRepeatFrequencyError] = useState(false)
    const [repeatType, setRepeatType] = useState("mês")
    const [monthRepeatType, setMonthRepeatType] = useState(`Todo dia ${newEventDay && newEventDay.getDate()}`)
    const [repeatTimes, setRepeatTimes] = useState(2)
    const [repeatTimesError, setRepeatTimesError] = useState(false)

    const [sunday, setSunday] = useState(false)
    const [monday, setMonday] = useState(false)
    const [tuesday , setTuesday] = useState(false)
    const [wednesday , setWednesday] = useState(false)
    const [thursday , setThursday] = useState(false)
    const [friday , setFriday] = useState(false)
    const [saturday , setSaturday] = useState(false)

    const [weekdayOfMonth, setWeekdayOfMonth] = useState(null)
    const [positionOfWeekdayOnMonth, setPositionOfWeekdayOnMonth] = useState(null)
    const [monthPositionText, setMonthPositionText] = useState(null)

    const { revenueOrExpense, openNewRevenueOrExpense, setOpenNewRevenueOrExpense, setRefreshRevenuesAndExpenses } = props

    Number.prototype.nth = function() {
      switch (this) {
        case 1: return "1"
        case 2: return "2"
        case 3: return "3"
        case 4: return "4"
        case 5: return "última"
      }
      return "última"
    }
    
    Date.prototype.posOfWeekdayOnMonth = function () {      
      var today = this.getDate()
      var m = this.getMonth()
      var weekday = ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'][this.getDay()]
      var month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][m]
      var weekdayPosition = Math.ceil(today/7).nth()
      if(weekdayPosition != "última") {
        if (weekday == "domingo" || weekday == "sábado") {
          weekdayPosition = weekdayPosition + "º"
        } else {
          weekdayPosition = weekdayPosition + "ª"
        }
      } else if (weekday == "domingo" || weekday == "sábado") {
        weekdayPosition = "último"
      }
      switch (Math.ceil(today/7).nth()) {
        case "1": setPositionOfWeekdayOnMonth(1)
        case "2": setPositionOfWeekdayOnMonth(2)
        case "3": setPositionOfWeekdayOnMonth(3)
        case "4": setPositionOfWeekdayOnMonth(4)
        case "última": setPositionOfWeekdayOnMonth(5)
      }
      setWeekdayOfMonth(weekday)
      return [`${weekday == "domingo" || weekday == "sábado" ? "Todo" : "Toda"}`, weekdayPosition, weekday].join(' ')
    }

    useEffect(() => {
      setLoadNameField(true)

      switch (newEventDay.getDay()) {
        case 0:
          setSunday(true)
          break
        case 1:
          setMonday(true)
          break
        case 2:
          setTuesday(true)
          break
        case 3:
          setWednesday(true)
          break
        case 4:
          setThursday(true)
          break
        case 5:
          setFriday(true)
          break
        case 6:
          setSaturday(true)
          break          
      }
    }, [])

    useEffect(() => {
      switch (newEventDay && newEventDay.getDay()) {
        case 0:
          setSunday(true)
          break
        case 1:
          setMonday(true)
          break
        case 2:
          setTuesday(true)
          break
        case 3:
          setWednesday(true)
          break
        case 4:
          setThursday(true)
          break
        case 5:
          setFriday(true)
          break
        case 6:
          setSaturday(true)
          break
      }
      setMonthPositionText(new Date(newEventDay).posOfWeekdayOnMonth())
    }, [newEventDay])

    useEffect(() => {
      const dt = DateTime.fromJSDate(newEventDay)
      if (!sunday && !monday && !tuesday && !wednesday && !thursday && !friday && !saturday) {
        switch (newEventDay.getDay()) {
          case 0:
            setSunday(true)
            break
          case 1:
            setMonday(true)
            break
          case 2:
            setTuesday(true)
            break
          case 3:
            setWednesday(true)
            break
          case 4:
            setThursday(true)
            break
          case 5:
            setFriday(true)
            break
          case 6:
            setSaturday(true)
            break
        }
      } else {
        switch (newEventDay.getDay()) {
          case 0:
            if (!sunday) {
              if (monday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 5 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 6 }).toISO()))
              }
            }
            break
          case 1:
            if (!monday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 5 }).toISO()))
              }
            }
            break
          case 2:
            if (!tuesday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 4 }).toISO()))
              }
            }
            break
          case 3:
            if (!wednesday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 3 }).toISO()))
              }
            }
            break
          case 4:
            if (!thursday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (friday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 2 }).toISO()))
              }
            }
            break
          case 5:
            if (!friday) {
              if (sunday) {
                setNewEventDay(new Date(dt.minus({ days: 5 }).toISO()))
              } else if (monday) {
                setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
              } else if (tuesday) {
                setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
              } else if (wednesday) {
                setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
              } else if (thursday) {
                setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
              } else if (saturday) {
                setNewEventDay(new Date(dt.plus({ days: 1 }).toISO()))
              }
            }
            break
            case 6:
              if (!saturday) {
                if (sunday) {
                  setNewEventDay(new Date(dt.minus({ days: 6 }).toISO()))
                } else if (monday) {
                  setNewEventDay(new Date(dt.minus({ days: 5 }).toISO()))
                } else if (tuesday) {
                  setNewEventDay(new Date(dt.minus({ days: 4 }).toISO()))
                } else if (wednesday) {
                  setNewEventDay(new Date(dt.minus({ days: 3 }).toISO()))
                } else if (thursday) {
                  setNewEventDay(new Date(dt.minus({ days: 2 }).toISO()))
                } else if (friday) {
                  setNewEventDay(new Date(dt.minus({ days: 1 }).toISO()))
                }
              }
              break
        }
      }
    }, [sunday, monday, tuesday, wednesday, thursday, friday, saturday])

    useEffect(() => {
      if (DateTime.fromISO(newEventStart) >= DateTime.fromISO(newEventEnd)) {
        setTimeError("A hora de início deve ser menor que a hora de término")
      } else {
        setTimeError(null)
      }
    }, [newEventStart, newEventEnd])

    useEffect(() => {
      if (repeatFrequency == 1 && repeatType == "dias") {
        setRepeatType("dia")
      }
      if (repeatFrequency == 1 && repeatType == "semanas") {
        setRepeatType("semana")
      }
      if (repeatFrequency == 1 && repeatType == "meses") {
        setRepeatType("mês")
      }
      if (repeatFrequency == 1 && repeatType == "anos") {
        setRepeatType("ano")
      }
      if (repeatFrequency > 1 && repeatType == "dia") {
        setRepeatType("dias")
      }
      if (repeatFrequency > 1 && repeatType == "semana") {
        setRepeatType("semanas")
      }
      if (repeatFrequency > 1 && repeatType == "mês") {
        setRepeatType("meses")
      }
      if (repeatFrequency > 1 && repeatType == "ano") {
        setRepeatType("anos")
      }
    }, [repeatFrequency])

    useEffect(() => {
      if (methods.priceToNumber(value) <= 0) {
        setValueError("O valor deve ser maior que zero")
      } else {
        setValueError(null)
      }
    }, [value])

    useEffect(() => {
      if (valueReceived) {
        setPaymentValue(value)
      }

    }, [value, valueReceived])

    const setFormChanged = props.setFormChanged
    const setNotification = props.setNotification
    const setLoading = props.setLoading
    const setRedirectToLogin = props.setRedirectToLogin

    const mandatoryFields = [
      name,
      description,
      value,
      newEventDay,
      newEventStart,
      newEventEnd,
    ]

    const errorList = [
      newEventDayError,
      newEventStartError,
      newEventEndError,
      timeError,
      repeatFrequencyError,
      valueError,
    ]

    const handleSubmit = async (event) => {
      let eventsArray = []
      if (!repeat) {
        eventsArray = [
          {
            start: new Date(`${DateTime.fromJSDate(newEventDay).toISODate()}T${newEventStart}`),
            end: new Date(`${DateTime.fromJSDate(newEventDay).toISODate()}T${newEventEnd}`),
            description: description,
            value: methods.priceToNumber(value)
          }
        ]
      } else {
        let recurrenceWeekdays = []
        if (sunday) recurrenceWeekdays.push(0)
        if (monday) recurrenceWeekdays.push(1)
        if (tuesday) recurrenceWeekdays.push(2)
        if (wednesday) recurrenceWeekdays.push(3)
        if (thursday) recurrenceWeekdays.push(4)
        if (friday) recurrenceWeekdays.push(5)
        if (saturday) recurrenceWeekdays.push(6)
        let currentWeekdayIndex = 0
        let currentWeek = 0
        let testWeekdayPosition = null
        let testDate = DateTime.fromJSDate(newEventDay)
        let testMonth = null
        let testYear = null
        let weeksToAdd = 0
        for (let i = 0; i < repeatTimes; i++) {
          let timeAddition = {
            days: 0,
            weeks: 0,
            months: 0,
            years: 0,
          }
          let timeSubtraction = {
            days: 0,
            weeks: 0,
            months: 0,
            years: 0,
          }
          let timeToAdd = repeatFrequency * i
          if (/dia/.test(repeatType)) {
            timeAddition.days = timeToAdd
          } else if (/semana/.test(repeatType)) {
            if (!recurrenceWeekdays[0]) {
              timeAddition.weeks = timeToAdd
            } else {
            if (currentWeekdayIndex > recurrenceWeekdays.length - 1) {
              currentWeekdayIndex = 0
              currentWeek++
            }
              if (i == 0) {
                for (let j = 0; j < 7; j++) {
                  if (newEventDay.getDay() > recurrenceWeekdays[currentWeekdayIndex]) {
                    currentWeekdayIndex++
                  }
                }
              }
              if (recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay() >= 0) {
                timeAddition.days = recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay()
              } else {
                timeSubtraction.days = (recurrenceWeekdays[currentWeekdayIndex] - newEventDay.getDay()) * (-1)
              }
              timeAddition.weeks = currentWeek * repeatFrequency
              currentWeekdayIndex++
            }
          } else if (/mês|mes/.test(repeatType)) {
            if (/dia/.test(monthRepeatType)) {
              timeAddition.months = timeToAdd
            } else {
              const getNewMonth = (month, monthsToAdd) => {
                return month + monthsToAdd - 12 * Math.floor((month + monthsToAdd)/12)
              }
              const weekdayPosition = Math.ceil(newEventDay.getDate()/7).nth()
              const originalMonth = newEventDay.getMonth()
              if (timeToAdd != 0) {
                while (testWeekdayPosition != weekdayPosition || testMonth != getNewMonth(originalMonth, timeToAdd)) {
                  testDate = testDate.plus({ days: 7 })
                  if (weekdayPosition != "última") {
                    testWeekdayPosition = Math.ceil(new Date(`${testDate.toISODate()}T${newEventStart}`).getDate()/7).nth()
                  } else {
                    let testDate2 = testDate.plus({ days: 7 })
                    if (new Date(`${testDate.toISODate()}T${newEventStart}`).getMonth() != new Date(`${testDate2.toISODate()}T${newEventStart}`).getMonth()) {
                      testWeekdayPosition = "última"
                    } else {
                      testWeekdayPosition = Math.ceil(new Date(`${testDate.toISODate()}T${newEventStart}`).getDate()/7).nth()
                    }
                  }
                  testMonth = new Date(`${testDate.toISODate()}T${newEventStart}`).getMonth()
                  testYear = new Date(`${testDate.toISODate()}T${newEventStart}`).getFullYear()
                  weeksToAdd += 1
                }
              }
              timeAddition.weeks = weeksToAdd
            }
          } else if (/ano/.test(repeatType)) {
            timeAddition.years = timeToAdd
          }
          let eventToPush = {
            start: new Date(`${DateTime.fromJSDate(newEventDay).plus(timeAddition).minus(timeSubtraction).toISODate()}T${newEventStart}`),
            end: new Date(`${DateTime.fromJSDate(newEventDay).plus(timeAddition).minus(timeSubtraction).toISODate()}T${newEventEnd}`),
            description: description,
            value: methods.priceToNumber(value),
            recurrenceWeekdays: recurrenceWeekdays,
          }
          eventsArray.push(eventToPush)
        }
      }

      let submitInfo = {
        event: event,
        setNotification: setNotification,
        setLoading: setLoading,
        setOpenComponent: setOpenNewRevenueOrExpense,
        mandatoryFields: mandatoryFields,
        errorList: errorList,
        ServiceMethod: RevenuesAndExpensesService.newRevenueOrExpense,
        params: {
          revenueOrExpense: revenueOrExpense,
          name: name,
          description: description,
          value: methods.priceToNumber(value),
          revenuesOrExpensesArray: eventsArray,
          valueReceived: valueReceived,
          paymentValue: methods.priceToNumber(paymentValue),
          paymentMethod: paymentMethod,
          paymentOtherMethodDescription: paymentOtherMethodDescription,
          paymentDate: newEventDay,
        },
        onSuccessText: revenueOrExpense == "revenue" ? (eventsArray.length > 1 ? "Receitas cadastradas." : "Receita cadastrada.") : (eventsArray.length > 1 ? "Despesas cadastradas." : "Despesa cadastrada."),
        onErrorText: "Não foi possível cadastrar. Tente novamente",
        onSuccessCallback: setRefreshRevenuesAndExpenses(),
        onErrorCallback: null,
        setRedirectToLogin: setRedirectToLogin,
      }

      methods.handleSubmit(submitInfo)
    }

    return (
      <Components.CardWithOverlay
        headerClassName={`${revenueOrExpense == "revenue" ? "has-background-success" : "has-background-danger"}`}
        title={`${revenueOrExpense == "revenue" ? "Cadastrar Nova Receita" : "Cadastrar Nova Despesa"}`}
        titleColorClassName="has-text-white"
        icon={revenueOrExpense == "revenue" ? faPlus : faMinus}
        setOpenCard={setOpenNewRevenueOrExpense}
      >

        <Column.Group multiline vcentered>

          <Column className="field-column mt-4 mb-3">
            <Column.Group multiline >

              <Components.Switch
                noText={true}
                label={
                  <Button color="info" outlined={!repeat} size="normal" as="div" fullwidth className="label-button">
                    <Icon >
                      <FontAwesomeIcon icon={faRepeat} />
                    </Icon>
                    <span>Repetir</span>
                  </Button>
                }
                labelSize={8}
                leftLabel={true}
                onChange={(checked) => setRepeat(checked)}
                checked={repeat}
              />
{/* 
              <Components.Switch
                label="Repetir:"
                leftLabel={true}
                onChange={(checked) => setRepeat(checked)}
                checked={repeat}
                setFormChanged={setFormChanged}
              />

              <Column size={12} className="p-0">
                <Column className="is-narrow pr-0 pl-2 py-2 is-12-touch">
                  <Label className="checkbox-container">
                    <Checkbox
                      type="checkbox"
                      checked={repeat}
                      onChange={e => {setRepeat(e.target.checked)}}
                    />
                    <Button as="div" text className="checkbox-button is-size-6 has-text-weight-normal has-background-white ">
                      <span className="checkmark" />
                      <Button color="info" outlined={!repeat} as="div" size="normal" >
                        <Icon>
                          <FontAwesomeIcon icon={faRepeat} />
                        </Icon>
                        <span>Repetir</span>
                      </Button>
                    </Button>
                  </Label>
                </Column>
              </Column> */}

                <Column size={12} className="field-column">
                  <span className="is-size-6 has-text-weight-bold has-text-dark">
                    <Column.Group vcentered className="m-0" breakpoint="mobile" >
                      <Column narrow className="p-0" >
                        <span className={`${!repeat ? "has-text-grey-light" : ""}`}>Repetir a cada:</span>
                      </Column>
                      <Column narrow className="p-0" >
                        <Input
                          disabled={!repeat}
                          className="repeat-times ml-2"
                          color={repeatFrequencyError ? "danger" : ""}
                          type="number"
                          min="1"
                          max="30"
                          value={repeatFrequency}
                          onChange={(e) => { setRepeatFrequency(e.target.value); if (isNaN(e.target.value) || e.target.value < 1 || e.target.value > 30) { setRepeatFrequencyError("A frequência deve ser um número de 1 a 30") } else { setRepeatFrequencyError(false) }; if (setFormChanged) { setFormChanged(true) } }}
                        />
                      </Column>
                      <Column narrow className="p-0 ml-2" >
                        <Select.Container>
                          <Select
                            disabled={!repeat}
                            type="text"
                            value={repeatType}
                            onChange={e => { setRepeatType(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                            name="repeat-type"
                          >
                            <Select.Option>{repeatFrequency == 1 ? "dia" : "dias"}</Select.Option>
                            <Select.Option>{repeatFrequency == 1 ? "semana" : "semanas"}</Select.Option>
                            <Select.Option>{repeatFrequency == 1 ? "mês" : "meses"}</Select.Option>
                            <Select.Option>{repeatFrequency == 1 ? "ano" : "anos"}</Select.Option>
                          </Select>
                        </Select.Container>
                      </Column>
                    </Column.Group>
                  </span>
                </Column>

                {/semana/.test(repeatType) &&
                  <Column size={12} className="field-column" >
                    <span className="is-size-6 has-text-weight-bold has-text-dark">
                      <Column.Group vcentered className="m-0" breakpoint="mobile" style={{ height: "43px" }} >
                        <Column narrow className="p-0 pr-1" >
                          <span className={`${!repeat ? "has-text-grey-light" : ""}`}>Dias da semana:</span>
                        </Column>
                        <Column narrow className="p-0" >
                          <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (sunday ? "#1a73e8" : "#e7e7e7"), color: (sunday ? "#ffffff" : "#000000") }}onClick={(e) => setSunday(!sunday)}>D</div>
                          <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (monday ? "#1a73e8" : "#e7e7e7"), color: (monday ? "#ffffff" : "#000000") }}onClick={(e) => setMonday(!monday)}>S</div>
                          <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (tuesday ? "#1a73e8" : "#e7e7e7"), color: (tuesday ? "#ffffff" : "#000000") }}onClick={(e) => setTuesday(!tuesday)}>T</div>
                          <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (wednesday ? "#1a73e8" : "#e7e7e7"), color: (wednesday ? "#ffffff" : "#000000") }}onClick={(e) => setWednesday(!wednesday)}>Q</div>
                          <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (thursday ? "#1a73e8" : "#e7e7e7"), color: (thursday ? "#ffffff" : "#000000") }}onClick={(e) => setThursday(!thursday)}>Q</div>
                          <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (friday ? "#1a73e8" : "#e7e7e7"), color: (friday ? "#ffffff" : "#000000") }}onClick={(e) => setFriday(!friday)}>S</div>
                          <div className={`weekday-button ${!repeat ? "has-background-light has-text-grey-light" : ""}`} style={{ backgroundColor: (saturday ? "#1a73e8" : "#e7e7e7"), color: (saturday ? "#ffffff" : "#000000") }}onClick={(e) => setSaturday(!saturday)}>S</div>
                        </Column>
                      </Column.Group>
                    </span>
                  </Column>
                }

                {/mês|mes/.test(repeatType) &&
                  <Column size={12} className="field-column" >
                    <span className="is-size-6 has-text-weight-bold has-text-dark">
                      <Column.Group vcentered className="m-0" breakpoint="mobile" style={{ height: "43px" }} >
                        <Column narrow className="p-0 pr-1" >
                          <span className={`${!repeat ? "has-text-grey-light" : ""}`}>Dia do mês:</span>
                        </Column>
                        <Column narrow className="p-0" >
                          <Select.Container>
                            <Select
                              disabled={!repeat}
                              type="text"
                              value={monthRepeatType}
                              onChange={e => { setMonthRepeatType(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                              name="month-repeat-type"
                            >
                              <Select.Option>{newEventDay && `Todo dia ${newEventDay.getDate()}`}</Select.Option>
                              <Select.Option>{monthPositionText}</Select.Option>
                            </Select>
                          </Select.Container>
                        </Column>
                      </Column.Group>
                    </span>
                  </Column>
                }

                <Column size={12} className="field-column">
                  <span className="is-size-6 has-text-weight-bold has-text-dark">
                    <Column.Group vcentered className="m-0" breakpoint="mobile" >
                      <Column narrow className="p-0 pr-1" >
                        <span className={`${!repeat ? "has-text-grey-light" : ""}`}>Total de repetições:</span>
                      </Column>
                      <Column narrow className="p-0" >
                        <Input
                          disabled={!repeat}
                          className="repeat-times ml-2"
                          color={repeatTimesError ? "danger" : ""}
                          type="number"
                          min="1"
                          max="60"
                          value={repeatTimes}
                          onChange={(e) => { setRepeatTimes(e.target.value); if (isNaN(e.target.value) || e.target.value < 1 || e.target.value > 60) { setRepeatTimesError("A quantidade de vezes deve ser um número de 1 a 60") } else { setRepeatTimesError(false) }; if (setFormChanged) { setFormChanged(true) } }}
                        />
                      </Column>
                      <Column narrow className="p-0 ml-2" >
                        <span className={`has-text-weight-normal ${!repeat ? "has-text-grey-light" : ""}`}>{repeatTimes == 1 ? "vez" : "vezes"}</span>
                      </Column>
                    </Column.Group>
                  </span>
                </Column>

              <Column size={12} className="p-0" >
                <hr className="form-divider mx-2" />
                <Components.GenericInput
                  required={true}
                  size={12}
                  label={`${revenueOrExpense == "revenue" ? "Nome da Receita:" : "Nome da Despesa:"}`}
                  placeholder=""
                  elementName="revenue-expense-name"
                  value={name}
                  setValue={setName}
                  setFormChanged={setFormChanged}
                />
              </Column>

              <Column size={12} className="p-0" >
                <Components.GenericInput
                  required={true}
                  size={12}
                  label="Descrição:"
                  placeholder="Pequena descrição da receita"
                  elementName="revenue-expense-description"
                  value={description}
                  setValue={setDescription}
                  setFormChanged={setFormChanged}
                />
              </Column>

              <Column size={repeat ? 8 : 12} className="p-0" >
                <Components.GenericInput
                  required={true}
                  size={12}
                  label={`Valor${repeat ? ` por ${revenueOrExpense == "revenue" ? "receita" : "despesa"}` : ""}:`}
                  placeholder="R$ 0,00"
                  elementName="value"
                  value={value}
                  setValue={setValue}
                  maskAndSetValue={methods.maskAndSetPrice}
                  setFormChanged={setFormChanged}
                />
              </Column>

              {repeat &&
                <Column size={4} className="p-0" >
                  <Components.GenericInput
                    disableSelect={true}
                    size={12}
                    label="Valor total:"
                    placeholder="Valor total"
                    elementName="value"
                    value={methods.numberToPrice((methods.priceToNumber(value) * repeatTimes).toFixed(2))}
                    setValue={null}
                    setFormChanged={setFormChanged}
                  />
                </Column>
              }

              <Column size={12} className="p-0">
                <hr className="form-divider mx-2" />
                <Column className="is-narrow pr-0 pl-2 py-2 is-12-touch">
                  <Label className="checkbox-container">
                    <Checkbox
                      type="checkbox"
                      checked={valueReceived}
                      onChange={e => {setValueReceived(e.target.checked)}}
                    />
                    <Button as="div" text className="checkbox-button is-size-6 has-text-weight-normal has-background-white ">
                      <span className="checkmark" />
                      <Button color="success" outlined={!valueReceived} as="div" size="normal" >
                        <Icon>
                          <FontAwesomeIcon icon={faDollarSign} />
                        </Icon>
                        <span>{`Cadastrar ${repeat ? "primeiro " : ""}valor ${revenueOrExpense == "revenue" ? "recebido" : "pago"}:`}</span>
                      </Button>
                    </Button>
                  </Label>
                </Column>
              </Column>

              <Column size={12} className="p-0" >

                <Components.GenericInput
                  disabled={!valueReceived}
                  size={12}
                  label={`${repeat ? "Primeiro valor" : "Valor"} ${revenueOrExpense == "revenue" ? "recebido" : "pago"}:`}
                  placeholder="R$ "
                  elementName="paymentValue"
                  value={paymentValue}
                  setValue={setPaymentValue}
                  maskAndSetValue={methods.maskAndSetPrice}
                  setFormChanged={setFormChanged}
                />

              </Column>

              <Column size={12} className="field-column" >
                <Field>
                  <Label className={`${!valueReceived ? "has-text-grey-light" : ""}`}>Forma de pagamento:</Label>
                  <Control expanded>
                    <Select.Container fullwidth>
                      <Select
                        disabled={!valueReceived}
                        type="text"
                        value={paymentMethod}
                        onChange={e => { setPaymentMethod(e.target.value); if (setFormChanged) { setFormChanged(true) } }}
                        name="paymentMethod"
                      >
                        <Select.Option>Dinheiro</Select.Option>
                        <Select.Option>Cartão de débito</Select.Option>
                        <Select.Option>Cartão de crédito</Select.Option>
                        <Select.Option>Pix</Select.Option>
                        <Select.Option>Boleto bancário</Select.Option>
                        <Select.Option>Outro</Select.Option>
                      </Select>
                    </Select.Container>
                  </Control>
                </Field>
              </Column>

              {paymentMethod == "Outro" &&
                <Column size={12} className="p-0" >
                  <Components.GenericInput
                    disabled={!valueReceived}
                    size={12}
                    label="Outro:"
                    placeholder="Descreva a forma de pagamento:"
                    elementName="otherMethod"
                    value={paymentOtherMethodDescription}
                    setValue={setPaymentOtherMethodDescription}
                    setFormChanged={setFormChanged}
                  />
                </Column>
              }

              <Column size={12} className="p-0" >
                <hr className="form-divider mx-2" />
              </Column>
              
              <Column narrow className="field-column" >
                
                <Label>{valueReceived ? (revenueOrExpense == "revenue" ? "Data de recebimento:" : "Data de pagamento:") : revenueOrExpense == "revenue" ? "Previsão de recebimento:" : "Data de vencimento:"}</Label>
                <Column className="date-input p-0">
                  <DatePicker
                    selected={newEventDay}
                    onChange={(date) => { setNewEventDay(date) }}
                    customInput={
                      <Components.DatePickerInput
                        icon={faCalendar}
                        dateError={newEventDayError}
                        setDateError={setNewEventDayError}
                      />}
                    dateFormat="dd/MM/yyyy"
                    locale="pt-BR"
                    strictParsing
                  />
                </Column>
              </Column>
             
            </Column.Group>
          </Column>

        </Column.Group>

        <Button.Group align="centered">
          <Button
            color="info"
            size="medium"
            className="has-text-weight-semibold"
            onClick={e => {
              handleSubmit(e)
            }} >
            <span>Cadastrar</span>
          </Button>
        </Button.Group>

      </Components.CardWithOverlay>
    )
  },

  CashFlow: (props) => {

    const { refreshRevenuesAndExpenses, setRefreshRevenuesAndExpenses, setNotification, setLoading, setRedirectToLogin } = props

    const [periodType, setPeriodType] = useState("Mês")

    const [chosenDate, setChosenDate] = useState(new Date(Date.now()))
    const [chosenDateError, setChosenDateError] = useState(false)

    const [useNotDoneTransactions, setUseNotDoneTransactions] = useState(true)

    const [initialDate, setInitialDate] = useState("")
    const [initialDateError, setInitialDateError] = useState(null)
    const [finalDate, setFinalDate] = useState("")
    const [finalDateError, setFinalDateError] = useState(null)

    const [receivedRevenues, setReceivedRevenues] = useState(0)
    const [paidExpenses, setPaidExpenses] = useState(0)
    const [unreceivedRevenues, setUnreceivedRevenues] = useState(0)
    const [unpaidExpenses, setUnpaidExpenses] = useState(0)

    const getRevenuesAndExpenses = async () => {
      setLoading(true)
      await RevenuesAndExpensesService.getRevenuesAndExpenses({
        periodType: periodType,
        chosenDate: chosenDate,
        initialDate: initialDate,
        finalDate: finalDate,
      })
      .then( (response) => {
        console.log(response.data)
        let receivedRevenuesToPush = [0]
        let paidExpensesToPush = [0]
        let unreceivedRevenuesToPush = [0]
        let unpaidExpensesToPush = [0]
        response.data.revenues.map(revenue => {
          if (revenue.payments && revenue.payments[0]) {
            let paid = 0
            revenue.payments.map(payment => {
              if (payment) {
                paid += payment.value
              }
            })
            receivedRevenuesToPush.push(paid)
            unreceivedRevenuesToPush.push(revenue.value - paid)
          } else {
            unreceivedRevenuesToPush.push(revenue.value)
          }
        })
        response.data.expenses.map(expense => {
          if (expense.payments && expense.payments[0]) {
            let paid = 0
            expense.payments.map(payment => {
              if (payment) {
                paid += payment.value
              }
            })
            paidExpensesToPush.push(paid)
            unpaidExpensesToPush.push(expense.value - paid)
          } else {
            unpaidExpensesToPush.push(expense.value)
          }
        })
        response.data.attendances.map(attendance => {
          if (attendance.payments && attendance.payments[0]) {
            let paid = 0
            attendance.payments.map(payment => {
              if (payment) {
                paid += payment.value
              }
            })
            receivedRevenuesToPush.push(paid)
            unreceivedRevenuesToPush.push(attendance.price - paid)
          } else {
            unreceivedRevenuesToPush.push(attendance.price)
          }
        })
        useNotDoneTransactions ?
        setChartData(
          [
            { "name": "Receitas", "Receitas": receivedRevenuesToPush.reduce((partialSum, a) => partialSum + a, 0) },
            { "name": "Despesas", "Despesas": paidExpensesToPush.reduce((partialSum, a) => partialSum + a, 0) * (-1) },
            { "name": "A receber", "A receber": unreceivedRevenuesToPush.reduce((partialSum, a) => partialSum + a, 0) },
            { "name": "A pagar", "A pagar": unpaidExpensesToPush.reduce((partialSum, a) => partialSum + a, 0) * (-1) }
          ]
        )
        :
        setChartData(
          [
            { "name": "Receitas", "Receitas": receivedRevenuesToPush.reduce((partialSum, a) => partialSum + a, 0) },
            { "name": "Despesas", "Despesas": paidExpensesToPush.reduce((partialSum, a) => partialSum + a, 0) * (-1) },
          ]
        )
        setReceivedRevenues(receivedRevenuesToPush.reduce((partialSum, a) => partialSum + a, 0))
        setPaidExpenses(paidExpensesToPush.reduce((partialSum, a) => partialSum + a, 0) * (-1))
        setUnreceivedRevenues(unreceivedRevenuesToPush.reduce((partialSum, a) => partialSum + a, 0))
        setUnpaidExpenses(unpaidExpensesToPush.reduce((partialSum, a) => partialSum + a, 0) * (-1))
        setLoading(false)
      })
    }

    const [chartData, setChartData] = useState(
      useNotDoneTransactions ?
      [
        { "name": "Receitas", "Receitas": receivedRevenues },
        { "name": "Despesas", "Despesas": paidExpenses },
        { "name": "A receber", "A receber": unreceivedRevenues },
        { "name": "A pagar", "A pagar": unpaidExpenses }
      ]
      :
      [
        { "name": "Receitas", "Receitas": receivedRevenues },
        { "name": "Despesas", "Despesas": paidExpenses }
      ]
    )

    useEffect( () => {
      if (periodType != "Personalizado" || (initialDate != "" && finalDate != "")) {
        getRevenuesAndExpenses()
      }
    }, [periodType, chosenDate, initialDate, finalDate])

    useEffect( () => {
      useNotDoneTransactions ?
      setChartData(
        [
          { "name": "Receitas", "Receitas": receivedRevenues },
          { "name": "Despesas", "Despesas": paidExpenses },
          { "name": "A receber", "A receber": unreceivedRevenues },
          { "name": "A pagar", "A pagar": unpaidExpenses }
        ]
      )
      :
      setChartData(
        [
          { "name": "Receitas", "Receitas": receivedRevenues },
          { "name": "Despesas", "Despesas": paidExpenses }
        ]
      )
    }, [useNotDoneTransactions])

    useEffect(() => {
      if (refreshRevenuesAndExpenses > 0)
      getRevenuesAndExpenses()
    }, [refreshRevenuesAndExpenses])

    return (
      <Fragment>

        <Column.Group vcentered multiline>

          <Column narrow>
            <Field>
              <Label >Filtrar por período:</Label>
              <Control>
                <Select.Container>
                  <Select
                    type="text"
                    value={periodType}
                    onChange={e => {setPeriodType(e.target.value)}}
                  >
                    <Select.Option>Dia</Select.Option>
                    <Select.Option>Mês</Select.Option>
                    <Select.Option>Ano</Select.Option>
                    <Select.Option>Personalizado</Select.Option>
                  </Select>
                </Select.Container>
              </Control>
            </Field>
          </Column>

          {periodType == "Dia" &&
            <Column narrow className="field-column date-input" >
              <Label>Data:</Label>
              <DatePicker
                selected={chosenDate}
                onChange={(date) => { setChosenDate(date); setChosenDateError(null) }}
                customInput={
                  <Components.DatePickerInput
                    icon={faCalendar}
                    dateError={chosenDateError}
                    setDateError={setChosenDateError}
                  />}
                dateFormat="dd/MM/yyyy"
                locale="pt-BR"
                strictParsing
              />
            </Column>
          }

          {periodType == "Mês" &&
            <Column narrow className="field-column date-input" >
              <Label>Mês:</Label>
              <DatePicker
                selected={chosenDate}
                onChange={(date) => { setChosenDate(date); setChosenDateError(null) }}
                customInput={
                  <Components.MonthOrYearPickerInput
                    icon={faCalendar}
                    dateError={chosenDateError}
                    setDateError={setChosenDateError}
                  />}
                dateFormat="MMM/yyyy"
                locale="pt-BR"
                strictParsing
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </Column>
          }

          {periodType == "Ano" &&
            <Column narrow className="field-column date-input" >
              <Label>Ano:</Label>
              <DatePicker
                selected={chosenDate}
                onChange={(date) => { setChosenDate(date); setChosenDateError(null) }}
                customInput={
                  <Components.MonthOrYearPickerInput
                    icon={faCalendar}
                    dateError={chosenDateError}
                    setDateError={setChosenDateError}
                  />}
                dateFormat="yyyy"
                locale="pt-BR"
                strictParsing
                showYearPicker
              />
            </Column>
          }

          {periodType == "Personalizado" &&
            <Components.DatePeriod
              required={false}
              elementName="date"
              initialDate={initialDate}
              initialDateError={initialDateError}
              finalDate={finalDate}
              finalDateError={finalDateError}
              setInitialDate={setInitialDate}
              setInitialDateError={setInitialDateError}
              setFinalDate={setFinalDate}
              setFinalDateError={setFinalDateError}
            />
          }


          <Components.CheckboxContainer
            columnSize="9"
            required={false}
            label="Usar previsão:"
          >

            <Components.Checkbox key={1} checked={useNotDoneTransactions} onChange={setUseNotDoneTransactions} >
              Incluir previsão de contas a receber e a pagar
            </Components.Checkbox>

          </Components.CheckboxContainer>

        </Column.Group>

        <Box>
          <Column.Group vcentered className="my-0">
            <Column size={8} className="finance-chart-container">
              <ResponsiveContainer>
                <BarChart
                  data={chartData}
                  margin={{ top: 20, right: 20, left: 0, bottom: 0 }}
                  stackOffset="sign"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    allowDataOverflow={true}
                    height={40}

                    tickMargin={15}
                  />
                  <YAxis 
                    domain={["auto", "auto"]}
                    tickFormatter={(value) => "R$ " + value}
                    fontSize={11}
                  />
                  <Tooltip formatter={(value) => methods.numberToPrice(value)} />
                  <ReferenceLine y={0} stroke="#000" />
                  <Bar name='Receitas' dataKey="Receitas" stackId="stack" fill="#3d4">
                    <LabelList dataKey="Receitas" position="top" formatter={(value) => methods.numberToPrice(value)} fontSize={12} />
                  </Bar>
                  <Bar dataKey="Despesas" stackId="stack" fill="#d33">
                    <LabelList dataKey="Despesas" position="top" formatter={(value) => methods.numberToPrice(value)} fontSize={12} />
                  </Bar>
                  <Bar dataKey="A receber" stackId="stack" fill="#9ea" >
                    <LabelList dataKey="A receber" position="top" formatter={(value) => methods.numberToPrice(value)} fontSize={12} />
                  </Bar>
                  <Bar dataKey="A pagar" stackId="stack" fill="#e99" >
                    <LabelList dataKey="A pagar" position="top" formatter={(value) => methods.numberToPrice(value)} fontSize={12} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Column>
            <Components.Counter
              useNotDoneTransactions={useNotDoneTransactions}
              receivedRevenues={receivedRevenues}
              paidExpenses={paidExpenses}
              unreceivedRevenues={unreceivedRevenues}
              unpaidExpenses={unpaidExpenses}
              setChartData={setChartData}
            />
          </Column.Group>
        </Box>

      </Fragment>
    )
  },

  Counter: (props) => {

    const [refresh, setRefresh] = useState(0)

    const finalFlowToShow = useRef(0)
    const intervalId = useRef(undefined)

    const { useNotDoneTransactions, receivedRevenues, paidExpenses, unreceivedRevenues, unpaidExpenses } = props


    useEffect(() => {
      clearInterval(intervalId.current)
      let finalFlow = receivedRevenues + paidExpenses + (useNotDoneTransactions ? unreceivedRevenues + unpaidExpenses : 0)
      finalFlowToShow.current = 0
      let finalFlowFraction = finalFlow/50
      intervalId.current = setInterval(() => {
        if (finalFlow > 0 ? finalFlow - finalFlowToShow.current <= finalFlowFraction : finalFlow - finalFlowToShow.current >= finalFlowFraction) {
          finalFlowToShow.current = finalFlow
        } else {
          finalFlowToShow.current = finalFlowToShow.current + finalFlowFraction
        }
        setRefresh(finalFlowToShow.current + 1)
        if (finalFlowToShow.current == finalFlow) clearInterval(intervalId.current)
      }, 20)
    }, [useNotDoneTransactions, receivedRevenues, paidExpenses, unreceivedRevenues, unpaidExpenses])

    return (

      <Column size={4} >
        <Title className="has-text-centered">
          {useNotDoneTransactions ? "Previsão:" : "Resultado:"}
        </Title>
        <Title className={"has-text-centered " + (finalFlowToShow.current >= 0 ? "has-text-success" : "has-text-danger")}>
          {methods.numberToPrice(finalFlowToShow.current)}
        </Title>
      </Column>

    )
  },

  RevenuesAndExpenses: (props) => {

    const { refreshRevenuesAndExpenses, setRefreshRevenuesAndExpenses, setNotification, setLoading, setRedirectToLogin } = props

    const [periodType, setPeriodType] = useState("Mês")

    const [chosenDate, setChosenDate] = useState(new Date(Date.now()))
    const [chosenDateError, setChosenDateError] = useState(false)

    const [useNotDoneTransactions, setUseNotDoneTransactions] = useState(true)

    const [initialDate, setInitialDate] = useState("")
    const [initialDateError, setInitialDateError] = useState(null)
    const [finalDate, setFinalDate] = useState("")
    const [finalDateError, setFinalDateError] = useState(null)

    const [receivedRevenues, setReceivedRevenues] = useState(0)
    const [paidExpenses, setPaidExpenses] = useState(0)
    const [unreceivedRevenues, setUnreceivedRevenues] = useState(0)
    const [unpaidExpenses, setUnpaidExpenses] = useState(0)

    const getRevenuesAndExpenses = async () => {
      setLoading(true)
      await RevenuesAndExpensesService.getRevenuesAndExpenses({
        periodType: periodType,
        chosenDate: chosenDate,
        initialDate: initialDate,
        finalDate: finalDate,
      })
      .then( (response) => {
        console.log(response.data)
        let receivedRevenuesToPush = [0]
        let paidExpensesToPush = [0]
        let unreceivedRevenuesToPush = [0]
        let unpaidExpensesToPush = [0]
        response.data.revenues.map(revenue => {
          if (revenue.payments && revenue.payments[0]) {
            let paid = 0
            revenue.payments.map(payment => {
              if (payment) {
                paid += payment.value
              }
            })
            receivedRevenuesToPush.push(paid)
            unreceivedRevenuesToPush.push(revenue.value - paid)
          } else {
            unreceivedRevenuesToPush.push(revenue.value)
          }
        })
        response.data.expenses.map(expense => {
          if (expense.payments && expense.payments[0]) {
            let paid = 0
            expense.payments.map(payment => {
              if (payment) {
                paid += payment.value
              }
            })
            paidExpensesToPush.push(paid)
            unpaidExpensesToPush.push(expense.value - paid)
          } else {
            unpaidExpensesToPush.push(expense.value)
          }
        })
        response.data.attendances.map(attendance => {
          if (attendance.payments && attendance.payments[0]) {
            let paid = 0
            attendance.payments.map(payment => {
              if (payment) {
                paid += payment.value
              }
            })
            receivedRevenuesToPush.push(paid)
            unreceivedRevenuesToPush.push(attendance.price - paid)
          } else {
            unreceivedRevenuesToPush.push(attendance.price)
          }
        })
        setLoading(false)
      })
    }
  
    useEffect( () => {
      if (periodType != "Personalizado" || (initialDate != "" && finalDate != "")) {
        getRevenuesAndExpenses()
      }
    }, [periodType, chosenDate, initialDate, finalDate])

    useEffect(() => {
      if (refreshRevenuesAndExpenses > 0)
      getRevenuesAndExpenses()
    }, [refreshRevenuesAndExpenses])

    return (
      <Fragment>

        <Column.Group vcentered multiline>

          <Column narrow>
            <Field>
              <Label >Filtrar por período:</Label>
              <Control>
                <Select.Container>
                  <Select
                    type="text"
                    value={periodType}
                    onChange={e => {setPeriodType(e.target.value)}}
                  >
                    <Select.Option>Dia</Select.Option>
                    <Select.Option>Mês</Select.Option>
                    <Select.Option>Ano</Select.Option>
                    <Select.Option>Personalizado</Select.Option>
                  </Select>
                </Select.Container>
              </Control>
            </Field>
          </Column>

          {periodType == "Dia" &&
            <Column narrow className="field-column date-input" >
              <Label>Data:</Label>
              <DatePicker
                selected={chosenDate}
                onChange={(date) => { setChosenDate(date); setChosenDateError(null) }}
                customInput={
                  <Components.DatePickerInput
                    icon={faCalendar}
                    dateError={chosenDateError}
                    setDateError={setChosenDateError}
                  />}
                dateFormat="dd/MM/yyyy"
                locale="pt-BR"
                strictParsing
              />
            </Column>
          }

          {periodType == "Mês" &&
            <Column narrow className="field-column date-input" >
              <Label>Mês:</Label>
              <DatePicker
                selected={chosenDate}
                onChange={(date) => { setChosenDate(date); setChosenDateError(null) }}
                customInput={
                  <Components.MonthOrYearPickerInput
                    icon={faCalendar}
                    dateError={chosenDateError}
                    setDateError={setChosenDateError}
                  />}
                dateFormat="MMM/yyyy"
                locale="pt-BR"
                strictParsing
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </Column>
          }

          {periodType == "Ano" &&
            <Column narrow className="field-column date-input" >
              <Label>Ano:</Label>
              <DatePicker
                selected={chosenDate}
                onChange={(date) => { setChosenDate(date); setChosenDateError(null) }}
                customInput={
                  <Components.MonthOrYearPickerInput
                    icon={faCalendar}
                    dateError={chosenDateError}
                    setDateError={setChosenDateError}
                  />}
                dateFormat="yyyy"
                locale="pt-BR"
                strictParsing
                showYearPicker
              />
            </Column>
          }

          {periodType == "Personalizado" &&
            <Components.DatePeriod
              required={false}
              elementName="date"
              initialDate={initialDate}
              initialDateError={initialDateError}
              finalDate={finalDate}
              finalDateError={finalDateError}
              setInitialDate={setInitialDate}
              setInitialDateError={setInitialDateError}
              setFinalDate={setFinalDate}
              setFinalDateError={setFinalDateError}
            />
          }


          <Components.CheckboxContainer
            columnSize="9"
            required={false}
            label="Usar previsão:"
          >

            <Components.Checkbox key={1} checked={useNotDoneTransactions} onChange={setUseNotDoneTransactions} >
              Incluir previsão de contas a receber e a pagar
            </Components.Checkbox>

          </Components.CheckboxContainer>

        </Column.Group>

        {!companies[0]  &&
          <Title size={5} className="has-text-centered has-text-danger" >
            Não foram encontrados resultados com os parâmetros informados.
          </Title>
        }

        <Column.Group>
          <Column className="table-container is-size-6">

            <Table fullwidth hoverable narrow >

              <Table.Head>
                <Table.Row>
                  <Table.Heading textAlign="centered" className="table-column-small" >
                    Tipo
                  </Table.Heading>
                  <Table.Heading>
                    Nome
                  </Table.Heading>
                  <Table.Heading>
                    Descrição
                  </Table.Heading>
                  <Table.Heading textAlign="centered" >
                    CNPJ
                  </Table.Heading>
                  <Table.Heading textAlign="centered" >
                    Cadastro
                  </Table.Heading>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                {companies.map((company, key) => (
                  <Table.Row key={key} className="cursor-pointer" onClick={e => {setObjectToEdit(company); history.push("/app/edit-company")}}>
                    <Table.Cell textAlign="centered">
                      {company.isActive && <span className="has-text-success has-text-weight-bold">Ativa</span>}
                      {!company.isActive && <span className="has-text-grey">Inativa</span>}
                    </Table.Cell>
                    <Table.Cell>
                      {company.name}
                    </Table.Cell>
                    <Table.Cell>
                      {company.tradingName}
                    </Table.Cell>
                    <Table.Cell textAlign="centered" >
                      {methods.maskAndSetCnpj(company.cnpj)}
                    </Table.Cell>
                    <Table.Cell textAlign="centered">
                      {methods.dateToString(company.createdAt)}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

            </Table>

          </Column>
        </Column.Group>

      </Fragment>
    )
  },

}

export default Components
export { methods }