import Api from "./api"

const ClinicalExamsService = {

  newClinicalExam: async (params) => {
    const response = await Api.post("/clinicalExams/newClinicalExam", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },
  
  editClinicalExam: async (params) => {
    let response = await Api.put("/clinicalExams/editClinicalExam", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getClinicalExamById: async (params) => {
    let response = await Api.post("/clinicalExams/getClinicalExamById", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  searchClinicalExamsByPatientId: async (params) => {
    let response = await Api.post("/clinicalExams/searchClinicalExamsByPatientId", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  searchClinicalExams: async (params) => {
    let response = await Api.post("/clinicalExams/searchClinicalExams", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteClinicalExam: async (params) => {
    let response = await Api.post("/clinicalExams/deleteClinicalExam", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default ClinicalExamsService