import React, { useState } from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import LoginView from "./views/login"
import AppView from "./views/app"
import ResetPasswordView from "./views/resetPassword"
import NotFoundView from "./views/notfound"

function Routes () {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={LoginView} />
        <Route exact path='/login' component={LoginView} />
        <Route path='/app' component={AppView} />
        <Route exact path='/resetPassword/:token' component={ResetPasswordView} />
        <Route component={NotFoundView} />
      </Switch>
  </BrowserRouter>
  )
}

export default Routes