import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { Column, Section } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import UsersService from "../../../../services/users"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { faEdit, faUserEdit } from '@fortawesome/free-solid-svg-icons'

const EditUser = (props) => {
  const [user, setUser] = useState(props.objectToEdit)

  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(null)

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(null)

  const [gender, setGender] = useState("")

  const [rgIdentifier, setRgIdentifier] = useState("")
  const [rgIdentifierError, setRgIdentifierError] = useState(null)
  const [rgDigit, setRgDigit] = useState("")
  const [rgUf, setRgUf] = useState("")

  const [cpf, setCpf] = useState("")
  const [cpfError, setCpfError] = useState(null)

  const [birthday, setBirthday] = useState("")
  const [birthdayError, setBirthdayError] = useState(null)
  
  const [areaCode, setAreaCode] = useState("")
  const [areaCodeError, setAreaCodeError] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberError, setPhoneNumberError] = useState(null)

  const [cep, setCep] = useState("")
  const [cepError, setCepError] = useState(null)
  const [street, setStreet] = useState("")
  const [adressNumber, setAdressNumber] = useState("")
  const [complement, setComplement] = useState("")
  const [neighborhood, setNeighborhood] = useState("")
  const [city, setCity] = useState("")
  const [uf, setUf] = useState("")

  const [isSystemAdm, setIsSystemAdm] = useState(false)
  const [isCompanyAdm, setIsCompanyAdm] = useState(false)
  const [isProfessional, setIsProfessional] = useState(false)
  const [isSecretary, setIsSecretary] = useState(false)
  const [accessLevelsError, setAccessLevelsError] = useState(null)

  const [professionalTitle, setProfessionalTitle] = useState("")
  const [councilName, setCouncilName] = useState("")
  const [councilRegister, setCouncilRegister] = useState("")

  const [createdAt, setCreatedAt] = useState("")
  const [lastAccess, setLastAccess] = useState("")
  const [isActive, setIsActive] = useState(false)

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = React.useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  let history = useHistory();

  const handleIsActiveChange = (checked) => {
    setIsActive(checked)
  }

  const mandatoryFields = []

  const errorList = [
    accessLevelsError,
  ]

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
      setNotification({
        success: false,
        text: "Favor preencher todos os campos obrigatórios",
        callback: () => {
          setNotification(false)
        }
      })
    } else if (methods.hasErrors(errorList)) {
      setNotification({
        success: false,
        text: methods.errorsToShow(errorList),
        callback: () => {
          setNotification(false)
        }
      })
    } else {
      setLoading(true)
      let userInfoToUpdate = {
        email: user.email,
        isSystemAdm: isSystemAdm,
        isCompanyAdm: isCompanyAdm,
        isProfessional: isProfessional,
        isSecretary: isSecretary,
        isActive: isActive,
      }
      await UsersService.editUser(userInfoToUpdate)
      .then( () => {      
        setLoading(false)
        setNotification({
          success: true,
          text: "Informações do usuário atualizadas.",
          callback: () => {
            setNotification(false)
            setFormChanged(false)
            history.replace("/app/home")
          }
        })
      })
      .catch(function (error) {
        console.log(error)
        try {
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setLoading(false)
            setNotification({
              success: false,
              text: error.response.data,
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {          
          setLoading(false)
          setNotification({
            success: false,
            text: "Não foi possível atualizar as informações. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      })
    }
  }

  useEffect( () => {
    if (accessLevelsError) {
      if (isSystemAdm || isCompanyAdm || isProfessional || isSecretary) {
        setAccessLevelsError(null)
      }
    } else {
      if (!isSystemAdm && !isCompanyAdm && !isProfessional && !isSecretary) {
        setAccessLevelsError("Selecione pelo menos um nível de acesso")
      }
    }
  }, [isSystemAdm, isCompanyAdm, isProfessional, isSecretary])

  useEffect( () => {
    const eventListener = (event) => {
      event.preventDefault()
      if (formChangedRef.current) {
        let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }
    window.addEventListener('beforeunload', eventListener)
    try {
      setName(user.name)
      setEmail(user.email)
      methods.maskAndSetAreaCode(user.areaCode, setAreaCode, setAreaCodeError)
      methods.maskAndSetPhoneNumber(user.phoneNumber, setPhoneNumber, setPhoneNumberError)
      setGender(user.gender)
      setRgIdentifier(user.rgIdentifier)
      setRgDigit(user.rgDigit)
      setRgUf(user.rgUf)
      methods.maskAndSetCpf(user.cpf, setCpf, setCpfError)
      methods.dateToString(user.birthday, setBirthday)
      methods.maskAndSetCep(user.cep, setCep)
      setStreet(user.street)
      setAdressNumber(user.adressNumber)
      setComplement(user.complement)
      setNeighborhood(user.neighborhood)
      setCity(user.city)
      setUf(user.uf)
      setProfessionalTitle(user.professionalTitle)
      setCouncilName(user.councilName)
      setCouncilRegister(user.councilRegister)
      setIsSystemAdm(user.isSystemAdm)
      setIsCompanyAdm(user.isCompanyAdm)
      setIsProfessional(user.isProfessional)
      setIsSecretary(user.isSecretary)
      methods.dateToString(user.createdAt, setCreatedAt)
      if (user.lastAccess) {
        methods.dateToString(user.lastAccess, setLastAccess)
      } else {
        setLastAccess("Nunca")
      }

      setIsActive(user.isActive)
    } catch (error) {
      console.log(error)
    }
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    }
  }, [])

  if (redirectToLogin || !user) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle icon={faUserEdit}>
        Editar Usuário
      </WorkspaceTitle>

      <Section className="workspace-content">

        <form onSubmit={handleSubmit} >

          <Column.Group multiline>

            <Components.GenericStaticField
              label="Nome:"
              value={name}
            />

            <Components.GenericStaticField
              label="E-mail:"
              value={email}
            />

            <Components.GenericStaticField
              label="Telefone:"
              value={`${areaCode} ${phoneNumber}`}
            />

            <Components.Gender
              gender={gender}
              setGender={() => {}}
            />

            <Components.GenericStaticField
              size={5}
              label="RG:"
              value={`${rgIdentifier}${rgDigit && `-`}${rgDigit}`}
            />

            <Components.GenericStaticField
              size={1}
              label="UF:"
              value={rgUf}
            />

            <Components.GenericStaticField
              size={3}
              label="CPF:"
              value={cpf}
            />

            <Components.GenericStaticField
              size={3}
              label="Data de Nascimento:"
              value={birthday}
            />

          </Column.Group>

          <Column.Group multiline>

            <Components.GenericStaticField
              size={3}
              label="CEP:"
              value={cep}
            />

            <Components.GenericStaticField
              size={7}
              label="Logradouro:"
              value={street}
            />

            <Components.GenericStaticField
              size={2}
              label="Número:"
              value={adressNumber}
            />

            <Components.GenericStaticField
              size={3}
              label="Complemento:"
              value={complement}
            />

            <Components.GenericStaticField
              size={3}
              label="Bairro:"
              value={neighborhood}
            />

            <Components.GenericStaticField
              size={5}
              label="Cidade:"
              value={city}
            />
            
            <Components.GenericStaticField
              size={1}
              label="UF:"
              value={uf}
            />

            {user.isProfessional &&

            <Fragment>

                <Components.GenericStaticField
                  size={5}
                  label="Título profissional:"
                  value={professionalTitle}
                />

                <Components.GenericStaticField
                  size={3}
                  label="Conselho prof.:"
                  value={councilName}
                />

                <Components.GenericStaticField
                  size={4}
                  label="Registro:"
                  value={councilRegister}
                />

            </Fragment>
            }

            <Components.GenericStaticField
              size={3}
              label="Cadastrado em:"
              value={createdAt}
            />

            <Components.GenericStaticField
              size={3}
              label="Último acesso:"
              value={lastAccess}
            />

          </Column.Group>

          <hr className="form-divider" />

          <Column.Group multiline>

            <Components.Switch
              label="Situação:"
              onChange={handleIsActiveChange}
              checked={isActive}
              setFormChanged={setFormChanged}
            />

            <Components.CheckboxContainer
              notNarrow={true}
              required={false}
              label="Níveis de acesso:"
            >
              {JSON.parse(localStorage.getItem('user')).isSystemAdm &&
                <Components.Checkbox key={0} color="dark" checked={isSystemAdm} onChange={setIsSystemAdm} setFormChanged={setFormChanged} >
                  Administrador do sistema
                </Components.Checkbox>
              }

              <Components.Checkbox key={1} color="info" checked={isCompanyAdm} onChange={setIsCompanyAdm} setFormChanged={setFormChanged} >
                Administrador da empresa
              </Components.Checkbox>

              <Components.Checkbox key={2} color="primary" checked={isProfessional} onChange={setIsProfessional} setFormChanged={setFormChanged} >
                Profissional de Saúde
              </Components.Checkbox>
              
              <Components.Checkbox key={3} color="success" checked={isSecretary} onChange={setIsSecretary} setFormChanged={setFormChanged} >
                Secretário
              </Components.Checkbox>

            </Components.CheckboxContainer>

          </Column.Group>

          <Components.SubmitButton icon={faEdit}>
            Atualizar
          </Components.SubmitButton>

        </form>

      </Section>

    </Section>
  )
}

export default EditUser;