import Api from "./api"

const DiskSpaceService = {

  diskSpace: async (params) => {
    const response = await Api.post("/diskSpace/getDiskSpace", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default DiskSpaceService