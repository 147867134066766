import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { Section, Button, Icon, Column } from "rbx"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import AttendancePackagesService from "../../../../services/attendancePackages"
import AttendancePackageTemplatesService from "../../../../services/attendancePackageTemplates"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { faCog, faBoxOpen, faPlus } from '@fortawesome/free-solid-svg-icons'


const AttendancePackages = (props) => {

  const [attendancePackages, setAttendancePackages] = useState(null)

  const [packageTemplates, setPackageTemplates] = useState([])
  const [chosenPackageTemplate, setChosenPackageTemplate] = useState(null)
  const [openNewAttendancePackage, setOpenNewAttendancePackage] = useState(false)
  const [openPackageTemplatesToChoose, setOpenPackageTemplatesToChoose] = useState(false)
  const [openManageAttendancePackageTemplates, setOpenManageAttendancePackageTemplates] = useState(false)

  const [attendancePackageName, setAttendancePackageName] = useState("")
  const [patient, setPatient] = useState("")

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = React.useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  useEffect( () => {
    const eventListener = (event) => {
      event.preventDefault()
      if (formChangedRef.current) {
        let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }
    window.addEventListener('beforeunload', eventListener)
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    }
  }, [])

  const getPackageTemplates = async () => {
    setPackageTemplates([])
    try {
      setLoading(true)
      await AttendancePackageTemplatesService.getAttendancePackageTemplates()
      .then( (response) => {
        setPackageTemplates(response.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      try {
        if (error.response.status == 401) {
          setLoading(false)
          setNotification({
            success: false,
            text: "Erro de autenticação. Você será redirecionado para a tela de login",
            callback: ( () => {
              setNotification(false)
              UsersService.logout()
              setRedirectToLogin(true)
            })
          })
        } else {
          setLoading(false)
          setNotification({
            success: false,
            text: error.response.data,
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      } catch {          
        setLoading(false)
        setNotification({
          success: false,
          text: "Não foi possível carregar as informações. Tente novamente",
          callback: ( () => {
            setNotification(false)
          })
        })
      }
    }
  }

  const getAttendancePackages = async (event) => {
    event.preventDefault()
    let submitInfo = {
      event: event,
      setNotification: setNotification,
      setLoading: setLoading,
      setOpenComponent: null,
      mandatoryFields: [],
      errorList: [],
      ServiceMethod: AttendancePackagesService.searchAttendancePackages,
      params: {
        attendancePackageName,
        patient: patient,
      },
      noSuccessNotification: true,
      onSuccessText: null,
      onErrorText: "Não foi possível pesquisar os pacotes de atendimentos. Tente novamente",
      onSuccessCallback: (response) => {
        setAttendancePackages(response.data)
      },
      onErrorCallback: null,
      setRedirectToLogin: setRedirectToLogin,
    }
    methods.handleSubmit(submitInfo)
  }

  useEffect( () => {
    getPackageTemplates()
  }, [])

  const getAttendancesOfOnePackage = async (attendancePackageId) => {
    try {
      setLoading(true)
      return await AttendancePackagesService.getAttendancesOfOnePackage({ attendancePackageId: attendancePackageId})
      .then( (response) => {
        return response.data
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      try {
        if (error.response.status == 401) {
          setLoading(false)
          setNotification({
            success: false,
            text: "Erro de autenticação. Você será redirecionado para a tela de login",
            callback: ( () => {
              setNotification(false)
              UsersService.logout()
              setRedirectToLogin(true)
            })
          })
        } else {
          setLoading(false)
          setNotification({
            success: false,
            text: error.response.data,
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      } catch {          
        setLoading(false)
        setNotification({
          success: false,
          text: "Não foi possível carregar as informações. Tente novamente",
          callback: ( () => {
            setNotification(false)
          })
        })
      }
    }
  }

  useEffect( () => {
    setOpenPackageTemplatesToChoose(false)
  }, [openNewAttendancePackage])

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle >
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faBoxOpen} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="is-hidden-touch mr-5">Pacotes de Atendimentos</span>
            <span className="is-hidden-desktop mr-5">Pacotes de Atend.</span>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Components.ToogleButton
              color="success"
              icon={faPlus}
              setOpen={setOpenPackageTemplatesToChoose}
            >
              Novo
            </Components.ToogleButton>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Components.ToogleButton
              color="primary"
              icon={faCog}
              setOpen={setOpenManageAttendancePackageTemplates}
            >
              Gerenciar Modelos
            </Components.ToogleButton>
          </Column>
        </Column.Group>
      </WorkspaceTitle>

      <form onSubmit={getAttendancePackages}  >

        <Components.SearchFiltersCard>

          <Column.Group multiline>

            <Components.GenericInput
              required={false}
              elementName="attendance-package-name"
              value={attendancePackageName}
              setValue={setAttendancePackageName}
            >
              Nome do Pacote:
            </Components.GenericInput>

            <Components.GenericInput
              required={false}
              elementName="patient-name"
              value={patient}
              setValue={setPatient}
            >
              Paciente:
            </Components.GenericInput>

          </Column.Group>

        </Components.SearchFiltersCard>

      </form>

      {attendancePackages &&
        <Components.SearchAttendancePackagesResults
          attendancePackages={attendancePackages}
          getAttendancePackages={getAttendancePackages}
          getAttendancesOfOnePackage={getAttendancesOfOnePackage}
          setObjectToEdit={props.setObjectToEdit}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

      {openPackageTemplatesToChoose &&
        <Components.PackageTemplatesToChoose
          packageTemplates={packageTemplates}
          setChosenPackageTemplate={setChosenPackageTemplate}
          setOpenPackageTemplatesToChoose={setOpenPackageTemplatesToChoose}
          setOpenNewAttendancePackage={setOpenNewAttendancePackage}
          setFormChanged={setFormChanged}
          setNotification={setNotification}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

      {openNewAttendancePackage &&
        <Components.NewAttendancePackage
          chosenPackageTemplate={chosenPackageTemplate}
          setOpenNewAttendancePackage={setOpenNewAttendancePackage}
          setFormChanged={setFormChanged}
          setNotification={setNotification}
          setLoading={setLoading}
          redirectToLogin={redirectToLogin}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

      {openManageAttendancePackageTemplates &&
        <Components.ManageAttendancePackageTemplates
          packageTemplates={packageTemplates}
          setPackageTemplates={setPackageTemplates}
          setOpenManageAttendancePackageTemplates={setOpenManageAttendancePackageTemplates}
          getPackageTemplates={getPackageTemplates}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

    </Section>
  )
}

export default AttendancePackages;