import React, { Fragment } from "react"
import "./App.scss"
import Routes from "./routes"

function App () {
  return (
    <Fragment>
      <Routes/>
    </Fragment>
  )
}

export default App;