import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { Section, Column, Button, Icon } from "rbx"
import Components, {methods} from "../methods-and-components/methods-and-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import AttendancePackageTemplatesService from "../../../../services/attendancePackageTemplates"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { faPlus, faCog } from '@fortawesome/free-solid-svg-icons'

const ManageAttendancePackageTemplates = (props) => {

  const [packageTemplates, setPackageTemplates] = useState([])
  const [openNewPackageTemplate, setOpenNewPackageTemplate] = useState(false)
  const [openEditPackageTemplate, setOpenEditPackageTemplate] = useState(false)

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = React.useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  useEffect( () => {
    const eventListener = (event) => {
      event.preventDefault()
      if (formChangedRef.current) {
        let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }
    window.addEventListener('beforeunload', eventListener)
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    }
  }, [])

  const getPackageTemplates = async () => {
    setLoading(true)
    setPackageTemplates([])
    try {
      await AttendancePackageTemplatesService.getAttendancePackageTemplates()
      .then( (response) => {
        setPackageTemplates(response.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      try {
        if (error.response.status == 401) {
          setLoading(false)
          setNotification({
            success: false,
            text: "Erro de autenticação. Você será redirecionado para a tela de login",
            callback: ( () => {
              setNotification(false)
              UsersService.logout()
              setRedirectToLogin(true)
            })
          })
        } else {
          setLoading(false)
          setNotification({
            success: false,
            text: error.response.data,
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      } catch {          
        setLoading(false)
        setNotification({
          success: false,
          text: "Não foi possível carregar as informações. Tente novamente",
          callback: ( () => {
            setNotification(false)
          })
        })
      }
    }
  }

  useEffect( () => {
    getPackageTemplates()
  }, [])

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle>
        <Column.Group vcentered breakpoint="mobile" className="mb-0" >
          <Column narrow className="py-0 pr-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faCog} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Gerenciar Modelos de Pacote</span>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Components.ToogleButton
              color="success"
              icon={faPlus}
              setOpen={setOpenNewPackageTemplate} // Inserir um setState para mudar um estado para que abra um popup em forma de card para adicionar um modelo
            >
              Novo
            </Components.ToogleButton>
          </Column>          
        </Column.Group>
      </WorkspaceTitle>

      <Components.PackageTemplateResults
        packageTemplates={packageTemplates}
        setOpenEditPackageTemplate={setOpenEditPackageTemplate}
        getPackageTemplates={getPackageTemplates}
        setFormChanged={setFormChanged}
        setNotification={setNotification}
        setLoading={setLoading}
        setRedirectToLogin={setRedirectToLogin}
      />

      {openNewPackageTemplate &&
        <Components.NewPackageTemplate
          setOpenNewPackageTemplate={setOpenNewPackageTemplate}
          getPackageTemplates={getPackageTemplates}
          setFormChanged={setFormChanged}
          setNotification={setNotification}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

      {openEditPackageTemplate &&
        <Components.EditPackageTemplate
          openEditPackageTemplate={openEditPackageTemplate}
          setOpenEditPackageTemplate={setOpenEditPackageTemplate}
          getPackageTemplates={getPackageTemplates}
          setFormChanged={setFormChanged}
          setNotification={setNotification}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

    </Section>
  )
}

export default ManageAttendancePackageTemplates;