import React from 'react';
import { Column, Section, Title, Icon } from "rbx"
import WorkspaceTitle from "./title"
import "../../../styles/workspace.scss"
import "../../../styles/loading.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench } from '@fortawesome/free-solid-svg-icons'

const Constructing = (props) => {
  return (
    <Section className="workspace-section">
      <WorkspaceTitle>
        Em construção
      </WorkspaceTitle>

      <Section className="workspace-content">

        <Column.Group centered breakpoint="mobile" textAlign="centered">
          <Column size={8} className="pt-6" >
            <Icon>
              <FontAwesomeIcon size="6x" icon={faWrench}/>
            </Icon>
          </Column>
        </Column.Group>

        <Column.Group>
          <Column size={12}>
            <Title size={1} className="has-text-custom-green-dark has-text-centered">
              Em construção
            </Title>
          </Column>
        </Column.Group>

      </Section>

    </Section>
  )
}

export default Constructing;