import React, { Fragment, useState, useEffect } from 'react';
import { Button, Field, Control, Input, Column, Help, Generic, Title } from "rbx";
import { Link, useParams } from "react-router-dom";
import LoadingAnimation from "../components/loading-animation"
import UsersService from "../../services/users"
import "../../styles/login-form.scss"

function ResetPasswordForm(props) {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [codigoErro, setCodigoErro] = useState("")
  const { token } = useParams()

  const HandleSubmit = async (event) => {
    if (password != passwordConfirm) {
      event.preventDefault()
      setCodigoErro("As senhas digitadas devem ser iguais.")
    } else {
      event.preventDefault()
      setLoading(true)
      await UsersService.resetPassword({ token: token, password: password })
      .then( () => {
        setPasswordChanged(true)
      })
      .catch(function (error) {
        try {
          setLoading(false)
          setCodigoErro(error.response.data)
        } catch {
          setLoading(false)
          setCodigoErro("Erro de conexão. Tente novamente.")
        }
      })
    }
  }

  if (passwordChanged) {
    return (
      <Column.Group centered>
        <Column size={12} className="pb-0">
          <Title subtitle size={6} className="has-text-success has-text-centered">
            Senha alterada com sucesso! 
          </Title>
          <Generic textAlign="centered">
            <Link to="/login" >
              <Button color="info" outlined>
                <b>Ir para tela de login</b>
              </Button>
              </Link>
          </Generic>
        </Column>
      </Column.Group>
    )
  }

  if (loading) {
    return (
      <Column.Group centered>
        <Column size={12} className="pb-0">
          <LoadingAnimation loaderClass="login-loader" />
        </Column>        
      </Column.Group>
    )
  }

  return (
    <Fragment>
      <Column.Group centered>
        <Column size={12} className="pb-0">
          <form onSubmit={HandleSubmit}>
            <Title subtitle size={6} className="has-text-grey-dark">
              Para reinicializar, insira a nova senha:
            </Title>

            <Field>
              <Control>
                <Input 
                  type="password"
                  autoComplete="new-password"
                  id="password"
                  placeholder="Nova senha:"
                  value={password}
                  onChange={e => setPassword(e.target.value)}                  
                  required
                  name="new-password"
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <Input 
                  type="password"
                  autoComplete="new-password"
                  id="passwordConfirm"
                  placeholder="Confirme a nova senha:"
                  value={passwordConfirm}
                  onChange={e => setPasswordConfirm(e.target.value)}
                  required
                  name="new-password"
                />
              </Control>
            </Field>
            
            <Help color="danger" className="pb-3">{codigoErro}</Help>
              
            <Field>
              <Button.Group align="centered">
                <Button color="info" align="centered" size="medium" className="has-text-weight-semibold">
                  Enviar
                </Button>
              </Button.Group>
            </Field>
          </form>

          <Generic textAlign="centered" className="pt-3">
            <Link to="/login" >
              <Button text textColor="grey">
                Ir para tela de login
              </Button>
            </Link>
          </Generic>

        </Column>
        
      </Column.Group>
    </Fragment>
  )
}

export default ResetPasswordForm;