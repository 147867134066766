import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Link } from "react-router-dom";
import { Column, Section, Title, Container, Card, Hero } from "rbx"
import logoImage from "../../assets/images/logo-512.png"

const NotFoundView = () => {

  return (
    <Section>
      <Title size="2" className="has-text-info has-text-centered">
        404: Página Não Encontrada
      </Title>
      <Link to="/">
        <Title size={3} className="has-text-link has-text-centered">
          <u>Voltar ao início</u>
        </Title>
      </Link>

      <Section>
        <Column.Group centered breakpoint="mobile" textAlign="centered">
          <Column size={6} >
            <img src={logoImage} alt="logo"/>
          </Column>
        </Column.Group>

        <Column.Group>
          <Column size={12}>
            <Title size={1} className="has-text-custom-green-dark has-text-centered">
              CliniWeb
            </Title>
            <Title subtitle size={6} className="has-text-grey has-text-centered">
              Gestão descomplicada
            </Title>
          </Column>
        </Column.Group>
      </Section>

    </Section>
  )};

export default NotFoundView;