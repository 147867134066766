import Api from "./api"

const CompaniesService = {

  newCompany: async (params) => {
    const response = await Api.post("/companies/newCompany", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
          "Content-Type": `multipart/form-data; boundary=${params._boundary}`,
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
  },
  
  editCompany: async (params) => {
    let response = await Api.put("/companies/editCompany", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged"),
      "Content-Type": `multipart/form-data; boundary=${params._boundary}`,
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },  

  getLogo: async (params) => {
    let response = await Api.post("/companies/getLogo", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  search: async (params) => {
    let response = await Api.post("/companies/search", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  searchMyCompany: async (params) => {
    let response = await Api.post("/companies/searchMyCompany", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  searchByName: async (params) => {
    let response = await Api.post("/companies/searchByName", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default CompaniesService