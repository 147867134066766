import React, { Fragment, useState, useEffect } from 'react';
import { Column, Section, Title, Container, Card, Menu } from "rbx"
import logoImage from "../../../assets/images/logo-512.png"
import WorkspaceTitle from "./title"

const Home = () => {

  return (
    <Section>
      <WorkspaceTitle>
        Bem-vindo!
      </WorkspaceTitle>
      
      <Section className="workspace-content">

        <Column.Group>
          <Column size={12}>
            <Title size={1} className="has-text-custom-green-dark has-text-centered">
              CliniWeb
            </Title>
            <Title subtitle size={6} className="has-text-grey has-text-centered">
              Gestão descomplicada
            </Title>
          </Column>
        </Column.Group>

      </Section>
      
    </Section>
  )
}

export default Home;