import Api from "./api"

const AttendancePackageTemplatesService = {

  newAttendancePackageTemplate: async (params) => {
    const response = await Api.post("/attendancePackageTemplates/newAttendancePackageTemplate", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },
  
  editAttendancePackageTemplate: async (params) => {
    let response = await Api.put("/attendancePackageTemplates/editAttendancePackageTemplate", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getAttendancePackageTemplates: async (params) => {
    let response = await Api.post("/attendancePackageTemplates/getAttendancePackageTemplates", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteAttendancePackageTemplate: async (params) => {
    let response = await Api.post("/attendancePackageTemplates/deleteAttendancePackageTemplate", params,
    {
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default AttendancePackageTemplatesService