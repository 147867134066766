import React, { Fragment } from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom"
import Home from "./home"
import MyAccount from "./my-account"
import ChangePassword from "./change-password"
import Calendar from './calendar';
import Patient from './patient';
import EditPatient from './patient/edit-patient';
import Attendances from './attendance';
import EditAttendance from './attendance/edit-attendance';
import AttendancePackages from './attendance-package';
import EditAttendancePackage from './attendance-package/edit-attendance-package';
import ManageAttendancePackageTemplates from './attendance-package/manage-attendance-package-templates';
import DocumentTemplates from './document-template';
import Users from './user';
import EditUser from './user/edit-user';
import EditMyCompany from './my-company/edit-my-company';
import Companies from "./company";
import EditCompany from './edit-company';
import Finances from './finance';
import Constructing from "./constructing"

const Workspace = (props) => {
  let { path } = useRouteMatch()

  const focusedItem = props.focusedItem
  const setFocusedItem = props.setFocusedItem
  const logoImage = props.logoImage
  const hasUnsavedChanges = props.hasUnsavedChanges
  const setHasUnsavedChanges = props.setHasUnsavedChanges
  const setNotification = props.setNotification
  
  return (
    <Switch>

      <Route exact path={path}>
        <Home />
      </Route>

      <Route path={`${path}/my-account`}>
        <MyAccount changeHeaderUserName={props.changeHeaderUserName} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/change-password`}>
        <ChangePassword hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/calendar`}>
        <Calendar objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/patient`}>
        <Patient setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/edit-patient`}>
        <EditPatient objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} focusedItem={focusedItem} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} logoImage={logoImage} />
      </Route>

      <Route path={`${path}/attendance`}>
        <Attendances objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/edit-attendance`}>
        <EditAttendance objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} focusedItem={focusedItem} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} logoImage={logoImage} />
      </Route>

      <Route path={`${path}/attendance-package`}>
        <AttendancePackages objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route> 

      <Route path={`${path}/edit-attendance-package`}>
        <EditAttendancePackage objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/manage-attendance-package-templates`}>
        <ManageAttendancePackageTemplates objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/document-template`}>
        <DocumentTemplates objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} focusedItem={focusedItem} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} logoImage={logoImage} />
      </Route>

      <Route path={`${path}/finance`}>
        <Finances objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/user`}>
        <Users objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/edit-user`}>
        <EditUser objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/company`}>
        <EditMyCompany objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} logoImage={props.logoImage} setLogoImage={props.setLogoImage} setTradingName={props.setTradingName} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/companies`}>
        <Companies objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route path={`${path}/edit-company`}>
        <EditCompany objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
      </Route>

      <Route>
        <Constructing />
      </Route>

    </Switch>
  )
}

export default Workspace;