import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { Column, Section, Field, Label, Control, Input, Button, Radio, Select, Icon } from "rbx"
import Components, { methods } from "./methods-and-components/methods-and-components"
import LoadingOverlay from "../loading-overlay"
import NotificationOverlay from "../notification-overlay"
import WorkspaceTitle from "./title"
import UsersService from "../../../services/users"
import "../../../styles/workspace.scss"
import "../../../styles/loading.scss"
import { ufList } from "../../../constants"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faExclamationTriangle, faUserEdit } from '@fortawesome/free-solid-svg-icons'

const MyAccount = (props) => {
  const [userIsProfessional, setUserIsProfessional] = useState(false)

  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(null)

  const [email, setEmail] = useState("")

  const [gender, setGender] = useState("")

  const [rgIdentifier, setRgIdentifier] = useState("")
  const [rgIdentifierError, setRgIdentifierError] = useState(null)
  const [rgDigit, setRgDigit] = useState("")
  const [rgUf, setRgUf] = useState("")

  const [cpf, setCpf] = useState("")
  const [cpfError, setCpfError] = useState(null)

  const [birthday, setBirthday] = useState("")
  const [birthdayError, setBirthdayError] = useState(null)

  const [areaCode, setAreaCode] = useState("")
  const [areaCodeError, setAreaCodeError] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberError, setPhoneNumberError] = useState(null)

  const [cep, setCep] = useState("")
  const [cepError, setCepError] = useState(null)
  const [street, setStreet] = useState("")
  const [adressNumber, setAdressNumber] = useState("")
  const [complement, setComplement] = useState("")
  const [neighborhood, setNeighborhood] = useState("")
  const [city, setCity] = useState("")
  const [uf, setUf] = useState("")

  const [professionalTitle, setProfessionalTitle] = useState("")
  const [councilName, setCouncilName] = useState("")
  const [councilRegister, setCouncilRegister] = useState("")
  
  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = React.useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  let history = useHistory();

  const mandatoryFields = [
    name
  ]

  const errorList = [
    nameError,
    cpfError,
    birthdayError,
    areaCodeError,
    phoneNumberError,
    cepError,
  ]

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
      setNotification({
        success: false,
        text: "Favor preencher todos os campos obrigatórios",
        callback: () => {
          setNotification(false)
        }
      })
    } else if (methods.hasErrors(errorList)) {
      setNotification({
        success: false,
        text: methods.errorsToShow(errorList),
        callback: () => {
          setNotification(false)
        }
      })
    } else {
      setLoading(true)
      let userInfoToUpdate = {
        name: name,
        email: email,
        areaCode: areaCode.replace(/\D/g,""),
        phoneNumber: phoneNumber.replace(/\D/g,""),
        gender: gender,
        rgIdentifier: rgIdentifier,
        rgDigit: rgDigit,
        rgUf: rgUf,
        cpf: cpf.replace(/\D/g,""),
        birthday: methods.stringToDate(birthday),
        cep: cep.replace(/\D/g,""),
        street: street,
        adressNumber: adressNumber,
        complement: complement,
        neighborhood: neighborhood,
        city: city,
        uf: uf,
        professionalTitle: professionalTitle,
        councilName: councilName,
        councilRegister: councilRegister,
      }
      await UsersService.editMyInfo(userInfoToUpdate)
      .then( () => {      
        setLoading(false)
        setNotification({
          success: true,
          text: "Informações atualizadas",
          callback: () => {
            setNotification(false)
            setFormChanged(false)
            history.push("/app/home")
          }
        })
        props.changeHeaderUserName(name)
      })
      .catch(function (error) {
        console.log(error)
        try {
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setLoading(false)
            setNotification({
              success: false,
              text: "Não foi possível atualizar as informações. Tente novamente",
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {          
          setLoading(false)
          setNotification({
            success: false,
            text: "Não foi possível atualizar as informações. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      })
    }
  }

  useEffect(()=>{
    const eventListener = (event) => {
      event.preventDefault()
      if (formChangedRef.current) {
        let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }
    window.addEventListener('beforeunload', eventListener)
    try {
      const user = JSON.parse(localStorage.getItem("user"))
      if (user.isProfessional) {
        setUserIsProfessional(user.isProfessional)
      }
      setName(user.name)
      setEmail(user.email)
      methods.maskAndSetAreaCode(user.areaCode, setAreaCode, setAreaCodeError)
      methods.maskAndSetPhoneNumber(user.phoneNumber, setPhoneNumber, setPhoneNumberError)
      setGender(user.gender)
      setRgIdentifier(user.rgIdentifier)
      setRgDigit(user.rgDigit)
      setRgUf(user.rgUf)
      methods.maskAndSetCpf(user.cpf, setCpf, setCpfError)
      methods.dateToString(user.birthday, setBirthday)
      methods.maskAndSetCep(user.cep, setCep, setCepError)
      setStreet(user.street)
      setAdressNumber(user.adressNumber)
      setComplement(user.complement)
      setNeighborhood(user.neighborhood)
      setCity(user.city)
      setUf(user.uf)
      setProfessionalTitle(user.professionalTitle)
      setCouncilName(user.councilName)
      setCouncilRegister(user.councilRegister)
      setFormChanged(false)
    } catch (error) {
      console.log(error)
    }
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, [])

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle icon={faUserEdit}>
        Minha conta
      </WorkspaceTitle>

      <Section className="workspace-content">

        <form onSubmit={handleSubmit} >

          <Column.Group multiline>

            <Components.UserName
              required={true}
              name={name}
              nameError={nameError}
              setName={setName}
              setNameError={setNameError}
              setFormChanged={setFormChanged}
            />

            <Components.EmailStatic
              value={email}
            />

            <Components.Phone
              required={false}
              areaCode={areaCode}
              areaCodeError={areaCodeError}
              setAreaCode={setAreaCode}
              setAreaCodeError={setAreaCodeError}
              phoneNumber={phoneNumber}
              phoneNumberError={phoneNumberError}
              setPhoneNumber={setPhoneNumber}
              setPhoneNumberError={setPhoneNumberError}
              setFormChanged={setFormChanged}
            />

            <Components.Gender
              gender={gender}
              setGender={setGender}
            />

            <Components.Rg
              required={false}
              rgIdentifier={rgIdentifier}
              rgIdentifierError={rgIdentifierError}
              setRgIdentifier={setRgIdentifier}
              setRgIdentifierError={setRgIdentifierError}
              rgDigit={rgDigit}
              setRgDigit={setRgDigit}
              rgDigit={rgDigit}
              setRgDigit={setRgDigit}
              rgUf={rgUf}
              setRgUf={setRgUf}
              setFormChanged={setFormChanged}
            />

            <Components.Cpf
              required={false}
              cpf={cpf}
              cpfError={cpfError}
              setCpf={setCpf}
              setCpfError={setCpfError}
              setFormChanged={setFormChanged}
            />

            <Components.Date
              required={false}
              label="Data de Nascimento:"
              elementName="birthday"
              date={birthday}
              dateError={birthdayError}
              setDate={setBirthday}
              setDateError={setBirthdayError}
              setFormChanged={setFormChanged}
            />

          </Column.Group>

          <Column.Group multiline>

            <Components.Cep
              required={false}
              cep={cep}
              setCep={setCep}
              cepError={cepError}
              setCepError={setCepError}
              setStreet={setStreet}
              setAdressNumber={setAdressNumber}
              setComplement={setComplement}
              setNeighborhood={setNeighborhood}
              setCity={setCity}
              setUf={setUf}
              setLoading={setLoading}
              setFormChanged={setFormChanged}
            />

            <Components.Street
              required={false}
              street={street}
              setStreet={setStreet}
              setFormChanged={setFormChanged}
            />

            <Components.AdressNumber
              required={false}
              adressNumber={adressNumber}
              setAdressNumber={setAdressNumber}
              setFormChanged={setFormChanged}
            />

            <Components.Complement
              required={false}
              complement={complement}
              setComplement={setComplement}
              setFormChanged={setFormChanged}
            />

            <Components.Neighborhood
              required={false}
              neighborhood={neighborhood}
              setNeighborhood={setNeighborhood}
              setFormChanged={setFormChanged}
            />

            <Components.CityAndUf
              required={false}
              city={city}
              setCity={setCity}
              uf={uf}
              setUf={setUf}
              setFormChanged={setFormChanged}
            />

          </Column.Group>

          {userIsProfessional &&
            <Fragment>

              <hr className="form-divider" />

              <Column.Group multiline>

                <Components.ProfessionalTitle
                  required={false}
                  professionalTitle={professionalTitle}
                  setProfessionalTitle={setProfessionalTitle}
                  setFormChanged={setFormChanged}
                />

                <Components.CouncilName
                  required={false}
                  councilName={councilName}
                  setCouncilName={setCouncilName}
                  setFormChanged={setFormChanged}
                />

                <Components.CouncilRegister
                  required={false}
                  councilRegister={councilRegister}
                  setCouncilRegister={setCouncilRegister}
                  setFormChanged={setFormChanged}
                />

              </Column.Group>
            </Fragment>
          }

          <Components.SubmitButton icon={faEdit}>
            Atualizar
          </Components.SubmitButton>

        </form>

      </Section>

    </Section>
  )
}

export default MyAccount;