import React, { Fragment, useState, useEffect } from 'react';
import { Button, Field, Control, Input, Column, Help, Label, Block, Generic, Checkbox, Title, Icon } from "rbx";
import { Link, Redirect } from "react-router-dom";
import LoadingAnimation from "../components/loading-animation"
import UsersService from "../../services/users"
import "../../styles/login-form.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faExclamationTriangle, faLock } from '@fortawesome/free-solid-svg-icons'

function LoginForm(props) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [redirectToHome, setRedirectToHome] = useState(false)
  const [loading, setLoading] = useState(false);
  const [codigoErro, setCodigoErro] = useState("")
  const [codigoErroEmail, setCodigoErroEmail] = useState("")
  const [keepLogged, setKeepLogged] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)

  const [emailSent, setEmailSent] = useState(false)

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    await UsersService.login({email: email, password: password, keepLogged: keepLogged})
    .then( () => {
      setRedirectToHome(true)
    })
    .catch(function (error) {
      try {
        setLoading(false)
        setCodigoErro(error.response.data.error)
      } catch {
        setLoading(false)
        setCodigoErro("Erro de conexão. Tente novamente.")
      }    
    })
  }

  const handleForgotPassword = async (event) => {
    setLoading(true)
    event.preventDefault()
    await UsersService.forgotPassword({ email: email })
    .then( () => {
      setLoading(false)
      setEmailSent(true)
    })
    .catch(function (error) {
      try {
        setLoading(false)
        setCodigoErroEmail(error.response.data.error)
      } catch {
        setLoading(false)
        setCodigoErroEmail("Erro de conexão com servidor. Tente novamente mais tarde.")
      }    
    })
  }

  useEffect( () => {
    if (props.autoLoginError) {
     setCodigoErro(props.autoLoginError)
    }
  }, [])

  if (redirectToHome)
    return <Redirect to={{pathname: "/app"}}/>

  if (props.loading || loading) {
    return (
      <Column.Group centered>
        <Column size={12} className="pb-0">
          <LoadingAnimation loaderClass="login-loader" />
        </Column>
      </Column.Group>
    )
  }

  return (
    <Fragment>
      <Column.Group centered>
        <Column size={12} className="pb-0">

          {!forgotPassword &&
            <Fragment>
              <form onSubmit={handleSubmit}>
                <Field>
                  <Control iconLeft>
                    <Input 
                      type="username"
                      autoComplete="username"
                      placeholder="E-mail:"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                      name="email"
                    />
                    <Icon size="small" align="left">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Icon>
                  </Control>
                </Field>
                <Field>
                  <Control iconLeft>
                    <Input 
                      type="password"
                      autoComplete="password"
                      placeholder="Senha:"
                      value={password}
                      onChange={e => setPassword(e.target.value)}                  
                      required
                      name="password"
                    />
                    <Icon size="small" align="left">
                      <FontAwesomeIcon icon={faLock} />
                    </Icon>
                  </Control>
                </Field>

                <Field className="mb-0">
                  <Control>
                    <Label className="checkbox-container">
                      <Checkbox
                        type="checkbox"
                        checked={keepLogged}
                        onChange={e => setKeepLogged(e.target.checked) }
                        name="keepLogged"
                      />
                      <Button as="div" text className="is-size-6 has-text-info-dark has-text-weight-normal" style={{ paddingLeft: "2.25rem" }} >
                        <span className="checkmark"/>
                        <span>Mantenha-me conectado</span>     
                      </Button>

                    </Label>
                  </Control>
                </Field>

                <Button.Group className="no-margin-buttons" align="centered">
                  <Button as="div" size="small" color="danger" className={codigoErro == "" && "is-invisible"}>
                    <Icon size="small" align="left">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </Icon>
                    <span>{codigoErro}</span>
                  </Button>
                </Button.Group>
                  
                <Field>
                  <Button.Group align="centered">
                    <Button color="info" size="medium" className="has-text-weight-semibold">
                      Entrar
                    </Button>
                  </Button.Group>
                </Field>
              </form>
              <Generic textAlign="centered" className="pt-2">
                <Button text textColor="grey"
                  onClick={() => setForgotPassword(true)}
                >
                  Esqueci minha senha
                </Button>
              </Generic>
            </Fragment>

            ||

            <Fragment>
              <Column.Group centered>
                <Column size={12} className="pb-0"> 

                  {!emailSent &&
                    <form onSubmit={handleForgotPassword}>
                      <Title subtitle size={6} className="has-text-grey-dark">
                        Informe o e-mail da conta que deseja reinicializar a senha:
                      </Title>
                      <Field>
                        <Control>
                          <Input 
                            type="email"
                            id="email"
                            placeholder="E-mail:"
                            value={email}
                            onChange={e => setEmail(e.target.value)}                  
                            required
                            name="email"
                          />
                        </Control>
                      </Field>
                      
                      <Help color="danger" className="pb-3">{codigoErroEmail}</Help>
                        
                      <Field>
                        <Button.Group align="centered">
                          <Button color="info" align="centered" size="medium" className="has-text-weight-semibold">
                            Enviar
                          </Button>
                        </Button.Group>
                      </Field>
                    </form>
                    ||
                    <Title subtitle size={6} className="has-text-success has-text-centered">
                      Um e-mail com as instruções para reinicialização de senha foi enviado para <b>{email}</b>. 
                    </Title>
                  }

                  <Generic textAlign="centered" className="pt-5 pb-6">
                    <Button color="info" outlined
                      onClick={() => {
                        setForgotPassword(false)
                        setEmailSent(false)
                      }}
                    >
                      <b>Voltar para tela de login</b>
                    </Button>
                  </Generic>

                </Column>
                
              </Column.Group>
            </Fragment>        
          }

        </Column>
        
      </Column.Group>
    </Fragment>
  )
}

export default LoginForm;