import React, { Fragment, useState } from 'react';
import "../../styles/loading.scss"
import LoadingAnimation from './loading-animation';


const LoadingOverlay = (props) => (
    <Fragment >
      <div className={!props.loaderClass ? "overlay-index-200" : "app-loading-overlay"}>
        <LoadingAnimation loaderClass={`workspace-loader ${props.loaderClass && props.loaderClass}`} />
      </div>
    </Fragment>
  )

export default LoadingOverlay;