import React, { useState, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section, Dropdown, Label, Button, Icon } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import DocumentTemplatesService from "../../../../services/documentTemplates"
import "../../../../styles/workspace.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faAngleDown, faFileLines } from '@fortawesome/free-solid-svg-icons'

const DocumentTemplates = (props) => {

  const [documentType, setDocumentType] = useState("TODOS")
  const [professional, setProfessional] = useState("")

  const [documentTemplates, setDocumentTemplates] = useState(null)

  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const [openNewDocumentTemplate, setOpenNewDocumentTemplate] = useState(false)
  const [openEditDocumentTemplate, setOpenEditDocumentTemplate] = useState(false)

  const [showSystemTemplates, setShowSystemTemplates] = useState(true)
  const [showOtherProfessionalTemplates, setShowOtherProfessionalTemplates] = useState(true)

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }

  const user = JSON.parse(localStorage.getItem('user'))

  const mandatoryFields = []

  const errorList = []

  const getDocumentTemplates = async (event) => {
    let submitInfo = {
      event: event,
      setNotification: setNotification,
      setLoading: setLoading,
      setOpenComponent: null,
      mandatoryFields: mandatoryFields,
      errorList: errorList,
      ServiceMethod: DocumentTemplatesService.searchDocumentTemplates,
      params: {
        documentType: documentType,
        professional: professional,
        showSystemTemplates: showSystemTemplates,
        showOtherProfessionalTemplates: showOtherProfessionalTemplates,
      },
      noSuccessNotification: true,
      onSuccessText: null,
      onErrorText: "Não foi possível pesquisar os modelos de documentos. Tente novamente",
      onSuccessCallback: (response) => {
        setDocumentTemplates(response.data)
      },
      onErrorCallback: null,
      setRedirectToLogin: setRedirectToLogin,
    }
    methods.handleSubmit(submitInfo)
  }

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle >
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faFileLines} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Modelos de documentos</span>
          </Column>
          {user.isProfessional &&
            <Column narrow className="py-0 pl-1 pr-0" >
              <Components.ToogleButton
                color="success"
                icon={faPlus}
                setOpen={setOpenNewDocumentTemplate}
              >
                Novo
              </Components.ToogleButton>
            </Column>
          }
        </Column.Group>
      </WorkspaceTitle>

      <form onSubmit={getDocumentTemplates} >

        <Components.SearchFiltersCard>

          <Column.Group multiline>

            <Column className="field-column">
              <Label>Tipo de documento:</Label>
              <Dropdown>
                <Dropdown.Trigger>
                  <Button onClick={(e) => e.preventDefault()} >
                    <span>{documentType}</span>
                    <Icon>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Icon>
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Content>
                    <Dropdown.Item onClick={() => {setDocumentType("TODOS")}}>TODOS</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Anamnese")}}>Anamnese</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Questionário")}}>Questionário</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Procedimento")}}>Procedimento</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Diagnóstico")}}>Diagnóstico</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Prognóstico")}}>Prognóstico</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Plano Terapêutico")}}>Plano Terapêutico</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Receituário")}}>Receituário</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Requisição de exames")}}>Requisição de exames</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Atestado")}}>Atestado</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Declaração")}}>Declaração</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Laudo")}}>Laudo</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setDocumentType("Termo de consentimento")}}>Termo de consentimento</Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Menu>
              </Dropdown>
            </Column>

            <Components.GenericInput
              required={false}
              elementName="professional"
              value={professional}
              setValue={setProfessional}
            >
              Profissional (parte do nome):
            </Components.GenericInput>

            <Components.CheckboxContainer
              columnSize="9"
              required={false}
              label="Situação:"
            >

              <Components.Checkbox checked={showSystemTemplates} onChange={setShowSystemTemplates} >
                Mostrar modelos do sistema
              </Components.Checkbox>

              <Components.Checkbox checked={showOtherProfessionalTemplates} onChange={setShowOtherProfessionalTemplates} >
                Mostrar modelos de outros profissionais
              </Components.Checkbox>

            </Components.CheckboxContainer>

          </Column.Group>

        </Components.SearchFiltersCard>

        {documentTemplates && 
          <Components.SearchDocumentTemplatesResults
            documentTemplates={documentTemplates}
            setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
            setObjectToEdit={props.setObjectToEdit}
            setNotification={setNotification}
            setFormChanged={setFormChanged}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </form>

      {openEditDocumentTemplate &&
        <Components.EditDocumentTemplateOverlay
          openEditDocumentTemplate={openEditDocumentTemplate}
          setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
          setDocumentTemplates={setDocumentTemplates}
          setObjectToEdit={props.setObjectToEdit}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

      {openNewDocumentTemplate &&
        <Components.NewDocumentTemplateOverlay
          setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
          setDocumentTemplates={setDocumentTemplates}
          setObjectToEdit={props.setObjectToEdit}
          setNotification={setNotification}
          setFormChanged={setFormChanged}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />
      }

    </Section>
  )
}

export default DocumentTemplates;