import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section, Title, Icon } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import CompaniesService from "../../../../services/companies"
import UsersService from "../../../../services/users"
import "../../../../styles/workspace.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFilter, faSearch, faUser, faPlus } from '@fortawesome/free-solid-svg-icons'

const Users = (props) => {

  const [name, setName] = useState("")

  const [email, setEmail] = useState("")

  const [cpf, setCpf] = useState("")
  const [cpfError, setCpfError] = useState(null)

  const [active, setActive] = useState(true)
  const [notActive, setNotActive] = useState(true)

  const [isSystemAdm, setIsSystemAdm] = useState(true)
  const [isCompanyAdm, setIsCompanyAdm] = useState(true)
  const [isProfessional, setIsProfessional] = useState(true)
  const [isSecretary, setIsSecretary] = useState(true)

  const [companyId, setCompanyId] = useState(null)

  const [city, setCity] = useState("")
  const [uf, setUf] = useState("")

  const [openNewUser, setOpenNewUser] = useState(false)

  const [users, setUsers] = useState(null)

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = React.useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const mandatoryFields = []

  const errorList = [
    cpfError,
  ]

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
      setNotification({
        success: false,
        text: "Favor preencher todos os campos obrigatórios",
        callback: () => {
          setNotification(false)
        }
      })
    } else if (methods.hasErrors(errorList)) {
      setNotification({
        success: false,
        text: methods.errorsToShow(errorList),
        callback: () => {
          setNotification(false)
        }
      })
    } else {
      setLoading(true)
      let searchFields = {
        name: name,
        email: email,
        cpf: cpf.replace(/\D/g,""),
        active: active,
        notActive: notActive,
        isSystemAdm: isSystemAdm,
        isCompanyAdm: isCompanyAdm,
        isProfessional: isProfessional,
        isSecretary: isSecretary,
        companyId: companyId
      }
      await UsersService.search(searchFields)
      .then( (response) => {
        setUsers(response.data)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        try {
          console.log(error.response.data)
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setNotification({
              success: false,
              text: "Não foi possível pesquisar os usuários. Tente novamente",
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {
          setNotification({
            success: false,
            text: "Não foi possível pesquisar os usuários. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      })
    }
  }

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}

      <WorkspaceTitle >
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faUser} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Usuários</span>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Components.ToogleButton
              color="success"
              icon={faPlus}
              setOpen={setOpenNewUser}
            >
              Novo
            </Components.ToogleButton>
          </Column>
        </Column.Group>
      </WorkspaceTitle>

      <form onSubmit={handleSubmit}  >

        <Components.SearchFiltersCard>

          <Column.Group multiline>

            <Components.GenericInput
              required={false}
              elementName="name"
              value={name}
              setValue={setName}
            >
              Nome:
            </Components.GenericInput>

            <Components.GenericInput
              required={false}
              elementName="email"
              value={email}
              setValue={setEmail}
            >
              E-mail:
            </Components.GenericInput>

            <Components.Cpf
              required={false}
              cpf={cpf}
              cpfError={cpfError}
              setCpf={setCpf}
              setCpfError={setCpfError}
            />

            <Components.CheckboxContainer
              columnSize="9"
              required={false}
              label="Situação:"
            >

              <Components.Checkbox key={0} checked={active} onChange={setActive} >
                Mostrar Ativos
              </Components.Checkbox>

              <Components.Checkbox key={1} checked={notActive} onChange={setNotActive} >
                Mostrar Inativos
              </Components.Checkbox>

            </Components.CheckboxContainer>

            <Components.CheckboxContainer
              notNarrow={true}
              required={false}
              label="Níveis de acesso:"
            >
              {JSON.parse(localStorage.getItem('user')).isSystemAdm &&
                <Components.Checkbox key={0} color="dark" checked={isSystemAdm} onChange={setIsSystemAdm} >
                  Adm. do sistema
                </Components.Checkbox>
              }

              <Components.Checkbox key={1} color="info" checked={isCompanyAdm} onChange={setIsCompanyAdm} >
                Adm. da empresa
              </Components.Checkbox>

              <Components.Checkbox key={2} color="primary" checked={isProfessional} onChange={setIsProfessional} >
                Profissional de Saúde
              </Components.Checkbox>
              
              <Components.Checkbox key={3}color="success" checked={isSecretary} onChange={setIsSecretary} >
                Secretário
              </Components.Checkbox>

            </Components.CheckboxContainer>

            {(JSON.parse(localStorage.getItem('user')).isSystemAdm) &&
              <Components.InputWithSearchCompany
                required={false}
                setCompanyId={setCompanyId}
              />
            }

          </Column.Group>

        </Components.SearchFiltersCard>

        {users && 
          <Components.SearchUsersResults
            users={users}
            setObjectToEdit={props.setObjectToEdit}
          />
        }

        {openNewUser &&
          <Components.NewUserOverlay
            setOpenNewUser={setOpenNewUser}
            setObjectToEdit={props.setObjectToEdit}
            setNotification={setNotification}
            setFormChanged={setFormChanged}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

      </form>

    </Section>
  )
}

export default Users;