import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { Section, Button, Title, Icon, Dropdown, Level, Delete, Label, Generic, Column, Card } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import WorkspaceTitle from "../title"
import AttendancesService from "../../../../services/attendances"
import ClinicalExamsService from "../../../../services/clinicalExams"
import { DateTime } from "luxon"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStethoscope, faPlus, faCloudArrowUp, faDownload, faTrash, faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import { faEye, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'


const EditAttendancePackage = (props) => {
  const [attendancePackage, setAttendancePackage] = useState(props.objectToEdit)
  
  const [patient, setPatient] = useState(attendancePackage && attendancePackage.patient)
  const [patientId, setPatientId] = useState(attendancePackage && attendancePackage.patientId)
  const [patientIdError, setPatientIdError] = useState(null)
  const [name, setName] = useState(attendancePackage && attendancePackage.name)
  const [description, setDescription] = useState(attendancePackage && attendancePackage.description)
  const [attendancesQuantity, setAttendancesQuantity] = useState(attendancePackage && attendancePackage.attendancesQuantity)
  const [price, setPrice] = useState(attendancePackage && attendancePackage.price)

  const [paymentInfoToRegister, setPaymentInfoToRegister] = useState(null)

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = React.useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }
  const [loading, setLoading] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const hasUnsavedChanges = props.hasUnsavedChanges
  const setHasUnsavedChanges = props.setHasUnsavedChanges
  const setNotification = props.setNotification

  let history = useHistory();

  useEffect( () => {
    setAttendancePackage(props.objectToEdit)
  }, [paymentInfoToRegister])

  const mandatoryFields = [
    patientId,
    name,
  ]

  let errorList = [
    patientIdError,
  ]

  useEffect( () => {
    const eventListener = (event) => {
      event.preventDefault()
      if (formChangedRef.current) {
        let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }
    window.addEventListener('beforeunload', eventListener)
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    }
  }, [])

  const handleSubmit = async (event) => {
    let submitInfo = {
      event: event,
      setNotification: setNotification,
      setLoading: setLoading,
      setOpenComponent: null,
      mandatoryFields: mandatoryFields,
      errorList: errorList,
      ServiceMethod: AttendancesService.editAttendancePackage,
      params: {
        patientId: patientId,
        name: name,
        description: description,
        attendancesQuantity: attendancesQuantity,
      },
      onSuccessText: "As informações do Pacote de atendimentos foram salvas.",
      onErrorText: "Não foi possível salvar as informações. Tente novamente.",
      onSuccessCallback: async () => { },
      onErrorCallback: null,
      setRedirectToLogin: setRedirectToLogin,
      setHasUnsavedChanges: setHasUnsavedChanges,
    }

    methods.handleSubmit(submitInfo)
  }

  if (redirectToLogin || !attendancePackage) {
    return <Redirect to="/app"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}

      <WorkspaceTitle icon={faBoxOpen} >
        <span>Editar Pacote de Atendimento</span>
      </WorkspaceTitle> 

      <Components.EditAttendancePackage
        attendancePackage={props.objectToEdit}
        setObjectToEdit={props.setObjectToEdit}
        setFormChanged={setFormChanged}
        setNotification={setNotification}
        setLoading={setLoading}
        setRedirectToLogin={setRedirectToLogin}
      />

    </Section>
  )
}

export default EditAttendancePackage