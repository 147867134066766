import React, { Fragment } from 'react';
import { Column, Title, Container, Card, Hero } from "rbx"
import logoImage from "../../assets/images/logo-512.png"
import ResetPasswordForm from "./form"

const ResetPasswordView = () => {
  
  return (
  <Fragment>
    <Hero color="custom-green-dark" size="fullheight">
      <Hero.Body className="pt-3 px-3">
        <Container>
          <Column.Group centered>
            <Column size={4}>
              <Card>
                <Card.Content>
                  
                  <Column.Group centered breakpoint="mobile" textAlign="centered">
                    <Column size={5} >
                      <img src={logoImage} alt="logo"/>
                    </Column>
                  </Column.Group>

                  <Column.Group>
                    <Column size={12} className="pt-0">
                      <Title size={2} className="has-text-custom-green-dark has-text-centered">
                        CliniWeb
                      </Title>
                      <Title subtitle size={6} className="has-text-grey has-text-centered">
                        Gestão de Clínicas descomplicada
                      </Title>
                    </Column>
                  </Column.Group>

                  <ResetPasswordForm/>
                    
                </Card.Content>
              </Card>
            </Column>
          </Column.Group>
        </Container>
      </Hero.Body>
    </Hero>
  </Fragment>
  )};

export default ResetPasswordView;