import Api from "./api"

const EventsService = {

  newEvent: async (params) => {
    const response = await Api.post("/events/newEvent", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
  },
  
  editEvent: async (params) => {
    let response = await Api.put("/events/editEvent", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getEvents: async (params) => {
    let response = await Api.post("/events/getEvents", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteEvent: async (params) => {
    let response = await Api.post("/events/deleteEvent", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  registerEventPayment: async (params) => {
    let response = await Api.post("/events/registerEventPayment", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default EventsService